import { T } from '@/types';

export const BrokenLink = (dto: T.BrokenLinkDto): T.BrokenLink => {
  return {
    compositionId: dto.compositionId,
    compositionStatusDisplayName: dto.compositionStatusDisplayName,
    compositionTitle: dto.compositionTitle,
    id: dto.id,
    text: dto.text,
    type: dto.type,
    url: dto.url,
  };
};
