import { modalMutations } from './mutations';

export type ModalState = { isOpen: boolean; view: string; props: object };

export const modalStore = {
  mutations: modalMutations,
  namespaced: true,
  state: {
    isOpen: false,
    props: {},
    view: '',
  },
};
