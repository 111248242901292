import _ from 'lodash';
import { T } from '@/types';
import { Markup } from './Markup';

export const Container = (dto: T.ContainerDto): T.Container => {
  return {
    commitNumber: dto.commitNumber,
    containerObjectIds: _.uniq(dto.containerObjectIds),
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.uuid,
    markup: Markup(dto.markup),
    ordinal: dto.ordinal,
    versionNumber: dto.versionNumber,
  };
};
