import {
  AttachmentContentDto,
  HeaderContentDto,
  ListContentDto,
  PictureContentDto,
  ReferenceContentDto,
  TableContentDto,
  TextContentDto,
} from '.';
import { CorrectionProcessBlockDto } from './CorrectionProcessBlockDto';
import { T } from '@/types';

const CorrectionContentDto = (
  model: T.CorrectionsMap,
): T.CorrectionContentObjectDto => {
  return {
    attachmentContent: model.attachmentContent
      ? AttachmentContentDto(model.attachmentContent)
      : undefined,
    headerContent: model.headerContent
      ? HeaderContentDto(model.headerContent)
      : undefined,
    id: model.id,
    listContent: model.listContent
      ? ListContentDto(model.listContent)
      : undefined,
    pictureContent: model.pictureContent
      ? PictureContentDto(model.pictureContent)
      : undefined,
    referenceContent: model.referenceContent
      ? ReferenceContentDto(model.referenceContent)
      : undefined,
    tableContent: model.tableContent
      ? TableContentDto(model.tableContent)
      : undefined,
    textContent: model.textContent
      ? TextContentDto(model.textContent)
      : undefined,
    type: model.type,
  };
};

export const CorrectionRequestDto = (
  compositionId: string,
  model: {
    modifiedTitle: string;
    modifiedContent: T.CorrectionsMap[];
    modifiedProcessBlock: T.CorrectionsMap[];
    modifiedMetadata: T.CorrectionMetadataObject[];
    comments: T.CorrectionComment[];
  },
): T.CorrectionRequestDto => {
  return {
    comments: model.comments,
    compositionId,
    modifiedContent: model.modifiedContent?.map(CorrectionContentDto),
    modifiedMetadata: model.modifiedMetadata,
    modifiedProcessBlock: model.modifiedProcessBlock?.reduce(
      (allBlocks: T.CorrectionProcessBlockDto[], correctedProcessBlock) => {
        allBlocks.push(
          ...(correctedProcessBlock.processBlock?.map((pb) => {
            return CorrectionProcessBlockDto({
              ...pb,
              childId: pb.id,
              id: correctedProcessBlock.id,
            });
          }) ?? []),
        );
        return allBlocks;
      },
      [],
    ),
    modifiedTitle: model.modifiedTitle,
  };
};
