import { T } from '@/types';

export type ContentState = typeof contentState;

export const contentState = {
  activeContentId: null as string | null,
  contents: {} as T.ContentsMap,
  editorCommandQueue: [] as T.EditorCommandQueueItem[],
  focusedContentId: null as string | null,
  isSavingContent: null as string | null,
  modifiedContents: {} as T.ModifiedContentsMap,
  preventContentSave: false,
};
