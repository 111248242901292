import { T } from '@/types';

export const CommentOnComment = (
  dto: T.CommentOnCommentDto,
): T.CommentOnComment => {
  return {
    comment: dto.comment,
    commentId: dto.commentId,
    commentOnCommentId: dto.commentOnCommentId,
    created: dto.created,
    createdBy: dto.createdBy,
    createdByUserId: dto.createdByUserId,
    id: dto.id,
    objectId: dto.objectId,
    reviewId: dto.reviewId,
    versionKey: dto.versionKey,
  };
};
