import { T } from '@/types';
import { CreateContentPayload } from './CreateContentPayload';

export const CreateContentWithIdPayload = (
  content: T.Content,
): T.CreateContentWithIdPayload => {
  return {
    ...CreateContentPayload(content),
    id: content.id,
  };
};
