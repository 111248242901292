import { T } from '@/types';
import { SystemMessageState as State } from './state';

export type SystemMessageMutations = typeof systemMessageMutations;

export const systemMessageMutations = {
  ADD_SYSTEM_MESSAGE(state: State, systemMessage: T.SystemMessage) {
    state.systemMessageQueue.push(systemMessage);
  },
  REMOVE_FIRST_SYSTEM_MESSAGE(state: State) {
    state.systemMessageQueue.splice(0, 1);
  },
  SET_ACTIVE_MESSAGE(state: State, activeSystemMessage: T.SystemMessage) {
    state.activeSystemMessage = activeSystemMessage;
  },
  SET_HIDE_SYSTEM_MESSAGE(state: State) {
    state.showSystemMessage = false;
  },
  SET_SHOW_SYSTEM_MESSAGE(state: State) {
    state.showSystemMessage = true;
  },
};
