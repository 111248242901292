import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';
import { OwnershipProxy as proxy } from '../proxies';

export const OwnershipService = {
  async createOwnership(payload: T.CreateOwnershipPayload) {
    const request = dtos.AddOwnershipRequestDto(payload);
    const response = await proxy.createOwnership(request);
    return models.Ownership(response.data);
  },
  async createOwnerships(payload: T.CreateMultipleOwnershipsPayload) {
    const request = dtos.AddMultipleOwnershipRequestDto(payload);
    const response = await proxy.createOwnerships(request);
    return response.data.map(models.Ownership);
  },
  async getAuthGroup(context: string, authGroup: string) {
    const response = await proxy.getAuthGroup(context, authGroup);
    return models.AuthorizationGroup(response.data);
  },
  async getLocks(objectIds: string[]) {
    const response = await proxy.getLocks(objectIds);
    return response.data.map(models.Ownership);
  },
  async getNPOs() {
    const response = await proxy.getNPOs();
    return response.data.map(models.LinkObject);
  },
  async getOwnershipStructures() {
    const response = await proxy.getOwnershipStructures();
    return response.data.map(models.OrganizationStructure);
  },
  async getOwnershipsForGroupTag(groupTag: string, type?: string) {
    const response = await proxy.getOwnershipsForGroupTag(groupTag, type);
    return response.data.map(models.Ownership);
  },
  async getOwnershipsForObject(objectId: string) {
    const response = await proxy.getOwnershipsForObject(objectId);
    return response.data.map(models.Ownership);
  },
  async getRegions() {
    const response = await proxy.getRegions();
    return response.data;
  },
  async getSpecificOwnerships(objectIds: string[]) {
    const response = await proxy.getSpecificOwnerships(objectIds);
    return response.data.map(models.OwnershipResponse);
  },
  async getSubjectGroups(informationType: string) {
    const response = await proxy.getSubjectGroups(informationType);
    return response.data.map(models.SubjectGroup);
  },
  async removeAllLocksForUser() {
    await proxy.removeAllLocksForUser();
  },
  async removeLock(objectId: string) {
    await proxy.removeLock(objectId);
  },
  async removeLocks(objectIds: string[]) {
    const response = await proxy.removeLocks(objectIds);
    return models.MultiUnlockResponse(response.data);
  },
  async setLock(payload: T.LockPayload) {
    const request = dtos.LockRequestDto(payload);
    const response = await proxy.setLock(request);
    return models.Ownership(response.data);
  },
  async setLocks(payload: T.MultiLockPayload[]) {
    const response = await proxy.setLocks(payload);
    return models.MultiLockResponse(response.data);
  },
};
