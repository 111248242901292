import { T } from '@/types';
import { ListContentData, TextContentData } from '.';

export const TableCell = (dto: T.CellDataDto): T.TableCell => {
  return {
    listContent: dto.listContent ? ListContentData(dto.listContent) : undefined,
    referenceContent: dto.referenceContent as T.ReferenceContentData,
    textContent: dto?.textContent
      ? TextContentData(dto.textContent)
      : undefined,
    type: dto.type,
  };
};
