import VueI18n from 'vue-i18n';
import { T } from '@/types';
import Vue, { VueConstructor } from 'vue';
import VueRouter, { Route } from 'vue-router';
import { VuetifyOptions } from '../../app/plugins';
import { Framework } from 'vuetify';

const TypedVueConstructor = Vue as VueConstructor<TypedVue>;

export type TypedVue = Vue & {
  $router: VueRouter;
  $route: Route;
  $store: T.Store;
  $vuetify: VuetifyOptions & Framework;
  $i18n: VueI18n;
};

export { TypedVueConstructor as Vue };
