export enum ServiceErrorCode {
  AUTHENTICATION_401_INVALID_TICKET = 'AUTHENTICATION_401_INVALID_TICKET',
  AUTHENTICATION_401_NO_PERSON_FOR_IDENTIFIER = 'AUTHENTICATION_401_NO_PERSON_FOR_IDENTIFIER',
  AUTHENTICATION_401 = 'AUTHENTICATION_401',
  CONTEXTUAL_401 = 'CONTEXTUAL_401',
  DUPLICATE_KEY_500 = 'DUPLICATE_KEY_500',
  ENTITY_ALREADY_EXIST_400 = 'ENTITY_ALREADY_EXIST_400',
  ENTITY_NOT_FOUND_404 = 'ENTITY_NOT_FOUND_404',
  EXPIRED_IDENTIFIER_401 = 'EXPIRED_IDENTIFIER_401',
  GENERAL_500 = 'GENERAL_500',
  INVALID_OTP_401 = 'INVALID_OTP_401',
  PERSON_LOCKED_401 = 'PERSON_LOCKED_401',
  VALIDATION_400 = 'VALIDATION_400',
}
