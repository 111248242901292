import { T } from '@/types';

export const ReceiverInfo = (dto: T.ReceiverInfoDto): T.ReceiverInfo => {
  return {
    email: dto.email,
    firstName: dto.firstName,
    lastName: dto.lastName,
    municipalities: dto.municipalities,
    region: dto.region,
    represents: dto.represents,
    representsDescription: dto.representsDescription,
  };
};
