import { T } from '@/types';
import { Composition } from './Composition';
import { Container } from './Container';
import { ContainerObject } from './ContainerObject';
import { Metadata } from './Metadata';
import { Template } from './Template';

export const CompositionWithContainersAndMetadata = (
  dto: T.CompositionWithContainersAndMetadataDto,
): T.CompositionWithContainersAndMetadata => {
  const containerObjects = {} as T.ContainerObjectMap;

  Object.entries(dto.containerObjects).forEach(([key, value]) => {
    containerObjects[key] = ContainerObject(value);
  });

  return {
    codeSystemsInUse: dto.codeSystemsInUse,
    composition: Composition(dto.composition),
    containerObjects,
    containers: dto.containers.map(Container),
    hasLevel3: dto.hasLevel3,
    metadata: dto.metadata.map(Metadata),
    template: Template(dto.template),
    templateUpgradeAvailable: dto.templateUpgradeAvailable ?? false,
  };
};
