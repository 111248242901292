import { T } from '@/types';
import { AttachmentContentDto } from './AttachmentContentDto';
import { HeaderContentDto } from './HeaderContentDto';
import { ListContentDto } from './ListContentDto';
import { PictureContentDto } from './PictureContentDto';
import { ReferenceContentDto } from './ReferenceContentDto';
import { TableContentDto } from './TableContentDto';
import { TextContentDto } from './TextContentDto';

export const ContentUpdateRequestDto = (
  model: T.ContentUpdatePayload,
): T.ContentUpdateRequestDto => {
  return {
    attachmentContent: model.attachmentContent
      ? AttachmentContentDto(model.attachmentContent)
      : undefined,
    headerContent: model.headerContent
      ? HeaderContentDto(model.headerContent)
      : undefined,
    listContent: model.listContent
      ? ListContentDto(model.listContent)
      : undefined,
    overrideId: model.overrideId ?? undefined,
    pictureContent: model.pictureContent
      ? PictureContentDto(model.pictureContent)
      : undefined,
    referenceContent: model.referenceContent
      ? ReferenceContentDto(model.referenceContent)
      : undefined,
    tableContent: model.tableContent
      ? TableContentDto(model.tableContent)
      : undefined,
    textContent: model.textContent
      ? TextContentDto(model.textContent)
      : undefined,
    type: model.type,
  };
};
