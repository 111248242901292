import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-content/contents';

export const ContentProxy = {
  async bulkUpdateStatus(request: T.UpdateMultipleContentStatusRequestDto) {
    return await proxy.put<T.UpdateMultipleContentStatusResponseDto>(
      `${URL}/status`,
      request,
    );
  },
  async createContent(
    groupTag: string,
    request: T.ContentCreateWithIdRequestDto,
  ) {
    return await proxy.post<T.ContentDto>(
      `${URL}?groupTag=${groupTag}`,
      request,
    );
  },
  async createMultipleContents(request: T.CreateMultipleContentRequestDto) {
    return await proxy.post<T.ContentDto[]>(`${URL}/multiple`, request);
  },
  async deleteContent(id: string) {
    return await proxy.delete(`${URL}/${id}`);
  },
  async getContent(id: string) {
    return await proxy.get<T.ContentDto>(`${URL}/${id}`);
  },
  async getContentHistory(id: string, index: number, pageSize: number) {
    return await proxy.get<T.ContentDto[]>(
      `${URL}/${id}/history?pageIndex=${index}&pageSize=${pageSize}`,
    );
  },
  async getContents(contentIds: string[]) {
    return await proxy.post<T.ContentDto[]>(`${URL}/withids`, contentIds);
  },
  async updateContent(contentId: string, request: T.ContentUpdateRequestDto) {
    return await proxy.put<T.ContentDto>(`${URL}/${contentId}`, request);
  },
  async updateStatus(contentId: string, request: T.UpdateStatusRequestDto) {
    return await proxy.put<T.ContentDto>(`${URL}/${contentId}/status`, request);
  },
};
