import { T } from '@/types';

export const SystemPlanningObject = (
  dto: T.SystemPlanningObjectDto[],
): T.SystemPlanningObject[] => {
  return dto.map((d) => {
    return {
      created: d.created,
      createdBy: d.createdBy,
      createdHr: d.createdHr,
      message: d.message,
      objectType: d.objectType,
      prio: d.prio,
      title: d.title,
      uuid: d.uuid,
      validFrom: d.validFrom,
      validFromHr: d.validFromHr,
      validTo: d.validTo,
      validToHr: d.validToHr,
    };
  });
};
