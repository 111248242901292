import { T } from '@/types';
import { SubListContentDto } from './SubListContentDto';
import { TextContentDto } from './TextContentDto';

export const ListRowDto = (listRowModel: T.ListRow): T.ListRowDto => {
  return {
    referenceItem: listRowModel.referenceItem,
    row: listRowModel.row ? { text: listRowModel.row.text } : null,
    sublist: listRowModel.sublist
      ? SubListContentDto(listRowModel.sublist)
      : null,
    textItem: listRowModel.textItem
      ? TextContentDto(listRowModel.textItem)
      : null,
    type: listRowModel.type,
  };
};
