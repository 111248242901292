import { contentActions } from './actions';
import { contentGetters } from './getters';
import { contentMutations } from './mutations';
import { contentState } from './state';

export const contentStore = {
  actions: contentActions,
  getters: contentGetters,
  mutations: contentMutations,
  namespaced: true,
  state: contentState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
export * from './utils';
