import { ReferenceHelper } from '@/core/objects/reference/reference-helper';
import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';
import { ReferenceProxy as proxy } from '../proxies';

export const ReferenceService = {
  async createReference(
    compositionId: string,
    commitNumber: number,
    contentId: string,
    versionNumber: number,
    referenceText: string,
  ) {
    const request: T.CreateReferenceRequestDto = {
      connectObject: {
        objectCommitNumber: commitNumber,
        objectId: contentId,
        objectVersion: versionNumber,
      },
      referenceRequest: {
        body: referenceText,
      },
    };
    const response = await proxy.createReference(compositionId, request);
    return models.ReferenceItem(response.data);
  },
  async deleteReference(
    referenceId: string,
    compositionId: string,
    contentId: string,
    commitNumber: number,
    versionNumber: number,
  ) {
    await proxy.deleteReference(
      referenceId,
      compositionId,
      contentId,
      commitNumber,
      versionNumber,
    );
  },
  async getReferencesForContents(
    compositionId: string,
    connectObjects: T.ConnectObjectDto[],
  ) {
    const response = await proxy.getReferencesForContents(
      compositionId,
      connectObjects,
    );
    return models.ReferenceResponse(response.data);
  },
  async getReferencesList(compositionId: string, contentIds: string[]) {
    const response = await proxy.getReferencesListByLatest(
      compositionId,
      contentIds,
    );
    return models.ReferenceResponse(response.data);
  },
  async getReferencesOnComposition(compositionId: string) {
    const response = await proxy.getReferencesOnComposition(compositionId);
    return ReferenceHelper.sortReferences(
      response.data.map(models.ReferenceItem),
    );
  },
  async getReferencesOnContent(compositionId: string, contentId: string) {
    const response = await proxy.getReferencesOnContentByLatest(
      compositionId,
      contentId,
    );
    return ReferenceHelper.sortReferences(
      response.data.map(models.ReferenceItem),
    );
  },
  async updateReference(
    referenceId: string,
    compositionId: string,
    payload: T.ReferencePayload,
  ) {
    const request = dtos.ReferenceRequestDto(payload);
    const response = await proxy.updateReference(
      referenceId,
      compositionId,
      request,
    );
    return models.ReferenceItem(response.data);
  },
  async useReference(
    referenceId: string,
    compositionId: string,
    commitNumber: number,
    contentId: string,
    versionNumber: number,
  ) {
    const request: T.ConnectReferenceRequestDto = {
      connectObject: {
        objectCommitNumber: commitNumber,
        objectId: contentId,
        objectVersion: versionNumber,
      },
    };
    const response = await proxy.useReference(
      referenceId,
      compositionId,
      request,
    );
    return models.ReferenceItem(response.data);
  },
};
