import { T } from '../../types';

export const CodeSystemRequestDto = (
  model: T.CodeSystem,
): T.CodeSystemRequestDto => {
  return {
    description: model.description,
    oid: model.oid,
    versionName: model.versionName,
  };
};
