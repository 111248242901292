import * as dtos from '@/objects/dtos';
import { PublicationType } from '@/objects/enums';
import * as models from '@/objects/models';
import { T } from '@/types';
import { PublicationProxy as proxy } from '../proxies';

export const PublicationService = {
  async correctPublication(
    publicationId: string,
    compositionId: string,
    payload: {
      modifiedTitle: string;
      modifiedContent: T.CorrectionsMap[];
      modifiedProcessBlock: T.CorrectionsMap[];
      modifiedMetadata: T.CorrectionMetadataObject[];
      comments: T.CorrectionComment[];
    },
  ) {
    const request = dtos.CorrectionRequestDto(compositionId, payload);
    await proxy.correctPublication(publicationId, request);
  },
  async createNationalReview(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createNationalReview(request);
    return models.PublicationReview(response.data);
  },
  async createNationalReviewPreview(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createNationalReviewPreview(request);
    return models.PublicationReview(response.data);
  },
  async createOpinionReview(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createOpinionReview(request);
    return models.PublicationReview(response.data);
  },

  async createPublication(payload: T.CreatePublicationPayload) {
    const request = dtos.CreatePublicationRequestDto(payload);
    const response = await proxy.createPublication(request);
    return models.PublicationReview(response.data);
  },
  async createPublicationBasedOnExisting(payload: T.CreatePublicationPayload) {
    const request = dtos.CreatePublicationRequestDto(payload);
    const response = await proxy.createPublicationBasedOnExisting(request);
    return models.PublicationReview(response.data);
  },
  async createPublicationCandidate(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createPublicationCandidate(request);
    return models.PublicationReview(response.data);
  },
  async createPublicationCandidateBasedOnExisting(
    compositionId: string,
    aspectPublishingSettings: T.AspectPublishingSettings[],
  ) {
    const request = dtos.ClonePublicationCandidateRequestDto(
      compositionId,
      aspectPublishingSettings,
    );
    const response = await proxy.createPublicationCandidateBasedOnExisting(
      request,
    );
    return models.PublicationReview(response.data);
  },
  async createPublicationCandidatePreview(
    payload: T.PublicationPreviewPayload,
  ) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);

    const response = await proxy.createPublicationCandidatePreview(request);
    return models.PublicationReview(response.data);
  },
  async createPublicationCandidatesPreviewBasedOnExisting(
    payload: T.PublicationPreviewPayload,
  ) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const latest = payload.latest;
    const response =
      await proxy.createPublicationPreviewFromCandidateBasedOnExisting(
        request,
        latest,
      );
    return models.PublicationReview(response.data);
  },
  async createPublicationPreviewBasedOnExisting(
    payload: T.PublicationPreviewPayload,
  ) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const latest = payload.latest;
    const response = await proxy.createPublicationPreviewBasedOnExisting(
      request,
      latest,
    );
    return models.PublicationReview(response.data);
  },

  async createRegionalPublicationPreviewFromCandidate(
    payload: T.PublicationPreviewPayload,
  ) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response =
      await proxy.createPublicationPreviewFromCandidateBasedOnExisting(
        request,
        false,
      );
    return models.PublicationReview(response.data);
  },
  async createReview(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createReview(request);
    return models.PublicationReview(response.data);
  },
  async createReviewPreview(payload: T.PublicationPreviewPayload) {
    const request = dtos.CreatePublicationOfTypeRequestDto(payload);
    const response = await proxy.createReviewPreview(request);
    return models.PublicationReview(response.data);
  },
  async deleteNationalReviewPublication(publicationId: string) {
    await proxy.deleteNationalReviewPublication(publicationId);
  },
  /**
   * @deprecated
   */
  async deletePublicationCandidate(publicationId: string) {
    await proxy.deletePublicationCandidate(publicationId);
  },
  async deleteRegionalAddons(
    compositionId: string,
    publicationId: string,
    body: { comment: string; objectIds: string[] },
  ) {
    await proxy.deleteRegionalAddons(compositionId, publicationId, body);
  },
  async deleteReviewPublication(publicationId: string) {
    await proxy.deleteReviewPublication(publicationId);
  },
  async getLatestPublication(compositionId: string) {
    const response = await proxy.getLatestPublication(compositionId);

    return models.PublicationReview(response.data);
  },
  async getLatestPublicationMetadata(compositionId: string) {
    const response = await proxy.getLatestPublicationMetadata(compositionId);
    return response.data.map(models.PublicationMetadata);
  },
  async getPublication(publicationId: string) {
    const response = await proxy.getPublication(publicationId);
    return models.PublicationReview(response.data);
  },
  async getPublicationExists(compositionId: string, assignment: string) {
    const response = await proxy.getPreviouslyPublished(
      compositionId,
      assignment,
    );

    return response.data!;
  },
  async getPublicationHistory(compositionId: string) {
    const response = await proxy.getPublicationHistory(compositionId);
    return response.data;
  },
  async getPublicationMetadata(publicationId: string) {
    const response = await proxy.getPublicationMetadata(publicationId);
    return response.data.map(models.PublicationMetadata);
  },
  async getPublicationVersion(publicationId: string, version: string) {
    const response = await proxy.getPublicationVersion(publicationId, version);
    return response.data;
  },
  async getPublications(compositionId: string, type?: PublicationType) {
    const response = await proxy.getPublicationsForComposition(
      compositionId,
      type,
    );
    return response.data.map(models.Publication);
  },
  async getPublicationsOfType(
    informationType: T.InformationType,
    index: number,
    pageSize: number,
  ) {
    const response = await proxy.getPublicationsOfType(
      informationType,
      index,
      pageSize,
    );
    return response.data.map(models.PublicationReview);
  },
  async pausePublication(compositionId: string, reason: string) {
    const res = await proxy.pausePublication(compositionId, reason);
    return models.PublicationReview(res.data);
  },
  async publishScheduledPublication(publicationId: string) {
    await proxy.publishScheduledPublication(publicationId);
  },
  async rejectPublicationCandidate(publicationId: string, comment = '') {
    await proxy.rejectPublicationCandidate(publicationId, comment);
  },
  async reschedulePublication(payload: {
    publicationId: string;
    scheduledPublicationDate: number;
  }) {
    await proxy.reschedulePublication(payload);
  },
  async updatePublicationCandidate(payload: T.CreatePublicationPayload) {
    const request = dtos.CreatePublicationRequestDto(payload);
    const response = await proxy.createPublication(request);
    return models.PublicationReview(response.data);
  },
};
