import { T } from '@/types';
import { ListRow } from '.';

export const ListContentData = (dto: T.ListContentDto): T.ListContentData => {
  return {
    header: dto.header,
    referenceId: dto.referenceId,
    rows: (dto.rows || []).map(ListRow),
    type: dto.type,
  };
};
