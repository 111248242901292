import { T } from '../../types';

export const CodeDto = (model: T.Code): T.CodeDto => {
  return {
    code: model.code,
    description: model.description,
    oid: model.oid,
    version: model.version,
  };
};
