import { userActions } from './actions';
import { userGetters } from './getters';
import { userMutations } from './mutations';
import { userState } from './state';

export const userStore = {
  actions: userActions,
  getters: userGetters,
  mutations: userMutations,
  namespaced: true,
  state: userState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
