import { ContentType, ReferenceCategory } from '@/objects/enums';
import { Services } from '@/services';
import { T } from '@/types';
import { CompositionUtils } from '../composition/utils';
import { ReferenceActions } from './actions.types';

export const referenceActions: ReferenceActions = {
  // async commitPublicationReferences({ commit }, publication) {
  //   if (!publication || !publication.references) {
  //     return;
  //   }
  // const references = Object.values(publication.references)
  //   .map((aspect) => aspect.objectItems)
  //   .flat();
  // const referenceMap = {} as Record<string, T.ReferenceItem[]>;
  // references.forEach((referenceObject) => {
  //   if (referenceObject.objectId) {
  //     referenceMap[referenceObject.objectId] = referenceObject.referenceItems;
  //   }
  // });
  // commit('publication/SET_REFERENCES', referenceMap, {
  //   root: true,
  // });
  // },

  async createNewReference({ dispatch, commit, rootState }, newReferenceText) {
    const compositionId = rootState.composition.activeComposition?.id;
    if (!compositionId) {
      return null;
    }
    try {
      const res = await Services.ReferenceV2.createReference(
        compositionId,
        newReferenceText,
      );

      if (res) {
        commit(
          'composition/SET_REFERENCES_V2',
          [...rootState.composition.activeCompositionReferencesV2, res],
          { root: true },
        );
      }
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av referens. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createReference({ dispatch, rootState }, payload) {
    try {
      const { contentId } = payload;
      const newReferenceString = payload.newReference as string;
      const newReference = payload.newReference as T.ReferenceV2Dto;
      const content = rootState.content.contents[contentId];
      const compositionId = rootState.composition.activeComposition?.id;
      if (!content || !compositionId) {
        return;
      }
      let reference: T.ReferenceV2Dto;

      if (newReference.id) {
        reference = newReference;
      } else {
        const res = await Services.ReferenceV2.createReference(
          compositionId,
          newReferenceString,
        );
        if (!res) {
          return;
        }
        reference = res;
      }
      switch (content.type) {
        case ContentType.TEXT:
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
                data: {
                  ...content.data,
                  blockReferences: [
                    ...(content.data.blockReferences ?? []),
                    {
                      referenceId: reference.id,
                      targetPart: {} as T.ContentReferenceV2['targetPart'],
                    },
                  ],
                },
              },
            },
            { root: true },
          );
          break;
        case ContentType.LIST:
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
                data: {
                  ...content.data,
                  referenceId: [
                    ...(content.data?.referenceId ?? []),
                    reference.id,
                  ],
                },
              },
            },
            { root: true },
          );
          break;
        case ContentType.TABLE:
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
                data: {
                  ...content.data,
                  referenceId: [
                    ...(content.data.referenceId ?? []),
                    reference.id,
                  ],
                },
              },
            },
            { root: true },
          );
          break;
        case ContentType.PROCESS:
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
                data: {
                  ...content.data,
                  referenceId: [
                    ...(content.data.referenceId ?? []),
                    reference.id,
                  ],
                },
              },
            },
            { root: true },
          );
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av referens. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async deleteAssociatedReferences({ dispatch, commit, rootState }, payload) {
    try {
      const res = await Services.Aggregator.deleteAssociatedReferences(payload);
      if (res.status === 200) {
        const compositionId = rootState.composition.activeComposition?.id;
        dispatch(
          'systemMessage/addSystemSuccessMessageToQueue',
          'Referens borttagen',
          { root: true },
        );
        if (compositionId) {
          dispatch('fetchReferencesOnComposition', compositionId);
          const containerObjectList = Object.values(
            rootState.composition.containerObjects,
          );
          const ids =
            CompositionUtils.getContainerObjectIdsByType(containerObjectList);
          commit('content/CLEAR_CONTENT', undefined, { root: true });
          await Promise.all([
            dispatch(
              'content/fetchContents',
              {
                contentIds: ids.contentIds,
              },
              { root: true },
            ),
            dispatch(
              'content/fetchContents',
              {
                contentIds: ids.processBlockIds,
                contentType: ContentType.PROCESS,
              },
              { root: true },
            ),
          ]);
        }
        return true;
      }
      return false;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av referens. Försök igen senare.',
        },
        { root: true },
      );
      return false;
    }
  },
  async deleteReference({ dispatch, commit, rootState }, payload) {
    const compositionId = rootState.composition.activeComposition?.id;
    if (!compositionId) {
      return null;
    }

    try {
      const res = await Services.Aggregator.deleteCompositionReference(
        compositionId,
        payload.referenceId,
      );
      if (res.deleted) {
        commit('composition/DELETE_REFERENCE_V2', payload.referenceId, {
          root: true,
        });
        dispatch(
          'systemMessage/addSystemSuccessMessageToQueue',
          'Referens borttagen',
          { root: true },
        );
        return null;
      }
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av referens. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async deleteReferenceInContent({ dispatch, rootState }, payload) {
    try {
      const content = rootState.content.contents[payload.contentId]!;

      switch (content.type) {
        case ContentType.TEXT:
          if (!content.data.blockReferences) {
            break;
          }
          content.data.blockReferences.splice(
            content.data.blockReferences.findIndex(
              (ref) => ref.referenceId === payload.referenceId,
            ),
            1,
          );
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
              },
            },
            { root: true },
          );
          break;
        case ContentType.LIST:
          if (!content.data.referenceId) {
            break;
          }
          content.data.referenceId.splice(
            content.data.referenceId.findIndex(
              (ref) => ref === payload.referenceId,
            ),
            1,
          );
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
              },
            },
            { root: true },
          );
          break;
        case ContentType.TABLE:
          if (!content.data.referenceId) {
            break;
          }
          content.data.referenceId.splice(
            content.data.referenceId.findIndex(
              (ref) => ref === payload.referenceId,
            ),
            1,
          );
          await dispatch(
            'content/updateContent',
            {
              content: {
                ...content,
              },
            },
            { root: true },
          );
          break;
        case ContentType.PROCESS:
        default:
          break;
      }
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av referens. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchAllReferences({ commit, dispatch, rootState }) {
    const compositionId = rootState.composition.activeComposition?.id;

    if (!compositionId) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Något gick fel vid hämtning av referenser. Kunde inte hitta ett aktivt kunskapsstöd. ' +
            'Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return null;
    }

    const contentIds = Object.values(rootState.composition.containers)
      .map((container) => container.containerObjectIds)
      .flat();

    try {
      const referenceResponse = await Services.Reference.getReferencesList(
        compositionId,
        contentIds,
      );

      const referenceRecords: Record<string, T.ReferenceItem[]> = {};

      referenceResponse.objectItems.forEach((objectItem) => {
        if (objectItem.objectId && objectItem.referenceItems) {
          referenceRecords[objectItem.objectId] = objectItem.referenceItems;
        }
      });

      commit('composition/SET_REFERENCES', referenceRecords, {
        root: true,
      });

      return referenceResponse;
    } catch (error) {
      throw dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av referenser. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchReferencesByContentIds({ commit, dispatch, rootState }, payload) {
    const { contentIds, referenceCategory } = payload;
    const compositionId =
      referenceCategory === ReferenceCategory.PUBLICATION
        ? rootState.publication.activePublication?.publication.compositionId
        : rootState.composition.activeComposition?.id;

    if (!compositionId) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Något gick fel vid hämtning av referenser. ' +
            'Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return null;
    }

    try {
      const referenceResponse = await Services.Reference.getReferencesList(
        compositionId,
        contentIds,
      );

      const referenceRecords: Record<string, T.ReferenceItem[]> = {};

      referenceResponse.objectItems.forEach((o) => {
        if (o.objectId && o.referenceItems) {
          referenceRecords[o.objectId] = o.referenceItems;
        }
      });

      switch (referenceCategory) {
        case ReferenceCategory.COMPOSITION:
          commit('composition/SET_REFERENCES', referenceRecords, {
            root: true,
          });
          break;
        case ReferenceCategory.PUBLICATION:
          commit('publication/SET_REFERENCES', referenceRecords, {
            root: true,
          });
          break;
        default:
          break;
      }

      return referenceResponse;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av referenser. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchReferencesOnComposition({ commit, dispatch }, compositionId) {
    try {
      const references = await Services.ReferenceV2.getAllV2References(
        compositionId,
      );
      commit('composition/SET_REFERENCES_V2', references, { root: true });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av referenser. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchReferencesOnContent({ commit, dispatch, rootState }, payload) {
    try {
      const content = rootState.content.contents[payload.contentId];
      const { referenceCategory } = payload;

      if (
        !content ||
        content.commitNumber === null ||
        content.versionNumber === null
      ) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: null,
            message:
              'Något gick fel vid hämtning av referenser. Försök igen senare.',
          },
          { root: true },
        );
        return [];
      }

      const references = await Services.Reference.getReferencesOnContent(
        payload.compositionId,
        content.id,
      );

      if (references.length > 0) {
        references.forEach((reference) => {
          commit('ADD_REFERENCE', reference);
        });

        switch (referenceCategory) {
          case ReferenceCategory.COMPOSITION:
            commit(
              'composition/SET_REFERENCES_ON_CONTENT',
              {
                objectId: payload.contentId,
                referenceItems: references,
              },
              { root: true },
            );
            break;
          case ReferenceCategory.PUBLICATION:
            commit(
              'publication/SET_REFERENCES_ON_CONTENT',
              {
                objectId: payload.contentId,
                referenceItems: references,
              },
              { root: true },
            );
            break;
          default:
            break;
        }
      }

      return references;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av referenser. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async updateReference({ commit, dispatch }, payload) {
    try {
      const latestReference = await Services.ReferenceV2.updateReference(
        payload.referenceId,
        payload.newText,
      );
      const newReference: T.ReferenceV2Dto = {
        ...latestReference,
      };
      commit('composition/UPDATE_REFERENCE', latestReference, { root: true });
      return newReference;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdatering av referens. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async useReference({ commit, dispatch, rootState }, payload) {
    try {
      const { compositionId, contentId, reference, referenceCategory } =
        payload;
      const content = rootState.content.contents[contentId]!;
      const latestReference = await Services.Reference.useReference(
        reference.id,
        compositionId,
        content.commitNumber ?? -1,
        content.id,
        content.versionNumber ?? -1,
      );

      switch (referenceCategory) {
        case ReferenceCategory.COMPOSITION:
          commit(
            'composition/ADD_REFERENCES_ON_CONTENT',
            {
              objectId: contentId,
              referenceItems: [latestReference],
            },
            { root: true },
          );
          break;
        case ReferenceCategory.PUBLICATION:
          commit(
            'publication/ADD_REFERENCES_ON_CONTENT',
            {
              objectId: contentId,
              referenceItems: [latestReference],
            },
            { root: true },
          );
          break;
        default:
          break;
      }

      return reference;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av referens. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
};
