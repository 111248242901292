import { modalStore } from '@/store/modal';
import { T } from '@/types';
import { Store } from 'vuex';
import { authorStore } from '../../store/author';
import { compositionStore } from '../../store/composition';
import { contentStore } from '../../store/content';
import { globalStore } from '../../store/global';
import { ownershipStore } from '../../store/ownership';
import { publicationStore } from '../../store/publication';
import { referenceStore } from '../../store/reference';
import { reviewStore } from '../../store/review';
import { systemMessageStore } from '../../store/system-message';
import { templateStore } from '../../store/template';
import { userStore } from '../../store/user';

export let store = undefined as unknown as Store<T.RootState>;

export const createStore = () => {
  store = new Store({
    modules: {
      author: authorStore,
      composition: compositionStore,
      content: contentStore,
      global: globalStore,
      modal: modalStore,
      ownership: ownershipStore,
      publication: publicationStore,
      reference: referenceStore,
      review: reviewStore,
      systemMessage: systemMessageStore,
      template: templateStore,
      user: userStore,
    },
  });

  return store;
};
