import { T } from '../../types';
import { AreaReference } from './AreaReference';
import { Coordinate } from './Coordinate';

export const RectangleArea = (dto: T.RectangleAreaDto): T.RectangleArea => {
  return {
    altText: dto.altText,
    bottomRight: Coordinate(dto.bottomRight),
    reference: AreaReference(dto.reference),
    topLeft: Coordinate(dto.topLeft),
  };
};
