import { T } from '@/types';
import { ContentType, HeaderType } from '@/objects/enums';

export const ContentUtils = {
  filterContentOnOwnership(
    contents: string[],
    ownerships: T.OwnershipMap,
    userAssignment: string,
    includeNational = false,
    nationalAssignment = [] as string[],
  ) {
    const filteredContents = contents.filter((contentId) => {
      const contentOwnership = ownerships[contentId];
      if (userAssignment && contentOwnership) {
        const organizationId = contentOwnership[0]?.organizationId;
        return (
          organizationId === userAssignment ||
          (includeNational &&
            organizationId &&
            nationalAssignment.includes(organizationId))
        );
      }
      return false;
    });
    return filteredContents;
  },
  filterContentOnPublicationType(
    contents: T.ContentsMap | T.PublicationContentsMap,
    containerObjects: T.ContainerObjectMap | T.PublicationContainerObjectMap,
    publicationTypeIds: string[],
  ): T.ContentsMap {
    const filteredContents: T.ContentsMap = {};
    Object.keys(contents).forEach((contentId) => {
      const containerObject = containerObjects[contentId];

      if (containerObject && containerObject.markup.aspects.length > 0) {
        const objectAspects = containerObject.markup.aspects;

        publicationTypeIds.forEach((id) => {
          if (objectAspects.find((aspect) => aspect.id === id)) {
            filteredContents[contentId] = contents[contentId];
          }
        });
      }
    });
    return filteredContents;
  },
  findListItem(contents: T.ContentsMap, contentId: string, itemId: string) {
    const content = contents[contentId];
    if (content && content.type === ContentType.LIST) {
      const list = content.data.rows;
      const index = list.findIndex((item) => item.id === itemId);
      if (index > -1) {
        return { index, list };
      }
    }

    return null;
  },
  getHeadersInContainers(
    containers: T.Container[] | T.PublicationContainer[],
    contents: T.ContentsMap,
    filterEmpty: Boolean = false,
  ): T.HeaderContent[] {
    const nonEmptyHeaders = filterEmpty
      ? this.getNonEmptyHeadersInContainers(containers, contents)
      : {};

    const headers = (containers as T.Container[]).reduce(
      (totalHeaders: T.HeaderContent[], container: T.Container) => {
        const containerHeaders = container.containerObjectIds.reduce(
          (objectHeaders: T.HeaderContent[], containerObjectId: string) => {
            const content = contents[containerObjectId];

            if (
              content &&
              content.type === ContentType.HEADER &&
              (!filterEmpty || nonEmptyHeaders[containerObjectId])
            ) {
              objectHeaders.push(content as T.HeaderContent);
            }
            return objectHeaders;
          },
          [],
        );

        const newTotalHeaders = totalHeaders.concat(containerHeaders);
        return newTotalHeaders;
      },
      [] as T.HeaderContent[],
    );
    return headers;
  },
  getNonEmptyHeadersInContainer(
    container: T.Container | T.PublicationContainer | T.ContainerSnapshot,
    contents: T.ContentsMap,
    headerLevelsToAlwaysInclude = [] as Array<HeaderType>,
  ): T.ContentsMap {
    const nonEmptyHeaders: T.ContentsMap = {};
    const headerStack: T.HeaderContent[] = [];

    container.containerObjectIds.forEach((contentId) => {
      const content = contents[contentId];
      if (content) {
        if (
          content.type === ContentType.HEADER &&
          !headerLevelsToAlwaysInclude.includes(content.data.type)
        ) {
          while (
            headerStack[headerStack.length - 1] &&
            content.data.type <= headerStack[headerStack.length - 1]!.data.type
          ) {
            headerStack.pop();
          }
          headerStack.push(content);
        } else {
          if (content.type === ContentType.HEADER) {
            headerStack.push(content);
          }
          headerStack.forEach((header) => {
            nonEmptyHeaders[header.id] = header;
          });
        }
      }
    });
    return nonEmptyHeaders;
  },
  getNonEmptyHeadersInContainers(
    containers: T.Container[] | T.PublicationContainer[],
    contents: T.ContentsMap,
    headerLevelsToAlwaysInclude = [] as Array<HeaderType>,
  ) {
    const nonEmptyHeaders: T.ContentsMap = {};

    containers.forEach((container) => {
      Object.assign(
        nonEmptyHeaders,
        ContentUtils.getNonEmptyHeadersInContainer(
          container,
          contents,
          headerLevelsToAlwaysInclude,
        ),
      );
    });

    return nonEmptyHeaders;
  },
  hasNonEmptyHeaderContent(container: T.Container, contents: T.ContentsMap) {
    const nonEmptyHeaders = ContentUtils.getNonEmptyHeadersInContainer(
      container,
      contents,
    );
    return Object.keys(nonEmptyHeaders).length > 0;
  },
};
