import { T } from '@/types';

export const CompositionForList = (
  dto: T.CompositionForListDto,
): T.CompositionForList => {
  return {
    id: dto.id,
    informationType: dto.informationType,
    title: dto.title,
    versionKey: dto.versionKey,
  };
};
