import _ from 'lodash';
import { T } from '@/types';
import { PublicationMetadataComment } from './PublicationMetadataComment';
import { PublicationMetadataLink } from './PublicationMetadataLink';
import { PublicationMetadataMarkup } from './PublicationMetadataMarkup';
import { PublicationSubjectGroup } from './PublicationSubjectGroup';

export const PublicationMetadata = (
  dto: T.PublicationMetadataDto,
): T.PublicationMetadata => {
  return {
    approved: dto.approved,
    approvedHr: dto.approvedHr,
    aspectId: dto.aspectId,
    aspectInitials: dto.aspectInitials,
    aspectName: dto.aspectName,
    assignment: dto.assignment,
    authors: _.uniq(dto.authors ?? []),
    comment: dto.comment ? PublicationMetadataComment(dto.comment) : null,
    contact: dto.contact ? PublicationMetadataLink(dto.contact) : null,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    markup: dto.markup
      ? PublicationMetadataMarkup(dto.markup)
      : {
          codes: [],
          proposedCodesForDiagnosis: [],
          searchTerms: [],
        },
    published: dto.published,
    publishedHr: dto.publishedHr,
    responsible: (dto.responsible ?? []).map(PublicationMetadataLink),
    reviewers: _.uniq(dto.reviewers ?? []),
    revised: dto.revised,
    revisedHr: dto.revisedHr,
    subjectGroups: (dto.subjectGroups ?? []).map(PublicationSubjectGroup),
  };
};
