import { T } from '@/types';

export const UpdateGroupRequestDto = (
  group: T.Group,
): T.UpdateGroupRequestDto => {
  return {
    description: group.description,
    displayName: group.displayName,
    groupAccessType: group.groupAccessType,
    members: group.members,
    metadata: group.metadata,
  };
};
