import { T } from '@/types';

export const UpdateCompositionTitleRequestDto = (
  payload: T.CompositionTitleUpdatePayload,
): T.UpdateCompositionTitleRequestDto => {
  return {
    clientObjectInformation: {
      commitNumber: payload.commitNumber,
      versionNumber: payload.versionNumber,
    },
    modifiedTitle: payload.modifiedTitle,
  };
};
