import { T } from '@/types';

export type OwnershipState = typeof ownershipState;

export const ownershipState = {
  locks: {} as Record<string, T.Ownership>,
  ownerships: {} as T.OwnershipMap,
  pendingLockReleases: [] as string[],
  resources: [] as string[],
  structure: null as T.OrganizationStructure | null,
};
