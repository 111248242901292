import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-search';

export const CodeProxy = {
  async getAllCodeSystems(): Promise<T.NewCodeSystemDto[]> {
    const response = await fetch(
      'https://prod2.kunskapsstod.se/api-codeserver/codeserver/codesystems',
    );
    return response.json();
  },
  async searchForCodes(oid: string, query: string, limit: number) {
    return await proxy.get<T.CodeSearchResponse>(
      `${URL}/codesystem/${oid}/search?text=${query}&page=0&pageSize=${limit}`,
    );
  },
};
