import { T } from '@/types';

export const QuestionRequestDto = (
  model: T.QuestionPayload,
): T.QuestionRequestDto => {
  return {
    question: model.question,
    startIndex: model.startIndex,
    stoppIndex: model.stopIndex,
  };
};
