import { T } from '@/types';
import {
  ContentType,
  HeaderType,
  ListRowType,
  ListType,
  StatusType,
  TableCellType,
} from '@/objects/enums';
import { v4 as uuidv4 } from 'uuid';
import { ProcessBlockType } from '@/objects/enums/ProcessBlockType';

export const ContentCreator = {
  createListItem(text = ''): T.ListRow {
    return {
      id: uuidv4(),
      referenceItem: null,
      row: null,
      sublist: null,
      textItem: { text },
      type: ListRowType.TEXT,
    };
  },
  createNewAttachment(): T.AttachmentContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        approvedBy: '',
        attachmentType: '',
        contentResponsible: '',
        fileName: '',
        fileSize: 0,
        mediaType: '',
        reference: '',
        validFrom: Date.now(),
        version: '',
      },
      type: ContentType.ATTACHMENT_REFERENCE,
    };
  },
  createNewContentBase(
    contentBase: Partial<T.ContentBase> = {},
  ): T.ContentBase {
    return {
      aggregatedReferenceIds: [],
      commitNumber: null,
      contentProperties: {
        publiclyAvailable:
          contentBase.contentProperties?.publiclyAvailable ?? true,
      },
      created: Date.now(),
      createdBy: null,
      createdHr: null,
      id: uuidv4(),
      overrideId: null,
      status: StatusType.LEVEL_1,
      versionKey: null,
      versionNumber: null,
    };
  },
  createNewHeader(headerType: HeaderType): T.HeaderContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        text: '',
        type: headerType,
      },
      type: ContentType.HEADER,
    };
  },
  createNewList(
    listType: ListType,
    listItems = [] as T.ListRow[],
  ): T.ListContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        rows: listItems,
        type: listType,
      },
      type: ContentType.LIST,
    };
  },
  createNewPicture(): T.PictureContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        alternativeText: '',
        fileName: '',
        pictureCreated: -1,
        pictureCreator: '',
        pictureName: '',
        pictureReferences: [],
        pictureText: '',
        rightsHolder: '',
      },
      type: ContentType.PICTURE_REFERENCE,
    };
  },
  createNewProcess(): T.ProcessContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        aggregatedReferenceIds: [],
        blockType: ProcessBlockType.INTERMEDIATE,
        contents: [],
        id: '',
        processBlocks: [],
        title: '',
      },
      type: ContentType.PROCESS,
    };
  },
  createNewReference(): T.ReferenceContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        displayReferenceText: { text: '' },
        reference: '',
      },
      type: ContentType.REFERENCE,
    };
  },
  createNewTable(): T.TableContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: {
        columnHeaders: [],
        rows: [],
        tableHeader: '',
      },
      type: ContentType.TABLE,
    };
  },
  createNewTableCell(
    content: T.TextContentData | T.ListContentData | T.ReferenceContentData,
    type = TableCellType.TEXT,
  ): T.TableCell {
    switch (type) {
      case TableCellType.TEXT:
        return {
          textContent: { ...(content as T.TextContentData) },
          type: TableCellType.TEXT,
        };
      case TableCellType.REFERENCE:
        return {
          referenceContent: content as T.ReferenceContentData,
          type: TableCellType.REFERENCE,
        };
      default:
        return {
          listContent: content as T.ListContentData,
          type: TableCellType.LIST,
        };
    }
  },
  createNewText(text = ''): T.TextContent {
    return {
      ...ContentCreator.createNewContentBase(),
      data: { text },
      type: ContentType.TEXT,
    };
  },
};
