import { T } from '@/types';
import { ContentCreateWithIdRequestDto } from './ContentCreateWithIdRequestDto';

export const CreateMultipleContentRequestDto = (
  model: T.CreateMultipleContentPayload,
): T.CreateMultipleContentRequestDto => {
  return {
    contents: model.contents.map(ContentCreateWithIdRequestDto),
  };
};
