import { T } from '@/types';

export const CompositionPlanningObject = (
  dto: T.CompositionPlanningObjectDto,
): T.CompositionPlanningObject => {
  return {
    aspectId: dto.aspectId,
    assignment: dto.assignment ?? undefined,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.id,
    objectType: dto.objectType,
    plannedDateForNationalReview: dto.plannedDateForNationalReview ?? 0,
    plannedDateForNationalReviewHr:
      dto.plannedDateForNationalReviewHr ?? undefined,
    uuid: dto.uuid ?? undefined,
    versionKey: dto.versionKey,
  };
};
