import { T } from '@/types';

export const PublicationSubjectGroup = (
  dto: T.PublicationSubjectGroupDto,
): T.PublicationSubjectGroup => {
  return {
    displayName: dto.displayName ?? '',
    name: dto.name ?? '',
  };
};
