import { T } from '@/types';

export const ReferenceHelper = {
  mapReferenceInString(
    references: T.ContentReferenceV2[],
  ): [string, T.ContentReferenceV2[]][] {
    return Object.entries(
      references.reduce((indexArr, ref) => {
        const newIndexArr = indexArr;
        const existingIndex = newIndexArr[ref.targetPart.endIndex];
        const reference = ref;
        if (existingIndex) {
          existingIndex.push(reference);
          newIndexArr[ref.targetPart.endIndex] = existingIndex;
        } else {
          newIndexArr[ref.targetPart.endIndex] = [reference];
        }

        return newIndexArr;
      }, {} as Record<string, T.ContentReferenceV2[]>),
    );
  },
  parseSubString(
    references: [string, T.ContentReferenceV2[]][],
    text: string,
    strIndex: string,
    currIndex: number,
  ): string {
    return text.substring(
      Number(references?.[currIndex - 1]?.[0] ?? 0),
      Number(strIndex),
    );
  },
  sortReferences(references: T.ReferenceItem[]) {
    return references.sort((a, b) => a.number - b.number);
  },
};
