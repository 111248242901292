import { store } from '@/app/plugins';
import { UrlHelper } from '@/core/helpers';
import { ProxyConfig, Services } from '@/services';
import { T } from '@/types';
import Cookies from 'js-cookie';

const BVK_GUID_PARAM = 'authguid';
/** TODO: If you login to BVO while logged in to this, BVO will overwrite the guid. */
const getNewTicket = async () => {
  const guid = UrlHelper.getUrlParam(BVK_GUID_PARAM);
  if (guid) {
    const { ticket } = await Services.Authentication.getTicket({ guid });
    Cookies.set(ProxyConfig.ticketName, ticket);
    UrlHelper.removeUrlParams();
    return ticket;
  }
  return null;
};

const getOldTicket = async () => {
  const ticket = Cookies.get(ProxyConfig.ticketName) ?? null;
  if (ticket) {
    const { valid } = await Services.Authentication.checkTicket({ ticket });
    return valid ? ticket : null;
  }
  return null;
};

export const ticketAuth = async ({ next }: T.NextFactoryContext) => {
  try {
    const ticket = (await getOldTicket()) ?? (await getNewTicket());
    if (ticket) {
      await store.dispatch('user/fetchUserFromTicket', ticket);
      next();
      return;
    }
  } catch {
    /**/
  }

  Cookies.remove(ProxyConfig.ticketName);
  UrlHelper.goToLoginPage();
};
