import { T } from '@/types';

export const ReviewObject = (dto: T.ReviewObjectDto): T.ReviewObject => {
  return {
    objectId: dto.objectId,
    publicationId: dto.id,
    reviewId: dto.reviewId,
    reviewObjectType: dto.reviewObjectType,
    versionKey: dto.versionKey,
  };
};
