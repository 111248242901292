import { publicationActions } from './actions';
import { publicationGetters } from './getters';
import { publicationMutations } from './mutations';
import { publicationState } from './state';

export const publicationStore = {
  actions: publicationActions,
  getters: publicationGetters,
  mutations: publicationMutations,
  namespaced: true,
  state: publicationState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
