import { SortBy, SortOrder } from '@/objects/enums';
import { T } from '@/types';
import qs from 'qs';
import { proxy } from '..';

const URL = '/api-search';

export const SearchProxy = {
  async getCompositions(request: T.CompositionListSearchRequestDto) {
    return await proxy.get<T.CompositionListResponseDto>(
      `${URL}/compositions?${qs.stringify(request, { arrayFormat: 'repeat' })}`,
    );
  },
  async getPublications(payload: {
    assignment: string;
    page?: number;
    title?: string;
    sortBy?: SortBy;
    sortOrder?: SortOrder;
  }) {
    return await proxy.get<
      T.PaginatedResponse & { matchingPublications: T.MatchedPublicationDto[] }
    >(
      `${URL}/publications?${qs.stringify(payload, { arrayFormat: 'repeat' })}`,
    );
  },
  async getReviews(payload: {
    assignment: string;
    closedOnly?: boolean;
    npo?: string[];
    name?: string;
    sortBy?: SortBy;
    sortOrder?: SortOrder;
  }) {
    return await proxy.get<T.ReviewListResponseDto>(
      `${URL}/reviews?${qs.stringify(payload, { arrayFormat: 'repeat' })}`,
    );
  },
  async getReviewsAggregates(payload: {
    assignment: string;
    closedOnly?: boolean;
    npo?: string[];
    name?: string;
  }) {
    return await proxy.get<T.ReviewAggregateResponseDto>(
      `${URL}/reviews/aggregates?${qs.stringify(payload, {
        arrayFormat: 'repeat',
      })}`,
    );
  },
  async getScheduledCompositions(payload: { pageSize: number; page: number }) {
    return await proxy.get<T.CompositionListResponseDto>(
      `${URL}/compositions/with_scheduled_publicationcandidate`,
      {
        params: {
          ...payload,
          sortBy: SortBy.SCHEDULED_PUBLISHING_DATE,
          sortOrder: SortOrder.ASC,
        },
      },
    );
  },
  async searchCompositionContent(
    searchTerm: string,
    params?: { pageSize?: number },
    signal?: AbortSignal,
  ) {
    // @ts-ignore next-row
    const urlParams = new URLSearchParams(params);
    return await proxy.post<T.CompositionForContentSearchResponseDto[]>(
      `${URL}/compositions/search${urlParams ? `?${urlParams}` : ''}`,
      {
        highlight: false,
        operator: 'AND',
        text: searchTerm,
      },
      { signal },
    );
  },
  async searchCompositionTitle(
    searchTerm: string,
    params?: { pageSize?: number },
    signal?: AbortSignal,
  ) {
    // @ts-ignore next-row
    const urlParams = new URLSearchParams(params);
    return await proxy.post<T.CompositionSearchResultsDto>(
      `${URL}/compositions/search/title${urlParams ? `?${urlParams}` : ''}`,
      { operator: 'AND', title: searchTerm },
      { signal },
    );
  },
};
