import { T } from '@/types';
import { v4 as uuidv4 } from 'uuid';

export const Member = (dto: T.MemberDto): T.Member => {
  return {
    email: dto.email,
    fullName: dto.fullName,
    title: dto.title,
    uuid: uuidv4(),
  };
};
