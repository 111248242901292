import { store } from '@/app/plugins';
import { Resources } from '@/core/resources';
import { T } from '@/types';

export const resourceCheck = ({ router, next }: T.NextFactoryContext) => {
  const isAllowed = store.getters['user/isResourceAvailable'](
    Resources.Access.main,
  );

  if (isAllowed) {
    next();
  } else {
    router.push({ name: 'noAccess' });
  }
};
