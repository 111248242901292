import { T } from '@/types';

export const ClonePublicationCandidateRequestDto = (
  compositionId: string,
  aspectPublishingSettings: T.AspectPublishingSettings[],
): T.ClonePublicationCandidateRequestDto => {
  return {
    compositionId,
    regionalAspectSpecificInformation: aspectPublishingSettings.map(
      (aspectSettings) => ({
        approvalDate: aspectSettings.approvalDate
          ? new Date(aspectSettings.approvalDate).valueOf()
          : 0,
        aspectId: aspectSettings.aspectId,
        comment: aspectSettings.comment ?? undefined,
        revisionDate: aspectSettings.plannedRevisionDate
          ? new Date(aspectSettings.plannedRevisionDate).valueOf()
          : 0,
      }),
    ),
  };
};
