import { ActiveMode } from '../../objects/enums/ActiveMode';
import { GlobalState as State } from './state';

export type GlobalGetters = typeof globalGetters;

export const globalGetters = {
  isBlockHandling(state: State) {
    return state.blockHandling === true;
  },
  isCommentHandling(state: State) {
    return state.activeMode === ActiveMode.COMMENT_HANDLING;
  },
  isCompositionPreview(state: State) {
    return state.activeMode === ActiveMode.COMPOSITION_PREVIEW;
  },
  isContainerHistory(state: State) {
    return state.activeMode === ActiveMode.CONTAINER_HISTORY;
  },
  isDefault(state: State) {
    return state.activeMode === ActiveMode.DEFAULT;
  },
  isPublicationPreview(state: State) {
    return state.activeMode === ActiveMode.PUBLICATION_PREVIEW;
  },
  isTemplateHandling(state: State) {
    return state.activeMode === ActiveMode.TEMPLATE_HANDLING;
  },
};
