import { PublicationType } from '@/objects/enums';
import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-publication/publications';

export const PublicationProxy = {
  async correctPublication(
    publicationId: string,
    request: T.CorrectionRequestDto,
  ) {
    await proxy.post<T.ExternalPublicationDto>(
      `${URL}/${publicationId}/correction`,
      request,
    );
  },
  async createNationalReview(request: T.CreatePublicationOfTypeRequestDto) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/nationalreviews`,
      request,
    );
  },
  async createNationalReviewPreview(
    request: T.CreatePublicationOfTypeRequestDto,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/nationalreviews/preview`,
      request,
    );
  },
  async createOpinionReview(request: T.CreatePublicationOfTypeRequestDto) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/opinionreviews`,
      request,
    );
  },
  async createPublication(request: T.CreatePublicationRequestDto) {
    return await proxy.post<T.ExternalPublicationDto>(URL, request);
  },
  async createPublicationBasedOnExisting(
    request: T.CreatePublicationRequestDto,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(`${URL}`, request);
  },
  async createPublicationCandidate(
    request: T.CreatePublicationOfTypeRequestDto,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/candidates`,
      request,
    );
  },
  async createPublicationCandidateBasedOnExisting(
    request: T.ClonePublicationCandidateRequestDto,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/candidates/clone_add_regional`,
      request,
    );
  },
  async createPublicationCandidatePreview(
    request: T.CreatePublicationOfTypeRequestDto,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/candidates/preview`,
      request,
    );
  },
  async createPublicationPreviewBasedOnExisting(
    request: T.CreatePublicationOfTypeRequestDto,
    latest?: boolean,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/preview/based_on_existing${latest ? `?latest=${latest}` : ''}`,
      request,
    );
  },
  async createPublicationPreviewFromCandidateBasedOnExisting(
    request: T.CreatePublicationOfTypeRequestDto,
    latest?: boolean,
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/candidates/preview/based_on_existing${
        latest ? `?latest=${latest}` : ''
      }`,
      request,
    );
  },
  async createReview(request: T.CreatePublicationOfTypeRequestDto) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/reviews`,
      request,
    );
  },
  async createReviewPreview(request: T.CreatePublicationOfTypeRequestDto) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/reviews/preview`,
      request,
    );
  },
  async deleteNationalReviewPublication(publicationId: string) {
    await proxy.delete(`${URL}/nationalreviews/${publicationId}`);
  },
  /**
   * @deprecated
   */
  async deletePublicationCandidate(publicationId: string) {
    await proxy.delete(`${URL}/candidates/${publicationId}`);
  },
  async deleteRegionalAddons(
    compId: string,
    pubId: string,
    body: { comment: string; objectIds: string[] },
  ) {
    return await proxy.post<T.ExternalPublicationDto>(
      `${URL}/${pubId}/deleteregional`,
      { ...body, compositionId: compId },
    );
  },
  async deleteReviewPublication(publicationId: string) {
    await proxy.delete(`${URL}/reviews/${publicationId}`);
  },
  async getLatestPublication(compositionId: string) {
    return await proxy.get<T.ExternalPublicationDto>(
      `${URL}/composition/${compositionId}/latest`,
    );
  },
  async getLatestPublicationMetadata(compositionId: string) {
    const response = await proxy.get<T.PublicationMetadataDto[]>(
      `${URL}/composition/${compositionId}/latest/metadata`,
      {
        validateStatus: (status: number) => {
          if ((status >= 200 && status < 300) || status === 404) {
            return true;
          }
          return false;
        },
      },
    );
    if (response.status === 404) {
      return { data: [] };
    }
    return response;
  },
  async getPreviouslyPublished(compositionId: string, assignment: string) {
    return await proxy.get<T.PreviousPublicationExistsDto>(
      `${URL}/${compositionId}/check_for_previously_published/assignment/${assignment}`,
    );
  },
  async getPublication(publicationId: string) {
    return await proxy.get<T.ExternalPublicationDto>(`${URL}/${publicationId}`);
  },
  async getPublicationHistory(compositionId: string) {
    return await proxy.get<T.HistoryPublicationDto[]>(
      `${URL}/compositionid/${compositionId}/history`,
    );
  },
  async getPublicationMetadata(publicationId: string) {
    return await proxy.get<T.PublicationMetadataDto[]>(
      `${URL}/${publicationId}/metadata`,
    );
  },
  async getPublicationVersion(publicationId: string, version: string) {
    return await proxy.get<T.ExternalPublicationDto>(
      `${URL}/${publicationId}/version/${version}`,
    );
  },
  async getPublicationsForComposition(
    compositionId: string,
    type?: PublicationType,
  ) {
    return await proxy.get<T.PublicationForListDto[]>(
      `${URL}/compositionid/${compositionId}`,
      { params: { type } },
    );
  },
  async getPublicationsOfType(
    informationType: T.InformationType,
    index: number,
    pageSize: number,
  ) {
    return await proxy.get<T.ExternalPublicationDto[]>(
      `${URL}/informationtype/${informationType}`,
      {
        headers: {
          index,
          pageSize,
        },
      },
    );
  },
  async pausePublication(compositionId: string, reason: string) {
    return await proxy.put<T.ExternalPublicationDto>(
      `${URL}/pause_latest_distribution/${compositionId}`,
      {
        reason,
      },
    );
  },
  async publishScheduledPublication(publicationId: string) {
    await proxy.post(`${URL}/publish`, { publicationId });
  },
  async rejectPublicationCandidate(publicationId: string, comment = '') {
    await proxy.put(`${URL}/candidates/${publicationId}/reject`, {
      comment,
    });
  },

  async reschedulePublication(payload: T.SchedulePublicationRequestDto) {
    await proxy.post(`${URL}/reschedule`, payload);
  },
};
