import { T } from '@/types';

export const AddOwnershipRequestDto = (
  model: T.CreateOwnershipPayload,
): T.AddOwnershipRequestDto => {
  return {
    groupTag: model.groupTag,
    objectId: model.objectId,
    objectType: model.objectType,
    organizationId: model.organizationId,
    organizationName: model.organizationName,
    ownershipRelation: model.ownershipRelation,
    sectionId: model.sectionId,
    sectionName: model.sectionName,
  };
};
