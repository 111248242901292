import { T } from '@/types';

export const EmailReceiver = (dto: T.EmailReceiverDto): T.EmailReceiver => {
  return {
    created: dto.created,
    createdBy: dto.createdBy,
    email: dto.email,
    fullName: dto.fullName,
    id: dto.id,
    reviewId: dto.reviewId,
    sentDate: dto.sentDate,
    updated: dto.updated,
    updatedBy: dto.updatedBy,
    versionKey: dto.versionKey,
  };
};
