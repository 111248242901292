import { T } from '@/types';

export const ReferenceUtils = {
  convertReferencesToList(
    references: Record<string, T.ReferenceItem[]>,
    objectIds: string[] = [],
  ) {
    const referenceList = [] as T.ReferenceItem[];
    const keys = objectIds.length === 0 ? Object.keys(references) : objectIds;

    keys.forEach((key) =>
      references[key]?.forEach((reference) => {
        referenceList.push(reference);
      }),
    );

    if (referenceList.length <= 1) {
      return referenceList;
    }

    const uniqueReferences = referenceList.reduce((values, current) => {
      const existingValue = values.some((val) => val.id === current.id);
      if (!existingValue) {
        values.push(current);
      }
      return values;
    }, [] as T.ReferenceItem[]);

    return uniqueReferences;
  },
  getReferenceById(referenceList: T.ReferenceItem[], referenceId: string) {
    return referenceList.filter((reference) => reference.id === referenceId)[0];
  },
  getUpdatedReferenceNumbers(
    contentIds: string[],
    references: Record<string, T.ReferenceItem[]>,
  ) {
    const filteredReferences = this.convertReferencesToList(
      references,
      contentIds,
    );
    const updatedReferenceNumbers = filteredReferences.map((reference, i) => {
      return { id: reference.id, number: i + 1 };
    });

    return updatedReferenceNumbers;
  },
  updateReferenceItems(
    references: Record<string, T.ReferenceItem[]>,
    reference: T.ReferenceItem,
  ) {
    const oldReferences = references;
    const newReferences: Record<string, T.ReferenceItem[]> = {};
    Object.keys(oldReferences).forEach((key) => {
      oldReferences[key]!.forEach((ref, i) => {
        if (ref.id === reference.id) {
          oldReferences[key]![i]! = reference;
          newReferences[key] = oldReferences[key]!;
        }
      });
    });
    return newReferences;
  },
};
