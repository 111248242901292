import { T } from '@/types';

export const StatusUpdateRequestDto = (
  model: T.StatusUpdatePayload,
): T.StatusUpdateRequestDto => {
  return {
    newStatus: model.newStatus,
    objectId: model.objectId,
  };
};
