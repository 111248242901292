import { T } from '@/types';

export const CodeSystem = (dto: T.CodeSystemDto): T.CodeSystem => {
  return {
    active: dto.active,
    description: dto.description ?? '',
    oid: dto.oid,
    versionName: dto.versionName ?? '',
  };
};

// TODO: Migrate to new code system structure when backend has updated data structure for code systems
export const NewCodeSystem = (dto: T.NewCodeSystemDto): T.CodeSystem => {
  return {
    active: true,
    description: dto.description ?? '',
    oid: dto.codeSystemOID,
    versionName: dto.versionKey ?? '',
  };
};
