import { T } from '@/types';

export const TemplateAspect = (dto: T.TemplateAspectDto): T.TemplateAspect => {
  return {
    code: dto.code,
    id: dto.id,
    initials: dto.initials,
    name: dto.name,
    oid: dto.oid,
  };
};
