import { T } from '@/types';
import { TableRowDto } from './TableRowDto';

export const TableContentDto = (
  model: T.TableContentData,
): T.TableContentDto => {
  return {
    columnHeaders: model.columnHeaders,
    referenceId: model.referenceId,
    rows: model.rows.map(TableRowDto),
    tableHeader: model.tableHeader,
  };
};
