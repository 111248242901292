import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';
import { AxiosResponse } from 'axios';
import { AggregatorProxy as proxy } from '../proxies';

export const AggregatorService = {
  async createComposition(payload: T.CreateCompositionPayload) {
    const request = dtos.CreateCompositionRequestDto(payload);
    const response = await proxy.createComposition(request);
    return models.CompositionWithContainersAndMetadata(response.data);
  },
  async createWordExport(compositionId: string, aspectId?: string) {
    const response = await proxy.createWordExport(compositionId, aspectId);
    return response.data;
  },
  async deleteAssociatedReferences(payload: T.ReferenceAssociationBody) {
    const res = await proxy.deleteAssociatedReferences(payload);
    return res;
  },
  async deleteCompositionReference(compositionId: string, referenceId: string) {
    const res = await proxy.deleteCompositionReference(
      compositionId,
      referenceId,
    );
    return res.data;
  },
  async getBrokenLink(
    requestId: string,
  ): Promise<AxiosResponse<T.BrokenLinksDto>> {
    const response = await proxy.getBrokenLinks(requestId);
    if (response.data.status === 'IN_PROGRESS') {
      await this.pause(500);
      return this.getBrokenLink(requestId);
    }
    return response;
  },
  async getBrokenLinks(includeNpos: boolean) {
    const requestId = await proxy.getRequestID(includeNpos);
    const response = await this.getBrokenLink(requestId.data.requestId);
    return models.BrokenLinks(response.data);
  },
  async getCompositionDecorate(compositionId: string) {
    const res = await proxy.getCompositionDecorate(compositionId);
    return res.data;
  },
  async getOutOfSyncContainerObjects(compositionId: string) {
    const response = await proxy.getOutOfSyncContainerObjects(compositionId);
    return Object.entries(response.data).reduce(
      (object, [id, containerObject]) => {
        // eslint-disable-next-line no-param-reassign
        object[id] = models.ContainerObject(containerObject);
        return object;
      },
      {} as Record<string, T.ContainerObject>,
    );
  },
  async getTimemachine(
    compositionId: string,
    containerUUID: string,
    commit?: number,
  ) {
    const response = await proxy.getTimemachine(
      compositionId,
      containerUUID,
      commit,
    );
    return models.ContainerTimeline(response.data);
  },
  async migrateComposition(compositionId: string, dryRun: boolean) {
    const response = await proxy.migrateComposition(compositionId, dryRun);
    return models.ExternalMigrationInformation(response.data);
  },
  async pause(time: number) {
    return new Promise<void>((resolve) => setTimeout(() => resolve(), time));
  },
};
