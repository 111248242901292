import { Services } from '@/services';

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  const unloadEvent = event;

  unloadEvent.preventDefault();
  unloadEvent.returnValue =
    'Det finns osparade ändringar. Är du säker på att du vill lämna sidan?';
};

export const WindowUnloadHelper = {
  allowUnload: () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  },
  preventUnload: () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  },
};

export function registerLockCleanup() {
  window.addEventListener('pagehide', () => {
    Services.Ownership.removeAllLocksForUser();
  });
}
