import { T } from '@/types';

export const MoveContainerObjectsRequestDto = (
  model: T.MoveContainerObjectsPayload,
): T.MoveContainerObjectsRequestDto => {
  return {
    sourceObjects: model.sourceObjects,
    targetContainerUUID: model.targetContainerUUID,
    targetParentId: model.targetParentId,
  };
};
