import { ObjectType } from '@/objects/enums';
import { T } from '@/types';

export const CompositionUtils = {
  findContainerObject(state: T.CompositionState, containerObjectId: string) {
    const indexes = CompositionUtils.getContentIndexes(
      state.containers,
      containerObjectId,
    );

    if (indexes) {
      const container = state.containers[indexes.containerIndex];
      if (container) {
        const containerObjectId =
          container.containerObjectIds[indexes.contentIndex];
        if (containerObjectId != null) {
          const containerObject = state.containerObjects[containerObjectId];
          return containerObject ?? null;
        }
      }
    }

    return null;
  },
  getContainerObjectIdsByType(containerObjectList: T.ContainerObject[]) {
    return containerObjectList.reduce(
      (total: { contentIds: string[]; processBlockIds: string[] }, content) => {
        switch (content.objectType) {
          case ObjectType.PROCESS_BLOCK:
            total.processBlockIds.push(content.id);
            break;
          default:
            total.contentIds.push(content.id);
            break;
        }
        return total;
      },
      { contentIds: [], processBlockIds: [] },
    );
  },
  getContentIndexes(
    containers: T.Container[],
    contentId: string,
  ): T.IndexMap | null {
    const containerIndex = containers.findIndex((container) => {
      return container.containerObjectIds.find(
        (containerObjectId) => containerObjectId === contentId,
      );
    });

    if (containerIndex !== -1) {
      const container = containers[containerIndex];
      if (container) {
        const contentIndex = container.containerObjectIds.findIndex(
          (containerObjectId) => {
            return containerObjectId === contentId;
          },
        );

        if (contentIndex > -1) {
          return { containerIndex, contentIndex };
        }
      }
    }

    return null;
  },
};
