<template>
  <v-app>
    <router-view name="leftNavBar" />
    <router-view name="header" />
    <v-main class="background-color">
      <router-view name="main" />
    </v-main>
    <v-snackbar
      v-model="showSystemMessage"
      :top="activeSystemMessage.top"
      :bottom="activeSystemMessage.bottom"
      :right="activeSystemMessage.right"
      :left="activeSystemMessage.left"
      :color="activeSystemMessage.color"
      :timeout="activeSystemMessage.timeout"
      transition="scroll-y-reverse-transition"
    >
      <v-row no-gutters class="justify-space-between flex-nowrap">
        <p class="ma-0">
          {{
            activeSystemMessage.text
          }}
        </p>
        <v-row
          v-if="systemMessageQueue.length > 1"
          no-gutters
          justify="end"
          class="ml-2"
        >
          <CustomButton
            text
            :outlined="false"
            color="white"
            small
            @click="nextMessage"
          >
            {{ `Nästa(${systemMessageQueue.length - 1})` }}
          </CustomButton>
        </v-row>
        <CustomButton
          v-else
          :outlined="false"
          color="white"
          class="ml-2"
          text
          small
          @click="showSystemMessage = false"
        >
          <span class="material-symbols-outlined">close</span>
        </CustomButton>
      </v-row>
    </v-snackbar>
    <Modal />
  </v-app>
</template>

<script lang="ts">
import { Vue } from '@/vue';
import { useState } from './store';
import Modal from '@/components/Modal.vue';
import CustomButton from '@/components/CustomButton.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Modal,
    CustomButton,
  },
  data: () => ({
    isEssentialsLoaded: false,
  }),
  computed: {
    ...useState({
      activeSystemMessage: 'systemMessage/activeSystemMessage',
      systemMessageQueue: 'systemMessage/systemMessageQueue',
    }),
    showSystemMessage: {
      get(): boolean {
        return this.$store.state.systemMessage.showSystemMessage;
      },
      set(value) {
        if (!value) {
          this.$store.dispatch(
            'systemMessage/showNextSystemMessage',
            undefined,
          );
        }
      },
    },
  },
  async created() {
    await this.$store.dispatch('ownership/fetchOwnershipStructures', undefined);

    this.isEssentialsLoaded = true;
  },
  methods: {
    nextMessage() {
      this.$store.dispatch('systemMessage/showNextSystemMessage', undefined);
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/styles/styles.scss';
@import '@/assets/styles/colors.scss';

#main-nav-bar {
  .v-toolbar__content {
    border-bottom: 1px solid #efefef;
  }
}

.background-color {
  background-color: $background;
}

.dashboard-table-background.v-data-table {
  background-color: rgb(0 0 0 / 0%);
}
</style>
