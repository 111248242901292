import { T } from '@/types';

export type UserState = typeof userState;

export const userState = {
  activeAuthorizationGroup: null as T.SimpleAuthorizationGroup | null,
  allAuthorizationGroups: null as T.SimpleAuthorizationGroup[] | null,
  assignmentGroups: [] as T.Group[],
  availableAuthorizationGroups: [] as T.SimpleAuthorizationGroup[],
  childAuthorizationGroups: [] as T.GroupsForContext[],
  personalGroups: [] as T.Group[],
  publicGroups: [] as T.Group[],
  resources: [] as string[],
  user: null as T.User | null,
};
