import { T } from '@/types';
import { UpdateMetadataRequestDto } from './UpdateMetadataRequestDto';

export const MetadataRequestDto = (
  model: T.MetadataPayload,
): T.MetadataRequestDto => {
  return {
    ...UpdateMetadataRequestDto(model),
    aspectId: model.aspectId,
  };
};
