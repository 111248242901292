import { LocalStorageHelper } from '@/core/helpers';
import { proxy, ProxyConfig } from '@/services';
import { T } from '@/types';
import { UserState as State } from './state';
import Vue from 'vue';

export type UserMutations = typeof userMutations;

export const userMutations = {
  ADD_ASSIGNMENT_GROUP(state: State, group: T.Group) {
    if (
      !state.assignmentGroups.some(
        (existingGroup) => existingGroup.id === group.id,
      )
    ) {
      state.assignmentGroups.push(group);
    }
  },
  ADD_CHILD_AUTHORIZATION_GROUP(state: State, group: T.Group) {
    const context = state.childAuthorizationGroups.find(
      (existingContext) => existingContext.contextValue === group.contextValue,
    );
    if (context) {
      if (
        !context.groups.some((existingGroup) => existingGroup.id === group.id)
      ) {
        context.groups.push(group);
      }
    }
  },
  ADD_MEMBER_TO_ASSIGNMENT_GROUP(
    state: State,
    payload: { groupId: string; newMember: T.Member },
  ) {
    const { groupId, newMember: memberToAdd } = payload;
    const groupToModify = state.assignmentGroups.find(
      (group) => group.id === groupId,
    );
    const index = state.assignmentGroups.findIndex(
      (group) => group.id === groupId,
    );
    if (groupToModify) {
      if (
        !groupToModify.members.some(
          (member) =>
            member.email.toLowerCase() === memberToAdd.email.toLowerCase(),
        )
      ) {
        groupToModify.members.push(memberToAdd);
      } else {
        const memberIndex = groupToModify.members.findIndex(
          (member) => member.email === memberToAdd.email,
        );
        groupToModify.members.splice(memberIndex, 1, memberToAdd);
      }
      Vue.set(state.assignmentGroups, index, groupToModify);
    }
  },
  ADD_MEMBER_TO_CHILD_AUTHORIZATION_GROUP(
    state: State,
    payload: { groupId: string; newMember: T.Member },
  ) {
    const { groupId, newMember: memberToAdd } = payload;
    state.childAuthorizationGroups.forEach((context, i) => {
      const groupToModify = context.groups.find(
        (group) => group.id === groupId,
      );
      const index = context.groups.findIndex((group) => group.id === groupId);
      if (groupToModify) {
        if (
          !groupToModify.members.some(
            (member) =>
              member.email.toLowerCase() === memberToAdd.email.toLowerCase(),
          )
        ) {
          groupToModify.members.push(memberToAdd);
        } else {
          const memberIndex = groupToModify.members.findIndex(
            (member) => member.email === memberToAdd.email,
          );
          groupToModify.members.splice(memberIndex, 1, memberToAdd);
        }
        const newGroupStructure = state.childAuthorizationGroups;
        const newGroup = newGroupStructure[i];
        if (newGroup && newGroup.groups[index]) {
          newGroup.groups[index] = groupToModify;
          Vue.set(state.childAuthorizationGroups, i, newGroup);
        }
      }
    });
  },
  ADD_MEMBER_TO_PERSONAL_GROUP(
    state: State,
    payload: { groupId: string; newMember: T.Member },
  ) {
    const { groupId, newMember: memberToAdd } = payload;
    const groupToModify = state.personalGroups.find(
      (group) => group.id === groupId,
    );
    const index = state.personalGroups.findIndex(
      (group) => group.id === groupId,
    );
    if (groupToModify) {
      if (
        !groupToModify.members.some(
          (member) =>
            member.email.toLowerCase() === memberToAdd.email.toLowerCase(),
        )
      ) {
        groupToModify.members.push(memberToAdd);
      } else {
        const memberIndex = groupToModify.members.findIndex(
          (member) => member.email === memberToAdd.email,
        );
        groupToModify.members.splice(memberIndex, 1, memberToAdd);
      }
      Vue.set(state.personalGroups, index, groupToModify);
    }
  },
  ADD_PERSONAL_GROUP(state: State, group: T.Group) {
    if (
      !state.personalGroups.some(
        (existingGroup) => existingGroup.id === group.id,
      )
    ) {
      state.personalGroups.push(group);
    }
  },
  REMOVE_ASSIGNMENT_GROUP(state: State, groupId: string) {
    const groupIndex = state.assignmentGroups.findIndex(
      (group) => group.id === groupId,
    );
    if (groupIndex > -1) {
      state.assignmentGroups.splice(groupIndex, 1);
    }
  },
  REMOVE_AUTHORIZATION_GROUP(state: State, groupId: string) {
    state.childAuthorizationGroups.forEach((context) => {
      const groupIndex = context.groups.findIndex(
        (group) => group.id === groupId,
      );
      if (groupIndex > -1) {
        context.groups.splice(groupIndex, 1);
      }
    });
  },
  REMOVE_MEMBER_FROM_ASSIGNMENT_GROUP(
    state: State,
    payload: { groupId: string; memberUuid: string },
  ) {
    const { groupId, memberUuid } = payload;
    const group = state.assignmentGroups.find((group) => group.id === groupId);
    if (group) {
      const index = group.members.findIndex(
        (member) => member.uuid.toLowerCase() === memberUuid.toLowerCase(),
      );
      if (index > -1) {
        group.members.splice(index, 1);
      }
    }
  },
  REMOVE_MEMBER_FROM_CHILD_AUTHORIZATION_GROUP(
    state: State,
    payload: { groupId: string; memberUuid: string },
  ) {
    const { groupId, memberUuid } = payload;
    state.childAuthorizationGroups.forEach((context) => {
      const groupToModify = context.groups.find(
        (group) => group.id === groupId,
      );
      if (groupToModify) {
        const memberIndex = groupToModify.members.findIndex(
          (member) => member.uuid.toLowerCase() === memberUuid.toLowerCase(),
        );
        if (memberIndex > -1) {
          groupToModify.members.splice(memberIndex, 1);
        }
      }
    });
  },
  REMOVE_MEMBER_FROM_PERSONAL_GROUP(
    state: State,
    payload: { groupId: string; memberUuid: string },
  ) {
    const { groupId, memberUuid } = payload;
    const groupToModify = state.personalGroups.find(
      (group) => group.id === groupId,
    );
    if (groupToModify) {
      const memberIndex = groupToModify.members.findIndex(
        (member) => member.uuid.toLowerCase() === memberUuid.toLowerCase(),
      );
      if (memberIndex > -1) {
        groupToModify.members.splice(memberIndex, 1);
      }
    }
  },
  REMOVE_PERSONAL_GROUP(state: State, groupId: string) {
    const groupIndex = state.personalGroups.findIndex(
      (group) => group.id === groupId,
    );
    if (groupIndex > -1) {
      state.personalGroups.splice(groupIndex, 1);
    }
  },
  SET_ACTIVE_AUTHORIZATION_GROUP(
    state: State,
    authGroup: T.SimpleAuthorizationGroup | null,
  ) {
    if (state.user && authGroup !== null) {
      LocalStorageHelper.setPreselectedAuthGroup(state.user.userId, authGroup);
      const name = `${authGroup.context}.${authGroup.name}`;
      proxy.defaults.headers![ProxyConfig.authorizationGroupHeaderName] = name;
    }

    state.activeAuthorizationGroup = authGroup;
  },
  SET_ALL_AUTHORIZATION_GROUPS(
    state: State,
    authGroups: T.SimpleAuthorizationGroup[],
  ) {
    state.allAuthorizationGroups = authGroups;
  },
  SET_ASSIGNMENT_GROUPS(state: State, assignmentGroups: T.Group[]) {
    state.assignmentGroups = assignmentGroups;
  },
  SET_AVAILABLE_AUTHORIZATION_GROUPS(
    state: State,
    authGroups: T.SimpleAuthorizationGroup[],
  ) {
    state.availableAuthorizationGroups = authGroups;
  },
  SET_CHILD_AUTHORIZATION_GROUPS(
    state: State,
    childAuthorizationGroups: T.GroupsForContext[],
  ) {
    state.childAuthorizationGroups = childAuthorizationGroups;
  },
  SET_PERSONAL_GROUPS(state: State, personalGroups: T.Group[]) {
    state.personalGroups = personalGroups;
  },
  SET_PUBLIC_GROUPS(state: State, publicGroups: T.Group[]) {
    state.publicGroups = publicGroups;
  },
  SET_RESOURCES(state: State, resources: string[]) {
    state.resources = resources;
  },
  SET_USER(state: State, user: T.User) {
    proxy.defaults.headers![ProxyConfig.ticketHeaderName] = user.ticket;

    state.user = user;
  },
  UPDATE_ASSIGNMENT_GROUP(state: State, group: T.Group) {
    const groupIndex = state.assignmentGroups.findIndex(
      (existingGroup) => existingGroup.id === group.id,
    );
    if (groupIndex > -1) {
      Vue.set(state.assignmentGroups, groupIndex, group);
    } else {
      userMutations.ADD_ASSIGNMENT_GROUP(state, group);
    }
  },
  UPDATE_CHILD_AUTHORIZATION_GROUP(state: State, group: T.Group) {
    const context = state.childAuthorizationGroups.find(
      (existingContext) => existingContext.contextValue === group.contextValue,
    );
    if (context) {
      const groupIndex = context.groups.findIndex(
        (existingGroup) => existingGroup.id === group.id,
      );
      if (groupIndex > -1) {
        Vue.set(context.groups, groupIndex, group);
      } else {
        userMutations.ADD_CHILD_AUTHORIZATION_GROUP(state, group);
      }
    }
  },
  UPDATE_PERSONAL_GROUP(state: State, group: T.Group) {
    const groupIndex = state.personalGroups.findIndex(
      (existingGroup) => existingGroup.id === group.id,
    );
    if (groupIndex > -1) {
      Vue.set(state.personalGroups, groupIndex, group);
    } else {
      userMutations.ADD_PERSONAL_GROUP(state, group);
    }
  },
};
