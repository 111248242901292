import { ReferenceHelper } from '@/core/objects/reference/reference-helper';
import { T } from '@/types';
import { ObjectItem } from './ObjectItem';
import { ReferenceItem } from './ReferenceItem';

export const ReferenceResponse = (
  dto: T.ReferenceResponseDto,
): T.ReferenceResponse => {
  return {
    objectItems: (dto.objectItems ?? []).map(ObjectItem),
    referenceItems: ReferenceHelper.sortReferences(
      (dto.referenceItems ?? []).map(ReferenceItem),
    ),
  };
};
