import { T } from '@/types';

export const UpdateProductionStatusRequestDto = (
  model: T.ProductionStatusUpdatePayload,
): T.UpdateProductionStatusRequestDto => {
  return {
    clientObjectInformation: model.clientObjectInformation,
    newStatus: model.newStatus,
  };
};
