import { T } from '@/types';
import { ContainerObjectRequestDto } from './ContainerObjectRequestDto';

export const AddObjectToContainerRequestDto = (
  model: T.AddObjectToContainerPayload,
): T.AddObjectToContainerRequestDto => {
  return {
    containerObject: ContainerObjectRequestDto(model.containerObject),
    parentId: model.parentId,
  };
};
