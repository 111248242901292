import { T } from '@/types';

export const Question = (dto: T.QuestionDto): T.Question => {
  return {
    id: dto.id,
    objectId: dto.objectId,
    question: dto.question,
    questionId: dto.questionId,
    reviewId: dto.reviewId,
    startIndex: dto.startIndex,
    stopIndex: dto.stoppIndex,
    versionKey: dto.versionKey,
  };
};
