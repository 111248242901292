import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';
import { TableRow } from './TableRow';

export const TableContent = (
  dto: T.ContentDto<ContentType.TABLE>,
): T.TableContent => {
  return {
    ...ContentBase(dto),
    data: {
      columnHeaders: dto.tableContent.columnHeaders,
      referenceId: dto.tableContent.referenceId,
      rows: (dto.tableContent.rows ?? []).map(TableRow),
      tableHeader: dto.tableContent.tableHeader,
    },
    type: ContentType.TABLE,
  };
};
