import { Resources } from '@/core/resources';
import { T } from '@/types';
import { UserState as State } from './state';

export type UserGetters = typeof userGetters;

export const userGetters = {
  activeAuthGroup(state: State) {
    return state.activeAuthorizationGroup
      ? `${state.activeAuthorizationGroup.context}.${state.activeAuthorizationGroup.name}`
      : '';
  },
  getAuthGroupFromContextValue(state: State) {
    return (contextValue: string) => {
      return (
        (state.allAuthorizationGroups ?? []).find((group) => {
          return `${group.context}.${group.name}` === contextValue;
        }) ?? null
      );
    };
  },
  isActiveUserInAssignment: (state: State) => (assignment: string) => {
    return state.availableAuthorizationGroups.some(
      (group) => group.context === assignment,
    );
  },
  isActiveUserInAuthorGroup:
    (state: State) => (assignment: string, authorGroup: string) => {
      return state.availableAuthorizationGroups.some(
        (group) => group.context === assignment && group.name === authorGroup,
      );
    },
  isActiveUserNational(
    state: State,
    _: unknown,
    __: unknown,
    rootGetters: T.GetterTree<{ ownership: T.OwnershipGetters }>,
  ) {
    const nationalOrganizations =
      rootGetters['ownership/nationalOrganizations'];
    if (state.user && nationalOrganizations.length > 0) {
      const nationalOrganization = nationalOrganizations.find(
        (organization) =>
          state.user!.assignmentName === organization.assignment,
      );
      return !!nationalOrganization;
    }
    return false;
  },
  /**
   * NOTE: All editors also have author capabilities.
   */
  isAuthorRole(state: State) {
    return (
      state.resources.includes(Resources.ClientRole.author) ||
      userGetters.isEditorRole(state)
    );
  },
  /**
   * NOTE: All super admins also have editor capabilities.
   */
  isEditorRole(state: State) {
    return (
      state.resources.includes(Resources.ClientRole.editor) ||
      userGetters.isSuperAdmin(state)
    );
  },
  isResourceAvailable(state: State) {
    return (resource: string) => {
      return state.resources.includes(resource);
    };
  },
  isSuperAdmin(state: State) {
    return state.resources.includes(Resources.ClientRole.superAdmin);
  },
};
