import { T } from '@/types';
import { ListRowDto } from '.';

export const ListContentDto = (model: T.ListContentData): T.ListContentDto => {
  return {
    referenceId: model.referenceId,
    rows: model.rows.map(ListRowDto),
    type: model.type,
  };
};
