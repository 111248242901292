import { T } from '@/types';
import { ContentReferenceV2 } from './Reference';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';

export const TextContent = (
  dto: T.ContentDto<ContentType.TEXT>,
): T.TextContent => {
  return {
    ...ContentBase(dto),
    data: {
      blockReferences: dto.textContent.reference
        ? (dto.textContent.reference
            .map(ContentReferenceV2)
            .filter((ref) => ref.targetPart === null) as T.ContentReferenceV2[])
        : [],
      reference: dto.textContent.reference
        ? (dto.textContent.reference
            .map(ContentReferenceV2)
            .filter((ref) => ref.targetPart !== null) as T.ContentReferenceV2[])
        : [],
      text: dto.textContent.text,
    },
    type: ContentType.TEXT,
  };
};
