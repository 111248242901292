import { T } from '@/types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { proxy, ProxyConfig } from '..';

const URL = '/api-ownership/ownerships';
const bvoProxy = axios.create({
  baseURL: process.env.VUE_APP_AUTH_URL,
}) as T.Proxy;

export const OwnershipProxy = {
  async createOwnership(request: T.AddOwnershipRequestDto) {
    return await proxy.post<T.OwnershipDto>(URL, request);
  },
  async createOwnerships(request: T.AddMultipleOwnershipRequestDto) {
    return await proxy.post<T.OwnershipDto[]>(`${URL}/multiple`, request);
  },
  async getAuthGroup(context: string, authGroup: string) {
    return await bvoProxy.get<T.AuthorizationGroupDto>(
      `authorizationgroups/context/${context}/name/${encodeURIComponent(
        authGroup,
      )}?virtualRole=se.skr.vks.editor`,
      {
        headers: {
          'x-hiqaa': Cookies.get(ProxyConfig.ticketName) ?? '',
        },
      },
    );
  },
  async getAuthGroupsByContext(context: string) {
    return await bvoProxy.get<T.AuthorizationGroupDto[]>(
      `authorizationgroups/context/${context}`,
      {
        headers: {
          'x-hiqaa': Cookies.get(ProxyConfig.ticketName) ?? '',
        },
      },
    );
  },
  async getLocks(objectIds: string[]) {
    return await proxy.post<T.OwnershipDto[]>(
      `${URL}/locks/multiple/get`,
      objectIds,
    );
  },
  async getNPOs() {
    return await proxy.get<T.LinkObjectDto[]>(`${URL}/npo`);
  },
  async getOwnershipStructures() {
    return await proxy.get<T.OrganizationStructureDto[]>(`${URL}/structures`);
  },
  async getOwnershipsForGroupTag(groupTag: string, type?: string) {
    return await proxy.get<T.OwnershipDto[]>(`${URL}/grouptag/${groupTag}`, {
      params: { type },
    });
  },
  async getOwnershipsForObject(objectId: string) {
    return await proxy.get<T.OwnershipDto[]>(`${URL}/${objectId}`);
  },
  async getRegions() {
    return await proxy.get<T.Organization[]>(`${URL}/regions`);
  },
  async getSpecificOwnerships(objectIds: string[]) {
    return await proxy.post<T.OwnershipResponseDto[]>(
      `${URL}/multiple/get`,
      objectIds,
    );
  },
  async getSubjectGroups(informationType: string) {
    return await proxy.get<T.SubjectGroupDto[]>(
      `${URL}/subjectgroups/${informationType}`,
    );
  },
  async removeAllLocksForUser() {
    return await proxy.delete(`${URL}/locks/user`);
  },
  async removeLock(objectId: string) {
    return await proxy.delete(`${URL}/locks/${objectId}`);
  },
  async removeLocks(objectIds: string[]) {
    return await proxy.post<T.MultiUnlockResponseDto>(
      `${URL}/locks/multiple/release`,
      objectIds,
    );
  },
  async setLock(request: T.LockRequestDto) {
    return await proxy.post<T.OwnershipDto>(`${URL}/locks`, request);
  },
  async setLocks(request: T.MultiLockRequestDto) {
    return await proxy.post<T.MultiLockResponseDto>(
      `${URL}/locks/multiple`,
      request,
    );
  },
};
