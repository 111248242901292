import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';

export const HeaderContent = (
  dto: T.ContentDto<ContentType.HEADER>,
): T.HeaderContent => {
  return {
    ...ContentBase(dto),
    data: {
      text: dto.headerContent.text,
      type: dto.headerContent.type,
    },
    type: ContentType.HEADER,
  };
};
