import { T } from '../../types';
import { ImageMapArea } from './ImageMapArea';

export const ProcessBlockImage = (
  dto: T.ProcessBlockImageDto,
): T.ProcessBlockImage => {
  return {
    alternativeText: dto.alternativeText,
    area: dto.area?.map((imageMapArea) => {
      return ImageMapArea(imageMapArea);
    }),
    pictureCreator: dto.pictureCreator,
    pictureText: dto.pictureText,
    url: dto.url,
  };
};
