import { T } from '@/types';
import { ListContentDto } from '.';


export const CellDataDto = (model: T.TableCell): T.CellDataDto => {
  return {
    listContent: model.listContent ? ListContentDto(model.listContent) : undefined,
    referenceContent: model.referenceContent,
    textContent: model.textContent,
    type: model.type,
  }
}