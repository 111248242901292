import { T } from '@/types';

export const SimpleAuthorizationGroups = (
  dto: T.SimpleAuthorizationGroupsDto,
): T.SimpleAuthorizationGroups => {
  return {
    assignmentSpan: dto.assignmentSpan,
    authorizationGroups: dto.authorizationGroups,
  };
};
