import { T } from '@/types';
import { Markup } from './Markup';

export const Composition = (dto: T.CompositionDto): T.Composition => {
  return {
    commitNumber: dto.commitNumber,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    deleted: dto.deleted ?? false,
    finalized: dto.finalized,
    id: dto.id,
    informationType: dto.informationType,
    markup: Markup(dto.markup),
    productionStatus: dto.productionStatus,
    publishedDate: dto.publishedDate,
    status: dto.status,
    title: dto.title,
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
