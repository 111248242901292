import { T } from '@/types';
import { ContentHelper } from '@/core/objects/content';
import { CompositionState as State } from './state';
import { CompositionUtils } from './utils';
import { ReferenceUtils } from '../reference/utils';
import { AspectChecker } from '@/core/checkers';

export type CompositionGetters = typeof compositionGetters;

export const compositionGetters = {
  getAllReferences(state: State, _: unknown, rootState: T.RootState) {
    let refIndex = 1;
    const updatedReferences: Record<string, number> = {};
    return state.containers.reduce((allReferences, container) => {
      container.containerObjectIds.forEach((containerId) => {
        const content = rootState.content.contents[containerId];
        const containerObject =
          rootState.composition.containerObjects[containerId];

        if (
          !(
            rootState.author.showAllAspects ||
            rootState.composition.selectedAspectsRead.length === 0 ||
            (containerObject &&
              AspectChecker.isAnyAspectInList(
                containerObject.markup.aspects,
                rootState.composition.selectedAspectsRead,
              ))
          )
        ) {
          return;
        }
        if (!content) {
          return;
        }
        const refs = content.aggregatedReferenceIds;
        refs.forEach((ref) => {
          if (!updatedReferences[ref]) {
            updatedReferences[ref] = refIndex;
            refIndex += 1;
            allReferences.push({
              id: ref,
              number: updatedReferences[ref] ?? 0,
              text:
                state.activeCompositionReferencesV2.find(
                  (curr) => curr.id === ref,
                )?.text ?? '',
            });
          }
        });
      });
      return allReferences;
    }, [] as T.ReferenceItem[]);
  },
  getAllSelectableContentIds(
    state: State,
    _: unknown,
    rootState: T.RootState,
    rootGetters: T.GetterTree<{
      author: T.AuthorGetters;
      ownership: T.OwnershipGetters;
    }>,
  ) {
    const isAspectMatchingComposition =
      rootGetters['author/isAspectMatchingComposition'];
    const isObjectOwnedByActiveUser =
      rootGetters['ownership/isObjectOwnedByActiveUser'];
    const { containerObjects, containers } = state;
    const { contents } = rootState.content;

    return containers.flatMap((container) => {
      return container.containerObjectIds.filter((objectId) => {
        const content = contents[objectId];
        const containerObject = containerObjects[objectId];
        return (
          content &&
          isObjectOwnedByActiveUser(objectId) &&
          !ContentHelper.isTemplateHeader(content) &&
          containerObject &&
          isAspectMatchingComposition(containerObject)
        );
      });
    });
  },
  getContainerObjectInfoAtIndex(state: State) {
    return (index: number) => {
      const container = state.containers[index];
      return {
        clientObjectInformation: {
          commitNumber: container?.commitNumber ?? null,
          versionNumber: container?.versionNumber ?? null,
        },
      };
    };
  },
  getContainerObjectListForContainer(state: State) {
    return (container: T.Container | T.ContainerSnapshot) => {
      const containerObjectList: T.ContainerObject[] = [];

      container.containerObjectIds.forEach((containerObjectId) => {
        const containerObject = state.containerObjects[containerObjectId];
        if (containerObject) {
          if (
            !containerObjectList.some(({ id }) => containerObject.id === id)
          ) {
            containerObjectList.push(containerObject);
          }
        }
      });

      return containerObjectList;
    };
  },
  getContentIndexes(state: State) {
    return (contentId: string) => {
      return CompositionUtils.getContentIndexes(state.containers, contentId);
    };
  },
  getReferenceById(state: State) {
    return (referenceId: string) => {
      const referenceList = ReferenceUtils.convertReferencesToList(
        state.activeCompositionReferences,
      );
      return ReferenceUtils.getReferenceById(referenceList, referenceId);
    };
  },
  isActiveCompositionReadOnly(
    state: State,
    _: unknown,
    rootState: T.RootState,
    rootGetters: T.GetterTree<{
      user: T.UserGetters;
      ownership: T.OwnershipGetters;
    }>,
  ) {
    if (!state.activeComposition) {
      return false;
    }
    const isActiveUserOwnerOrMember =
      rootGetters['ownership/isActiveUserOwnerOrMember'];
    const isActiveUserNational = rootGetters['user/isActiveUserNational'];
    const isEditorRole = rootGetters['user/isEditorRole'];

    return (
      !isActiveUserOwnerOrMember(state.activeComposition.id) &&
      (!isActiveUserNational || !isEditorRole)
    );
  },
  isCompositionModified(state: State, _: unknown, rootState: T.RootState) {
    return (
      Object.keys(rootState.content.modifiedContents).length > 0 ||
      state.compositionObjectModified
    );
  },
};
