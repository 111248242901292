import { T } from '@/types';
import { Vue } from '@/vue';
import { CompositionState as State } from './state';
import { CompositionUtils } from './utils';

export type CompositionMutations = typeof compositionMutations;

export const compositionMutations = {
  ADD_COMPOSITION(state: State, composition: T.CompositionForList) {
    if (!state.compositions.some(({ id }) => composition.id === id)) {
      state.compositions.push(composition);
    }
  },
  ADD_CONTAINER_OBJECT_AT_INDEX(
    state: State,
    payload: { containerObject: T.ContainerObject; indexes: T.IndexMap },
  ) {
    const { containerIndex, contentIndex } = payload.indexes;
    const container = state.containers[containerIndex];
    if (container) {
      const objectId = payload.containerObject.id;

      Vue.set(state.containerObjects, objectId, payload.containerObject);

      if (!container.containerObjectIds.includes(objectId)) {
        container.containerObjectIds.splice(contentIndex, 0, objectId);
      }
    }
  },
  ADD_OBJECT_ASPECT(
    state: State,
    payload: {
      aspect: T.Aspect;
      containerObjectId: string;
    },
  ) {
    const containerObject = state.containerObjects[payload.containerObjectId];
    if (containerObject?.markup) {
      if (
        !containerObject.markup.aspects.some(
          ({ id }) => payload.aspect.id === id,
        )
      ) {
        containerObject.markup.aspects.push(payload.aspect);
      }
    }
  },
  ADD_REFERENCES_ON_CONTENT(state: State, referenceMapping: T.ObjectItem) {
    if (referenceMapping.objectId) {
      const existingMapping =
        state.activeCompositionReferences[referenceMapping.objectId];
      if (existingMapping) {
        existingMapping.push(...referenceMapping.referenceItems);
      } else {
        Vue.set(
          state.activeCompositionReferences,
          referenceMapping.objectId,
          referenceMapping.referenceItems,
        );
      }
    }
  },
  DELETE_COMPOSITION(state: State, compositionId: string) {
    const index = state.compositions.findIndex((composition) => {
      return composition.id === compositionId;
    });
    state.compositions.splice(index, 1);
  },
  DELETE_CONTAINEROBJECT(state: State, containerObjectId: string) {
    const indexes = CompositionUtils.getContentIndexes(
      state.containers,
      containerObjectId,
    );
    if (indexes) {
      compositionMutations.DELETE_CONTAINEROBJECT_AT_INDEX(state, indexes);
    }
  },
  DELETE_CONTAINEROBJECT_AT_INDEX(state: State, indexes: T.IndexMap) {
    const container = state.containers[indexes.containerIndex];
    const containerObjectId =
      container?.containerObjectIds[indexes.contentIndex];

    if (container && containerObjectId != null) {
      Vue.delete(state.containerObjects, containerObjectId);
      container.containerObjectIds.splice(indexes.contentIndex, 1);
    }
  },
  DELETE_OBJECT_ASPECT(
    state: State,
    payload: T.IndexMap & { aspectIndex: number },
  ) {
    const container = state.containers[payload.containerIndex];
    if (container) {
      const containerObjectId =
        container.containerObjectIds[payload.contentIndex];
      if (containerObjectId != null) {
        const containerObject = state.containerObjects[containerObjectId];
        if (containerObject?.markup) {
          containerObject.markup.aspects.splice(payload.aspectIndex, 1);
        }
      }
    }
  },
  DELETE_REFERENCE_ON_CONTENT(
    state: State,
    payload: { contentId: string; reference: T.ReferenceItem },
  ) {
    const indexToRemove = state.activeCompositionReferences[payload.contentId]
      ?.map((r) => r.id)
      .indexOf(payload.reference.id);
    if (indexToRemove !== undefined && indexToRemove > -1) {
      state.activeCompositionReferences[payload.contentId]!.splice(
        indexToRemove,
        1,
      );
    }
  },
  DELETE_REFERENCE_V2(state: State, referenceId: string) {
    const index = state.activeCompositionReferencesV2.findIndex(
      (ref) => ref.id === referenceId,
    );
    state.activeCompositionReferencesV2.splice(index, 1);
  },
  SET_ACTIVE_COMPOSITION(
    state: State,
    activeComposition: T.Composition | null,
  ) {
    state.activeComposition = activeComposition;
  },
  SET_AVAILABLE_INFORMATION_ACTORS(
    state: State,
    availableInformationActors: T.Code[],
  ) {
    state.availableInformationActors = availableInformationActors;
  },
  SET_BLOCK_MARKUP(
    state: State,
    payload: {
      containerObjectId: string;
      markup: T.Markup;
    },
  ) {
    const containerObject = state.containerObjects[payload.containerObjectId];
    if (containerObject) {
      Vue.set(containerObject, 'markup', payload.markup);
    }
  },
  SET_BROKEN_LINKS(state: State, brokenLinks: T.BrokenLinks) {
    state.brokenLinks = brokenLinks;
  },
  SET_CODE_SYSTEMS_IN_USE(
    state: State,
    codeSystemsInUse: Record<string, T.CodeSystem>,
  ) {
    state.codeSystemsInUse = codeSystemsInUse;
  },
  SET_COMPOSITIONS(state: State, compositions: T.CompositionForList[]) {
    state.compositions = compositions;
  },
  SET_COMPOSITION_MODIFIED(state: State, payload: boolean) {
    state.compositionObjectModified = payload;
  },
  SET_COMPOSITION_PLANNING_OBJECTS(
    state: State,
    payload: T.CompositionPlanningObject[],
  ) {
    state.compositionPlanningObjects = payload;
  },
  SET_COMP_PUBLICATION(state: State, publication: T.PublicationReview | null) {
    Vue.set(state, 'compositionPublication', publication);
  },
  SET_CONTAINEROBJECTS(state: State, containerObjects: T.ContainerObjectMap) {
    state.containerObjects = containerObjects;
  },
  SET_CONTAINERS(state: State, containers: T.Container[]) {
    state.containers = containers;
  },
  SET_IS_PERFORMING_SELECTION_ACTION(
    state: State,
    isPerformingSelectionAction: boolean,
  ) {
    state.isPerformingSelectionAction = isPerformingSelectionAction;
  },
  SET_LEVEL_3_CONTENT_EXISTS(state: State, level3ContentExists: boolean) {
    state.level3ContentExists = level3ContentExists;
  },
  SET_MARKUP_CODES(state: State, codes: T.Code[]) {
    if (state.activeComposition) {
      state.activeComposition.markup = {
        aspects: state.activeComposition.markup.aspects,
        codes,
      };
    }
  },
  SET_METADATA(state: State, metadata: T.Metadata[]) {
    state.metadata = metadata;
  },
  SET_METADATA_FOR_ASPECT(state: State, metadata: T.Metadata) {
    const metadataIndex = state.metadata.findIndex((currMetadata) => {
      return (
        currMetadata.aspectId === metadata.aspectId &&
        currMetadata.assignment === metadata.assignment
      );
    });
    if (metadataIndex > -1) {
      state.metadata.splice(metadataIndex, 1);
      state.metadata.push(metadata);
      Vue.set(state, 'metadata', state.metadata);
    }
  },
  SET_MIGRATION_INFORMATION(
    state: State,
    migrationInformation: T.ExternalMigrationInformation,
  ) {
    state.migrationInformation = migrationInformation;
  },
  SET_PUBLICATION_LOADING(state: State, isLoading: boolean) {
    state.isLoadingCompositionPub = isLoading;
  },
  SET_REFERENCES(state: State, references: Record<string, T.ReferenceItem[]>) {
    state.activeCompositionReferences = references;
  },
  SET_REFERENCES_ON_CONTENT(state: State, referenceMapping: T.ObjectItem) {
    if (referenceMapping.objectId) {
      Vue.set(
        state.activeCompositionReferences,
        referenceMapping.objectId,
        referenceMapping.referenceItems,
      );
    }
  },
  SET_REFERENCES_V2(state: State, references: T.ReferenceV2Dto[]) {
    state.activeCompositionReferencesV2 = references;
  },
  SET_SELECTED_ASPECTS_READ(state: State, aspects: T.Aspect[]) {
    state.selectedAspectsRead = aspects;
  },
  SET_SHOW_BLOCKS_FROM_OTHER_OWNERS(
    state: State,
    showBlocksFromOtherOwners: boolean,
  ) {
    state.showBlocksFromOtherOwners = showBlocksFromOtherOwners;
  },
  SET_TEMPLATE_UPGRADE_AVAILABLE(
    state: State,
    templateUpgradeAvailable: boolean,
  ) {
    state.templateUpgradeAvailable = templateUpgradeAvailable;
  },
  SET_TIMELINE(state: State, payload: T.ContainerTimeline | null) {
    Vue.set(
      state.containerHistory,
      'activeSnapshot',
      payload?.snapshot ?? null,
    );
    Vue.set(state.containerHistory, 'commits', payload?.commits ?? []);
  },
  SET_TIMELINE_ACTIVE_ID(state: State, containerId: string | null) {
    state.containerHistory.activeContainerId = containerId ?? '';
  },
  SET_TIMELINE_CONTAINERIDS_WITH_HISTORY(
    state: State,
    containerIds: string[] | null,
  ) {
    Vue.set(
      state.containerHistory,
      'containerIdsWithHistory',
      containerIds ?? [],
    );
  },
  SET_TIMELINE_PREV_SNAPSHOT(
    state: State,
    snapshot: T.ContainerSnapshot | null,
  ) {
    Vue.set(state.containerHistory, 'prevSnapshot', snapshot);
  },
  UPDATE_COMPOSITION_COMMIT_DATA(
    state: State,
    payload: {
      commitNumber: number;
      versionNumber: number;
    },
  ) {
    if (state.activeComposition) {
      state.activeComposition.commitNumber = payload.commitNumber;
      state.activeComposition.versionNumber = payload.versionNumber;
    }
  },
  UPDATE_COMPOSITION_PLANNING_OBJECT(
    state: State,
    compositionPlanningObject: T.CompositionPlanningObject,
  ) {
    const index = state.compositionPlanningObjects.findIndex(({ uuid }) => {
      return compositionPlanningObject.uuid === uuid;
    });

    if (index !== -1) {
      Vue.set(
        state.compositionPlanningObjects,
        index,
        compositionPlanningObject,
      );
    }
  },
  UPDATE_CONTAINER(state: State, container: T.Container) {
    const index = state.containers.findIndex(({ id }) => {
      return container.id === id;
    });

    if (index !== -1) {
      Vue.set(state.containers, index, container);
    }
  },
  UPDATE_CONTAINER_OBJECT(state: State, containerObject: T.ContainerObject) {
    Vue.set(state.containerObjects, containerObject.id, containerObject);
  },
  UPDATE_REFERENCE(state: State, reference: T.ReferenceV2Dto) {
    const index = state.activeCompositionReferencesV2.findIndex(
      (ref) => ref.id === reference.id,
    );
    state.activeCompositionReferencesV2.splice(index, 1, reference);
  },
};
