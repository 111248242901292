import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';
import { CompositionProxy as proxy } from '../proxies';

export const CompositionService = {
  async archiveComposition(payload: {
    compositionId: string;
    message: string;
  }) {
    await proxy.archiveComposition(payload.compositionId, payload.message);
  },
  async bulkUpdateContainerObjectStatus(
    compositionId: string,
    statusUpdateRequests: T.StatusUpdatePayload[],
  ) {
    const request = {
      requests: statusUpdateRequests.map(dtos.StatusUpdateRequestDto),
    };
    const response = await proxy.bulkUpdateContainerObjectStatus(
      compositionId,
      request,
    );
    return response.data.map(models.ContainerObject);
  },
  async createCompositionPlanningObjects(
    compositionId: string,
    newCompositionPlanningObjects: T.AspectSpecificCompositionPlanningObject[],
  ) {
    const response = await proxy.createCompositionPlanningObjects(
      compositionId,
      newCompositionPlanningObjects.map(
        dtos.AspectSpecificCompositionPlanningObjectDto,
      ),
    );
    return response.data.map(models.CompositionPlanningObject);
  },
  async createMetaForComposition(id: string, payload: T.MetadataPayload) {
    const request = dtos.MetadataRequestDto(payload);
    const response = await proxy.createMetaForComposition(id, request);
    return models.Metadata(response.data);
  },
  async createMultipleMetaForComposition(
    id: string,
    payloads: T.MetadataPayload[],
  ) {
    const request = dtos.MultipleMetadataRequestDto(payloads);
    const response = await proxy.createMultipleMetaForComposition(id, request);
    return response.data.map((metadata) => {
      return models.Metadata(metadata);
    });
  },
  async deleteComposition(id: string) {
    await proxy.deleteComposition(id);
  },
  async deleteContainerObject(
    compositionId: string,
    containerId: string,
    objectId: string,
  ) {
    await proxy.deleteContainerObject(compositionId, containerId, objectId);
  },
  async getAllInformationActors() {
    const response = await proxy.getAllInformationActors();
    return response.data.map(models.Code);
  },
  async getCompositionCommits(compositionId: string) {
    const response = await proxy.getCompositionCommits(compositionId);
    return response.data.map(models.Composition);
  },
  async getCompositionPlanningObjects(compositionId: string) {
    const response = await proxy.getCompositionPlanningObjects(compositionId);
    return response.data.map(models.CompositionPlanningObject);
  },
  async getCompositionWithContainersAndMetadata(id: string) {
    const response = await proxy.getCompositionWithContainersAndMetadata(id);
    return models.CompositionWithContainersAndMetadata(response.data);
  },
  async getCompositions() {
    const response = await proxy.getCompositions();
    return response.data.map(models.CompositionForList);
  },
  async getContainerIdsWithMultipleCommits(compositionId: string) {
    const response = await proxy.getContainerIdsWithMultipleCommits(
      compositionId,
    );
    return response.data;
  },
  async getContainers(id: string) {
    const response = await proxy.getContainers(id);
    return response.data.map(models.Container);
  },
  async getLevel3ContainerObjects(compositionId: string) {
    const response = await proxy.getLevel3ContainerObjects(compositionId);
    return response.data.map(models.ContainerObject);
  },
  async getMetaForComposition(id: string) {
    const response = await proxy.getMetaForComposition(id);
    return response.data.map(models.Metadata);
  },
  async getNotLevel3ContainerObjects(compositionId: string) {
    const response = await proxy.getNotLevel3ContainerObjects(compositionId);
    return response.data.map(models.ContainerObject);
  },

  async moveContainerObjects(
    compositionId: string,
    payload: T.MoveContainerObjectsPayload,
  ) {
    const request = dtos.MoveContainerObjectsRequestDto(payload);
    const response = await proxy.moveContainerObjects(compositionId, request);
    return response.data.map(models.Container);
  },
  async updateCompositionMarkup(
    compositionId: string,
    payload: T.MarkupPayload,
  ) {
    const request = dtos.MarkupRequestDto(payload);
    const response = await proxy.updateCompositionMarkup(
      compositionId,
      request,
    );
    return models.Composition(response.data);
  },
  async updateCompositionPlanningObject(
    compositionId: string,
    uuid: string,
    productionPlanningObject: T.AspectSpecificCompositionPlanningObject,
  ) {
    const response = await proxy.updateCompositionPlanningObject(
      compositionId,
      uuid,
      dtos.AspectSpecificCompositionPlanningObjectDto(productionPlanningObject),
    );
    return models.CompositionPlanningObject(response.data);
  },
  async updateCompositionProductionStatus(
    compositionId: string,
    payload: T.ProductionStatusUpdatePayload,
  ) {
    const request = dtos.UpdateProductionStatusRequestDto(payload);
    const response = await proxy.updateCompositionProductionStatus(
      compositionId,
      request,
    );
    return models.Composition(response.data);
  },
  async updateCompositionTitle(payload: T.CompositionTitleUpdatePayload) {
    const request = dtos.UpdateCompositionTitleRequestDto(payload);
    const response = await proxy.updateCompositionTitle(
      payload.compositionId,
      request,
    );
    return models.Composition(response.data);
  },
  async updateContainerObject(
    compositionId: string,
    containerUUID: string,
    payload: T.AddObjectToContainerPayload,
  ) {
    const request = dtos.AddObjectToContainerRequestDto(payload);
    const response = await proxy.updateContainerObject(
      compositionId,
      containerUUID,
      request,
    );
    return models.Container(response.data);
  },
  async updateContainerObjectsMarkup(
    compositionId: string,
    request: T.MultipleMarkupRequestDto,
  ) {
    const response = await proxy.updateContainerObjectsMarkup(
      compositionId,
      request,
    );
    return response.data.map(models.ContainerObject);
  },
  async updateMetaForComposition(
    compositionId: string,
    aspectId: string,
    payload: T.UpdateMetadataPayload,
  ) {
    const request = dtos.UpdateMetadataRequestDto(payload);
    const response = await proxy.updateMetaForComposition(
      compositionId,
      aspectId,
      request,
    );
    return models.Metadata(response.data);
  },
};
