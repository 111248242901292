import _ from 'lodash';
import { T } from '@/types';
import { PublicationMarkup } from './PublicationMarkup';

export const PublicationContainer = (
  dto: T.PublicationContainerDto,
): T.PublicationContainer => {
  return {
    containerObjectIds: _.uniq(dto.containerObjectIds),
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.uuid,
    markup: PublicationMarkup(dto.markup),
    ordinal: dto.ordinal,
  };
};
