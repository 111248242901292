import { ContextType } from '@/objects/enums';
import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-group/groups';

export const GroupProxy = {
  async addMember(
    contextType: ContextType,
    contextValue: string,
    id: string,
    request: T.MemberRequestDto,
  ) {
    return await proxy.put<T.GroupDto>(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/id/${id}/members`,
      request,
    );
  },
  async changeAccessType(
    contextType: ContextType,
    contextValue: string,
    id: string,
    request: T.SetGroupAccessTypeRequestDto,
  ) {
    return await proxy.put<T.GroupDto>(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/id/${id}/accesstype`,
      request,
    );
  },
  async createGroup(request: T.CreateGroupRequestDto) {
    return await proxy.post<T.GroupDto>(URL, request);
  },
  async deleteGroup(
    contextType: ContextType,
    contextValue: string,
    id: string,
  ) {
    return await proxy.delete(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/id/${id}`,
    );
  },
  async getGroupsForContext(contextType: ContextType, contextValue: string) {
    return await proxy.get<T.GroupDto[]>(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}`,
    );
  },
  async getGroupsForContexts(payloads: T.ContextRequestDto[]) {
    return await proxy.post<T.GroupsForContextDto[]>(
      `${URL}/findformultiplecontext`,
      payloads,
    );
  },
  async getPublicGroups() {
    return await proxy.get<T.GroupDto[]>(`${URL}/search/public/global`);
  },
  async getPublicGroupsForAssignment(assignment: string) {
    return await proxy.get<T.GroupDto[]>(
      `${URL}/search/public/assignment/${assignment}`,
    );
  },
  async removeMember(
    contextType: ContextType,
    contextValue: string,
    id: string,
    email: string,
  ) {
    return await proxy.delete(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/id/${id}/members/${email}`,
    );
  },
  /**
   * Undocumented.
   */
  async removeMembers(
    contextType: ContextType,
    contextValue: string,
    name: string,
    emails: string[],
  ) {
    return await proxy.post<T.GroupDto>(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/name/${name}/members`,
      emails,
    );
  },
  async updateGroup(
    contextType: ContextType,
    contextValue: string,
    id: string,
    request: T.UpdateGroupRequestDto,
  ) {
    return await proxy.put<T.GroupDto>(
      `${URL}/contexttype/${contextType}/contextvalue/${contextValue}/id/${id}`,
      request,
    );
  },
};
