import * as models from '@/objects/models';
import { CodeProxy as proxy } from '../proxies';

export const CodeService = {
  async getAllCodeSystems() {
    const response = await proxy.getAllCodeSystems();
    return response.map(models.NewCodeSystem);
  },
  async searchForCodes(oid: string, query: string, limit: number) {
    const response = await proxy.searchForCodes(oid, query, limit);
    return response.data.matchingCodes.map(models.Code);
  },
};
