import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';
import { TextContentData } from './TextContentData';

export const ReferenceContent = (
  dto: T.ContentDto<ContentType.REFERENCE>,
): T.ReferenceContent => {
  return {
    ...ContentBase(dto),
    data: {
      displayReferenceText: TextContentData(
        dto.referenceContent.displayReferenceText,
      ),
      reference: dto.referenceContent.reference,
    },
    type: ContentType.REFERENCE,
  };
};
