import { ContentType } from '@/objects/enums';
import { T } from '@/types';
import Vue from 'vue';
import { ContentState as State } from './state';
import { ContentUtils } from './utils';

export type ContentMutations = typeof contentMutations;

export const contentMutations = {
  ADD_CONTENT_PROPERTY<K extends keyof T.Content>(
    state: State,
    payload: {
      id: string;
      key: K;
      value: T.Content[K];
    },
  ) {
    const content = state.contents[payload.id];
    if (content) {
      Vue.set(content, payload.key, payload.value);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  ADD_LIST_ITEMS(
    state: State,
    payload: {
      contentId: string;
      index: number;
      items: T.ListRow[];
    },
  ) {
    const content = state.contents[payload.contentId];
    if (content && content.type === ContentType.LIST) {
      content.data.rows.splice(payload.index, 1, ...payload.items);
      Vue.set(state.modifiedContents, payload.contentId, true);
    }
  },
  ADD_LIST_ITEM_AFTER(
    state: State,
    payload: {
      contentId: string;
      id: string;
      item: T.ListRow;
    },
  ) {
    const result = ContentUtils.findListItem(
      state.contents,
      payload.contentId,
      payload.id,
    );

    if (result) {
      result.list.splice(result.index + 1, 0, payload.item);
      Vue.set(state.modifiedContents, payload.contentId, true);
    }
  },
  CLEAR_CONTENT(state: State) {
    Vue.set(state, 'contents', {});
    Vue.set(state, 'focusedContentId', null);
  },
  CLEAR_MODIFIED_CONTENT(state: State) {
    Vue.set(state, 'modifiedContents', {});
  },

  DELETE_CONTENT(state: State, contentId: string) {
    Vue.delete(state.contents, contentId);
    Vue.delete(state.modifiedContents, contentId);
  },
  DELETE_LIST_ITEM(
    state: State,
    payload: {
      contentId: string;
      itemId: string;
    },
  ) {
    const result = ContentUtils.findListItem(
      state.contents,
      payload.contentId,
      payload.itemId,
    );

    if (result) {
      result.list.splice(result.index, 1);
      Vue.set(state.modifiedContents, payload.contentId, true);
    }
  },
  QUEUE_EDITOR_COMMAND(state: State, command: T.EditorCommandQueueItem) {
    state.editorCommandQueue.push(command);
  },
  REMOVE_NEXT_EDITOR_COMMAND(state: State) {
    state.editorCommandQueue.shift();
  },
  SET_ACTIVE_CONTENT(state: State, activeContentId: string | null) {
    state.activeContentId = activeContentId;
  },
  SET_CONTENT(state: State, content: T.Content) {
    Vue.set(state.contents, content.id, content);
  },
  SET_CONTENT_IS_MODIFIED(state: State, contentId: string) {
    Vue.set(state.modifiedContents, contentId, true);
  },
  SET_CONTENT_IS_UNMODIFIED(state: State, contentId: string) {
    Vue.delete(state.modifiedContents, contentId);
  },
  SET_CONTENT_TYPE(
    state: State,
    payload: {
      id: string;
      type: ContentType;
    },
  ) {
    const content = state.contents[payload.id];
    if (content) {
      Vue.set(content, 'type', payload.type);
    }
  },
  SET_FOCUSED_CONTENT(state: State, focusedContentId: string | null) {
    state.focusedContentId = focusedContentId;
  },

  SET_PREVENT_CONTENT_SAVE(state: State, preventContentSave: boolean) {
    state.preventContentSave = preventContentSave;
  },

  SET_SAVING_CONTENT(state: State, savingContent: string | null) {
    Vue.set(state, 'isSavingContent', savingContent);
  },
  UPDATE_ATTACHMENT_PROPERTY<K extends keyof T.AttachmentContentData>(
    state: State,
    payload: {
      id: string;
      key: K;
      value: T.AttachmentContentData[K];
    },
  ) {
    const content = state.contents[payload.id] as
      | T.AttachmentContent
      | undefined;
    if (content) {
      Vue.set(content.data, payload.key, payload.value);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  UPDATE_CONTENT(state: State, content: T.Content) {
    Vue.set(state.contents, content.id, content);
    Vue.set(state.modifiedContents, content.id, true);
  },
  UPDATE_CONTENTS(state: State, newContents: T.Content[]) {
    newContents.forEach((newContent) => {
      const content = state.contents[newContent.id];
      if (!content || newContent.commitNumber !== content.commitNumber) {
        Vue.set(state.contents, newContent.id, newContent);
      }
    });
  },
  UPDATE_HEADER(
    state: State,
    payload: {
      id: string;
      text: string;
    },
  ) {
    const content = state.contents[payload.id] as T.HeaderContent | undefined;
    if (content) {
      Vue.set(content.data, 'text', payload.text);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  UPDATE_LIST_DATA(
    state: State,
    payload: { id: string; data: T.ListContentData },
  ) {
    const content = state.contents[payload.id] as T.ListContent | undefined;
    if (content) {
      Vue.set(content, 'data', payload.data);
      Vue.set(state.modifiedContents, payload.id, true);
    } else {
      throw new Error(
        `Trying to update data on list, but content with id: ${payload.id} does not exist.`,
      );
    }
  },
  UPDATE_PICTURE_PROPERTY<K extends keyof T.PictureContentData>(
    state: State,
    payload: {
      id: string;
      key: K;
      value: T.PictureContentData[K];
    },
  ) {
    const content = state.contents[payload.id] as T.PictureContent | undefined;
    if (content) {
      Vue.set(content.data, payload.key, payload.value);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  UPDATE_PROCESS_PROPERTY<K extends keyof T.ProcessBlock>(
    state: State,
    payload: {
      id: string;
      key: K;
      value: T.ProcessBlock[K];
    },
  ) {
    const content = state.contents[payload.id];
    if (content) {
      Vue.set(content.data, payload.key, payload.value);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  UPDATE_REFERENCE_PROPERTY(
    state: State,
    payload: {
      id: string;
      key: string;
      value: string;
    },
  ) {
    const content = state.contents[payload.id] as
      | T.ReferenceContent
      | undefined;
    if (content) {
      switch (payload.key) {
        case 'text':
          Vue.set(content.data.displayReferenceText, 'text', payload.value);
          break;
        case 'reference':
          Vue.set(content.data, 'reference', payload.value);
          break;
        default:
          break;
      }
      Vue.set(content.data, payload.key, payload.value);
      Vue.set(state.modifiedContents, payload.id, true);
    }
  },
  UPDATE_TABLE_DATA(
    state: State,
    payload: { id: string; data: T.TableContentData },
  ) {
    const content = state.contents[payload.id] as T.TableContent | undefined;
    if (content) {
      Vue.set(content, 'data', payload.data);
      Vue.set(state.modifiedContents, payload.id, true);
    } else {
      throw new Error(
        `Trying to update data on table, but content with id: ${payload.id} does not exist.`,
      );
    }
  },
  UPDATE_TEXT(
    state: State,
    payload: {
      id: string;
      data: T.TextContentData;
    },
  ) {
    const content = state.contents[payload.id] as T.TextContent | undefined;
    if (content) {
      Vue.set(content, 'data', payload.data);
      Vue.set(state.modifiedContents, payload.id, true);
    } else {
      throw new Error(
        `Trying to update text on content, but content with id: ${payload.id} does not exist.`,
      );
    }
  },
};
