import _ from 'lodash';
import { T } from '@/types';

export const UpdateMetadataRequestDto = (
  model: T.UpdateMetadataPayload,
): T.UpdateMetadataRequestDto => {
  return {
    authors: _.uniq(model.authors),
    contact: model.contact,
    markup: model.markup,
    medicalApproved: model.medicalApproved,
    published: model.published,
    responsible: model.responsible,
    reviewers: model.reviewers ? _.uniq(model.reviewers) : undefined,
    revised: model.revised,
    subjectGroups: model.subjectGroups,
  };
};
