import { ContentHelper } from '@/core/objects/content';
import { StatusType } from '@/objects/enums';
import { T } from '../../types';
import { ContentState as State } from './state';

export type ContentGetters = typeof contentGetters;

export const contentGetters = {
  contentOwnedByUserHasLevel3(
    state: State,
    _: unknown,
    rootState: T.RootState,
    rootGetters: T.GetterTree<{
      author: T.AuthorGetters;
      ownership: T.OwnershipGetters;
    }>,
  ): boolean {
    const level3ContentExists = rootState.composition.level3ContentExists;
    if (level3ContentExists) {
      return contentGetters
        .getContentsOwnedByUser(state, rootGetters)
        .some((content) => content.status === StatusType.LEVEL_3);
    }
    return false;
  },
  getActiveContent(state: State) {
    return state.focusedContentId
      ? state.contents[state.focusedContentId]
      : undefined;
  },
  getContentsOwnedByUser(
    state: State,
    rootGetters: T.GetterTree<{
      author: T.AuthorGetters;
      ownership: T.OwnershipGetters;
    }>,
  ): T.Content[] {
    const filteredContents: T.Content[] = [];
    const isObjectOwnedByActiveUser =
      rootGetters['ownership/isObjectOwnedByActiveUser'];
    Object.keys(state.contents).forEach((objectId) => {
      if (isObjectOwnedByActiveUser(objectId)) {
        const content = state.contents[objectId];
        if (content) {
          filteredContents.push(content);
        }
      }
    });
    return filteredContents;
  },
  getNearestSavedContainerObjectId(state: State) {
    return (container: T.Container, index: number) => {
      for (let i = index - 1; i >= 0; i -= 1) {
        const contentId = container.containerObjectIds[i];
        if (contentId != null) {
          const content = state.contents[contentId];

          if (content && !ContentHelper.isContentNewAndUnsaved(content)) {
            return content.id;
          }
        }
      }

      return null;
    };
  },
  hasContent(state: State) {
    return (contentId: string) => {
      return !!state.contents[contentId];
    };
  },
  isContentModified(state: State) {
    return (content: T.Content) => {
      return !!state.modifiedContents[content.id];
    };
  },
};
