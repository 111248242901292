import { T } from '@/types';
import { ContentType } from '../enums';

export const CreateContentPayload = (
  content: T.Content,
): T.CreateContentPayload => {
  return {
    attachmentContent:
      content.type === ContentType.ATTACHMENT_REFERENCE ? content.data : null,
    headerContent: content.type === ContentType.HEADER ? content.data : null,
    listContent: content.type === ContentType.LIST ? content.data : null,
    overrideId: content.overrideId,
    pictureContent:
      content.type === ContentType.PICTURE_REFERENCE ? content.data : null,
    referenceContent:
      content.type === ContentType.REFERENCE ? content.data : null,
    tableContent: content.type === ContentType.TABLE ? content.data : null,
    textContent: content.type === ContentType.TEXT ? content.data : null,
    type: content.type,
  };
};
