import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';

export const AttachmentContent = (
  dto: T.ContentDto<ContentType.ATTACHMENT_REFERENCE>,
): T.AttachmentContent => {
  return {
    ...ContentBase(dto),
    data: {
      approvedBy: dto.attachmentContent.approvedBy ?? '',
      attachmentType: dto.attachmentContent.attachmentType ?? '',
      contentResponsible: dto.attachmentContent.contentResponsible ?? '',
      fileName: dto.attachmentContent.fileName ?? '',
      fileSize: dto.attachmentContent.fileSize,
      mediaType: dto.attachmentContent.mediaType ?? '',
      reference: dto.attachmentContent.reference ?? '',
      validFrom: dto.attachmentContent.validFrom,
      version: dto.attachmentContent.version ?? '',
    },
    type: ContentType.ATTACHMENT_REFERENCE,
  };
};
