import { T } from '@/types';

export const SimpleProcessBlock = (
  dto: T.SimpleProcessBlockDto,
): T.SimpleProcessBlock => {
  return {
    blockHandle: dto.blockHandle,
    commitNumber: dto.commitNumber,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.id,
    status: dto.status,
    title: dto.title,
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
