import { AggregatorService } from './services/aggregator-service';
import { AuthenticationService } from './services/authentication-service';
import { CodeService } from './services/code-service';
import { CompositionService } from './services/composition-service';
import { ContentService } from './services/content-service';
import { GroupService } from './services/group-service';
import { OwnershipService } from './services/ownership-service';
import { ProcessBlockService } from './services/process-block-service';
import { ProductionPlanningService } from './services/production-planning-service';
import { PublicationService } from './services/publication-service';
import { ReferenceService } from './services/reference-service';
import { ReferenceServiceV2 } from './services/reference-service-v2';
import { ReviewService } from './services/review-service';
import { SearchService } from './services/search-service';
import { TemplateService } from './services/template-service';

export const Services = {
  Aggregator: AggregatorService,
  Authentication: AuthenticationService,
  Code: CodeService,
  Composition: CompositionService,
  Content: ContentService,
  Group: GroupService,
  Ownership: OwnershipService,
  ProcessBlock: ProcessBlockService,
  ProductionPlanning: ProductionPlanningService,
  Publication: PublicationService,
  Reference: ReferenceService,
  ReferenceV2: ReferenceServiceV2,
  Review: ReviewService,
  Search: SearchService,
  Template: TemplateService,
};
