import { T } from '@/types';

export const LinkObject = (dto: T.LinkObjectDto): T.LinkObject => {
  return {
    description: dto.description,
    id: dto.id,
    longName: dto.longName,
    name: dto.name,
    url: dto.url,
  };
};
