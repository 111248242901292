import { T } from '@/types';

export type PublicationState = typeof publicationState;

export const publicationState = {
  activePublication: null as T.PublicationReview | null,
  activePublicationMetadata: null as T.PublicationMetadata | null,
  activePublicationReferences: {} as Record<string, T.ReferenceItem[]>,
  correctedPublicationContent: {} as T.CorrectedPublicationContentMap,
  correctedPublicationMetadata: [] as T.CorrectionMetadataObject[],
  correctedPublicationProcessBlocks: {} as T.CorrectedPublicationContentMap,
  correctedPublicationTitle: null as string | null,
  highlightedContent: null as string | null,
  publications: [] as T.PublicationReview[],
  publishedPublication: false,
  selectedAspectsRead: [] as T.Aspect[],
  showBlocksFromOtherOwners: false,
};
