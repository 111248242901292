import _ from 'lodash';
import { T } from '@/types';

export const MultiUnlockResponse = (
  dto: T.MultiUnlockResponseDto,
): T.MultiUnlockResponse => {
  return {
    failedObjectIds: dto.failedObjectIds,
    releasedLocks: _.uniq(dto.releasedLocks),
    unreleasedLocks: dto.unreleasedLocks,
  };
};
