import * as dtos from '@/objects/dtos';
import { AddContainerRequestDto } from '@/objects/dtos/AddContainerRequestDto';
import { HeaderType } from '@/objects/enums';
import * as models from '@/objects/models';
import { T } from '@/types';
import { TemplateProxy as proxy } from '../proxies';

export const TemplateService = {
  async addTemplateContainer(
    headers: T.TemplateHeader[],
    templateId: string,
    parentContainerUUID?: string,
  ) {
    const request = AddContainerRequestDto(headers, parentContainerUUID);
    const response = await proxy.addTemplateContainer(templateId, request);
    return models.Template(response.data);
  },
  async addTemplateHeader(
    request: T.TemplateHeaderDto,
    templateId: string,
    containerUUID: string,
  ) {
    const response = await proxy.addTemplateHeader(
      request,
      templateId,
      containerUUID,
    );
    return models.Template(response.data);
  },
  async createTemplate(template: T.NewTemplate) {
    const request = dtos.CreateTemplateRequestDto(template);
    const response = await proxy.createTemplate(request);
    return models.Template(response.data);
  },
  async getAvailableAspects() {
    const response = await proxy.getAvailableAspects();
    return response.data.map(models.TemplateAspect);
  },
  async getInformationTypes() {
    const response = await proxy.getInformationTypes();
    return response.data.map(models.InformationTypeModel);
  },
  async getTemplate(id: string) {
    const response = await proxy.getTemplate(id);
    return models.Template(response.data);
  },
  async getTemplates() {
    const response = await proxy.getTemplates();
    return response.data.map(models.Template);
  },
  async moveHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
    targetContainerUUID: string,
    targetParentHeaderUUID?: string,
  ) {
    const request = {
      targetContainerUUID,
      targetParentHeaderUUID,
    };
    const response = await proxy.moveHeaderInTemplate(
      templateId,
      containerUUID,
      headerUUID,
      request,
    );
    return models.Template(response.data);
  },
  async moveTemplateContainer(
    templateId: string,
    containerUUID: string,
    targetParentContainerUUID?: string,
  ) {
    const response = await proxy.moveTemplateContainer(
      templateId,
      containerUUID,
      targetParentContainerUUID,
    );
    return models.Template(response.data);
  },
  async removeContainerFromTemplate(templateId: string, containerUUID: string) {
    const response = await proxy.removeContainerFromTemplate(
      templateId,
      containerUUID,
    );
    return models.Template(response.data);
  },
  async removeHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
  ) {
    const response = await proxy.removeHeaderInTemplate(
      templateId,
      containerUUID,
      headerUUID,
    );
    return models.Template(response.data);
  },
  async removeTemplate(id: string, ignoreFinalized: boolean) {
    await proxy.removeTemplate(id, ignoreFinalized);
  },
  async templateAllowsAddons(templateId: string, versionKey: string) {
    const response = await proxy.templateAllowsAddons(templateId, versionKey);
    return response.data;
  },
  async updateAddonSettings(addonsAllowed: boolean, templateId: string) {
    const response = await proxy.updateAddonSettings(addonsAllowed, templateId);
    return models.Template(response.data);
  },
  async updateHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
    header: string,
    headerType: HeaderType,
  ) {
    /* TemplateHeaderDto */
    const request = { header, headerType };
    const response = await proxy.updateHeaderInTemplate(
      templateId,
      containerUUID,
      headerUUID,
      request,
    );
    return models.Template(response.data);
  },
  async updateMetaTemplateData(
    request: T.UpdateTemplateRequestDto,
    templateId: string,
  ) {
    const response = await proxy.updateMetaTemplateData(templateId, request);
    return models.Template(response.data);
  },
  async updateTemplate(id: string, payload: T.Template) {
    const request = dtos.UpdateTemplateRequestDto(payload);
    const response = await proxy.updateTemplate(id, request);
    return models.Template(response.data);
  },
};
