import { T } from '@/types';
import { Aspect } from './Aspect';
import { CodeSystem } from './CodeSystem';
import { InformationTypeModel } from './InformationTypeModel';
import { TemplateContainer } from './TemplateContainer';

export const Template = (dto: T.TemplateDto): T.Template => {
  return {
    addonsAllowed: dto.addonsAllowed,
    commitNumber: dto.commitNumber,
    containers: dto.containers.map(TemplateContainer),
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    deleted: dto.deleted,
    finalized: dto.finalized,
    id: dto.id,
    informationType: InformationTypeModel(dto.informationType),
    name: dto.name,
    validAspects: dto.validAspects.map(Aspect).sort((a, b) => {
      return b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0;
    }),
    validCodeSystems: dto.validCodeSystems.map(CodeSystem),
    validCodeSystemsForDiagnosis:
      dto.validCodeSystemsForDiagnosis.map(CodeSystem),
    validHeaders: dto.validHeaders,
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
