import { T } from '@/types';
import { CommentStatus, ReviewStatus } from '../enums';
import { ListReviewComment } from './Comment';
import { ReviewMetaData } from './ReviewMetaData';

export const Review = (dto: T.ReviewDto): T.Review => {
  return {
    auxiliaryMessage: dto.auxiliaryMessage ?? '',
    created: dto.created,
    createdBy: dto.createdBy,
    deleteStatus: dto.deleteStatus,
    expires: dto.expires,
    header: dto.header,
    id: dto.reviewId,
    message: dto.message,
    metaData: ReviewMetaData(dto.metaData),
    ownerGroup: dto.ownerGroup,
    ownerGroupId: dto.ownerGroupId,
    publicLinkId: dto.publicLinkId,
    publicationId: dto.id,
    publicationName: dto.publicationName,
    responsible: dto.responsible,
    reviewStatus: dto.reviewStatus,
    reviewType: dto.reviewType,
    statusChangeTimeInMs: dto.statusChangeTimeInMs,
    updated: dto.updated,
    updatedBy: dto.updatedBy,
    versionKey: dto.versionKey,
  };
};

export const ListReview = (dto: T.ListReviewHitDto): T.ListReviewHit => {
  // Receivers from comments when the reviewStatus == CLOSED
  const closedReceivers = dto.comments.reduce(
    (receivers: Record<string, T.ReceiverInfo>, currComment) => {
      const newReceivers = receivers;
      if (currComment.receiverInfo) {
        newReceivers[currComment.receiverInfo.email] = currComment.receiverInfo;
      }
      return newReceivers;
    },
    {},
  );

  return {
    ...dto,
    closedReceivers:
      dto.reviewStatus === ReviewStatus.CLOSED
        ? Object.values(closedReceivers)
        : [],
    comments: dto.comments.map(ListReviewComment),
    nrOfUnhandledComments: dto.comments.filter(
      (comment) => comment.commentStatus === CommentStatus.OPEN,
    ).length,
  };
};
