const IneraColors = {
  ineraBlack: '#1b1b1b',
  ineraDisableGrey: '#f5f5f5',
  ineraEarth2: '#6F5E4D',
  ineraGrey: '#5f5f5f',
  ineraLightGrey: '#e0e0e0',
  ineraOcean3: '#00706e',
  ineraOcean4: '#e6f1f1',
  ineraSun: '#f6a519',
  ineraSun3: '#f08122',
  ineraSun4: '#fef6e8',
};

export const Colors = {
  ...IneraColors,
  action: '#3b4971',
  addon: '#2ad832',
  addonBackground: '#c1f7ac',
  background: '#f7f4f2',
  cancel: '#969696',
  danger: '#de4343',
  dangerDark: '#A73232',
  dangerLight: '#FAE0E0',
  default: '#cccccc',
  error: '#E81313',
  gray: '#aeabab',
  grayLight: '#E0E0E0',
  otherAddon: '#d87a66',
  otherAddonBackground: '#f7e5dd',
  primary: '#00706e',
  primaryAccent: '#e6f3f2',
  reviewBlue: '#002670',
  reviewYellow: '#F3BC5C',
  success: '#5bba8a',
  textDefault: '#000000',
  warning: '#e1a02b',
};
