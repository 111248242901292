import { T } from '@/types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ProxyConfig } from '..';

const proxy = axios.create({
  baseURL: process.env.VUE_APP_AUTH_URL,
}) as T.Proxy;

export const AuthenticationProxy = {
  async checkTicket(request: T.ValidateTicketRequestDto) {
    return await proxy.post<T.ValidateTicketResponseDto>(
      '/auth/ticket/validate',
      request,
    );
  },
  async getAllAuthorizationGroups() {
    return await proxy.get<T.SimpleAuthorizationGroupDto[]>(
      '/authorizationgroups/simplified',
    );
  },
  async getAuthorizationGroups() {
    return await proxy.get<T.SimpleAuthorizationGroupsDto>(
      '/authorizationgroups/findby/ticket',
      {
        headers: {
          'x-hiqaa': Cookies.get(ProxyConfig.ticketName) ?? '',
        },
      },
    );
  },
  async getResourcesForGroup(context: string, name: string) {
    return await proxy.get<string[]>(
      `/authorizationgroups/${context}/${name}/resources`,
      {
        headers: {
          'x-hiqaa': Cookies.get(ProxyConfig.ticketName) ?? '',
        },
      },
    );
  },
  async getTicket(guid: T.GuidDto) {
    return await proxy.post<T.TicketDto>('/auth/login/guid', guid);
  },
  async getTicketInfo(ticket: T.TicketDto) {
    return await proxy.post<T.TicketInformationDto>(
      '/auth/ticket/info',
      ticket,
    );
  },
};
