import { OwnershipRelationType } from '@/objects/enums';
import { T } from '@/types';
import { OwnershipState as State } from './state';

export type OwnershipGetters = typeof ownershipGetters;

function getBaseOrganizations(
  structure: T.OrganizationStructure,
): T.Organization[] {
  if (structure.structureType === 'FLAT') {
    return structure.flatOrganizations[0]
      ? [structure.flatOrganizations[0]]
      : [];
  }
  return structure.hierarchicalOrganizations ?? [];
}

export const ownershipGetters = {
  getAllAssignments(state: State) {
    const nationalAssignments = state.structure
      ? getBaseOrganizations(state.structure)
      : null;
    if (nationalAssignments) {
      const allAssignments = nationalAssignments.map((assignment) => {
        const assignments = [assignment];
        if (assignment.children && assignment.children.length > 0) {
          return assignments.concat(assignment.children);
        }
        return assignments;
      });
      return allAssignments.flat();
    }
    return [];
  },
  getAssignmentDisplayName(state: State) {
    return (assignment: string): string => {
      return (
        ownershipGetters.getAssignmentObject(state)(assignment)?.displayName ??
        ''
      );
    };
  },
  getAssignmentObject(state: State) {
    return (assignment: string | null) => {
      if (!assignment) {
        return null;
      }

      const allOrganizations = ownershipGetters.getAllAssignments(state);
      return (
        allOrganizations.find((organization) => {
          return organization.assignment === assignment;
        }) ?? null
      );
    };
  },
  getOwnerAuthGroupForObject(state: State, _: unknown, rootState: T.RootState) {
    return (objectId: string) => {
      const user = rootState.user;
      if (user) {
        const ownership = ownershipGetters.getOwnerForObject(state)(objectId);
        return (
          user.availableAuthorizationGroups.find((authGroup) => {
            return ownership
              ? `${authGroup.context}.${authGroup.name}` === ownership.sectionId
              : false;
          }) ?? null
        );
      }
      return null;
    };
  },
  getOwnerForObject(state: State) {
    return (objectId: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return (
        ownerships.find((currOwnership) => {
          return (
            currOwnership.ownershipRelation === OwnershipRelationType.OWNER
          );
        }) ?? null
      );
    };
  },
  getOwnershipObjectForAssignment(state: State) {
    return (objectId: string, assignmentName: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return (
        ownerships.find((ownership) => {
          return ownership.organizationId === assignmentName;
        }) ?? null
      );
    };
  },
  getOwnershipObjectForUser(state: State) {
    return (objectId: string, userId: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return (
        ownerships.find((ownership) => {
          return ownership.userId === userId;
        }) ?? null
      );
    };
  },
  getRegionsFromOwnership(state: State): T.Organization[] | undefined {
    const ownership = state.structure?.hierarchicalOrganizations.map(
      (organization) => {
        const retArr = [];
        retArr.push(organization);
        organization.children.map((org) => retArr.push(org));
        return retArr;
      },
    );
    return ownership?.flat();
  },
  isActiveUserOwnerOrMember(state: State, _: unknown, rootState: T.RootState) {
    return (objectId: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      const assignmentName = rootState.user.user?.assignmentName;
      const availableAuthGroups = rootState.user.availableAuthorizationGroups;

      const assignmentsOwnership = ownerships.find((ownership) => {
        return ownership.organizationId === assignmentName;
      });
      if (assignmentsOwnership && availableAuthGroups) {
        return availableAuthGroups.some((authGroup) => {
          const id = `${authGroup.context}.${authGroup.name}`;
          return id === assignmentsOwnership.sectionId;
        });
      }
      return false;
    };
  },
  isAssignmentMemberOrOwnerOfObject(state: State) {
    return (objectId: string, assignment: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return ownerships.some((ownership) => {
        return ownership.organizationId === assignment;
      });
    };
  },
  isAssignmentNational(state: State) {
    return (assignment: string) => {
      const organization = ownershipGetters
        .nationalOrganizations(state)
        .find((organization) => organization.assignment === assignment);
      return !!organization;
    };
  },
  isLocked(state: State) {
    return (contentId: string): boolean => {
      const locks = state.locks;
      return !!locks[contentId];
    };
  },
  isLockedByActiveUser(state: State, _: unknown, rootState: T.RootState) {
    return (contentId: string): boolean => {
      const activeUser = rootState.user.user;
      const locks = state.locks;
      const lock = locks[contentId];
      if (activeUser && lock) {
        return lock.userId === activeUser.userId;
      }
      return false;
    };
  },
  isLockedbyAnotherUser(state: State, _: unknown, rootState: T.RootState) {
    return (contentId: string): boolean => {
      return (
        ownershipGetters.isLocked(state)(contentId) &&
        !ownershipGetters.isLockedByActiveUser(
          state,
          undefined,
          rootState,
        )(contentId)
      );
    };
  },
  isObjectNational(state: State) {
    return (objectId: string) => {
      const ownership = ownershipGetters.getOwnerForObject(state)(objectId);
      if (ownership) {
        const nationalOwnership = ownershipGetters
          .nationalOrganizations(state)
          .find(
            (organization) =>
              organization.assignment === ownership.organizationId,
          );
        return !!nationalOwnership;
      }
      return false;
    };
  },
  isObjectOwnedByActiveUser(state: State, _: unknown, rootState: T.RootState) {
    return (objectId: string) => {
      const user = rootState.user.user;
      if (user) {
        return ownershipGetters.isObjectOwnedByUser(state)(objectId, user);
      }
      return false;
    };
  },
  isObjectOwnedByUser(state: State) {
    return (objectId: string, user: T.User) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return ownerships.some((ownership) => {
        return (
          ownership.organizationId === user.assignmentName &&
          ownership.ownershipRelation === OwnershipRelationType.OWNER
        );
      });
    };
  },
  isUserMemberOfObject(state: State) {
    return (objectId: string, userId: string) => {
      const ownerships = state.ownerships[objectId] ?? [];
      return ownerships.some((ownership) => {
        return (
          ownership.userId === userId &&
          ownership.ownershipRelation === OwnershipRelationType.MEMBER
        );
      });
    };
  },
  nationalOrganizations(state: State) {
    return state.structure ? getBaseOrganizations(state.structure) : [];
  },
};
