import _ from 'lodash';
import { T } from '@/types';

export const CreateCompositionRequestDto = (
  model: T.CreateCompositionPayload,
): T.CreateCompositionRequestDto => {
  return {
    authors: _.uniq(model.authors),
    name: model.name,
    responsible: model.responsible,
    templateId: model.templateId,
  };
};
