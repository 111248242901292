import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';
import { AuthenticationProxy as proxy } from '../proxies';

export const AuthenticationService = {
  async checkTicket(ticket: T.Ticket) {
    const request = dtos.ValidateTicketRequestDto(ticket);
    const response = await proxy.checkTicket(request);
    return models.ValidateTicketResponse(response.data);
  },
  async getAllAuthorizationGroups() {
    const response = await proxy.getAllAuthorizationGroups();
    return response.data.map(models.SimpleAuthorizationGroup);
  },
  async getAuthorizationGroups() {
    const response = await proxy.getAuthorizationGroups();
    return models.SimpleAuthorizationGroups(response.data);
  },
  async getResourcesForGroup(payload: T.GroupPayload) {
    const response = await proxy.getResourcesForGroup(
      payload.context,
      payload.name,
    );
    return response.data;
  },
  async getTicket(guid: T.Guid) {
    const response = await proxy.getTicket(guid);
    return models.Ticket(response.data);
  },
  async getTicketInfo(ticket: T.Ticket) {
    const response = await proxy.getTicketInfo(ticket);
    return models.User(response.data);
  },
};
