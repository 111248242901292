import * as dtos from '@/objects/dtos';
import { StatusType } from '@/objects/enums';
import * as models from '@/objects/models';
import { T } from '@/types';
import { ContentProxy as proxy } from '../proxies';

export const ContentService = {
  async bulkUpdateStatus(contentIds: string[], newStatus: StatusType) {
    const response = await proxy.bulkUpdateStatus({ contentIds, newStatus });
    const failedContents = (response.data.failedContents ?? []).map(
      models.FailedContent,
    );
    const successfullyUpdatedContents = (
      response.data.successfullyUpdatedContents ?? []
    ).map(models.Content);
    return {
      failedContents,
      successfullyUpdatedContents,
    };
  },
  async createContent(groupTag: string, payload: T.CreateContentWithIdPayload) {
    const request = dtos.ContentCreateWithIdRequestDto(payload);
    const response = await proxy.createContent(groupTag, request);
    return models.Content(response.data);
  },
  async createMultipleContents(payload: T.CreateMultipleContentPayload) {
    const request = dtos.CreateMultipleContentRequestDto(payload);
    const response = await proxy.createMultipleContents(request);
    return response.data.map(models.Content);
  },
  async deleteContent(id: string) {
    await proxy.deleteContent(id);
  },
  async getContent(id: string) {
    const response = await proxy.getContent(id);
    return models.Content(response.data);
  },
  async getContentHistory(id: string, index: number, pageSize: number) {
    let contentRemaining = pageSize;
    let resultContent: T.Content[] = [];
    /* eslint-disable no-await-in-loop */
    for (let i = index; contentRemaining > 0; i += 1) {
      const localPageSize = contentRemaining < 50 ? contentRemaining : 50;
      const response = await proxy.getContentHistory(id, i, localPageSize);

      resultContent = resultContent.concat(response.data.map(models.Content));
      contentRemaining -= response.data.length;
      if (response.data.length < localPageSize) {
        break;
      }
    }
    return resultContent;
  },
  async getContents(contentIds: string[]) {
    const response = await proxy.getContents(contentIds);
    return response.data.map(models.Content);
  },
  async updateContent(contentId: string, payload: T.CreateContentPayload) {
    const request = dtos.ContentUpdateRequestDto(payload);
    const response = await proxy.updateContent(contentId, request);
    return models.Content(response.data);
  },
  async updateStatus(contentId: string, newStatus: StatusType) {
    const response = await proxy.updateStatus(contentId, { newStatus });
    return models.Content(response.data);
  },
};
