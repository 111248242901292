import { T } from '@/types';

export const ReviewMetaDataDto = (
  model: T.ReviewMetaData,
): T.ReviewMetaDataDto => {
  return {
    availableAspectIds: model.availableAspectIds
      ? JSON.stringify(model.availableAspectIds)
      : undefined,
    defaultAspectId: model.defaultAspectId || undefined,
    includeRegionalContent: model.includeRegionalContent
      ? JSON.stringify(model.includeRegionalContent)
      : undefined,
    selectedAspectIds: model.selectedAspectIds
      ? JSON.stringify(model.selectedAspectIds)
      : undefined,
  };
};
