import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-template/templates';

export const TemplateProxy = {
  async addTemplateContainer(
    templateId: string,
    request: T.AddContainerRequestDto,
  ) {
    return await proxy.post<T.TemplateDto>(
      `${URL}/${templateId}/containers`,
      request,
    );
  },
  async addTemplateHeader(
    request: T.TemplateHeaderDto,
    templateId: string,
    containerUUID: string,
  ) {
    return await proxy.post<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}/headers`,
      request,
    );
  },
  async createTemplate(request: T.CreateTemplateRequestDto) {
    return await proxy.post<T.TemplateDto>(`${URL}`, request);
  },
  async getAvailableAspects() {
    return await proxy.get<T.TemplateAspectDto[]>(`${URL}/aspects`);
  },
  async getInformationTypes() {
    return await proxy.get<T.InformationTypeDto[]>(`${URL}/informationtypes`);
  },
  async getTemplate(id: string) {
    return await proxy.get<T.TemplateDto>(`${URL}/${id}`);
  },
  async getTemplates() {
    return await proxy.get<T.TemplateDto[]>(`${URL}`);
  },
  async moveHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
    request: T.MoveHeaderRequest,
  ) {
    return await proxy.put<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}/headers/${headerUUID}/move`,
      request,
    );
  },
  async moveTemplateContainer(
    templateId: string,
    containerUUID: string,
    targetParentContainerUUID?: string,
  ) {
    return await proxy.put<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}/move`,
      { targetParentContainerUUID },
    );
  },
  async removeContainerFromTemplate(templateId: string, containerUUID: string) {
    return await proxy.delete<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}`,
    );
  },
  async removeHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
  ) {
    return await proxy.delete<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}/headers/${headerUUID}`,
    );
  },
  async removeTemplate(id: string, ignoreFinalized: boolean) {
    return await proxy.delete(
      `${URL}/${id}?ignoreFinalized=${ignoreFinalized}`,
    );
  },
  async templateAllowsAddons(templateId: string, versionKey: string) {
    return await proxy.get<{ addonsAllowed: boolean }>(
      `${URL}/${templateId}/addons`,
      {
        versionKey,
      },
    );
  },
  async updateAddonSettings(addonsAllowed: boolean, templateId: string) {
    return await proxy.put<T.TemplateDto>(`${URL}/${templateId}/addons`, {
      addonsAllowed,
    });
  },
  async updateHeaderInTemplate(
    templateId: string,
    containerUUID: string,
    headerUUID: string,
    request: T.TemplateHeaderDto,
  ) {
    return await proxy.put<T.TemplateDto>(
      `${URL}/${templateId}/containers/${containerUUID}/headers/${headerUUID}`,
      request,
    );
  },
  async updateMetaTemplateData(
    id: string,
    request: T.UpdateTemplateRequestDto,
  ) {
    return await proxy.put<T.TemplateDto>(`${URL}/${id}`, request);
  },
  async updateTemplate(id: string, request: T.UpdateTemplateRequestDto) {
    return await proxy.put<T.TemplateDto>(`${URL}/${id}`, request);
  },
};
