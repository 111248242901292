import { T } from '@/types';

export const Organization = (
  dto: T.BaseOrganizationDto | T.HierarchicalOrganizationDto,
): T.Organization => {
  return {
    assignment: dto.assignment,
    children: 'children' in dto ? dto.children.map(Organization) : [],
    code: dto.code,
    codeSystemIdentifier: dto.codeSystemIdentifier,
    displayName: dto.displayName,
    shortName: dto.shortName,
  };
};
