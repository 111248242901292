export enum EditorCommand {
  INSERT_TEXT = 'insertText',
  INSERT_LINK = 'insertLink',
  INCREASE_LIST_INDENT = 'wrapSubList',
  DECREASE_LIST_INDENT = 'externalLiftListItem',
  SET_RECOMMENDATION = 'setRecommendation',
  SET_REFERENCE = 'setReference',
  DELETE_REFERENCE = 'deleteReference',
  TOGGLE_TABLE_HEADER = 'toggleTableHeader',
  TOGGLE_HEADER_ROW = 'toggleHeaderRow',
  ADD_ROW_BEFORE = 'addRowBefore',
  ADD_ROW_AFTER = 'addRowAfter',
  ADD_COLUMN_BEFORE = 'addColumnBefore',
  ADD_COLUMN_AFTER = 'addColumnAfter',
  DELETE_ROW = 'deleteRow',
  DELETE_COLUMN = 'deleteColumn',
  TOGGLE_BULLET_LIST = 'toggleBulletList',
}
