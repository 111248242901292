import { T } from '@/types';
import * as models from '@/objects/models';

export const ContainerTimeline = (
  dto: T.ContainerTimelineDto,
): T.ContainerTimeline => {
  return {
    commits: dto.commits.map(models.TimelineCommit),
    snapshot: dto.snapshot ? models.ContainerSnapshot(dto.snapshot) : null,
  };
};
