import { ContentType } from '@/objects/enums';
import { T } from '@/types';

const FORMATTED_URL_REGEXP = /^https?:\/\//;
const VALID_URL_REGEXP = /^(ftp|http|https):\/\/[^ "]+$/;

const isNotEmpty = (text: string | null) => {
  return !!text && /\w/.test(text);
};

const validators: {
  [KType in ContentType]: (content: T.ContentOfType<KType>) => {
    devReason: string;
    userReason: string;
    result: boolean;
  };
} = {
  ATTACHMENT_REFERENCE(content) {
    return {
      devReason: 'References in attachment.data cannot be empty',
      result: isNotEmpty(content.data.reference),
      userReason: 'Bilagor måste innehålla en fil',
    };
  },
  HEADER() {
    return { devReason: '', result: true, userReason: '' };
    // Needs UI handling if we want to disallow newlines
    // return validateText(content.data.text);
  },
  LIST() {
    return { devReason: '', result: true, userReason: '' };
  },
  PICTURE_REFERENCE(content) {
    return {
      devReason: 'data.alternativeText cannot be empty',
      result:
        !!content.data.alternativeText &&
        isNotEmpty(content.data.alternativeText),
      userReason: 'Bildbeskrivning får ej lämnas tomt',
    };
  },
  PROCESS() {
    // TODO: Add validation when more data is available
    return {
      devReason: '',
      result: true,
      userReason: '',
    };
  },
  REFERENCE(content) {
    const { reference, displayReferenceText } = content.data;
    const text = displayReferenceText.text;
    return {
      devReason: 'Link must be valid and label not empty',
      result: ContentValidator.isValidUrl(reference) && isNotEmpty(text),
      userReason: 'Länkar måste vara giltiga och länktext måste finnas',
    };
  },
  TABLE(content) {
    return {
      devReason: 'Table must have at least 1 content row',
      result: !!(content.data.rows.length > 0),
      userReason: 'Tabellen måste innehålla minst en rad förutom rukrikrad',
    };
  },
  TEXT() {
    return { devReason: '', result: true, userReason: '' };
  },
};

export const ContentValidator = {
  isValid(content: T.Content) {
    return (validators[content.type] as Function)(content).result;
  },
  isValidLink(text: string) {
    return FORMATTED_URL_REGEXP.test(text);
  },
  isValidUrl(text: string) {
    return VALID_URL_REGEXP.test(text);
  },
  validateContent(content: T.Content) {
    return (validators[content.type] as Function)(content);
  },
};
