import _ from 'lodash';
import { T } from '@/types';

export const MultiLockResponse = (
  dto: T.MultiLockResponseDto,
): T.MultiLockResponse => {
  return {
    acquiredLocks: _.uniq(dto.acquiredLocks),
    failedObjectIds: _.uniq(dto.failedObjectIds),
    unAcquiredLocks: _.uniq(dto.unAcquiredLocks),
  };
};
