import { T } from '@/types';
import { v4 as uuidv4 } from 'uuid';

export const MetadataLink = (dto: T.MetadataLinkDto): T.MetadataLink => {
  return {
    description: dto.description ?? '',
    id: uuidv4(),
    longName: dto.longName,
    name: dto.name,
    url: dto.url ?? '',
  };
};
