export enum ListType {
  // DEFINITION = 'DEFINITION',
  // NONE = 'NONE',
  ORDERED = 'NUMBERED',
  // TABLE = 'TABLE',
  UNORDERED = 'BULLETED',
}

export enum ListRowType {
  TEXT = 'TEXT',
  REFERENCE = 'REFERENCE',
}
