import { T } from '@/types';

export const InformationTypeModel = (
  dto: T.InformationTypeDto,
): T.InformationType => {
  return {
    code: dto.code,
    description: dto.displayName ?? '',
    id: dto.id ?? '',
    oid: dto.oid,
    version: dto.version ?? '',
  };
};
