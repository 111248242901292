import { T } from '@/types';

export const Ownership = (dto: T.OwnershipDto): T.Ownership => {
  return {
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    groupTag: dto.groupTag,
    id: dto.id,
    objectType: dto.objectType,
    organizationId: dto.organizationId,
    organizationName: dto.organizationName,
    ownershipRelation: dto.ownershipRelation,
    sectionId: dto.sectionId,
    sectionName: dto.sectionName,
    userFullName: dto.userFullName,
    userId: dto.userId,
    versionKey: dto.versionKey,
  };
};
