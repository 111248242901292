/* eslint-disable @typescript-eslint/no-explicit-any */
import { T } from '@/types';
import { ContentType } from '../enums';
import { AttachmentContent } from './AttachmentContent';
import { HeaderContent } from './HeaderContent';
import { ListContent } from './ListContent';
import { PictureContent } from './PictureContent';
import { ReferenceContent } from './ReferenceContent';
import { TableContent } from './TableContent';
import { TextContent } from './TextContent';

export const Content = <TType extends ContentType>(
  dto: T.ContentDto<TType>,
): T.ContentOfType<TType> => {
  switch (dto.type) {
    case ContentType.ATTACHMENT_REFERENCE:
      return AttachmentContent(dto as any) as any;
    case ContentType.HEADER:
      return HeaderContent(dto as any) as any;
    case ContentType.LIST:
      return ListContent(dto as any) as any;
    case ContentType.PICTURE_REFERENCE:
      return PictureContent(dto as any) as any;
    case ContentType.REFERENCE:
      return ReferenceContent(dto as any) as any;
    case ContentType.TABLE:
      return TableContent(dto as any) as any;
    case ContentType.TEXT:
      return TextContent(dto as any) as any;
    default:
      throw new TypeError(
        'Failed to create model "Content". Input is not a valid ContentDto.',
      );
  }
};
