import { T } from '../../types';

export const SessionStorageHelper = {
  get<K extends keyof T.SessionStorage>(key: K) {
    const item = window.sessionStorage.getItem(key);
    return item === null
      ? undefined
      : (JSON.parse(item) as T.SessionStorage[K]);
  },
  getIn<K extends keyof T.SessionStorage>(path: [K, string]) {
    const object = SessionStorageHelper.get(path[0]);
    if (object) {
      return object[path[1]] as T.SessionStorage[K][string];
    }

    return undefined;
  },
  set<K extends keyof T.SessionStorage>(key: K, value: T.SessionStorage[K]) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  setIn<K extends keyof T.SessionStorage>(
    path: [K, string],
    value: T.SessionStorage[K][string],
  ) {
    SessionStorageHelper.set(path[0], {
      ...SessionStorageHelper.get(path[0])!,
      [path[1]]: value,
    });
  },
};
