import { T } from '../../types';
import * as models from '@/objects/models';
import { ProcessBlockContentType } from '../enums';
import { v4 as uuidv4 } from 'uuid';

export const BlockContent = (dto: T.BlockContentDto): T.BlockContent => {
  switch (dto.type) {
    case ProcessBlockContentType.TEXT:
      return {
        data: models.TextContentData(dto.textContent),
        id: uuidv4(),
        type: dto.type,
      };
    case ProcessBlockContentType.LIST:
      return {
        data: models.ListContentData(dto.listContent),
        id: uuidv4(),
        type: dto.type,
      };
    default:
      throw new TypeError(
        'Failed to create model "BlockContent". Input is not a valid BlockContentDto.',
      );
  }
};
