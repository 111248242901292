import { ContentType, HeaderType } from '@/objects/enums';
import { T } from '@/types';
import { ContentCreator } from './content-creator';

const BULLET_REGEXP = /[·•]/g;
const BULLET_SPLIT_REGEXP = /\s*[·•]\s*/;

export const ContentHelper = {
  getBlockReferencesFromContent(content: T.Content | T.BlockContent): string[] {
    let references: string[] = [];
    switch (content.type) {
      case ContentType.TABLE:
      case ContentType.LIST:
      case ContentType.PROCESS:
        references = content.data.referenceId ?? [];
        break;
      case ContentType.TEXT:
        references =
          content.data.blockReferences?.map((ref) => ref.referenceId) ?? [];
        break;
      default:
        break;
    }
    return references;
  },
  getListItemsFromText(text: string) {
    const listItems: T.ListRow[] = text
      .split(BULLET_SPLIT_REGEXP)
      .slice(1)
      .map(ContentCreator.createListItem);
    return listItems;
  },
  isContainerHeader(content: T.Content) {
    return (
      content.type === ContentType.HEADER &&
      [HeaderType.H1].includes(content.data?.type)
    );
  },
  isContentHeader(content: T.Content) {
    return (
      content.type === ContentType.HEADER && content.data.type === HeaderType.H3
    );
  },
  isContentNewAndUnsaved(content: T.Content | null) {
    return !!content && content.commitNumber == null;
  },
  isEmpty(content: T.Content): boolean {
    switch (content.type) {
      case ContentType.ATTACHMENT_REFERENCE:
        return (
          content.data.validFrom === null &&
          content.data.approvedBy.length === 0 &&
          content.data.mediaType.length === 0 &&
          content.data.contentResponsible.length === 0 &&
          content.data.version.length === 0 &&
          content.data.reference.length === 0
        );
      case ContentType.HEADER:
        return content.data.text.length === 0 ?? false;
      case ContentType.LIST:
        return (
          content.data.rows
            .map(
              (item) =>
                item.textItem?.text ||
                ''.concat(item.referenceItem?.displayReferenceText.text ?? ''),
            )
            .join('') === ''
        );
      case ContentType.REFERENCE:
        return (
          content.data.displayReferenceText.text.length === 0 ||
          content.data.reference.length === 0
        );
      case ContentType.PICTURE_REFERENCE:
        return (
          (content.data.pictureCreated === null ||
            content.data.pictureCreated === -1) &&
          content.data.alternativeText.length === 0 &&
          content.data.pictureName.length === 0 &&
          content.data.rightsHolder.length === 0 &&
          content.data.pictureCreator.length === 0 &&
          content.data.fileName.length === 0 &&
          content.data.pictureReferences.length === 0
        );
      case ContentType.TEXT:
        return content.data.text.length === 0 ?? false;
      case ContentType.PROCESS:
        // TODO: Add validation for empty Process content when more data is available
        return false;
      default:
        return false;
    }
  },
  isListInText(text: string) {
    return BULLET_REGEXP.test(text);
  },
  isTemplateHeader(content: T.Content) {
    return (
      content.type === ContentType.HEADER &&
      [HeaderType.H1, HeaderType.H2].includes(content.data?.type)
    );
  },
  toSingleLine(text: string | null) {
    return text?.replace(/[\r\n]/g, ' ') ?? '';
  },
};
