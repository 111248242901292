import { EmailReceiver, Receiver } from '@/objects/models';
import { T } from '@/types';
import { ReviewStatus } from '../enums';
import { ObjectResponse } from './ObjectResponse';
import { Review } from './Review';

export const ReviewResponse = (dto: T.ReviewResponseDto): T.ReviewResponse => {
  // Receivers from comments when the reviewStatus == CLOSED

  let closedReceivers: T.ReceiverInfo[] = [];
  if (dto.review.reviewStatus === ReviewStatus.CLOSED) {
    closedReceivers = Object.values(
      dto.comments.reduce(
        (receivers: Record<string, T.ReceiverInfo>, currComment) => {
          const newReceivers = receivers;
          if (currComment.receiverInfo) {
            newReceivers[currComment.receiverInfo.email] =
              currComment.receiverInfo;
          }
          return newReceivers;
        },
        {},
      ),
    );
  }
  return {
    closedReceivers,
    comments: dto.comments,
    emailReceivers: dto.emailReceivers.map(EmailReceiver),
    objectResponses: dto.objectResponses.map(ObjectResponse),
    receivers: dto.receivers.map(Receiver),
    review: Review(dto.review),
  };
};
