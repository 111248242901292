export enum ContentType {
  ATTACHMENT_REFERENCE = 'ATTACHMENT_REFERENCE',
  HEADER = 'HEADER',
  LIST = 'LIST',
  PICTURE_REFERENCE = 'PICTURE_REFERENCE',
  REFERENCE = 'REFERENCE',
  TABLE = 'TABLE',
  TEXT = 'TEXT',
  PROCESS = 'PROCESS',
}
