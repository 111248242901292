const nkdResources = {
  Assignment: {
    national: 'se.skl.nkd.assignment.nbr',
  },
};

const vksResources = {
  Access: {
    main: 'se.skr.vks.authoringclient.main',
  },
  ClientRole: {
    author: 'se.skr.vks.authoringclient.author',
    editor: 'se.skr.vks.authoringclient.editor',
    superAdmin: 'se.skr.vks.authoringclient.superadmin',
  },
  Composition: {
    CREATE: 'se.skr.vks.composition.create',
    DELETE: 'se.skr.vks.composition.delete',
    UPDATE: 'se.skr.vks.composition.update',
  },
  Content: {
    CRUD: 'se.skr.vks.content.crud',
  },
  Group: {
    CRUD: 'se.skr.vks.groupservice.crud',
  },
  Media: {
    CRUD: 'se.skr.vks.media.crud',
  },
  Ownership: {
    lockCRUD: 'se.skr.vks.ownership.lock.crud',
    ownershipCRUD: 'se.skr.vks.ownership.crud',
  },
  Publication: {
    CRUD: 'se.skr.vks.publication.crud',
  },
  Review: {
    CRUD: 'se.skr.vks.review.crud',
  },
  Role: {
    author: 'se.skr.vks.author',
    editor: 'se.skr.vks.editor',
    superAdmin: 'se.skr.vks.superadmin',
  },
  Template: {
    CRUD: 'se.skr.vks.template.crud',
  },
  UseCase: {
    basic: 'se.skr.vks.usecase.basicaccess',
    extended: 'se.skr.vks.usecase.extendedaccess',
    superAdmin: 'se.skr.vks.usecase.superadminaccess',
  },
};

export const Resources = {
  ...nkdResources,
  ...vksResources,
};
