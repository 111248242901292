import { SearchProxy as proxy } from '../proxies';
import { T } from '@/types';
import { CompositionListSearchRequestDto } from '@/objects/dtos/CompositionListSearchRequestDto';
import * as models from '@/objects/models';
import { SortBy, SortOrder } from '@/objects/enums';
import { ListReview } from '@/objects/models';

export const SearchService = {
  async getCompositions(payload: T.CompositionListSearchRequest) {
    return (
      await proxy.getCompositions(CompositionListSearchRequestDto(payload))
    ).data;
  },
  async getPublications(payload: {
    assignment: string;
    page?: number;
    title?: string;
    sortBy?: SortBy;
    sortOrder?: SortOrder;
  }) {
    return (await proxy.getPublications(payload)).data;
  },
  async getReviews(payload: {
    assignment: string;
    closedOnly?: boolean;
    npo?: string[];
    name?: string;
    sortBy?: SortBy;
    sortOrder?: SortOrder;
  }) {
    const res = await proxy.getReviews(payload);
    return {
      ...res.data,
      matchingReviews: res.data.matchingReviews.map(ListReview),
    };
  },
  async getReviewsAggregates(payload: {
    assignment: string;
    closedOnly?: boolean;
    npo?: string[];
    name?: string;
  }) {
    return (await proxy.getReviewsAggregates(payload)).data;
  },
  async getScheduledCompositions(payload: { pageSize: number; page: number }) {
    return (await proxy.getScheduledCompositions(payload)).data;
  },
  async searchCompositionContent(
    searchTerm: string,
    params?: { pageSize?: number },
    signal?: AbortSignal,
  ) {
    const response = await proxy.searchCompositionContent(
      searchTerm,
      params,
      signal,
    );
    return models.CompositionForContentSearchResponse(response.data);
  },
  async searchCompositionTitle(
    searchTerm: string,
    params?: { pageSize?: number },
    signal?: AbortSignal,
  ) {
    const response = await proxy.searchCompositionTitle(
      searchTerm,
      params,
      signal,
    );
    return models.CompositionSearchResults(response.data);
  },
};
