import { T } from '@/types';

export const OwnershipResponse = (
  dto: T.OwnershipResponseDto,
): T.OwnershipResponse => {
  return {
    objectId: dto.objectId,
    ownerships: dto.ownerships,
  };
};
