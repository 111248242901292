import { T } from '@/types';
import { ContentType, ListType } from '@/objects/enums';
import { ContentCreator } from './content-creator';

const createContentConverters = <TFrom extends ContentType>() => ({
  ATTACHMENT_REFERENCE: null,
  HEADER: null,
  LIST: null,
  PICTURE_REFERENCE({
    content,
  }: T.ConversionPayload<TFrom, ContentType.PICTURE_REFERENCE>) {
    return {
      ...ContentCreator.createNewPicture(),
      id: content.id,
    };
  },
  PROCESS: null,
  REFERENCE({ content }: T.ConversionPayload<TFrom, ContentType.REFERENCE>) {
    return {
      ...ContentCreator.createNewReference(),
      id: content.id,
    };
  },
  TABLE: null,
  TEXT: null,
});

const listContentFromText = (text: string, type: ListType) => {
  return {
    rows: listItemsFromText(text),
    type,
  };
};

const listItemsFromText = (text: string) => {
  const cleanedText = text.startsWith('* ') ? text.slice(2) : text;
  return cleanedText.split(' * ').map(ContentCreator.createListItem);
};

const textFromListItems = (items: T.ListRow[]) => {
  const text = items
    .map((item) => (item.textItem ? item.textItem.text : ''))
    .join(' * ');
  return `* ${text}`;
};

const contentConverters: T.ContentConverterMap = {
  ATTACHMENT_REFERENCE:
    createContentConverters<ContentType.ATTACHMENT_REFERENCE>(),
  HEADER: {
    ...createContentConverters<ContentType.HEADER>(),
    HEADER({ content, meta }) {
      return {
        ...content,
        data: {
          text: content.data?.text ?? '',
          type: meta.type,
        },
        type: ContentType.HEADER,
      };
    },
    LIST({ content, meta }) {
      return {
        ...content,
        data: listContentFromText(content?.data.text, meta.type),
        type: ContentType.LIST,
      };
    },
    TEXT({ content }) {
      return {
        ...content,
        data: {
          text: content.data?.text ?? '',
        },
        type: ContentType.TEXT,
      };
    },
  },
  LIST: {
    ...createContentConverters<ContentType.LIST>(),
    HEADER({ content, meta }) {
      return {
        ...content,
        data: {
          text: textFromListItems(content.data.rows ?? []),
          type: meta.type,
        },
        type: ContentType.HEADER,
      };
    },
    LIST({ content, meta }) {
      return {
        ...content,
        data: {
          ...content.data,
          type: meta.type,
        },
        type: ContentType.LIST,
      };
    },
    TEXT({ content }) {
      return {
        ...content,
        data: {
          text: textFromListItems(content.data.rows ?? []),
        },
        type: ContentType.TEXT,
      };
    },
  },
  PICTURE_REFERENCE: createContentConverters<ContentType.PICTURE_REFERENCE>(),
  PROCESS: createContentConverters<ContentType.PROCESS>(),
  REFERENCE: createContentConverters<ContentType.REFERENCE>(),
  TABLE: createContentConverters<ContentType.TABLE>(),
  TEXT: {
    ...createContentConverters<ContentType.TEXT>(),
    HEADER({ content, meta }) {
      return {
        ...content,
        data: {
          text: content.data?.text ?? '',
          type: meta.type,
        },
        type: ContentType.HEADER,
      };
    },
    LIST({ content, meta }) {
      return {
        ...content,
        data: {
          rows: listItemsFromText(content.data?.text || ''),
          type: meta.type,
        },
        type: ContentType.LIST,
      };
    },
    PROCESS({ content }) {
      return {
        ...ContentCreator.createNewProcess(),
        id: content.id,
      };
    },
    TABLE({ content }) {
      const text = content.data?.text;
      return {
        ...content,
        data: {
          columnHeaders: ['Kolumnrubrik1', 'Kolumnrubrik2', 'Kolumnrubrik3'],
          rows: [...Array(4)].map(() => {
            return {
              cellData: [...Array(3)].map(() =>
                ContentCreator.createNewTableCell({ text: '' }),
              ),
            };
          }),
          tableHeader: text && text.length > 0 ? text : 'Tabellrubrik',
        },
        type: ContentType.TABLE,
      };
    },
  },
};

export const ContentConverter = {
  convertContentToType<TFrom extends ContentType, TTo extends ContentType>(
    content: T.ContentOfType<TFrom>,
    meta: T.ContentMetaMap[TFrom],
    type: TTo,
  ): T.ContentOfType<TTo> {
    const contentConverter = contentConverters[content.type][type];
    return (contentConverter as Function | null)?.({ content, meta });
  },
  hasConversion(from: ContentType, to: ContentType) {
    return !!contentConverters[from][to];
  },
};
