import { T } from '@/types';
import { ReviewMetaDataDto } from '@/objects/dtos';

export const ReviewRequestDto = (
  model: T.ReviewPayload,
): T.ReviewRequestDto => {
  return {
    auxiliaryMessage: model.auxiliaryMessage,
    expires: model.expires,
    heading: model.heading,
    message: model.message,
    metaData: model.metaData ? ReviewMetaDataDto(model.metaData) : undefined,
    name: model.name,
    objectRequests: model.objectRequests,
    ownerGroup: model.ownerGroup,
    publicationId: model.publicationId,
    responsible: model.responsible,
    reviewType: model.reviewType,
  };
};
