import { T } from '../../types';
import { AreaReference } from './AreaReference';
import { Coordinate } from './Coordinate';

export const CircleArea = (dto: T.CircleAreaDto): T.CircleArea => {
  return {
    altText: dto.altText,
    center: Coordinate(dto.center),
    radius: dto.radius,
    reference: AreaReference(dto.reference),
  };
};
