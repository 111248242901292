import { T } from '@/types';

export const PictureContentDto = (
  model: T.PictureContentData,
): T.PictureContentDto => {
  return {
    alternativeText: model.alternativeText,
    fileName: model.fileName,
    pictureCreated: model.pictureCreated,
    pictureCreator: model.pictureCreator,
    pictureName: model.pictureName,
    pictureReferences: model.pictureReferences,
    pictureText: model.pictureText,
    rightsHolder: model.rightsHolder,
  };
};
