const BVK_URL = process.env.VUE_APP_BVK_URL;

export const UrlHelper = {
  getLogoutUrl() {
    const { location } = window;
    const targetUrl = encodeURI(
      `${location.protocol}//${location.host}/#/utloggad`,
    );
    return `${process.env.VUE_APP_BVK_URL}/auth/logout?targetUrl=${targetUrl}`;
  },
  getUrl() {
    const { location } = window;
    return `${location.protocol}//${location.host}${location.pathname}`;
  },
  getUrlParam(param: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  },
  goToLoginPage() {
    window.location.assign(
      `${BVK_URL}/auth/login?targetUrl=${encodeURI(
        this.getUrl(),
      )}&rootcontext=se.skl.nkd.`,
    );
  },
  removeUrlParams() {
    const uri = window.location.toString();

    window.history.replaceState(
      {},
      document.title,
      uri.substring(0, uri.indexOf('?')),
    );
  },
};
