import { T } from '@/types';
import { TextContentDto } from './TextContentDto';

export const SubListContentDto = (subListModel: T.SubList) => {
  return {
    items: subListModel.rows.map(SubListRowDto),
    type: subListModel.type,
  };
};

const SubListRowDto = (subListRow: T.SubListRow) => {
  return {
    referenceItem: subListRow.referenceItem,
    row: subListRow.row ? { text: subListRow.row.text } : null,
    textItem: subListRow.textItem ? TextContentDto(subListRow.textItem) : null,
    type: subListRow.type,
  };
};
