import { Vue } from '@/vue';
import { SelectMode, StatusType } from '@/objects/enums';
import { T } from '@/types';
import { AuthorState as State } from './state';

export type AuthorMutations = typeof authorMutations;

export const authorMutations = {
  ADD_BLOCK_TO_EXPANDED(state: State, blockId: string) {
    if (!state.expandedBlocks.includes(blockId)) {
      state.expandedBlocks.push(blockId);
    }
  },
  ADD_MULTIPLE_SELECTED_CONTENT_ID(state: State, contentIds: string[]) {
    contentIds.forEach((contentId) =>
      authorMutations.ADD_SELECTED_CONTENT_ID(state, contentId),
    );
  },
  ADD_SELECTED_CONTENT_ID(state: State, contentId: string) {
    if (!state.selectedContentIds.includes(contentId)) {
      state.selectedContentIds.push(contentId);
    }
  },
  CLEAR_EXPANDED_BLOCKS(state: State) {
    state.expandedBlocks = [];
  },
  CLEAR_SELECTED_CONTENT_IDS(state: State) {
    Vue.set(state, 'selectedContentIds', []);
  },
  REMOVE_BLOCK_FROM_EXPANDED(state: State, blockId: string) {
    const index = state.expandedBlocks.findIndex((expandedBlockId) => {
      return expandedBlockId === blockId;
    });
    state.expandedBlocks.splice(index, 1);
  },
  REMOVE_SELECTED_CONTENT_ID(state: State, contentId: string) {
    const index = state.selectedContentIds.findIndex(
      (existingContentId) => existingContentId === contentId,
    );
    if (index > -1) {
      state.selectedContentIds.splice(index, 1);
    }
  },
  REPLACE_EXPANDED_BLOCK(
    state: State,
    { oldId, newId }: { oldId: string; newId: string },
  ) {
    const index = state.expandedBlocks.findIndex((id) => id === oldId);
    if (index > -1) {
      state.expandedBlocks.splice(index, 1, newId);
    }
  },
  SET_ASPECT_MISMATCH(state: State, aspectMismatch: boolean) {
    state.aspectMismatch = aspectMismatch;
  },
  SET_BULK_STATUS(state: State, bulkStatus: StatusType | null) {
    state.bulkStatus = bulkStatus;
  },
  SET_LOCKED_BULK_ITEMS(state: State, lockedBulkItems: T.LockedBulkItem[]) {
    state.bulkItemsLocked = lockedBulkItems;
  },
  SET_SAVED_FILTER(state: State, filter: T.Filter | null) {
    state.savedFilter = filter;
  },
  SET_SELECTED_ASPECTS_WRITE(state: State, aspects: T.Aspect[]) {
    state.selectedAspectsWrite = aspects;
  },
  SET_SELECT_MODE(state: State, selectMode: SelectMode | null) {
    state.selectMode = selectMode;
  },
  SET_SHOW_ALL_ASPECTS(state: State, showAllAspects: boolean) {
    state.showAllAspects = showAllAspects;
  },
};
