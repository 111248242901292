import { reviewActions } from './actions';
import { reviewGetters } from './getters';
import { reviewMutations } from './mutations';
import { reviewState } from './state';

export const reviewStore = {
  actions: reviewActions,
  getters: reviewGetters,
  mutations: reviewMutations,
  namespaced: true,
  state: reviewState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
