import { T } from '@/types';

export const CreatePublicationOfTypeRequestDto = (
  model: T.PublicationPreviewPayload,
): T.CreatePublicationOfTypeRequestDto => {
  return {
    compositionId: model.compositionId,
    defaultAspectId: model.defaultAspectId,
    optionalTitle: model.title,
  };
};
