import { ProcessBlockProxy as proxy } from '../proxies';
import * as dtos from '@/objects/dtos';
import * as models from '@/objects/models';
import { T } from '@/types';

export const ProcessBlockService = {
  async createProcessBlock(
    payload: T.CreateProcessBlockRequest,
    groupTag: string,
  ) {
    const request = dtos.CreateProcessBlockRequestDto(payload);
    const response = await proxy.createProcessBlock(request, groupTag);
    return models.ProcessBlock(response.data);
  },
  async createProcessBlockAndAddToExisting(
    id: string,
    parentId: string,
    payload: T.CreateProcessBlockRequest,
  ) {
    const request = dtos.CreateProcessBlockRequestDto(payload);
    const response = await proxy.createProcessBlockAndAddToExisting(
      id,
      parentId,
      request,
    );
    return models.ProcessBlock(response.data);
  },
  async deleteProcessBlock(payload: { rootId: string; childId: string }) {
    const response = await proxy.deleteProcessBlock(payload);
    return response.data;
  },
  async fetchProcessBlock(id: string) {
    const response = await proxy.fetchProcessBlock(id);
    return models.ProcessBlock(response.data);
  },
  async fetchProcessBlockHistory(id: string, index: number, pageSize: number) {
    let contentRemaining = pageSize;
    let resultContent: T.SimpleProcessBlock[] = [];
    /* eslint-disable no-await-in-loop */
    for (let i = index; contentRemaining > 0; i += 1) {
      const localPageSize = contentRemaining < 50 ? contentRemaining : 50;
      const response = await proxy.fetchProcessBlockHistory(
        id,
        i,
        localPageSize,
      );

      resultContent = resultContent.concat(
        response.data.map(models.SimpleProcessBlock),
      );
      contentRemaining -= response.data.length;
      if (response.data.length < localPageSize) {
        break;
      }
    }
    return resultContent;
  },
  async fetchProcessBlocks(ids: string[]) {
    const response = await proxy.fetchProcessBlocks(ids);
    return response.data.map(models.ProcessBlock);
  },
  async moveChildProcessBlock(
    id: string,
    sourceId: string,
    payload: T.MoveProcessBlockRequest,
  ) {
    const response = await proxy.moveChildProcessBlock(id, sourceId, payload);
    return models.ProcessBlock(response.data);
  },
  async patchChildProcessblock(
    id: string,
    childId: string,
    payload: T.PatchProcessBlockRequest,
  ) {
    const request = dtos.PatchProcessBlockRequestDto(payload);
    const response = await proxy.patchChildProcessBlock(id, childId, request);
    return models.ProcessBlock(response.data);
  },
  async updateProcessBlock(id: string, payload: T.CreateProcessBlockRequest) {
    const request = dtos.CreateProcessBlockRequestDto(payload);
    const response = await proxy.updateProcessBlock(id, request);
    return models.ProcessBlock(response.data);
  },
  async updateProcessBlockStatus(
    payload: T.UpdateProcessBlockStatusRequestDto,
  ) {
    const response = await proxy.updateProcessBlockStatus(payload);
    return response.data;
  },
};
