import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-composition/compositions';

export const CompositionProxy = {
  async archiveComposition(compositionId: string, message: string) {
    return await proxy.put(
      `/api-aggregator/compositions/${compositionId}/archive`,
      { message },
    );
  },
  async bulkUpdateContainerObjectStatus(
    compositionId: string,
    request: T.MultipleStatusUpdateRequest,
  ) {
    return await proxy.put<T.ContainerObjectDto[]>(
      `${URL}/${compositionId}/containerobjects/status`,
      request,
    );
  },
  async createCompositionPlanningObjects(
    compositionId: string,
    aspectSpecificCompositionPlanningObjects: T.AspectSpecificCompositionPlanningObjectDto[],
  ) {
    return await proxy.post<T.CompositionPlanningObjectDto[]>(
      `/api-productionplanning/productionplanning/composition/${compositionId}`,
      { aspectSpecificCompositionPlanningObjects },
    );
  },
  async createMetaForComposition(
    compositionId: string,
    request: T.MetadataRequestDto,
  ) {
    return await proxy.post<T.MetadataDto>(
      `${URL}/${compositionId}/metadata`,
      request,
    );
  },
  async createMultipleMetaForComposition(
    compositionId: string,
    request: T.MultipleMetadataRequestDto,
  ) {
    return await proxy.post<T.MetadataDto[]>(
      `${URL}/${compositionId}/metadata/multiple`,
      request,
    );
  },
  async deleteComposition(compositionId: string) {
    return await proxy.delete(`${URL}/${compositionId}`);
  },
  async deleteContainerObject(
    compositionId: string,
    containerId: string,
    objectId: string,
  ) {
    return await proxy.delete(
      `${URL}/${compositionId}/containers/${containerId}/containerobjects/${objectId}`,
    );
  },
  async getAllInformationActors() {
    return await proxy.get<T.CodeDto[]>(
      `/api-processblock/processblocks/information_actors`,
    );
  },
  async getCompositionCommits(compositionId: string) {
    return await proxy.get<T.CompositionDto[]>(
      `${URL}/${compositionId}/commits`,
    );
  },
  async getCompositionPlanningObjects(compositionId: string) {
    return await proxy.get<T.CompositionPlanningObjectDto[]>(
      `/api-productionplanning/productionplanning/composition/${compositionId}`,
    );
  },
  async getCompositionWithContainersAndMetadata(compositionId: string) {
    return await proxy.get<T.CompositionWithContainersAndMetadataDto>(
      `${URL}/${compositionId}`,
      { params: { checkForLevel3: true } },
    );
  },
  async getCompositions() {
    return await proxy.get<T.CompositionForListDto[]>(
      `${URL}/informationtype/BEHANDLINGSREKOMMENDATION?pageIndex=0&pageSize=1000`,
    );
  },
  async getContainerIdsWithMultipleCommits(compositionId: string) {
    return await proxy.get<string[]>(
      `${URL}/${compositionId}/containers/multiplecommits`,
    );
  },
  async getContainers(compositionId: string) {
    return await proxy.get<T.ContainerDto[]>(
      `${URL}/${compositionId}/containers`,
    );
  },
  async getLevel3ContainerObjects(compositionId: string) {
    return await proxy.get<T.ContainerObjectDto[]>(
      `${URL}/${compositionId}/containerobjects/level3`,
    );
  },
  async getMetaForComposition(compositionId: string) {
    return await proxy.get<T.MetadataDto[]>(`${URL}/${compositionId}/metadata`);
  },
  async getNotLevel3ContainerObjects(compositionId: string) {
    return await proxy.get<T.ContainerObjectDto[]>(
      `${URL}/${compositionId}/containerobjects/notlevel3`,
    );
  },
  async moveContainerObjects(
    compositionId: string,
    request: T.MoveContainerObjectsRequestDto,
  ) {
    return await proxy.put<T.ContainerDto[]>(
      `${URL}/${compositionId}/containerobjects/move`,
      request,
    );
  },
  async updateCompositionMarkup(
    compositionId: string,
    request: T.MarkupRequestDto,
  ) {
    return await proxy.put<T.CompositionDto>(
      `${URL}/${compositionId}/markup`,
      request,
    );
  },
  async updateCompositionPlanningObject(
    compositionId: string,
    uuid: string,
    compositionPlanningObject: T.AspectSpecificCompositionPlanningObjectDto,
  ) {
    return await proxy.put<T.CompositionPlanningObjectDto>(
      `/api-productionplanning/productionplanning/composition/${compositionId}/uuid/${uuid}`,
      {
        plannedDateForNationalReview:
          compositionPlanningObject.plannedDateForNationalReview,
      },
    );
  },
  async updateCompositionProductionStatus(
    compositionId: string,
    request: T.UpdateProductionStatusRequestDto,
  ) {
    return await proxy.put<T.CompositionDto>(
      `${URL}/${compositionId}/productionstatus`,
      request,
    );
  },
  async updateCompositionTitle(
    compositionId: string,
    request: T.UpdateCompositionTitleRequestDto,
  ) {
    return await proxy.put<T.CompositionDto>(
      `${URL}/${compositionId}/title`,
      request,
    );
  },
  async updateContainerObject(
    compositionId: string,
    containerId: string,
    request: T.AddObjectToContainerRequestDto,
  ) {
    return await proxy.put<T.ContainerDto>(
      `${URL}/${compositionId}/containers/${containerId}/containerobjects`,
      request,
    );
  },
  async updateContainerObjectsMarkup(
    compositionId: string,
    request: T.MultipleMarkupRequestDto,
  ) {
    return await proxy.put<T.ContainerObjectDto[]>(
      `${URL}/${compositionId}/containerobjects/markup`,
      request,
    );
  },
  async updateMetaForComposition(
    compositionId: string,
    aspectId: string,
    request: T.UpdateMetadataRequestDto,
  ) {
    return await proxy.put<T.MetadataDto>(
      `${URL}/${compositionId}/metadata/${aspectId}`,
      request,
    );
  },
};
