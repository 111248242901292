import { ReviewObject } from './ReviewObject';
import { T } from '@/types';
import { Question } from './Question';

export const ObjectResponse = (dto: T.ObjectResponseDto): T.ObjectResponse => {
  return {
    comments: dto.comments,
    commentsOnComment: dto.commentsOnComment,
    question: dto.question ? Question(dto.question) : null,
    reviewObject: ReviewObject(dto.reviewObject),
    upvotes: dto.upVotes,
  };
};
