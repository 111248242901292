import { ActiveMode } from '@/objects/enums/ActiveMode';
/* eslint-disable no-console */
import { Services } from '@/services';
import { T } from '@/types';
import { CompositionActions } from './actions.types';
import { ContentType, ObjectType } from '@/objects/enums';
import { CompositionUtils } from './utils';

export const compositionActions: CompositionActions = {
  async archiveComposition({ dispatch }, payload) {
    try {
      await Services.Composition.archiveComposition(payload);
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        'Kunskapsstödet har nu arkiverats tillsammans med all tillhörande data.',
        { root: true },
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid arkiveringen av kunskapsstödet. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async bulkUpdateContainerObjectStatus(
    { commit, dispatch, rootState },
    payload,
  ) {
    try {
      const containerObjects =
        await Services.Composition.bulkUpdateContainerObjectStatus(
          rootState.composition.activeComposition!.id,
          payload,
        );
      containerObjects.forEach((containerObject) => {
        commit('UPDATE_CONTAINER_OBJECT', containerObject);
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Det gick inte att uppdatera status för kontext. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }
  },
  async clearCompositionStates({ commit, dispatch }) {
    commit('global/SET_ACTIVE_MODE', ActiveMode.DEFAULT, { root: true });
    commit('SET_ACTIVE_COMPOSITION', null);
    commit('SET_LEVEL_3_CONTENT_EXISTS', false);
    commit('SET_CONTAINERS', []);
    commit('SET_METADATA', []);
    commit('SET_CONTAINEROBJECTS', {});
    commit('SET_REFERENCES', {});
    commit('SET_SELECTED_ASPECTS_READ', []);
    await dispatch('clearTimelineMode', undefined);
  },
  async clearTimeline({ commit }) {
    commit('SET_TIMELINE_ACTIVE_ID', null);
    commit('SET_TIMELINE', null);
    commit('SET_TIMELINE_PREV_SNAPSHOT', null);
  },
  async clearTimelineMode({ commit, dispatch }) {
    dispatch('clearTimeline', undefined);
    commit('SET_TIMELINE_CONTAINERIDS_WITH_HISTORY', null);
    commit('global/SET_ACTIVE_MODE', ActiveMode.DEFAULT, { root: true });
  },
  async createComposition({ commit, dispatch }, payload) {
    try {
      const data = await Services.Aggregator.createComposition(payload);

      commit('ADD_COMPOSITION', data.composition);
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        `${data.composition.title} är nu redo att användas!`,
        { root: true },
      );

      return data;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av kunskapsstöd. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createCompositionPlanningObjects({ commit, dispatch }, payload) {
    try {
      const compositionPlanningObjects =
        await Services.Composition.createCompositionPlanningObjects(
          payload.compositionId,
          payload.newProductionPlanningItems,
        );
      commit('SET_COMPOSITION_PLANNING_OBJECTS', compositionPlanningObjects);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapandet av produktionsplaneringsinformation. Vänligen försök igen.',
        },
        {
          root: true,
        },
      );
    }
  },
  async deleteContainerObject({ commit, dispatch, state }, containerObject) {
    const container = state.containers.find((container) => {
      return container.containerObjectIds.includes(containerObject.id);
    });

    if (!state.activeComposition || !container) {
      return;
    }

    const compositionId = state.activeComposition.id;
    const objectId = containerObject.id;

    await Services.Composition.deleteContainerObject(
      compositionId,
      container.id,
      objectId,
    );

    const contentPromise = () =>
      dispatch(
        'content/fetchContents',
        { contentIds: container.containerObjectIds },
        { root: true },
      );
    // TODO: Find a better way to seperate content/processBlocks here
    const processblockPromise = () =>
      dispatch(
        'content/fetchContents',
        {
          contentIds: container.containerObjectIds,
          contentType: ContentType.PROCESS,
        },
        { root: true },
      );
    const ownershipPromise = dispatch(
      'ownership/fetchChangesForOwnerships',
      container.containerObjectIds,
      { root: true },
    );

    await Promise.all([
      contentPromise(),
      processblockPromise(),
      ownershipPromise,
    ]);

    commit('DELETE_CONTAINEROBJECT', objectId);

    dispatch('content/deleteUnusedContentFromState', undefined, {
      root: true,
    });
  },
  async fetchAndLoadCompositionPlanningObjects(
    { commit, dispatch },
    compositionId,
  ) {
    try {
      const response = await Services.Composition.getCompositionPlanningObjects(
        compositionId,
      );
      commit('SET_COMPOSITION_PLANNING_OBJECTS', response);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtandet av produktionsplaneringsinformation. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchAndLoadContainerIdsWithHistory({ commit }, compositionId) {
    if (!compositionId) {
      commit('SET_TIMELINE_CONTAINERIDS_WITH_HISTORY', null);
      return [];
    }

    const containerIdsWithMultipleCommits =
      await Services.Composition.getContainerIdsWithMultipleCommits(
        compositionId,
      );

    commit(
      'SET_TIMELINE_CONTAINERIDS_WITH_HISTORY',
      containerIdsWithMultipleCommits,
    );
    return containerIdsWithMultipleCommits;
  },
  async fetchAndLoadInformationActors({ commit, dispatch }) {
    try {
      const availableInformationActors =
        await Services.Composition.getAllInformationActors();
      commit('SET_AVAILABLE_INFORMATION_ACTORS', availableInformationActors);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtandet av tillgängliga kodverk. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchAndLoadTimeline({ commit, dispatch }, payload) {
    try {
      const activeTimeline = await Services.Aggregator.getTimemachine(
        payload.compositionId,
        payload.containerId,
        payload.commitNumber,
      );
      if (!activeTimeline.snapshot || activeTimeline.commits.length === 0) {
        commit('SET_TIMELINE', activeTimeline);
        commit('SET_TIMELINE_PREV_SNAPSHOT', null);
        commit('SET_TIMELINE_ACTIVE_ID', payload.containerId);

        return;
      }
      const currentCommitNr = activeTimeline.snapshot.containerCommit;
      const currentIndex = activeTimeline.commits.findIndex(
        (commit) => commit.commit === currentCommitNr,
      );
      const newCommitNr = activeTimeline.commits[currentIndex + 1]?.commit;
      if (currentIndex > -1 && newCommitNr) {
        const prevTimeline = await Services.Aggregator.getTimemachine(
          payload.compositionId,
          payload.containerId,
          newCommitNr,
        );
        commit('SET_TIMELINE_PREV_SNAPSHOT', prevTimeline.snapshot);
      } else {
        commit('SET_TIMELINE_PREV_SNAPSHOT', null);
      }
      commit('SET_TIMELINE_ACTIVE_ID', payload.containerId);
      commit('SET_TIMELINE', activeTimeline);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtandet av historik. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchBrokenLinks({ commit, dispatch }, includeNpos) {
    try {
      const data = await Services.Aggregator.getBrokenLinks(includeNpos);
      commit('SET_BROKEN_LINKS', data);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtandet av brutna länkar. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchCompositionCommits({ dispatch }, compositionId) {
    try {
      const data = await Services.Composition.getCompositionCommits(
        compositionId,
      );
      return data;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtandet av kunskapsstöden. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchCompositionContentSearchResults({ dispatch }, payload) {
    try {
      const data = await Services.Search.searchCompositionContent(
        payload.searchTerm,
        payload.params,
        payload.signal,
      );
      return data;
    } catch (error) {
      if (payload?.signal !== undefined) {
        console.info(
          `The request for ${payload.searchTerm} was cancelled by a new search request`,
        );
      } else {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Något gick fel vid sökandet av innehåll. Försök igen senare.',
          },
          { root: true },
        );
      }
      return [];
    }
  },
  async fetchCompositionSearchResults({ dispatch }, payload) {
    try {
      const data = await Services.Search.searchCompositionTitle(
        payload.searchTerm,
        payload.params,
        payload.signal,
      );
      // const sortedData = {
      //   ...data,
      //   matchingCompositions: sortSearchResultByTitle(
      //     data.matchingCompositions,
      //   ) as T.CompositionForSearchResults[],
      // };
      return data;
    } catch (error) {
      if (payload?.signal !== undefined) {
        console.info(
          `The request for ${payload.searchTerm} was cancelled by a new search request`,
        );
      } else {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Något gick fel vid sökandet av kunskapsstöd. Försök igen senare.',
          },
          { root: true },
        );
      }
      return null;
    }
  },
  async fetchCompositionWithContainersAndMetadata(
    { commit, dispatch },
    compositionId,
  ) {
    try {
      const data =
        await Services.Composition.getCompositionWithContainersAndMetadata(
          compositionId,
        );
      commit('SET_ACTIVE_COMPOSITION', data.composition);
      commit('template/SET_ACTIVE_TEMPLATE', data.template, { root: true });
      commit('SET_CONTAINEROBJECTS', data.containerObjects);
      commit('SET_CONTAINERS', data.containers);
      commit('SET_METADATA', data.metadata);
      commit('SET_LEVEL_3_CONTENT_EXISTS', data.hasLevel3);
      commit('SET_TEMPLATE_UPGRADE_AVAILABLE', data.templateUpgradeAvailable);
      commit('SET_COMPOSITION_MODIFIED', false);
      commit('SET_CODE_SYSTEMS_IN_USE', data.codeSystemsInUse);

      return data;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtande av kunskapsstödet. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchCompositions({ commit, dispatch }) {
    try {
      const compositionForList = await Services.Composition.getCompositions();

      commit('SET_COMPOSITIONS', compositionForList);

      return compositionForList;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtande av kunskapsstödet. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchCompositionsFromSearch({ dispatch }, payload) {
    try {
      return await Services.Search.getCompositions(payload || {});
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av listan av kunskapsstöd. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchMigrationInformation({ dispatch, commit }, payload) {
    try {
      const res = await Services.Aggregator.migrateComposition(
        payload.compositionId,
        true,
      );
      commit('SET_MIGRATION_INFORMATION', res);
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av listan av kunskapsstöd. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async getLevel3ContainerObjects({ dispatch }, compositionId) {
    try {
      const containerObjects =
        await Services.Composition.getLevel3ContainerObjects(compositionId);
      return containerObjects;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av context i nivå "Klar". Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return [];
    }
  },
  getOutOfSyncContainerObjects(context, compositionId: string) {
    return Services.Aggregator.getOutOfSyncContainerObjects(compositionId);
  },
  async migrateComposition({ dispatch }, payload) {
    try {
      await Services.Aggregator.migrateComposition(
        payload.compositionId,
        false,
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av listan av kunskapsstöd. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }
  },
  async moveObjects({ commit, dispatch, state }, payload) {
    if (!state.activeComposition) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Kunde inte hitta ett aktivt kunskapsstöd. Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return;
    }

    try {
      const containers = await Services.Composition.moveContainerObjects(
        state.activeComposition.id,
        payload,
      );

      containers.forEach((container) => {
        commit('UPDATE_CONTAINER', container);
      });

      commit('author/CLEAR_SELECTED_CONTENT_IDS', undefined, { root: true });
      commit('SET_IS_PERFORMING_SELECTION_ACTION', false);
    } catch (error) {
      commit('author/CLEAR_SELECTED_CONTENT_IDS', undefined, { root: true });
      commit('SET_IS_PERFORMING_SELECTION_ACTION', false);

      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid flytt av innehåll. Ladda om sidan och testa igen.',
        },
        { root: true },
      );
    }
  },
  async updateActiveCompositionProductionStatus(
    { commit, dispatch, state },
    productionStatus,
  ) {
    try {
      if (!state.activeComposition) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: null,
            message:
              'Kunde inte hitta ett aktivt kunskapsstöd. Ladda om sidan och testa igen.',
          },
          { root: true },
        );
        return;
      }
      const composition =
        await Services.Composition.updateCompositionProductionStatus(
          state.activeComposition.id,
          {
            clientObjectInformation: {
              commitNumber: state.activeComposition.commitNumber,
              versionNumber: state.activeComposition.versionNumber,
            },
            newStatus: productionStatus,
          },
        );
      commit('SET_ACTIVE_COMPOSITION', composition);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdatering av produktionsstatus. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }
  },
  async updateCompositionMarkup({ commit, dispatch, state }, payload) {
    try {
      if (!state.activeComposition) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: null,
            message:
              'Kunde inte hitta ett aktivt kunskapsstöd. Ladda om sidan och testa igen.',
          },
          { root: true },
        );
        return null;
      }

      const composition = await Services.Composition.updateCompositionMarkup(
        state.activeComposition.id,
        {
          ...payload,
          aspects: payload.aspects.map((aspect) => aspect.id),
          clientObjectInformation: {
            commitNumber: state.activeComposition.commitNumber,
            versionNumber: state.activeComposition.versionNumber,
          },
          id: state.activeComposition.id,
        },
      );

      commit('UPDATE_COMPOSITION_COMMIT_DATA', composition);

      if (composition.markup) {
        commit('SET_MARKUP_CODES', composition.markup.codes);
      }

      return composition;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdatering av märkning. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async updateCompositionTitle({ dispatch, commit }, payload) {
    const errorMessage = 'Kunde inte uppdatera titeln till kunskapsstödet';
    try {
      const res = await Services.Composition.updateCompositionTitle(payload);
      commit('SET_ACTIVE_COMPOSITION', res);
      commit('SET_COMPOSITION_MODIFIED', false);
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: "Composition title couldn't update",
          message: errorMessage,
        },
        { root: true },
      );
      return null;
    }
  },
  async updateContainer(
    { commit, dispatch, getters, rootGetters, state },
    payload,
  ) {
    const compositionId = state.activeComposition?.id ?? null;
    const indexes = getters.getContentIndexes(payload.containerObject.id);
    const getNearestSavedContainerObjectId =
      rootGetters['content/getNearestSavedContainerObjectId'];

    if (compositionId !== null && indexes) {
      const container = state.containers[indexes.containerIndex]!;
      const parentId = getNearestSavedContainerObjectId(
        container,
        indexes.contentIndex,
      );

      if (!parentId) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: 'ParentId of Container missing',
            message:
              'Blocket kunde inte sparas. Ladda om sidan och försök igen.',
          },
          { root: true },
        );
      } else {
        try {
          const newContainer = await Services.Composition.updateContainerObject(
            compositionId,
            container.id,
            {
              containerObject: {
                markup: payload.containerObject.markup ?? undefined,
                markupOverrides: payload.containerObject.markupOverrides,
                objectId: payload.containerObject.id,
                objectType: payload.containerObject.objectType,
              },
              parentId,
            },
          );

          await Promise.all([
            dispatch(
              'content/fetchContents',
              {
                contentIds: newContainer.containerObjectIds,
                contentType:
                  payload.containerObject.objectType ===
                  ObjectType.PROCESS_BLOCK
                    ? ContentType.PROCESS
                    : undefined,
              },
              {
                root: true,
              },
            ),
            dispatch(
              'ownership/fetchChangesForOwnerships',
              newContainer.containerObjectIds,
              { root: true },
            ),
          ]);
        } catch (error) {
          dispatch(
            'systemMessage/addSystemErrorMessageToQueue',
            {
              error,
              message:
                'Blocket kunde inte sparas. Ladda om sidan och försök igen.',
            },
            { root: true },
          );
          return;
        }

        commit(
          'content/SET_CONTENT_IS_UNMODIFIED',
          payload.containerObject.id,
          {
            root: true,
          },
        );

        return;
      }
    }

    dispatch(
      'systemMessage/addSystemErrorMessageToQueue',
      {
        error: null,
        message: 'Blocket kunde inte sparas. Ladda om sidan och försök igen.',
      },
      {
        root: true,
      },
    );
  },
  async updateContainerObjectStatus({ commit, dispatch, state }, payload) {
    if (!state.activeComposition) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Kunde inte hitta ett aktivt kunskapsstöd. Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return;
    }

    try {
      await dispatch(
        'ownership/lockContent',
        { contentId: payload.contentId },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte uppdatera blockets status. Blocket är låst av någon annan.',
        },
        { root: true },
      );
      return;
    }

    try {
      const containerObjects =
        await Services.Composition.bulkUpdateContainerObjectStatus(
          state.activeComposition.id,
          [
            {
              newStatus: payload.newStatus,
              objectId: payload.contentId,
            },
          ],
        );

      containerObjects.forEach((containerObject) => {
        commit('UPDATE_CONTAINER_OBJECT', containerObject);
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte uppdatera blockets status. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return;
    }

    await dispatch(
      'ownership/lockContent',
      { contentId: payload.contentId },
      { root: true },
    );
  },
  async updateContainerObjectType({ commit, state }, payload) {
    const { id, type } = payload;
    const container = CompositionUtils.findContainerObject(state, id);
    if (container) {
      container.objectType = type;
      commit('composition/UPDATE_CONTAINER_OBJECT', container, {
        root: true,
      });
    }
  },
  async updateContainerObjectsMarkup(
    { commit, dispatch, state },
    { compositionId, containerObjectIds },
  ) {
    try {
      const request: T.MultipleMarkupRequestDto = {
        requests: containerObjectIds.map((id) => {
          const containerObject = state.containerObjects[id]!;
          const objectRequest: T.MarkupRequestDto = {
            aspects: containerObject.markup.aspects.map((aspect) => aspect.id),
            clientObjectInformation: {
              commitNumber: containerObject.commitNumber!,
              versionNumber: containerObject.versionNumber!,
            },
            codes: containerObject.markup.codes,
            objectId: id,
          };
          return objectRequest;
        }),
      };
      const containerObjects =
        await Services.Composition.updateContainerObjectsMarkup(
          compositionId,
          request,
        );
      containerObjects.forEach((containerObject: T.ContainerObject) => {
        commit('UPDATE_CONTAINER_OBJECT', containerObject);
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Målgruppsändring kunde inte sparas. Ladda om sidan och försök igen.',
        },
        {
          root: true,
        },
      );
    }
  },
  async updateProductionPlanningItem({ dispatch, commit }, payload) {
    try {
      const compositionPlanningObject =
        await Services.Composition.updateCompositionPlanningObject(
          payload.compositionId,
          payload.uuid,
          payload.productionPlanningObject,
        );
      commit('UPDATE_COMPOSITION_PLANNING_OBJECT', compositionPlanningObject);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdateringen av produktionsplaneringsinformation. Vänligen försök igen.',
        },
        {
          root: true,
        },
      );
    }
  },
};
