import { T } from '../../types';
import * as models from '.';
import { ContentType, StatusType } from '../enums';

export const ProcessBlock = (dto: T.ProcessBlockDto): T.ProcessBlock => {
  return {
    aggregatedReferenceIds: dto.aggregatedReferenceIds,
    blockHandle: dto.blockHandle,
    blockType: dto.blockType,
    commitNumber: dto.commitNumber,
    contents: dto.contents.map((content) => {
      return models.BlockContent(content);
    }),
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.id,
    image: dto.image ? models.ProcessBlockImage(dto.image) : undefined,
    informationActor: dto.informationActor
      ? models.Code(dto.informationActor)
      : undefined,
    ingress: dto.ingress,
    processBlocks: dto.processBlocks.map((block) => models.ProcessBlock(block)),
    processStepType: dto.processStepType,
    referenceId: dto.referenceId,
    status: dto.status,
    title: dto.title,
    versionKey: dto.id,
    versionNumber: dto.versionNumber,
  };
};

// Model for converting processBlock as composition content
export const ProcessBlockContent = (block: T.ProcessBlock) => {
  return {
    aggregatedReferenceIds: block.aggregatedReferenceIds,
    commitNumber: block.commitNumber ?? null,
    contentProperties: null,
    created: block.created ?? 0,
    createdBy: block.createdBy ?? null,
    createdHr: block.createdHr ?? null,
    data: block,
    id: block.id,
    overrideId: null,
    status: block.status ?? StatusType.LEVEL_1,
    type: 'PROCESS' as ContentType.PROCESS,
    versionKey: block.versionKey ?? null,
    versionNumber: block.versionNumber ?? null,
  };
};
