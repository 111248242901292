import { T } from '@/types';

export const AttachmentContentDto = (
  model: T.AttachmentContentData,
): T.AttachmentContentDto => {
  return {
    approvedBy: model.approvedBy,
    attachmentType: model.attachmentType,
    contentResponsible: model.contentResponsible,
    fileName: model.fileName,
    fileSize: model.fileSize,
    mediaType: model.mediaType,
    reference: model.reference,
    validFrom: model.validFrom,
    version: model.version,
  };
};
