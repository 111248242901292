import { T } from '../../types';

export const AddContainerRequestDto = (
  headers: T.TemplateHeader[],
  parentContainerUUID?: string,
): T.AddContainerRequestDto => {
  return {
    containerRequest: {
      headers,
    },
    parentContainerUUID,
  };
};
