import { compositionActions } from './actions';
import { compositionGetters } from './getters';
import { compositionMutations } from './mutations';
import { compositionState } from './state';

export const compositionStore = {
  actions: compositionActions,
  getters: compositionGetters,
  mutations: compositionMutations,
  namespaced: true,
  state: compositionState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
