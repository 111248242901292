import { T } from '@/types';
import { Group } from '.';

export const GroupsForContext = (
  dto: T.GroupsForContextDto,
): T.GroupsForContext => {
  return {
    context: dto.context,
    contextValue: dto.contextValue,
    groups: dto.groups.map(Group),
  };
};
