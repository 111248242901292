import { T } from '@/types';

export const ReceiverRequestDto = (
  model: T.ReceiverPayload,
): T.ReceiverRequestDto => {
  return {
    email: model.email,
    fullName: model.fullName,
  };
};
