import { Services } from '@/services';
import { TemplateActions } from './actions.types';

export const templateActions: TemplateActions = {
  async addTemplateContainer({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.addTemplateContainer(
        payload.headers,
        payload.templateId,
        payload.parentContainerUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte lägga till container för mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async addTemplateHeader({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.addTemplateHeader(
        payload.request,
        payload.templateId,
        payload.containerUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte lägga till rubrik för mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchTemplate({ dispatch, commit }, templateId) {
    try {
      const template = await Services.Template.getTemplate(templateId);
      commit('SET_ACTIVE_TEMPLATE', template);
      return template;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte hämta mall för id: ${templateId}`,
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchTemplateAspects({ dispatch }) {
    try {
      const templateAspects = await Services.Template.getAvailableAspects();
      return templateAspects;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte hämta tillgängliga målgrupper, Vänligen ladda om sidan och försök igen.`,
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchTemplateInformationTypes() {
    return await Services.Template.getInformationTypes();
  },
  async fetchTemplates({ commit }) {
    const templates = await Services.Template.getTemplates();

    commit('SET_TEMPLATES', templates);

    return templates;
  },
  async moveHeaderInTemplate({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.moveHeaderInTemplate(
        payload.templateId,
        payload.containerUUID,
        payload.headerUUID,
        payload.targetContainerUUID,
        payload.targetParentHeaderUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte flytta rubrik i mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async moveTemplateContainer({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.moveTemplateContainer(
        payload.templateId,
        payload.containerUUID,
        payload.targetParentContainerUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte flytta container i mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async removeContainerFromTemplate({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.removeContainerFromTemplate(
        payload.templateId,
        payload.containerUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte ta bort container från mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async removeHeaderInTemplate({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.removeHeaderInTemplate(
        payload.templateId,
        payload.containerUUID,
        payload.headerUUID,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte ta bort rubrik från mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async removeTemplate({ commit, state }, payload) {
    await Services.Template.removeTemplate(
      payload.template.id,
      payload.ignoreFinalized,
    );

    commit(
      'REMOVE_TEMPLATE',
      state.templates.findIndex((template) => {
        return template.id === payload.template.id;
      }),
    );
  },
  async saveTemplate({ commit, dispatch }, payload) {
    try {
      const template = await Services.Template.createTemplate(payload);

      commit('ADD_TEMPLATE', template);
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        `${template.name} är nu redo att användas!`,
        { root: true },
      );

      return template;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `${payload.name} kunde inte sparas. Vänligen ladda om sidan och försök igen.`,
        },
        { root: true },
      );
      return null;
    }
  },
  async updateAddonSettings({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.updateAddonSettings(
        payload.addonsAllowed,
        payload.templateId,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte uppdatera mallinställningar`,
        },
        { root: true },
      );
      return null;
    }
  },
  async updateHeaderInTemplate({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.updateHeaderInTemplate(
        payload.templateId,
        payload.containerUUID,
        payload.headerUUID,
        payload.header,
        payload.headerType,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte uppdatera rubrik i mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async updateMetaTemplateData({ dispatch, commit }, payload) {
    try {
      const response = await Services.Template.updateMetaTemplateData(
        payload.request,
        payload.templateId,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte uppdatera metadata i mall`,
        },
        { root: true },
      );
      return null;
    }
  },
  async updateTemplate({ commit, state }, payload) {
    const id = state.templates[payload.index]?.id;
    if (id) {
      const template = await Services.Template.updateTemplate(
        id,
        payload.template,
      );

      commit('UPDATE_TEMPLATE', {
        index: payload.index,
        template,
      });
    }
  },
  async updateTemplateTitle({ commit, dispatch }, payload) {
    try {
      const response = await Services.Template.updateMetaTemplateData(
        { name: payload.title },
        payload.templateId,
      );
      commit('SET_ACTIVE_TEMPLATE', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: `Kunde inte uppdatera titel i mall`,
        },
        { root: true },
      );
      return null;
    }
  },
};
