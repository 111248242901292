import { T } from '@/types';
import { ContentProperties } from './ContentProperties';

export const ContentBase = (dto: T.ContentBaseDto): T.ContentBase => {
  return {
    aggregatedReferenceIds: dto.aggregatedReferenceIds,
    commitNumber: dto.commitNumber,
    contentProperties: dto.contentProperties
      ? ContentProperties(dto.contentProperties)
      : null,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.id,
    overrideId: dto.overrideId,
    status: dto.status,
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
