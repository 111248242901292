import * as dtos from '@/objects/dtos';
import { CommentStatus, ReviewStatus } from '@/objects/enums';
import * as models from '@/objects/models';
import { T } from '@/types';
import { ReviewProxy as proxy } from '../proxies';

export const ReviewService = {
  async addReceiver(
    compositionId: string,
    reviewId: string,
    payload: T.ReceiverPayload,
  ) {
    const request = dtos.ReceiverRequestDto(payload);
    const response = await proxy.addReceiver(compositionId, reviewId, request);
    return models.EmailReceiver(response.data);
  },
  async addReceivers(
    compositionId: string,
    reviewId: string,
    payloads: T.ReceiverPayload[],
  ) {
    const requests = payloads.map(dtos.ReceiverRequestDto);
    const response = await proxy.addReceivers(
      compositionId,
      reviewId,
      requests,
    );
    return response.data.map(models.EmailReceiver);
  },
  async addReviewObjects(
    compositionId: string,
    reviewId: string,
    payload: T.AddReviewObjectsPayload,
  ) {
    const request = dtos.ObjectRequestsDto(payload);
    const response = await proxy.addReviewObjects(
      compositionId,
      reviewId,
      request,
    );
    return response.data.map(models.ObjectResponse);
  },
  async closeReview(reviewId: string, compositionId: string, message: string) {
    const response = await proxy.closeReview(reviewId, compositionId, message);
    return models.Review(response.data);
  },
  async createCommentOnComment(
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
    comment: string,
  ) {
    const response = await proxy.createCommentOnComment(
      compositionId,
      reviewId,
      objectId,
      commentId,
      comment,
    );
    return models.CommentOnComment(response.data);
  },
  async createQuestion(
    compositionId: string,
    reviewId: string,
    objectId: string,
    payload: T.QuestionPayload,
  ) {
    const request = dtos.QuestionRequestDto(payload);
    const response = await proxy.createQuestion(
      compositionId,
      reviewId,
      objectId,
      request,
    );
    return models.Question(response.data);
  },
  async createReview(compositionId: string, payload: T.ReviewPayload) {
    const request = dtos.ReviewRequestDto(payload);
    const response = await proxy.createReview(compositionId, request);
    return models.Review(response.data);
  },
  async deleteCommentOnComment(
    commentOnCommentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
  ) {
    await proxy.deleteCommentOnComment(
      commentOnCommentId,
      compositionId,
      reviewId,
      objectId,
      commentId,
    );
  },
  async deleteQuestion(
    compositionId: string,
    reviewId: string,
    objectId: string,
  ) {
    await proxy.deleteQuestion(compositionId, reviewId, objectId);
  },
  async deleteReceiver(email: string, compositionId: string, reviewId: string) {
    await proxy.deleteReceiver(email, compositionId, reviewId);
  },
  async deleteReview(reviewId: string, compositionId: string) {
    await proxy.deleteReview(reviewId, compositionId);
  },
  async getCommentUpvotes(
    compositionId: string,
    reviewId: string,
    commentId: string,
  ) {
    const response = await proxy.getCommentUpvotes(
      compositionId,
      reviewId,
      commentId,
    );
    return response.data.map(models.Upvote);
  },
  async getCommentsOnComment(
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
  ) {
    const response = await proxy.getCommentsOnComment(
      compositionId,
      reviewId,
      objectId,
      commentId,
    );
    return response.data.map(models.CommentOnComment);
  },
  async getFullReviews(compositionId: string) {
    const response = await proxy.getFullReviews(compositionId);
    return response.data.map(models.ReviewResponse);
  },
  async getQuestions(compositionId: string, reviewId: string) {
    const response = await proxy.getQuestions(compositionId, reviewId);
    return response.data.map(models.Question);
  },
  async getReceivers(compositionId: string, reviewId: string) {
    const response = await proxy.getReceivers(compositionId, reviewId);
    return response.data.map(models.Receiver);
  },
  async getReview(reviewId: string, compositionId: string) {
    const response = await proxy.getReview(reviewId, compositionId);
    return models.ReviewResponse(response.data);
  },
  async getReviews(compositionId: string) {
    const response = await proxy.getReviews(compositionId);
    return response.data.map(models.Review);
  },
  async sendEmailToAllReceivers(reviewId: string, compositionId: string) {
    const response = await proxy.sendEmailToAllReceivers(
      reviewId,
      compositionId,
    );
    return models.ReviewResponse(response.data);
  },
  async sendEmailToReceivers(
    reviewId: string,
    compositionId: string,
    receiverIds: string[],
  ) {
    const response = await proxy.sendEmailToReceivers(
      reviewId,
      compositionId,
      receiverIds,
    );
    return response.data.map(models.EmailReceiver);
  },
  async updateCommentOnComment(
    commentOnCommentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
    comment: string,
  ) {
    const response = await proxy.updateCommentOnComment(
      commentOnCommentId,
      compositionId,
      reviewId,
      objectId,
      commentId,
      comment,
    );
    return models.CommentOnComment(response.data);
  },
  async updateCommentStatus(
    commentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    status: CommentStatus,
  ) {
    const response = await proxy.updateCommentStatus(
      commentId,
      compositionId,
      reviewId,
      objectId,
      status,
    );
    return models.Comment(response.data);
  },
  async updateReview(
    reviewId: string,
    compositionId: string,
    payload: T.ReviewPayload,
  ) {
    const request = dtos.ReviewRequestDto(payload);
    const response = await proxy.updateReview(reviewId, compositionId, request);
    return models.Review(response.data);
  },
  async updateReviewStatus(
    reviewId: string,
    compositionId: string,
    status: ReviewStatus,
  ) {
    const response = await proxy.updateReviewStatus(
      reviewId,
      compositionId,
      status,
    );
    return models.Review(response.data);
  },
};
