/* eslint-disable @typescript-eslint/no-explicit-any */
import { T } from '@/types';

export const useGetters = <TMap extends Record<string, keyof T.RootGetterTree>>(
  map: TMap,
) => {
  const getters = {} as {
    [K in keyof TMap]: () => T.RootGetterTree[TMap[K]];
  };

  Object.entries(map).forEach(([_getterKey, _accessor]) => {
    const getterKey = _getterKey as keyof TMap;
    const accessor = _accessor as keyof T.RootGetterTree;

    getters[getterKey] = function (this: T.TypedVue) {
      return this.$store.getters[accessor];
    } as any;
  });

  return getters;
};

export const useState = <TMap extends Record<string, keyof T.RootStateTree>>(
  map: TMap,
) => {
  const state = {} as {
    [K in keyof TMap]: () => T.RootStateTree[TMap[K]];
  };

  Object.entries(map).forEach(([_stateKey, _accessor]) => {
    const stateKey = _stateKey as keyof TMap;
    const tokens = (_accessor as keyof T.RootStateTree).split('/');
    const namespace = tokens[0] as keyof T.RootState;
    const key = tokens[1] as keyof T.RootState[keyof T.RootState];

    state[stateKey] = function (this: T.TypedVue) {
      return this.$store.state[namespace][key];
    };
  });

  return state;
};
