import { ReferenceHelper } from '@/core/objects/reference/reference-helper';
import { ReferenceItem } from '@/objects/models';
import { T } from '@/types';

export const ObjectItem = (dto: T.ObjectItemDto): T.ObjectItem => {
  return {
    objectId: dto.objectId,
    referenceItems: ReferenceHelper.sortReferences(
      (dto.referenceItems ?? []).map(ReferenceItem),
    ),
  };
};
