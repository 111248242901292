import { T } from '@/types';
import { Vue } from '@/vue';
import { ReferenceUtils } from '../reference/utils';
import { PublicationState as State } from './state';
import { ContentType } from '@/objects/enums';

export type PublicationMutations = typeof publicationMutations;

export const publicationMutations = {
  ADD_PUBLICATION(state: State, publication: T.PublicationReview) {
    state.publications.push(publication);
  },
  ADD_REFERENCES_ON_CONTENT(state: State, referenceMapping: T.ObjectItem) {
    if (referenceMapping.objectId) {
      const existingMapping =
        state.activePublicationReferences[referenceMapping.objectId];
      if (existingMapping) {
        existingMapping.push(...referenceMapping.referenceItems);
      } else {
        Vue.set(
          state.activePublicationReferences,
          referenceMapping.objectId,
          referenceMapping.referenceItems,
        );
      }
    }
  },
  CLEAR_CORRECTED_PUBLICATION_CONTENT(state: State) {
    Vue.set(state, 'correctedPublicationContent', {});
    Vue.set(state, 'correctedPublicationProcessBlocks', {});
  },
  CLEAR_PUBLICATION_METADATA(state: State) {
    state.correctedPublicationMetadata = [];
  },
  CORRECT_PUBLICATION_CONTENT(
    state: State,
    payload: {
      id: string;
      type: ContentType;
      value: T.ContentData | T.ProcessBlock[];
    },
  ) {
    switch (payload.type) {
      case ContentType.TEXT: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          id: payload.id,
          textContent: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.TABLE: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          id: payload.id,
          tableContent: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.LIST: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          id: payload.id,
          listContent: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.REFERENCE: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          id: payload.id,
          referenceContent: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.ATTACHMENT_REFERENCE: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          attachmentContent: payload.value,
          id: payload.id,
          type: payload.type,
        });
        break;
      }
      case ContentType.PICTURE_REFERENCE: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          id: payload.id,
          pictureContent: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.PROCESS: {
        Vue.set(state.correctedPublicationProcessBlocks, payload.id, {
          id: payload.id,
          processBlock: payload.value,
          type: payload.type,
        });
        break;
      }
      case ContentType.HEADER: {
        Vue.set(state.correctedPublicationContent, payload.id, {
          headerContent: payload.value,
          id: payload.id,
          type: payload.type,
        });
        break;
      }
      default:
        break;
    }
  },
  CORRECT_PUBLICATION_METADATA<K extends keyof T.CorrectionMetadataObject>(
    state: State,
    payload: {
      aspectId: string;
      assignment: string;
      field: K;
      value: T.CorrectionMetadataObject[K];
    },
  ) {
    const existingIndex = state.correctedPublicationMetadata.findIndex(
      (curr) => curr.aspectId === payload.aspectId,
    );
    if (existingIndex !== -1) {
      const existing = state.correctedPublicationMetadata[existingIndex];
      if (!existing) {
        return;
      }
      state.correctedPublicationMetadata.splice(existingIndex, 1, {
        ...existing,
        [payload.field]: payload.value,
      });
    } else {
      state.correctedPublicationMetadata = [
        ...state.correctedPublicationMetadata,
        {
          aspectId: payload.aspectId,
          assignment: payload.assignment,
          [payload.field]: payload.value,
        } as T.CorrectionMetadataObject,
      ];
    }
  },
  CORRECT_PUBLICATION_TITLE(state: State, title: string | null) {
    state.correctedPublicationTitle = title;
  },
  DELETE_REFERENCE_ON_CONTENT(
    state: State,
    payload: { contentId: string; reference: T.ReferenceItem },
  ) {
    const indexToRemove = state.activePublicationReferences[
      payload.contentId
    ]?.indexOf(payload.reference);
    if (indexToRemove !== undefined && indexToRemove > -1) {
      state.activePublicationReferences[payload.contentId]!.splice(
        indexToRemove,
        1,
      );
    }
  },
  SET_ACTIVE_PUBLICATION(
    state: State,
    activePublication: T.PublicationReview | null,
  ) {
    Vue.set(state, 'activePublication', activePublication);
  },
  SET_CONTAINER_OBJECT_ASPECTS(
    state: State,
    payload: {
      aspects: T.Aspect[];
      contentId: string;
    },
  ) {
    const containerObject =
      state.activePublication?.containerObjects[payload.contentId];
    if (containerObject) {
      containerObject.markup.aspects = payload.aspects;
    }
  },
  SET_HIGHLIGHTED_CONTENT(state: State, highlightedContent: string | null) {
    state.highlightedContent = highlightedContent;
  },
  SET_PUBLICATIONS(state: State, publications: T.PublicationReview[]) {
    state.publications = publications;
  },
  SET_PUBLISHED_PUBLICATION(state: State, publishedPublication: boolean) {
    state.publishedPublication = publishedPublication;
  },
  SET_REFERENCES(state: State, references: Record<string, T.ReferenceItem[]>) {
    Vue.set(state, 'activePublicationReferences', references);
  },
  SET_REFERENCES_ON_CONTENT(state: State, referenceMapping: T.ObjectItem) {
    if (referenceMapping.objectId) {
      Vue.set(
        state.activePublicationReferences,
        referenceMapping.objectId,
        referenceMapping.referenceItems,
      );
    }
  },
  SET_SELECTED_ASPECTS_READ(state: State, aspects: T.Aspect[]) {
    state.selectedAspectsRead = aspects;
  },
  SET_SHOW_BLOCKS_FROM_OTHER_OWNERS(
    state: State,
    showBlocksFromOtherOwners: boolean,
  ) {
    state.showBlocksFromOtherOwners = showBlocksFromOtherOwners;
  },
  SET_TITLE(state: State, title: string) {
    if (state.activePublication) {
      state.activePublication.publication.title = title;
    }
  },
  UPDATE_REFERENCE(state: State, reference: T.ReferenceItem) {
    const references = ReferenceUtils.updateReferenceItems(
      state.activePublicationReferences,
      reference,
    );
    Vue.set(state, 'activePublicationReferences', references);
  },
};
