import _ from 'lodash';
import { T } from '@/types';
import { Code } from '.';

export const MetadataMarkup = (
  dto?: T.MetadataMarkupDto | null,
): T.MetadataMarkup => {
  return {
    codes: (dto?.codes ?? []).map(Code),
    proposedCodesForDiagnosis: (dto?.proposedCodesForDiagnosis ?? []).map(Code),
    searchTerms: _.uniq(dto?.searchTerms ?? []),
  };
};
