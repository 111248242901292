import { T } from '@/types';

export const LocalStorageHelper = {
  get(key: string): unknown {
    const item = window.localStorage.getItem(key);
    if (!item) {
      return undefined;
    }
    return JSON.parse(item);
  },

  getPreselectedAuthGroup(userId: string) {
    const item = window.localStorage.getItem(
      `${userId}:user:activeAuthorizationGroup`,
    );
    return item === null
      ? undefined
      : (JSON.parse(item) as T.SimpleAuthorizationGroup);
  },

  getSelectedAspectsRead(userId: string, compositionId: string): T.Aspect[] {
    return (LocalStorageHelper.get(
      `${userId}:composition:${compositionId}:selectedAspectsRead`,
    ) ?? []) as T.Aspect[];
  },

  getSelectedAspectsWrite(userId: string, compositionId: string): T.Aspect[] {
    return (LocalStorageHelper.get(
      `${userId}:composition:${compositionId}:selectedAspectsWrite`,
    ) ?? []) as T.Aspect[];
  },

  getShowAllAspects(userId: string, compositionId: string): boolean {
    return (LocalStorageHelper.get(
      `${userId}:composition:${compositionId}:showAllAspects`,
    ) ?? false) as boolean;
  },

  getShowBlocksFromOtherOwners(userId: string, compositionId: string): boolean {
    return (LocalStorageHelper.get(
      `${userId}:composition:${compositionId}:showBlocksFromOtherOwners`,
    ) ?? false) as boolean;
  },

  getStartSelectedMembers(userId: string): string[] {
    const value = LocalStorageHelper.get(`${userId}:start:selectedMembers`);
    return Array.isArray(value) ? value : [];
  },

  getStartSelectedNpos(userId: string): string[] {
    const value = LocalStorageHelper.get(`${userId}:start:selectedNpos`);
    return Array.isArray(value) ? value : [];
  },
  getStartSelectedSections(userId: string): string[] {
    const value = LocalStorageHelper.get(`${userId}:start:selectedSections`);
    return Array.isArray(value) ? value : [];
  },
  getSystemPlanningObjectsRead(userId: string) {
    return (LocalStorageHelper.get(
      `${userId}:user:systemPlanningObjectsRead`,
    ) ?? []) as T.SystemPlanningObject[];
  },
  setPreselectedAuthGroup(
    userId: string,
    authGroup: T.SimpleAuthorizationGroup,
  ) {
    window.localStorage.setItem(
      `${userId}:user:activeAuthorizationGroup`,
      JSON.stringify(authGroup),
    );
  },
  setSelectedAspectsRead(
    userId: string,
    compositionId: string,
    selectedAspects: T.Aspect[],
  ) {
    window.localStorage.setItem(
      `${userId}:composition:${compositionId}:selectedAspectsRead`,
      JSON.stringify(selectedAspects),
    );
  },

  setSelectedAspectsWrite(
    userId: string,
    compositionId: string,
    selectedAspects: T.Aspect[],
  ) {
    window.localStorage.setItem(
      `${userId}:composition:${compositionId}:selectedAspectsWrite`,
      JSON.stringify(selectedAspects),
    );
  },

  setShowAllAspects(userId: string, compositionId: string, value: Boolean) {
    window.localStorage.setItem(
      `${userId}:composition:${compositionId}:showAllAspects`,
      JSON.stringify(value),
    );
  },

  setShowBlocksFromOtherOwners(
    userId: string,
    compositionId: string,
    value: Boolean,
  ) {
    window.localStorage.setItem(
      `${userId}:composition:${compositionId}:showBlocksFromOtherOwners`,
      JSON.stringify(value),
    );
  },

  setStartSelectedMembers(userId: string, selectedMembers: string[]) {
    window.localStorage.setItem(
      `${userId}:start:selectedMembers`,
      JSON.stringify(selectedMembers),
    );
  },

  setStartSelectedNpos(userId: string, selectedNpos: string[]) {
    window.localStorage.setItem(
      `${userId}:start:selectedNpos`,
      JSON.stringify(selectedNpos),
    );
  },
  setStartSelectedSections(userId: string, selectedSections: string[]) {
    window.localStorage.setItem(
      `${userId}:start:selectedSections`,
      JSON.stringify(selectedSections),
    );
  },
  setSystemPlanningObjectsRead(userId: string, sysObj: T.SystemPlanningObject) {
    window.localStorage.setItem(
      `${userId}:user:systemPlanningObjectsRead`,
      JSON.stringify([...this.getSystemPlanningObjectsRead(userId), sysObj]),
    );
  },
};
