import {
  browserCheck,
  groupAuth,
  resourceCheck,
  ticketAuth,
} from '@/app/middleware';
import Header from '@/components/Header.vue';
import { T } from '@/types';
import { store } from '@/app/plugins';
import VueRouter, { RouteConfig } from 'vue-router';
import { OwnershipType } from '@/objects/enums';

const routes: RouteConfig[] = [
  {
    components: {
      header: Header,
      leftNavBar: () => import('@/components/navigation/PageNavigation.vue'),
      main: () => import(/* webpackChunkName: "Document" */ '@/views/Home.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'home',
    path: '/',
  },
  {
    components: {
      header: Header,
      leftNavBar: () => import('@/components/navigation/PageNavigation.vue'),
      main: () =>
        import(
          /* webpackChunkName: "Document" */ '@/views/TemplateCreator.vue'
        ),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'templateCreator',
    path: '/skapa-mall/',
  },
  {
    components: {
      header: Header,
      leftNavBar: () => import('@/components/navigation/PageNavigation.vue'),
      main: () =>
        import(/* webpackChunkName: "Document" */ '@/views/Templates.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'templates',
    path: '/mallar',
  },
  {
    components: {
      header: Header,
      main: () =>
        import('@/components/composition/markup/CompositionMarkup.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'compositionMarkup',
    path: '/kunskapsstod/:compositionId/om',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () =>
        import(
          '@/components/composition/authorization/CompositionAuthorization.vue'
        ),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'compositionAuthorization',
    path: '/kunskapsstod/:compositionId/behorighet',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/Composition.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'composition',
    path: '/kunskapsstod/:compositionId/:tab',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    path: '/kunskapsstod/:compositionId',
    redirect: '/kunskapsstod/:compositionId/innehall',
  },
  {
    components: {
      header: Header,
      leftNavBar: () => import('@/components/navigation/PageNavigation.vue'),
      main: () => import('@/views/CompositionCreator.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'compositionCreator',
    path: '/skapa-kunskapsstod',
  },
  {
    components: {
      header: Header,
      leftNavBar: () =>
        import('@/components/publication/navigation/PublicationNavigation.vue'),
      main: () => import('@/views/PublicationFlow.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'publicationFlow',
    path: '/kunskapsstod/:compositionId/publiceringsflode/:step',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      leftNavBar: () =>
        import('@/components/publication/navigation/PublicationNavigation.vue'),
      main: () => import('@/views/RegionalPublicationFlow.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'regionalPublicationFlow',
    path: '/kunskapsstod/:compositionId/regionaltPubliceringsFlode/:step',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/ActivePublication.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'activePublication',
    path: '/kunskapsstod/:compositionId/publikation/aktiv',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/ReviewFlow.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'review',
    path: '/kunskapsstod/:compositionId/granskning/:pubId/:step?',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      leftNavBar: () =>
        import('@/components/publication/navigation/PublicationNavigation.vue'),
      main: () => import('@/views/PublicationPreview.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'preview',
    path: '/forhandsgranskning/:compositionId',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/ChangeStatusFlow.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'publication-status',
    path: '/publikation/status/:compositionId',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      leftNavBar: () =>
        import('@/components/publication/navigation/PublicationNavigation.vue'),
      main: () => import('@/views/CorrectionFlow.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'correction-flow',
    path: '/publikation/korrigering/:compositionId/:step',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/DeletePublicationObjectsView.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'regional-deletion',
    path: '/publikation/:compositionId/publikation/regional-borttagning',
    props: {
      header: {
        showCompositionToolbar: true,
      },
    },
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/EditTemplate.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'edit-template',
    path: '/mall/redigera/:templateId',
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/Account.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'account',
    path: '/konto',
  },
  {
    components: {
      header: Header,
      main: () => import('@/views/Groups.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, groupAuth, resourceCheck],
    },
    name: 'groups',
    path: '/grupper',
  },
  {
    components: {
      main: () => import('@/views/AuthGroupSelector.vue'),
    },
    meta: {
      middleware: [browserCheck, ticketAuth, resourceCheck],
    },
    name: 'authorizationGroups',
    path: '/behorighetsgrupper',
  },
  {
    components: {
      main: () =>
        import(/* webpackChunkName: "Document" */ '@/views/InvalidBrowser.vue'),
    },
    name: 'invalidBrowser',
    path: '/browser-stods-ej',
  },
  {
    components: {
      main: () =>
        import(/* webpackChunkName: "Document" */ '@/views/NoAccess.vue'),
    },
    meta: {
      middleware: [browserCheck],
    },
    name: 'noAccess',
    path: '/atkomst-nekad',
  },
  {
    components: {
      main: () =>
        import(/* webpackChunkName: "Document" */ '@/views/LoggedOut.vue'),
    },
    name: 'loggedOut',
    path: '/utloggad',
  },
];

const nextFactory = (
  context: T.NextFactoryContext,
  middleware: ((...parameters: unknown[]) => void)[],
  index: number,
) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) {
    return context.next;
  }

  const nextFunction: T.NextFunction = (...parameters) => {
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...parameters, ...context, next: nextMiddleware });
  };
  return nextFunction;
};

export const createRouter = () => {
  const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    if (from.name === 'composition') {
      store.dispatch('ownership/releaseAllActiveUserLocksByType', [
        OwnershipType.CONTENT,
        OwnershipType.PROCESS,
      ]);
    }

    if (to.meta?.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];
      const context = {
        from,
        next,
        router,
        to,
      };

      const nextMiddleware = nextFactory(context, middleware, 1);
      return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
  });

  return router;
};
