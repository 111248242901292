import { ModalState as State } from './index';

export type ModalMutations = typeof modalMutations;

export const modalMutations = {
  CLOSE_MODAL(state: State) {
    state.isOpen = false;
    state.props = {};
    state.view = '';
  },
  SET_ACTIVE_MODAL(
    state: State,
    payload: {
      view: string;
      props: object;
    },
  ) {
    state.view = payload.view;
    state.props = payload.props;
    state.isOpen = true;
  },
};
