import { T } from '@/types';
import { BrokenLink } from './BrokenLink';

export const BrokenLinks = (dto: T.BrokenLinksDto): T.BrokenLinks => {
  if (dto.status === 'FAILED') {
    throw new Error('getBrokenLinks has returned status: FAILED');
  } else {
    return {
      invalidURLs: dto.invalidLinks.map((url) => {
        return BrokenLink(url);
      }),
      nrOfCheckedURLs: dto.nrOfCheckedURLs,
    };
  }
};
