import { T } from '@/types';

export type CompositionState = typeof compositionState;

export const compositionState = {
  activeComposition: null as T.Composition | null,
  activeCompositionReferences: {} as Record<string, T.ReferenceItem[]>,
  activeCompositionReferencesV2: [] as T.ReferenceV2Dto[],
  availableInformationActors: [] as T.Code[],
  brokenLinks: null as T.BrokenLinks | null,
  codeSystemsInUse: null as Record<string, T.CodeSystem> | null,
  compositionObjectModified: false,
  compositionPlanningObjects: [] as T.CompositionPlanningObject[],
  compositionPublication: null as null | T.PublicationReview,
  compositions: [] as T.CompositionForList[],
  containerHistory: {
    activeContainerId: '',
    activeSnapshot: null as T.ContainerSnapshot | null,
    commits: [] as T.TimelineCommit[],
    containerIdsWithHistory: [] as string[],
    prevSnapshot: null as T.ContainerSnapshot | null,
  },
  containerObjects: {} as T.ContainerObjectMap,
  containers: [] as T.Container[],
  isLoadingCompositionPub: false,
  isPerformingSelectionAction: false,
  isPreviewContainerHistoryMode: false,
  level3ContentExists: false,
  metadata: [] as T.Metadata[],
  migrationInformation: {} as T.ExternalMigrationInformation,
  ownerships: ['Nationell', 'Stockholms län'] as string[],
  selectedAspectsRead: [] as T.Aspect[],
  showBlocksFromOtherOwners: false,
  templateUpgradeAvailable: false,
};
