import { T } from '@/types';

export const CreateGroupRequestDto = (
  model: T.CreateGroupPayload,
): T.CreateGroupRequestDto => {
  return {
    context: model.context,
    contextValue: model.contextValue,
    description: model.description,
    displayName: model.displayName,
    groupAccessType: model.groupAccessType,
    members: model.members,
    metadata: model.metadata,
  };
};
