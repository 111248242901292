import { T } from '@/types';

export type ReviewState = typeof reviewState;

export const reviewState = {
  activeQuestions: [] as T.Question[],
  activeReview: null as T.ReviewResponse | null,
  addingReceivers: false,
  badResponse: { all: [], bad: [] } as {
    bad: string[];
    all: T.ReceiverPayload[];
  },
  objectResponseBeingHandled: null as T.ObjectResponseBeingHandled | null,
  reviewBeingHandled: null as T.ReviewResponse | null,
  reviews: [] as T.Review[],
};
