import { T } from '@/types';
import Vue from 'vue';
import { TemplateState as State } from './state';

export type TemplateMutations = typeof templateMutations;

export const templateMutations = {
  ADD_TEMPLATE(state: State, template: T.Template) {
    if (!state.templates.some(({ id }) => template.id === id)) {
      state.templates.push(template);
    }
  },
  REMOVE_TEMPLATE(state: State, index: number) {
    state.templates.splice(index, 1);
  },
  SET_ACTIVE_TEMPLATE(state: State, template: T.Template) {
    state.activeTemplate = template;
  },
  SET_TEMPLATES(state: State, templates: T.Template[]) {
    state.templates = templates;
  },
  UPDATE_CONTAINER_FLOW(state: State, templateArr: T.TemplateContainer[]) {
    state.flow.containers = templateArr;
  },
  UPDATE_TEMPLATE(
    state: State,
    payload: {
      template: T.Template;
      index: number;
    },
  ) {
    Vue.set(state.templates, payload.index, payload.template);
  },
};
