import { T } from '@/types';
import { ContainerObject } from './ContainerObject';
import { Content, ProcessBlockContent, ProcessBlock } from '@/objects/models';

export const ContainerSnapshot = (
  dto: T.ContainerSnapshotDto,
): T.ContainerSnapshot => {
  const containerObjects = {} as T.ContainerObjectMap;
  Object.entries(dto.containerObjects).forEach(([key, value]) => {
    containerObjects[key] = ContainerObject(value);
  });
  const contents = {} as Record<string, T.Content>;
  Object.entries(dto.contents).forEach(([key, contentDto]) => {
    contents[key] = Content(contentDto);
  });

  const processBlocks: Record<string, T.ProcessContent> = {};
  Object.entries(dto.processBlocks).forEach(([key, currBlock]) => {
    const processBlock = ProcessBlock(currBlock);
    processBlocks[key] = ProcessBlockContent(processBlock);
  });
  return {
    compositionId: dto.compositionId,
    containerCommit: dto.containerCommit,
    containerObjectIds: dto.containerObjectIds,
    containerObjects,
    containerStartTime: dto.containerStartTime,
    containerStopTime: dto.containerStopTime,
    containerVersion: dto.containerVersion,
    contents,
    id: dto.containerUUID,
    processBlocks,
  };
};
