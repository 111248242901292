import { T } from '@/types';
import { AspectSpecificInformationDto } from './AspectSpecificInformationDto';

export const CreatePublicationRequestDto = (
  model: T.CreatePublicationPayload,
): T.CreatePublicationRequestDto => {
  return {
    aspectSpecificInformation: model.aspectSpecificInformation?.map(
      AspectSpecificInformationDto,
    ),
    comment: model.comment,
    compositionId: model.compositionId,
    optionalDefaultAspectId: model.optionalDefaultAspectId,
    optionalForceCreation: model.optionalForceCreation,
    optionalTitle: model.optionalTitle,
    publishDate: model.publishDate,
    schedulingRequest: model.schedulingRequest,
    source: model.source,
    sourceId: model.sourceId,
  };
};
