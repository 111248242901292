import { ObjectType, StatusType } from '@/objects/enums';
import { T } from '../../../types';

/**
 * TODO: Refactor so that all model creators are in the same place.
 */
export const ContainerObjectCreator = {
  createNewContainerObject(payload: {
    aspects: T.Aspect[];
    id: string;
    status: StatusType;
    type: ObjectType;
  }): T.ContainerObject {
    return {
      commitNumber: null,
      created: Date.now(),
      createdBy: null,
      createdHr: null,
      id: payload.id,
      markup: {
        aspects: payload.aspects,
        codes: [],
      },
      markupOverrides: {},
      objectType: payload.type,
      status: payload.status,
      versionNumber: null,
    };
  },
};
