import { T } from '@/types';

export const ReferenceContentDto = (
  model: T.ReferenceContentData,
): T.ReferenceContentDto => {
  return {
    displayReferenceText: model.displayReferenceText,
    reference: model.reference,
  };
};
