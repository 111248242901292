import { T } from '@/types';
import { Aspect } from './Aspect';
import { CodeSystem } from './CodeSystem';
import { CompositionWithContainersAndMetadata } from './CompositionWithContainersAndMetadata';
import { Content } from '@/objects/models';

export const ExternalMigrationInformation = (
  dto: T.ExternalMigrationInformationDto,
): T.ExternalMigrationInformation => {
  const volatileContent = {} as Record<string, T.Content>;
  Object.entries(dto.volatileContent).forEach(([key, contentDto]) => {
    volatileContent[key] = Content(contentDto);
  });

  return {
    addedContainerUUIDs: dto.addedContainerUUIDs,
    addedObjectIds: dto.addedObjectIds,
    migratedComposition: CompositionWithContainersAndMetadata(
      dto.migratedComposition,
    ),
    movedContainerUUIDs: dto.movedContainerUUIDs,
    movedObjectIds: dto.movedObjectIds,
    removedContainerUUIDs: dto.removedContainerUUIDs,
    removedObjectIds: dto.removedObjectIds,
    updatedObjectIds: dto.updatedObjectIds,
    validAspectsAfterMigration: dto.validAspectsAfterMigration.map(Aspect),
    validAspectsBeforeMigration: dto.validAspectsBeforeMigration.map(Aspect),
    validCodeSystemsAfterMigration:
      dto.validCodeSystemsAfterMigration.map(CodeSystem),
    validCodeSystemsBeforeMigration:
      dto.validCodeSystemsBeforeMigration.map(CodeSystem),
    validCodeSystemsForDiagnosisAfterMigration:
      dto.validCodeSystemsForDiagnosisAfterMigration.map(CodeSystem),
    validCodeSystemsForDiagnosisBeforeMigration:
      dto.validCodeSystemsForDiagnosisBeforeMigration.map(CodeSystem),
    validHeadersAfterMigration: dto.validHeadersAfterMigration,
    validHeadersBeforeMigration: dto.validHeadersBeforeMigration,
    volatileContent,
  };
};
