import { ActiveMode } from '@/objects/enums/ActiveMode';
import { Services } from '@/services';
import { GlobalActions } from './actions.types';

export const globalActions: GlobalActions = {
  async clearCompositionGlobalStates({ commit }) {
    commit('SET_ACTIVE_MODE', ActiveMode.DEFAULT);
  },
  async fetchAndLoadSystemPlanningInformation({ commit }) {
    const systemPlanningObjects =
      await Services.ProductionPlanning.getSystemPlanningInformation();

    commit('SET_SYSTEM_PLANNING_OBJECTS', systemPlanningObjects);
  },
  async fetchCodeSystems({ commit, dispatch }) {
    try {
      const codeSystems = await Services.Code.getAllCodeSystems();

      commit('SET_CODE_SYSTEMS', codeSystems);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtande av kodverk. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchNPOs({ commit }) {
    const npos = await Services.Ownership.getNPOs();
    commit('SET_NPOS', npos);
    return npos
  },
  async fetchSubjectGroups({ commit }) {
    const subjectGroups = await Services.Ownership.getSubjectGroups(
      'BEHANDLINGSREKOMMENDATION',
    );

    commit('SET_SUBJECT_GROUPS', subjectGroups);
  },
};
