import { T } from '@/types';
import { ContentReferenceV2 } from './Reference';

export const TextContentData = (dto: T.TextContentDto): T.TextContentData => {
  return {
    basisForRecommendation: dto.basisForRecommendation,
    reference: dto.reference
      ? (dto.reference
          .map(ContentReferenceV2)
          .filter((ref) => ref.targetPart) as T.ContentReferenceV2[])
      : [],
    text: dto.text,
  };
};
