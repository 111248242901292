import vLinkify from 'v-linkify';
import Vue from 'vue';
import vueClipboards from 'vue-clipboards';
import vueRouter from 'vue-router';
import vuetify from 'vuetify/lib';
import vuex from 'vuex';
import App from './App.vue';
import { createRouter, createStore, createVuetify } from './app/plugins';
import { registerLockCleanup } from '@/core/helpers';
import VueI18n from 'vue-i18n';
import sv from '@/app/locales/sv.json';

Vue.use(vLinkify);
Vue.use(vueClipboards);
Vue.use(vueRouter);
Vue.use(vuetify);
Vue.use(vuex);
Vue.use(VueI18n);

const messages = {
  se: sv,
};

const i18n = new VueI18n({
  locale: 'se',
  messages,
});

Vue.config.productionTip = false;
Vue.config.performance = true;

new Vue({
  i18n,
  render: (h) => h(App),
  router: createRouter(),
  store: createStore(),
  vuetify: createVuetify(),
}).$mount('#app');

registerLockCleanup();
