import { SelectMode, StatusType } from '@/objects/enums';
import { T } from '@/types';

export const authorUtils = {
  getAvailableAspects(publication: T.PublicationReview): T.Aspect[] {
    const metaAspects = publication.metadata
      .filter(
        (metadata, index, self) =>
          self.findIndex(
            (metadata2) => metadata.aspectId === metadata2.aspectId,
          ) === index,
      )
      .sort((a, b) => {
        return b.aspectName.toUpperCase() > a.aspectName.toUpperCase() ? -1 : 0;
      });
    if (metaAspects) {
      return metaAspects.map((aspect) => ({
        code: aspect.aspectId.substring(aspect.aspectId.length - 4),
        id: aspect.aspectId,
        initials: aspect.aspectInitials,
        name: aspect.aspectName,
        oid: aspect.aspectId.substring(0, aspect.aspectId.length - 5),
      }));
    }
    return [];
  },
  getAvailableAspectsForCurrentUser(
    publication: T.PublicationReview,
    user: T.User,
  ): T.Aspect[] {
    const metaAspects = publication.metadata
      .filter((metadata) => metadata.assignment === user.assignmentName)
      .sort((a, b) => {
        return b.aspectName.toUpperCase() > a.aspectName.toUpperCase() ? -1 : 0;
      });

    if (metaAspects) {
      return metaAspects.map((aspect) => ({
        code: aspect.aspectId.substring(aspect.aspectId.length - 4),
        id: aspect.aspectId,
        initials: aspect.aspectInitials,
        name: aspect.aspectName,
        oid: aspect.aspectId.substring(0, aspect.aspectId.length - 5),
      }));
    }
    return [];
  },
  getAvailableMetadata(
    publication: T.PublicationReview,
    chosenAspectIds: string[] | null,
  ): T.PublicationMetadata[] {
    const metaAspects = publication.metadata.filter(
      (metadata, index, self) =>
        self.findIndex(
          (metadata2) => metadata.aspectId === metadata2.aspectId,
        ) === index,
    );
    if (chosenAspectIds) {
      return metaAspects.filter((a) => chosenAspectIds.includes(a.aspectId));
    }
    return metaAspects;
  },
  modeContentFilter({
    bulkStatus,
    selectMode,
  }: T.AuthorState): (content: T.Content | null) => boolean {
    if (selectMode === SelectMode.MOVE) {
      return () => true;
    }

    if (selectMode === SelectMode.MARKUP) {
      return () => true;
    }

    if (selectMode === SelectMode.STATUS) {
      if (!bulkStatus) {
        return () => false;
      }

      // Nothing can change to level 1
      if (bulkStatus === StatusType.LEVEL_1) {
        return () => false;
      }

      // Only level 1 can change to level 2
      if (bulkStatus === StatusType.LEVEL_2) {
        return (content) => {
          return !!(content && content.status === StatusType.LEVEL_1);
        };
      }

      if (bulkStatus === StatusType.LEVEL_3) {
        return (content) => {
          return !!(content && content.status === StatusType.LEVEL_2);
        };
      }
    }

    // No selection mode, none can be selected
    return () => false;
  },
};
