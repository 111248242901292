import { MetadataRequestDto } from '@/objects/dtos';
import { T } from '@/types';

export const MultipleMetadataRequestDto = (
  models: T.MetadataPayload[],
): T.MultipleMetadataRequestDto => {
  return {
    requests: models.map(MetadataRequestDto),
  };
};
