import { T } from '@/types';
import * as dtos from '@/objects/dtos';

export const CreateProcessBlockRequestDto = (
  model: T.CreateProcessBlockRequest,
): T.CreateProcessBlockRequestDto => {
  return {
    blockHandle: model.blockHandle,
    blockType: model.blockType,
    contents: model.contents?.map((content) => {
      return dtos.BlockContentDto(content);
    }),
    id: model.id,
    image: model.image,
    informationActor: model.informationActor
      ? dtos.CodeDto(model.informationActor)
      : undefined,
    ingress: model.ingress,
    processBlocks: model.processBlocks?.map((processBlock) => {
      return dtos.CreateProcessBlockRequestDto(processBlock);
    }),
    processStepType: model.processStepType,
    referenceId: model.referenceId,
    title: model.title,
  };
};

export const PatchProcessBlockRequestDto = (
  model: T.PatchProcessBlockRequest,
): T.PatchProcessBlockRequestDto => {
  return {
    blockHandle: model.blockHandle,
    contents: model.contents?.map((content) => {
      return dtos.BlockContentDto(content);
    }),
    id: model.id,
    image: model.image,
    informationActor: model.informationActor
      ? dtos.CodeDto(model.informationActor)
      : undefined,
    ingress: model.ingress,
    processBlocks: model.processBlocks?.map((processBlock) => {
      return dtos.CreateProcessBlockRequestDto(processBlock);
    }),
    processStepType: model.processStepType,
    processblockType: model.blockType,
    status: model.status,
    title: model.title,
  };
};
