import { T } from '@/types';

export const ValidateTicketResponse = (
  dto: T.ValidateTicketResponseDto,
): T.ValidateTicketResponse => {
  return {
    ticket: dto.ticket,
    valid: dto.valid,
  };
};
