import { T } from '@/types';
import { PublicationMarkup } from './PublicationMarkup';

export const PublicationContainerObject = (
  dto: T.PublicationContainerObjectDto,
): T.PublicationContainerObject => {
  return {
    commitNumber: dto.commitNumber,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    defaultAspectId: dto.defaultAspectId,
    id: dto.objectId,
    markup: PublicationMarkup(dto.markup),
    objectType: dto.objectType,
    ownership: dto.ownership,
    status: dto.status,
    versionNumber: dto.versionNumber,
  };
};
