import { T } from '@/types';
import { HeaderType } from '../enums';

export const TemplateHeader = (dto: T.TemplateHeaderDto): T.TemplateHeader => {
  return {
    header: dto.header ?? '',
    headerType: dto.headerType as HeaderType,
    id: dto.uuid ?? '',
  };
};
