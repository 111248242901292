import { ActiveMode } from '@/objects/enums/ActiveMode';
import { T } from '@/types';

export type GlobalState = typeof globalState;

export const globalState = {
  activeMode: ActiveMode.DEFAULT,
  blockHandling: false,
  codeSystems: null as T.CodeSystem[] | null,
  dualColumn: true,
  npos: [] as T.LinkObject[],
  preventNavigation: false,
  subjectGroups: [] as T.SubjectGroup[],
  systemPlanningObjects: [] as T.SystemPlanningObject[],
  systemPlanningObjectsRead: [] as T.SystemPlanningObject[],
};
