<template>
  <v-app-bar
    id="main-nav-bar"
    app
    flat
    clipped-left
    color="white"
    class="d-print-none"
  >
    <template v-if="mobileSearchMode && $vuetify.breakpoint.mobile">
      <SearchBar full-width @setMobileSearchMode="setMobileSearchMode" />
    </template>
    <template v-else>
      <h1>
        <v-toolbar-title id="template-start-page">
          <router-link to="/" class="text-decoration-none routerTextColor">
            {{ $i18n.t('ui.headings.app_title_abv') }}
          </router-link>
        </v-toolbar-title>
      </h1>
      <v-divider class="ml-5 title-divider-disabled" vertical />
      <div class="toolbar-notification__wrapper" title="Observera detta är en arkivversion, publicerade kunskapsstöd finns på vardpersonal.1177.se">
        <div class="toolbar-notification">
          <v-icon class="material-symbols-outlined" color="secondary">
            warning
          </v-icon>
          <span class="toolbar-notification__text">Observera detta är en arkivversion, publicerade kunskapsstöd finns på <a href="https://vardpersonal.1177.se">vardpersonal.1177.se</a></span>
        </div>
      </div>
      <router-view name="toolbar" />
      <v-toolbar-items v-if="showCompositionToolbar">
        <CompositionPublicationMenu
          v-if="activeComposition"
          :active-composition="activeComposition"
          :composition-id="activeCompositionId"
          :contents="contents"
          :container-objects="containerObjects"
        />
        <PublicationMenu
          v-else-if="activePublication"
          :composition-id="activeCompositionId"
          :active-publication="activePublication"
        />
        <v-divider vertical inset />
      </v-toolbar-items>
      <v-spacer />
      <SearchBar
        :is-mobile="$vuetify.breakpoint.mobile"
        @setMobileSearchMode="setMobileSearchMode"
      />
      <BrokenLinkMenu
        v-if="isActiveUserNational && isEditorRole"
        :broken-links-list="brokenLinksList"
        :loading-broken-links="loadingBrokenLinks"
        :number-of-changed-urls="numberOfChangedUrls"
      />
      <SupportMaterial
        :is-active-user-national="isActiveUserNational"
        :is-editor-role="isEditorRole"
        @fetchBrokenLinks="fetchBrokenLinks"
      />
      <UserAccountMenu :assignment-object="assignmentObject" />
    </template>
  </v-app-bar>
</template>

<script lang="ts">
import { Vue } from '@/vue';
import { T } from '@/types';
import { useGetters, useState } from '@/store';
import { Services } from '@/services';

export default Vue.extend({
  name: 'Header',
  components: {
    BrokenLinkMenu: () => import('@/components/header/BrokenLinkMenu.vue'),
    SearchBar: () => import('@/components/search/SearchBar.vue'),
    SupportMaterial: () => import('@/components/header/SupportMaterial.vue'),
    CompositionPublicationMenu: () =>
      import('@/components/header/CompositionPublicationMenu.vue'),
    PublicationMenu: () => import('@/components/header/PublicationMenu.vue'),
    UserAccountMenu: () => import('@/components/header/UserAccountMenu.vue'),
  },
  props: {
    showCompositionToolbar: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingBrokenLinks: false,
    mobileSearchMode: false,
  }),
  computed: {
    ...useGetters({
      getAssignmentObject: 'ownership/getAssignmentObject',
      isActiveUserNational: 'user/isActiveUserNational',
      isCommentHandling: 'global/isCommentHandling',
      isEditorRole: 'user/isEditorRole',
    }),
    ...useState({
      activeComposition: 'composition/activeComposition',
      activeMode: 'global/activeMode',
      activePublication: 'publication/activePublication',
      brokenLinks: 'composition/brokenLinks',
      contents: 'content/contents',
      containerObjects: 'composition/containerObjects',
      user: 'user/user',
    }),
    assignmentObject(): T.Organization | null {
      return this.getAssignmentObject(this.user!.assignmentName);
    },
    activeCompositionId(): string {
      return this.$route.params.compositionId!;
    },
    brokenLinksList(): T.BrokenLink[] | null {
      if (this.brokenLinks) {
        return this.brokenLinks.invalidURLs;
      }
      return null;
    },
    numberOfChangedUrls(): number | null {
      if (this.brokenLinks) {
        return this.brokenLinks.nrOfCheckedURLs;
      }
      return null;
    },
  },
  watch: {
    '$route.params.compositionId': {
      handler(newValue: string, oldValue: string) {
        if (newValue === oldValue) {
          return;
        }
        this.getPublication();
      },
      deep: true,
    },
  },
  created() {
    this.getPublication();
  },
  methods: {
    setMobileSearchMode() {
      this.mobileSearchMode = !this.mobileSearchMode;
    },
    async fetchBrokenLinks(includeNpos: boolean) {
      this.loadingBrokenLinks = true;
      await this.$store.dispatch('composition/fetchBrokenLinks', includeNpos);
      this.loadingBrokenLinks = false;
    },
    async getPublication() {
      if (!this.showCompositionToolbar) {
        return;
      }

      if (this.activeCompositionId) {
        this.$store.commit('composition/SET_PUBLICATION_LOADING', true);
        try {
          const publication = await Services.Publication.getLatestPublication(
            this.activeCompositionId,
          );
          this.$store.commit('composition/SET_COMP_PUBLICATION', publication);
        } catch {
          this.$store.commit('composition/SET_COMP_PUBLICATION', null);
        }
        this.$store.commit('composition/SET_PUBLICATION_LOADING', false);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.broken-link-item:hover {
  background-color: $lightGray;
}

#main-nav-bar {
  z-index: 100;
  align-items: center;
}

.routerTextColor {
  color: $ineraBlack;
  font-size: 24px;
}

.v-toolbar__items {
  align-items: center;
  font-size: 14px;
}

.toolbar-title:hover {
  background: $lightGray;
}

.toolbar-notification {
  background: #ffc400;
  padding: 8px;
  margin: 8px;
  border-radius: 20px;
  min-width: 40px;
  display: block;
}

.toolbar-notification__wrapper {
  container: notification / inline-size;
  width: 100%;
  display: block;
  max-width: 600px;
  min-width: 50px;
}

.toolbar-notification__text {
  font-size: smaller;
}

@container notification (width < 599px) {
  .toolbar-notification__text {
    display: none;
  }

  .toolbar-notification {
    width: 40px;
  }
}

@media screen and (width <= 1000px) {
  .title-divider-disabled {
    display: none !important;
  }
}

@media screen and (width <= 650px) {
  .assignment-object-disabled {
    display: none;
  }
}

.registry-icon {
  font-size: 16px;
}
</style>
