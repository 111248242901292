import { T } from '@/types';
import { Markup } from './Markup';

export const ContainerObject = (
  dto: T.ContainerObjectDto,
): T.ContainerObject => {
  const markupOverrides = {} as Record<string, T.Markup>;

  Object.entries(dto.markupOverrides ?? []).forEach(([key, markupDto]) => {
    markupOverrides[key] = Markup(markupDto);
  });

  return {
    commitNumber: dto.commitNumber,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    id: dto.objectId,
    level3Exist: dto.level3Exist,
    markup: Markup(dto.markup),
    markupOverrides,
    objectType: dto.objectType,
    status: dto.status,
    uuid: dto.uuid,
    versionNumber: dto.versionNumber,
  };
};
