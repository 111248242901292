import { T } from '@/types';

export const AspectSpecificInformationDto = (
  model: T.AspectPublishingSettings,
): T.AspectSpecificInformationDto => {
  return {
    approvedDate: model.approvalDate
      ? new Date(model.approvalDate).valueOf()
      : undefined,
    aspectId: model.aspectId,
    comment: model.comment ?? undefined,
    revisionDate: model.plannedRevisionDate
      ? new Date(model.plannedRevisionDate).valueOf()
      : undefined,
  };
};
