import { T } from '@/types';
import { ReceiverInfo } from './ReceiverInfo';

export const Receiver = (dto: T.ReceiverDto): T.Receiver => {
  return {
    created: dto.created,
    gsiEmail: dto.gsiEmail,
    id: dto.id,
    receiverInfo: dto.receiverInfo ? ReceiverInfo(dto.receiverInfo) : null,
    receiverStatus: dto.receiverStatus,
    receiverType: dto.receiverType,
    reviewId: dto.reviewId,
    sentDate: dto.sentDate,
    title: dto.title,
    updated: dto.updated,
  };
};
