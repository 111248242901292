import _ from 'lodash';
import { T } from '@/types';

export const AddMultipleOwnershipRequestDto = (
  model: T.CreateMultipleOwnershipsPayload,
): T.AddMultipleOwnershipRequestDto => {
  return {
    groupTag: model.groupTag,
    objectIds: _.uniq(model.objectIds),
    objectType: model.objectType,
    organizationId: model.organizationId,
    organizationName: model.organizationName,
    ownershipRelation: model.ownershipRelation,
    sectionId: model.sectionId,
    sectionName: model.sectionName,
  };
};
