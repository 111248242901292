import { T } from '@/types';

export const ContainerObjectRequestDto = (
  model: T.ContainerObjectPayload,
): T.ContainerObjectRequestDto => {
  return {
    markup: model.markup,
    markupOverrides: model.markupOverrides,
    objectId: model.objectId,
    objectType: model.objectType,
  };
};
