import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase } from './ContentBase';
import { PictureReference } from './PictureReference';

export const PictureContent = (
  dto: T.ContentDto<ContentType.PICTURE_REFERENCE>,
): T.PictureContent => {
  return {
    ...ContentBase(dto),
    data: {
      alternativeText: dto.pictureContent.alternativeText ?? '',
      fileName: dto.pictureContent.fileName,
      pictureCreated: dto.pictureContent.pictureCreated,
      pictureCreator: dto.pictureContent.pictureCreator ?? '',
      pictureName: dto.pictureContent.pictureName,
      pictureReferences: (dto.pictureContent.pictureReferences ?? []).map(
        PictureReference,
      ),
      pictureText: dto.pictureContent.pictureText ?? '',
      rightsHolder: dto.pictureContent.rightsHolder ?? '',
    },
    type: ContentType.PICTURE_REFERENCE,
  };
};
