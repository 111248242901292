import { T } from '@/types';
import { ProcessBlock, ProcessBlockContent } from './ProcessBlock';
import { Publication } from './Publication';
import { PublicationContainer } from './PublicationContainer';
import { PublicationContainerObject } from './PublicationContainerObject';
import { PublicationContent } from './PublicationContent';
import { PublicationMetadata } from './PublicationMetadata';

export const PublicationReview = (
  dto: T.ExternalPublicationDto,
): T.PublicationReview => {
  const containerObjects = {} as T.PublicationContainerObjectMap;

  Object.keys(dto.containerObjects).forEach((key) => {
    containerObjects[key] = PublicationContainerObject(
      dto.containerObjects[key]!,
    );
  });

  const content: Record<string, T.PublicationContent> = {};
  Object.entries(dto.content).forEach(([key, value]) => {
    content[key] = PublicationContent(value);
  });

  Object.entries(dto.processBlock).forEach(([key, value]) => {
    const processBlock = ProcessBlock(value);
    content[key] = ProcessBlockContent(processBlock);
  });

  return {
    containerObjects,
    containers: (dto.containers ?? []).map(PublicationContainer),
    content,
    metadata: (dto.metadata ?? []).map(PublicationMetadata),
    publication: Publication(dto.publication),
    references: dto.references,
  };
};
