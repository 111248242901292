import { T } from '../../types';
import { Aspect } from './Aspect';
import { Code } from './Code';

export const Markup = (dto?: T.MarkupDto | null): T.Markup => {
  return {
    aspects: (dto?.aspects ?? []).map(Aspect),
    codes: (dto?.codes ?? []).map(Code),
  };
};
