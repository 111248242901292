import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-processblock';

export const ProcessBlockProxy = {
  async createProcessBlock(
    request: T.CreateProcessBlockRequestDto,
    groupTag: string,
  ) {
    return await proxy.post<T.ProcessBlockDto>(
      `${URL}/processblocks?groupTag=${groupTag}`,
      request,
    );
  },
  async createProcessBlockAndAddToExisting(
    id: string,
    parentId: string,
    request: T.CreateProcessBlockRequestDto,
  ) {
    return await proxy.post<T.ProcessBlockDto>(
      `${URL}/processblocks/${id}/children/${parentId}`,
      request,
    );
  },
  async deleteProcessBlock(payload: { rootId: string; childId: string }) {
    return await proxy.delete<T.ProcessBlockDto>(
      `${URL}/processblocks/${payload.rootId}/children/${payload.childId}`,
    );
  },
  async fetchProcessBlock(id: string) {
    return await proxy.get<T.ProcessBlockDto>(`${URL}/processblocks/${id}`);
  },
  async fetchProcessBlockHistory(id: string, index: number, pageSize: number) {
    return await proxy.get<T.SimpleProcessBlockDto[]>(
      `${URL}/processblocks/${id}/history?pageIndex=${index}&pageSize=${pageSize}`,
    );
  },
  async fetchProcessBlocks(ids: string[]) {
    return await proxy.post<T.ProcessBlockDto[]>(
      `${URL}/processblocks/withids`,
      ids,
    );
  },
  async moveChildProcessBlock(
    id: string,
    sourceId: string,
    request: T.MoveProcessBlockRequest,
  ) {
    return await proxy.put<T.ProcessBlockDto>(
      `${URL}/processblocks/${id}/children/${sourceId}/move`,
      request,
    );
  },
  async patchChildProcessBlock(
    id: string,
    childId: string,
    request: T.PatchProcessBlockRequestDto,
  ) {
    return await proxy.patch<T.ProcessBlockDto>(
      `${URL}/processblocks/${id}/children/${childId}`,
      request,
    );
  },
  async updateProcessBlock(
    id: string,
    request: T.CreateProcessBlockRequestDto,
  ) {
    return await proxy.put<T.ProcessBlockDto>(
      `${URL}/processblocks/${id}`,
      request,
    );
  },
  async updateProcessBlockStatus(
    request: T.UpdateProcessBlockStatusRequestDto,
  ) {
    return await proxy.patch<T.UpdateProcessBlockStatusResponse>(
      `${URL}/processblocks/status`,
      request,
    );
  },
};
