import { CommentStatus, ReviewStatus } from '@/objects/enums';
import { T } from '@/types';
import { Vue } from '@/vue';
import { ReviewState as State } from './state';

export type ReviewMutations = typeof reviewMutations;

export const reviewMutations = {
  ADD_COMMENT_ON_COMMENT(
    state: State,
    payload: {
      commentOnComment: T.CommentOnComment;
      index: number;
      objectResponseIndex: number;
    },
  ) {
    const objectResponses = state.reviewBeingHandled?.objectResponses;
    const objectResponse = objectResponses?.[payload.objectResponseIndex];
    const comment = objectResponse?.comments[payload.index];
    if (
      comment &&
      !comment.commentsOnComment.some(
        ({ commentOnCommentId }) =>
          payload.commentOnComment.commentOnCommentId === commentOnCommentId,
      )
    ) {
      comment.commentsOnComment.push(payload.commentOnComment);
    }
  },
  ADD_OBJECT_RESPONSES(state: State, objectResponses: T.ObjectResponse[]) {
    if (state.activeReview) {
      state.activeReview.objectResponses.push(...objectResponses);
    }
  },
  ADD_QUESTION(
    state: State,
    payload: {
      index: number;
      question: T.Question;
    },
  ) {
    const objectResponse = state.activeReview?.objectResponses[payload.index];
    if (objectResponse) {
      objectResponse.question = payload.question;
    }
  },
  ADD_RECEIVER(state: State, receiver: T.EmailReceiver) {
    if (state.activeReview) {
      const { emailReceivers } = state.activeReview;
      const index = emailReceivers.findIndex(({ email }) => {
        return receiver.email === email;
      });

      if (index === -1) {
        emailReceivers.push(receiver);
      } else {
        emailReceivers.splice(index, 1, receiver);
      }
    }
  },
  ADD_RECEIVERS(state: State, newReceivers: T.EmailReceiver[]) {
    newReceivers.forEach((newReceiver) => {
      if (state.activeReview) {
        const { emailReceivers } = state.activeReview;
        const index = emailReceivers.findIndex(({ email }) => {
          return newReceiver.email === email;
        });

        if (index === -1) {
          emailReceivers.push(newReceiver);
        } else {
          emailReceivers.splice(index, 1, newReceiver);
        }
      }
    });
  },
  ADD_REVIEW(state: State, review: T.Review) {
    if (!state.reviews.some(({ id }) => review.id === id)) {
      state.reviews.push(review);
    }
  },
  CLEAR_REVIEWS(state: State) {
    state.reviews = [];
    state.activeReview = null;
  },
  DELETE_COMMENT_ON_COMMENT(
    state: State,
    payload: {
      index: number;
      objectResponseIndex: number;
      remove: number;
    },
  ) {
    const objectResponses = state.reviewBeingHandled?.objectResponses;
    const objectResponse = objectResponses?.[payload.objectResponseIndex];
    const comment = objectResponse?.comments[payload.index];
    if (comment) {
      comment.commentsOnComment.splice(payload.remove, 1);
    }
  },
  DELETE_QUESTION(state: State, questionId: number) {
    const objectResponse = state.activeReview?.objectResponses[questionId];
    if (objectResponse) {
      objectResponse.question = null;
    }
  },
  REMOVE_RECEIVER(state: State, index: number) {
    if (state.activeReview) {
      state.activeReview.emailReceivers.splice(index, 1);
    }
  },
  SET_ACTIVE_QUESTIONS(state: State, questions: T.Question[]) {
    Vue.set(state, 'activeQuestions', questions);
  },
  SET_ACTIVE_REVIEW(state: State, review: T.Review | null) {
    if (review) {
      Vue.set(state, 'activeReview', {
        ...(state.activeReview
          ? {
              ...state.activeReview,
              review,
            }
          : {
              objectResponses: [],
              receivers: [],
              review,
            }),
      });
    } else {
      state.activeReview = null;
    }
  },
  SET_ACTIVE_REVIEW_RESPONSE(
    state: State,
    activeReview: T.ReviewResponse | null,
  ) {
    state.activeReview = activeReview;
  },
  SET_ADDING_RECEIVERS(state: State, status: boolean) {
    state.addingReceivers = status;
  },
  SET_BAD_RESPONSE(
    state: State,
    payload: { bad: string[]; all: T.ReceiverPayload[] },
  ) {
    state.badResponse = payload;
  },
  SET_COMMENTS_ON_COMMENT(
    state: State,
    payload: {
      commentsOnComment: T.CommentOnComment[];
      index: number;
      objectResponseIndex: number;
    },
  ) {
    const objectResponses = state.reviewBeingHandled?.objectResponses;
    const objectResponse = objectResponses?.[payload.objectResponseIndex];
    const comment = objectResponse?.comments[payload.index];
    if (comment) {
      comment.commentsOnComment = payload.commentsOnComment;
    }
  },
  SET_METADATA(
    state: State,
    payload: { metaData: T.ReviewMetaData; reviewId: string },
  ) {
    const { metaData, reviewId } = payload;
    const review = state.reviews.find((review) => review.id === reviewId);
    if (review) {
      Vue.set(review, 'metaData', metaData);
    }
  },
  SET_OBJECT_RESPONSE_BEING_HANDLED(
    state: State,
    objectResponseBeingHandled: T.ObjectResponseBeingHandled | null,
  ) {
    state.objectResponseBeingHandled = objectResponseBeingHandled;
  },
  SET_REVIEW(
    state: State,
    payload: {
      review: T.Review;
      index: number;
    },
  ) {
    Vue.set(state.reviews, payload.index, payload.review);
  },
  SET_REVIEWS(state: State, reviews: T.Review[]) {
    state.reviews = reviews;
  },
  SET_REVIEW_BEING_HANDLED(
    state: State,
    reviewBeingHandled: T.ReviewResponse | null,
  ) {
    state.reviewBeingHandled = reviewBeingHandled;
  },
  UPDATE_COMMENT_ON_COMMENT(
    state: State,
    payload: {
      commentOnComment: T.CommentOnComment;
      index: number;
      objectResponseIndex: number;
      removeIndex: number;
    },
  ) {
    const objectResponses = state.reviewBeingHandled?.objectResponses;
    const objectResponse = objectResponses?.[payload.objectResponseIndex];
    const comment = objectResponse?.comments[payload.index];
    if (comment) {
      comment.commentsOnComment.splice(
        payload.removeIndex,
        1,
        payload.commentOnComment,
      );
    }
  },
  UPDATE_COMMENT_STATUS(
    state: State,
    payload: {
      objectId: string;
      commentId: string;
      status: CommentStatus;
    },
  ) {
    const objectRes = state.reviewBeingHandled?.objectResponses.find(
      (response) => {
        return response.reviewObject.objectId === payload.objectId;
      },
    );
    if (!objectRes) {
      return;
    }
    const comment = objectRes.comments.find((response) => {
      return response.commentId === payload.commentId;
    });

    const reviewComment = state.reviewBeingHandled?.comments.find(
      (rComment) => rComment.commentId === payload.commentId,
    );

    if (comment) {
      comment.commentStatus = payload.status;
    }
    if (reviewComment) {
      reviewComment.commentStatus = payload.status;
    }
  },
  UPDATE_EMAIL_RECEIVER(
    state: State,
    payload: {
      receiver: T.EmailReceiver;
      index: number;
    },
  ) {
    if (state.activeReview) {
      Vue.set(
        state.activeReview.emailReceivers,
        payload.index,
        payload.receiver,
      );
    }
  },
  UPDATE_RECEIVER(
    state: State,
    payload: {
      receiver: T.Receiver;
      index: number;
    },
  ) {
    if (state.activeReview) {
      Vue.set(state.activeReview.receivers, payload.index, payload.receiver);
    }
  },
  UPDATE_REVIEW(
    state: State,
    payload: { reviewPayload: T.ReviewPayload; reviewId: string },
  ) {
    const { reviewPayload, reviewId } = payload;
    const review = state.reviews.find((review) => review.id === reviewId);
    if (review) {
      if (reviewPayload.expires) {
        Vue.set(review, 'expires', reviewPayload.expires);
      }
      if (reviewPayload.heading) {
        Vue.set(review, 'header', reviewPayload.heading);
      }
      if (reviewPayload.auxiliaryMessage) {
        Vue.set(review, 'auxiliaryMessage', reviewPayload.auxiliaryMessage);
      }
      if (reviewPayload.metaData) {
        Vue.set(review, 'metaData', reviewPayload.metaData);
      }
      if (reviewPayload.name) {
        Vue.set(review, 'name', reviewPayload.name);
      }
      if (reviewPayload.ownerGroup) {
        Vue.set(review, 'ownerGroup', reviewPayload.ownerGroup);
      }
      if (reviewPayload.publicationId) {
        Vue.set(review, 'publicationName', reviewPayload.publicationId);
      }
      if (reviewPayload.reviewType) {
        Vue.set(review, 'reviewType', reviewPayload.reviewType);
      }
    }
  },
  UPDATE_REVIEW_STATUS(
    state: State,
    payload: { reviewId: string; reviewStatus: ReviewStatus },
  ) {
    const review = state.reviews.find(
      (review) => review.id === payload.reviewId,
    );
    if (review) {
      review.reviewStatus = payload.reviewStatus;
      if (state.reviewBeingHandled) {
        state.reviewBeingHandled.review.reviewStatus = review.reviewStatus;
      }
    }
  },
};
