import { T } from '@/types';
import { ReceiverInfo } from './ReceiverInfo';

export const Comment = (dto: T.CommentDto): T.Comment => {
  return {
    comment: dto.comment,
    commentId: dto.commentId,
    commentStatus: dto.commentStatus,
    commentType: dto.commentType,
    commentsOnComment: dto.commentsOnComment,
    compositionId: dto.compositionId,
    created: dto.created,
    objectId: dto.objectId,
    receiverId: dto.receiverId,
    receiverInfo: ReceiverInfo(dto.receiverInfo),
    reviewId: dto.reviewId,
    startIndex: dto.startIndex,
    stopIndex: dto.stopIndex,
    title: dto.title,
    type: dto.type,
    versionKey: dto.versionKey,
  };
};

export const ListReviewComment = (
  dto: T.ListReviewHitCommentDto,
): T.Comment => {
  return {
    comment: dto.comment,
    commentId: dto.commentId,
    commentStatus: dto.commentStatus,
    commentType: dto.commentType,
    commentsOnComment: [],
    compositionId: '',
    created: dto.created,
    objectId: '',
    receiverId: '',
    receiverInfo: ReceiverInfo(dto.receiverInfo),
    reviewId: '',
    startIndex: 0,
    stopIndex: 0,
    title: '',
    type: '',
    versionKey: '',
  };
};
