import { ActiveMode } from '@/objects/enums/ActiveMode';
import { T } from '@/types';
import { GlobalState as State } from './state';

export type GlobalMutations = typeof globalMutations;

export const globalMutations = {
  SET_ACTIVE_MODE(state: State, activeMode: ActiveMode) {
    state.activeMode = activeMode;
  },
  SET_BLOCK_HANDLING(state: State, isBlockHandling: boolean) {
    state.blockHandling = isBlockHandling;
  },
  SET_CODE_SYSTEMS(state: State, codeSystems: T.CodeSystem[]) {
    state.codeSystems = codeSystems;
  },
  SET_DUAL_COLUMN(state: State, dualColumnMode: boolean) {
    state.dualColumn = dualColumnMode;
  },
  SET_NPOS(state: State, npos: T.LinkObject[]) {
    state.npos = npos;
  },
  SET_PREVENT_NAVIGATION(state: State, preventNavigation: boolean) {
    state.preventNavigation = preventNavigation;
  },
  SET_SUBJECT_GROUPS(state: State, subjectGroups: T.SubjectGroup[]) {
    state.subjectGroups = subjectGroups;
  },
  SET_SYSTEM_PLANNING_OBJECTS(
    state: State,
    systemPlanningObjects: T.SystemPlanningObject[],
  ) {
    state.systemPlanningObjects = systemPlanningObjects;
  },
  SET_SYSTEM_PLANNING_OBJECTS_READ(
    state: State,
    systemPlanningObjectsRead: T.SystemPlanningObject[],
  ) {
    state.systemPlanningObjectsRead = systemPlanningObjectsRead;
  },
};
