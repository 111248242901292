import { T } from '@/types';
import { Aspect } from './Aspect';

export const PublicationMarkup = (
  dto?: T.PublicationMarkupDto | null,
): T.PublicationMarkup => {
  return {
    aspects: (dto?.aspects ?? []).map(Aspect),
    codes: dto?.codes ?? [],
  };
};
