import { ReferenceV2 } from '@/objects/models';
import { ReferenceProxyV2 as proxy } from '../proxies';

export const ReferenceServiceV2 = {
  async createReference(compositionId: string, referenceText: string) {
    const response = await proxy.createReference(compositionId, referenceText);
    return response.data;
  },
  async deleteReference(id: string) {
    return await proxy.deleteReference(id);
  },
  async getAllV2References(compositionId: string) {
    const response = await proxy.getAllV2References(compositionId);
    const reference = response.data.map(ReferenceV2);
    return reference;
  },
  async getV2Reference(referenceId: string) {
    const response = await proxy.getV2Reference(referenceId);
    const reference = ReferenceV2(response.data);
    return reference;
  },
  async updateReference(referenceId: string, referenceText: string) {
    const response = await proxy.updateReference(referenceId, referenceText);
    return response.data;
  },
};
