import { OwnershipType } from '@/objects/enums';
import { T } from '@/types';
import Vue from 'vue';
import { OwnershipState as State } from './state';

export type OwnershipMutations = typeof ownershipMutations;

function setOwnership(state: State, ownership: T.Ownership) {
  if (!state.ownerships[ownership.id]) {
    Vue.set(state.ownerships, ownership.id, [ownership]);
    return;
  }

  const ownershipsForObject = state.ownerships[ownership.id];

  if (
    ownershipsForObject &&
    !ownershipsForObject.some(
      (existingOwnership) =>
        existingOwnership.id === ownership.id &&
        existingOwnership.organizationId === ownership.organizationId,
    )
  ) {
    ownershipsForObject.push(ownership);
  }
}

export const ownershipMutations = {
  ADD_LOCK(state: State, lock: T.Ownership) {
    Vue.set(state.locks, lock.id, lock);
  },
  ADD_MULTIPLE_LOCKS(state: State, locks: T.Ownership[]) {
    locks.forEach((lock) => ownershipMutations.ADD_LOCK(state, lock));
  },
  ADD_OWNERSHIP(state: State, ownership: T.Ownership) {
    setOwnership(state, ownership);
  },
  ADD_OWNERSHIPS(state: State, ownerships: T.Ownership[]) {
    ownerships.forEach((ownership) => setOwnership(state, ownership));
  },
  CLEAR_AND_SET_LOCKS(state: State, locks: T.Ownership[]) {
    Vue.set(state, 'locks', {});
    locks.forEach((lock) => Vue.set(state.locks, lock.id, lock));
  },
  CLEAR_AND_SET_LOCKS_BY_TYPE(
    state: State,
    payload: { locks: T.Ownership[]; lockType: OwnershipType },
  ) {
    Object.values(state.locks).forEach((lock) => {
      if (lock.objectType === payload.lockType) {
        Vue.delete(state.locks, lock.id);
      }
    });
    payload.locks.forEach((lock) => Vue.set(state.locks, lock.id, lock));
  },
  RELEASE_LOCK_COMPLETE(state: State, objectId: string) {
    const index = state.pendingLockReleases.findIndex(
      (lockId) => lockId === objectId,
    );
    if (index > -1) {
      state.pendingLockReleases.splice(index, 1);
    }
  },
  RELEASE_LOCK_STARTED(state: State, objectId: string) {
    if (!state.pendingLockReleases.includes(objectId)) {
      state.pendingLockReleases.push(objectId);
    }
  },
  RELEASE_MULTIPLE_LOCK_COMPLETE(state: State, objectIds: string[]) {
    objectIds.forEach((objectId) =>
      ownershipMutations.RELEASE_LOCK_COMPLETE(state, objectId),
    );
  },
  RELEASE_MULTIPLE_LOCK_STARTED(state: State, objectIds: string[]) {
    objectIds.forEach((objectId) =>
      ownershipMutations.RELEASE_LOCK_STARTED(state, objectId),
    );
  },
  REMOVE_LOCK(state: State, lockId: string) {
    Vue.delete(state.locks, lockId);
  },
  REMOVE_MULTIPLE_LOCKS(state: State, lockIds: string[]) {
    lockIds.forEach((lockId) => ownershipMutations.REMOVE_LOCK(state, lockId));
  },
  SET_OWNERSHIP_FOR_MULTIPLE_OBJECTS(
    state: State,
    payload: T.OwnershipResponse[],
  ) {
    payload.forEach((ownershipItem) =>
      ownershipMutations.SET_OWNERSHIP_FOR_OBJECT(state, {
        containerObjectId: ownershipItem.objectId,
        ownerships: ownershipItem.ownerships,
      }),
    );
  },
  SET_OWNERSHIP_FOR_OBJECT(
    state: State,
    payload: {
      containerObjectId: string;
      ownerships: T.Ownership[];
    },
  ) {
    Vue.set(state.ownerships, payload.containerObjectId, payload.ownerships);
  },
  SET_RESOURCES(state: State, resources: string[]) {
    state.resources = resources;
  },
  SET_STRUCTURE(state: State, structure: T.OrganizationStructure) {
    state.structure = structure;
  },
};
