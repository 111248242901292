import { store } from '@/app/plugins';
import { LocalStorageHelper } from '@/core/helpers/local-storage-helper';
import { T } from '@/types';
import VueRouter from 'vue-router';

const goToAuthGroupSelector = (router: VueRouter) => {
  router.push({ name: 'authorizationGroups' });
};

export const groupAuth = async ({ router, next }: T.NextFactoryContext) => {
  const activeAuthorizationGroup = store.state.user.activeAuthorizationGroup;
  const user = store.state.user.user;

  if (user) {
    const preselectedAuthGroup = LocalStorageHelper.getPreselectedAuthGroup(
      user.userId,
    );

    if (preselectedAuthGroup && !activeAuthorizationGroup) {
      try {
        await store.dispatch(
          'user/populateActiveAuthorizationGroup',
          preselectedAuthGroup,
        );
      } catch (error) {
        console.error(error);
        goToAuthGroupSelector(router);
        return;
      }
    } else if (!preselectedAuthGroup || !activeAuthorizationGroup) {
      try {
        await store.dispatch('user/populateAuthorizationGroup');
      } catch (error) {
        console.error(error);
        goToAuthGroupSelector(router);
        return;
      }
    }
  }

  next();
};
