import { T } from '@/types';
import { Member } from './Member';

export const Group = (dto: T.GroupDto): T.Group => {
  return {
    commitNumber: dto.commitNumber,
    context: dto.context,
    contextValue: dto.contextValue,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    description: dto.description,
    displayName: dto.displayName,
    groupAccessType: dto.groupAccessType,
    id: dto.id,
    members: dto.members.map(Member),
    metadata: dto.metadata,
    restriction: dto.restriction,
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
