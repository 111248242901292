import { T } from '@/types';

export const UpdateTemplateRequestDto = (
  model: T.Template,
): T.UpdateTemplateRequestDto => {
  return {
    containers: model.containers,
    informationType: model.informationType,
    name: model.name,
    validAspectIds: model.validAspects.map((aspect) => aspect.id),
    validCodeSystems: model.validCodeSystems,
    validCodeSystemsForDiagnosis: model.validCodeSystemsForDiagnosis,
    validHeaders: model.validHeaders,
  };
};
