import { T } from '@/types';

export const PublicationMetadataLink = (
  dto: T.PublicationMetadataLinkDto,
): T.PublicationMetadataLink => {
  return {
    description: dto.description ?? '',
    longName: dto.longName ?? '',
    name: dto.name ?? '',
    organization: dto.organization ?? '',
    url: dto.url ?? '',
  };
};
