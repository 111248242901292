import { T } from '@/types';

export const PublicationMetadataComment = (
  dto: T.PublicationMetadataCommentDto,
): T.PublicationMetadataComment => {
  return {
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    message: dto.message ?? '',
  };
};
