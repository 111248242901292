import { T } from '@/types';

export const CompositionSearchResults = (
  dto: T.CompositionSearchResultsDto,
): T.CompositionSearchResults => {
  return {
    hits: dto.hits,
    matchingCompositions: dto.matchingCompositions,
    maxScore: dto.maxScore,
    page: dto.page,
    pageSize: dto.pageSize,
  };
};
