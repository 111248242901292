import { T } from '@/types';

export const ContentReferenceV2 = (
  dto: T.ContentReferenceV2Dto,
): T.ContentReferenceV2Dto => {
  return {
    referenceId: dto.referenceId,
    targetPart: dto.targetPart,
  };
};

export const ReferenceV2 = (dto: T.ReferenceV2Dto): T.ReferenceV2Dto => {
  return {
    compositionId: dto.compositionId,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    deleted: dto.deleted,
    id: dto.id,
    text: dto.text,
    versionKey: dto.versionKey,
  };
};

export const Reference = (dto: T.ReferenceDto): T.Reference => {
  return {
    commitNumber: dto.commitNumber,
    compositionId: dto.id,
    created: dto.created,
    createdBy: dto.createdBy,
    deleteStatus: dto.deleteStatus,
    finalized: dto.finalized,
    id: dto.referenceId,
    number: dto.number,
    ownerGroup: dto.ownerGroup,
    ownerGroupId: dto.ownerGroupId,
    publishedDate: dto.publishedDate,
    referenceText: dto.referenceText,
    statusChangeTimeInMs: dto.statusChangeTimeInMs,
    updated: dto.updated,
    updatedBy: dto.updatedBy,
    versionKey: dto.versionKey,
  };
};
