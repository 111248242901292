import { T } from '@/types';
import * as dtos from '@/objects/dtos';

export const CorrectionProcessBlockDto = (
  model: T.CorrectionProcessBlock,
): T.CorrectionProcessBlockDto => {
  return {
    blockHandle: model.blockHandle,
    childId: model.childId,
    contents: model.contents.map((content) => {
      return dtos.BlockContentDto(content);
    }),
    id: model.id,
    image: model.image,
    ingress: model.ingress,
    title: model.title,
  };
};
