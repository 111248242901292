import { T } from '@/types';
import { Organization } from './Organization';

export const OrganizationStructure = (
  dto: T.OrganizationStructureDto,
): T.OrganizationStructure => {
  return {
    flatOrganizations: dto.flatOrganizations.map(Organization),
    hierarchicalOrganizations: dto.hierarchicalOrganizations.map(Organization),
    name: dto.name,
    structureType: dto.structureType,
  };
};
