import { T } from '@/types';

export const Aspect = (dto: T.AspectDto): T.Aspect => {
  return {
    code: dto.code,
    id: dto.id,
    initials: dto.initials || dto.name.slice(0, 2),
    name: dto.name,
    oid: dto.oid,
  };
};
