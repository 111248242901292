import { templateActions } from './actions';
import { templateGetters } from './getters';
import { templateMutations } from './mutations';
import { templateState } from './state';

export const templateStore = {
  actions: templateActions,
  getters: templateGetters,
  mutations: templateMutations,
  namespaced: true,
  state: templateState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
