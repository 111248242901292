import { T } from '@/types';

export type TemplateState = typeof templateState;

export const templateState = {
  activeTemplate: {} as T.Template,
  flow: {
    containers: [] as T.TemplateContainer[],
  },
  templates: [] as T.Template[],
};
