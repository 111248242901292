import { CommentStatus, ReviewStatus } from '@/objects/enums';
import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-review';

export const ReviewProxy = {
  async addReceiver(
    compositionId: string,
    reviewId: string,
    request: T.ReceiverRequestDto,
  ) {
    return await proxy.post<T.EmailReceiverDto>(
      `${URL}/emailreceiver/composition/${compositionId}/review/${reviewId}`,
      request,
    );
  },
  async addReceivers(
    compositionId: string,
    reviewId: string,
    requests: T.ReceiverRequestDto[],
  ) {
    return await proxy.post<T.EmailReceiverDto[]>(
      `${URL}/emailreceiver/list/composition/${compositionId}/review/${reviewId}`,
      requests,
    );
  },
  async addReviewObjects(
    compositionId: string,
    reviewId: string,
    request: T.ObjectRequestsDto,
  ) {
    return await proxy.post<T.ObjectResponseDto[]>(
      `${URL}/reviewobjects/composition/${compositionId}/review/${reviewId}`,
      request,
    );
  },
  async closeReview(reviewId: string, compositionId: string, message: string) {
    return await proxy.put<T.ReviewDto>(
      `${URL}/review/close/${reviewId}/composition/${compositionId}`,
      { closedMessage: message },
    );
  },
  async createCommentOnComment(
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
    comment: string,
  ) {
    return await proxy.post<T.CommentOnCommentDto>(
      `${URL}/coc/composition/${compositionId}/review/${reviewId}/objectid/${objectId}/comment/${commentId}`,
      comment,
    );
  },
  async createQuestion(
    compositionId: string,
    reviewId: string,
    objectId: string,
    request: T.QuestionRequestDto,
  ) {
    return await proxy.post<T.QuestionDto>(
      `${URL}/questions/composition/${compositionId}/review/${reviewId}/objectid/${objectId}`,
      request,
    );
  },
  async createReview(compositionId: string, request: T.ReviewRequestDto) {
    return await proxy.post<T.ReviewDto>(
      `${URL}/review/composition/${compositionId}`,
      request,
    );
  },
  async deleteCommentOnComment(
    commentOnCommentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
  ) {
    return await proxy.delete(
      `${URL}/coc/${commentOnCommentId}/composition/${compositionId}/review/${reviewId}/objectid/${objectId}/comment/${commentId}`,
    );
  },
  async deleteQuestion(
    compositionId: string,
    reviewId: string,
    objectId: string,
  ) {
    return await proxy.delete(
      `${URL}/questions/composition/${compositionId}/review/${reviewId}/objectid/${objectId}`,
    );
  },
  async deleteReceiver(email: string, compositionId: string, reviewId: string) {
    return await proxy.delete(
      `${URL}/emailreceiver/${email}/composition/${compositionId}/review/${reviewId}`,
    );
  },
  async deleteReview(reviewId: string, compositionId: string) {
    return await proxy.delete(
      `${URL}/review/${reviewId}/composition/${compositionId}`,
    );
  },
  async getCommentUpvotes(
    compositionId: string,
    reviewId: string,
    commentId: string,
  ) {
    return await proxy.get<T.UpVoteDto[]>(
      `${URL}/upvotes/composition/${compositionId}/review/${reviewId}/objectid/${commentId}`,
    );
  },
  async getCommentsOnComment(
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
  ) {
    return await proxy.get<T.CommentOnCommentDto[]>(
      `${URL}/coc/composition/${compositionId}/review/${reviewId}/objectid/${objectId}/comment/${commentId}`,
    );
  },
  async getFullReviews(compositionId: string) {
    return await proxy.get<T.ReviewResponseDto[]>(
      `${URL}/review/full/composition/${compositionId}`,
    );
  },
  async getQuestions(compositionId: string, reviewId: string) {
    return await proxy.get<T.QuestionDto[]>(
      `${URL}/questions/composition/${compositionId}/review/${reviewId}`,
    );
  },
  async getReceivers(compositionId: string, reviewId: string) {
    return await proxy.get<T.ReceiverDto[]>(
      `${URL}/receiver/composition/${compositionId}/review/${reviewId}`,
    );
  },
  async getReview(reviewId: string, compositionId: string) {
    return await proxy.get<T.ReviewResponseDto>(
      `${URL}/review/full/${reviewId}/composition/${compositionId}`,
    );
  },
  async getReviews(compositionId: string) {
    return await proxy.get<T.ReviewDto[]>(
      `${URL}/review/composition/${compositionId}`,
    );
  },
  async sendEmailToAllReceivers(reviewId: string, compositionId: string) {
    return await proxy.put<T.ReviewResponseDto>(
      `${URL}/email/send/${reviewId}/composition/${compositionId}`,
    );
  },
  async sendEmailToReceivers(
    reviewId: string,
    compositionId: string,
    receiverIds: string[],
  ) {
    return await proxy.post<T.EmailReceiverDto[]>(
      `${URL}/email/send/${reviewId}/composition/${compositionId}`,
      receiverIds,
    );
  },
  async updateCommentOnComment(
    commentOnCommentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    commentId: string,
    comment: string,
  ) {
    return await proxy.put<T.CommentOnCommentDto>(
      `${URL}/coc/${commentOnCommentId}/composition/${compositionId}/review/${reviewId}/objectid/${objectId}/comment/${commentId}`,
      comment,
    );
  },
  async updateCommentStatus(
    commentId: string,
    compositionId: string,
    reviewId: string,
    objectId: string,
    status: CommentStatus,
  ) {
    return await proxy.put<T.CommentDto>(
      `${URL}/comments/handled/${commentId}/composition/${compositionId}/review/${reviewId}/objectid/${objectId}/status/${status}`,
    );
  },
  async updateReview(
    reviewId: string,
    compositionId: string,
    request: T.ReviewRequestDto,
  ) {
    return await proxy.put<T.ReviewDto>(
      `${URL}/review/${reviewId}/composition/${compositionId}`,
      request,
    );
  },
  async updateReviewStatus(
    reviewId: string,
    compositionId: string,
    status: ReviewStatus,
  ) {
    return await proxy.put<T.ReviewDto>(
      `${URL}/review/handled/${reviewId}/composition/${compositionId}/status/${status}`,
    );
  },
};
