import { T } from '@/types';

export const MarkupRequestDto = (
  model: T.MarkupPayload,
): T.MarkupRequestDto => {
  return {
    aspects: model.aspects,
    clientObjectInformation: model.clientObjectInformation,
    codes: model.codes,
    objectId: model.id,
  };
};
