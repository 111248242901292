import { ContentHelper } from '@/core/objects/content';
import { ContentType, OwnershipType, SelectMode } from '@/objects/enums';
import { T } from '@/types';
import { AuthorActions } from './actions.types';

export const authorActions: AuthorActions = {
  async bulkSelectAll({ dispatch, rootState, commit, rootGetters }) {
    const contentPayload = rootGetters[
      'composition/getAllSelectableContentIds'
    ].map((contentId) => {
      return {
        objectId: contentId,
        objectType:
          rootState.content.contents[contentId]?.type === ContentType.PROCESS
            ? OwnershipType.PROCESS
            : OwnershipType.CONTENT,
      };
    });

    const userLocked: string[] = (
      await dispatch('ownership/lockMultipleContent', contentPayload, {
        root: true,
      })
    ).map((lock) => lock.id);
    commit('ADD_MULTIPLE_SELECTED_CONTENT_ID', userLocked);
  },
  async clearObjects({ commit }) {
    commit('CLEAR_SELECTED_CONTENT_IDS', undefined);
  },
  async clearSelected({ dispatch }) {
    await dispatch('clearObjects', undefined);
  },
  async getAllSelectableObjectRefs({ getters, rootState }) {
    const { isAspectMatchingComposition } = getters;
    const { containerObjects, containers } = rootState.composition;
    const { contents } = rootState.content;
    const { ownerships } = rootState.ownership;
    const { user } = rootState.user;

    const userAssignment = user?.assignmentName;

    return containers.flatMap((container) => {
      return container.containerObjectIds.flatMap((objectId): T.ObjectRef[] => {
        const content = contents[objectId];
        const owners = ownerships[objectId] ?? [];
        const containerObject = containerObjects[objectId];
        return content &&
          owners.some((owner) => owner.organizationId === userAssignment) &&
          !ContentHelper.isTemplateHeader(content) &&
          containerObject &&
          isAspectMatchingComposition(containerObject)
          ? [{ containerId: container.id, objectId }]
          : [];
      });
    });
  },
  async resetFilter({ commit, state }) {
    if (!state.savedFilter) {
      return;
    }

    const { showAllAspects } = state.savedFilter;

    commit('SET_SHOW_ALL_ASPECTS', showAllAspects);
    commit('SET_SAVED_FILTER', null);
  },
  async setDeselected({ commit }, id) {
    commit('REMOVE_SELECTED_CONTENT_ID', id);
  },
  async setMultipleSelected({ commit }, contentIds) {
    commit('ADD_MULTIPLE_SELECTED_CONTENT_ID', contentIds);
  },
  async setSelectMode(
    { commit, rootState, dispatch, rootGetters, state },
    newSelectMode,
  ) {
    if (newSelectMode && state.expandedBlocks.length > 0) {
      commit('CLEAR_EXPANDED_BLOCKS', undefined);
    }
    if (
      newSelectMode === SelectMode.STATUS &&
      !rootGetters['user/isAuthorRole']
    ) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message: 'Du har inte behörighet att ändra statusnivåer på block.',
        },
        { root: true },
      );
    }

    if (newSelectMode) {
      await dispatch('ownership/fetchClearAndSetAllContentLocks', undefined, {
        root: true,
      });
    }

    const oldSelectMode = state.selectMode;
    if (oldSelectMode === SelectMode.MOVE) {
      dispatch('resetFilter', undefined);
    }

    const contentPayload = rootGetters[
      'composition/getAllSelectableContentIds'
    ].map((contentId) => {
      return {
        objectId: contentId,
        objectType:
          rootState.content.contents[contentId]?.type === ContentType.PROCESS
            ? OwnershipType.PROCESS
            : OwnershipType.CONTENT,
      };
    });

    if (newSelectMode === SelectMode.MARKUP) {
      await dispatch('ownership/lockMultipleContent', contentPayload, {
        root: true,
      });
    }
    if (newSelectMode === SelectMode.MOVE) {
      await dispatch('showAllContent', undefined);
      await dispatch('ownership/lockMultipleContent', contentPayload, {
        root: true,
      });
    }
    if (newSelectMode === SelectMode.STATUS) {
      await dispatch('ownership/lockMultipleContent', contentPayload, {
        root: true,
      });
    }
    if (newSelectMode === null) {
      commit('SET_BULK_STATUS', null);
      await dispatch('clearSelected', undefined);
      dispatch(
        'ownership/releaseAllActiveUserLocksByType',
        [OwnershipType.CONTENT, OwnershipType.PROCESS],
        {
          root: true,
        },
      );
    }
    commit('SET_SELECT_MODE', newSelectMode);
  },

  async setSelected({ commit }, contentId) {
    commit('ADD_SELECTED_CONTENT_ID', contentId);
  },

  async showAllContent({ commit, state }, saveFilter = true) {
    if (saveFilter) {
      commit('SET_SAVED_FILTER', {
        showAllAspects: state.showAllAspects,
      });
    }

    commit('SET_SHOW_ALL_ASPECTS', true);
  },
};
