import { T } from '@/types';
import { ContentType } from '../enums';
import { Content } from './Content';

export const PublicationContent = <TType extends ContentType>(
  dto: T.PublicationContentDto<TType>,
): T.PublicationContent<TType> => {
  return {
    ...Content(dto),
    deleted: dto.deleted ?? false,
    id: dto.objectId,
    overrideId: dto.overrideId,
    parentId: dto.id,
  };
};
