import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-v2reference/';
export const ReferenceProxyV2 = {
  async createReference(compositionId: string, text: string) {
    return await proxy.post<T.ReferenceV2Dto>(URL, { compositionId, text });
  },
  async deleteReference(id: string) {
    return await proxy.delete(`${URL}${id}`);
  },
  async getAllV2References(compositionId: string) {
    return await proxy.get<T.ReferenceV2Dto[]>(
      `${URL}search/composition/${compositionId}`,
    );
  },
  async getV2Reference(referenceId: string) {
    return await proxy.get<T.ReferenceV2Dto>(`${URL}${referenceId}`);
  },
  async updateReference(referenceId: string, referenceText: string) {
    return await proxy.put<T.ReferenceV2Dto>(`${URL}${referenceId}`, {
      text: referenceText,
    });
  },
};
