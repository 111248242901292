import { AspectChecker } from '@/core/checkers';
import { T } from '@/types';
import { AuthorState as State } from './state';
import { authorUtils } from './utils';

export type AuthorGetters = typeof authorGetters;

export const authorGetters = {
  isAspectMatchingComposition(
    state: State,
    _: unknown,
    rootState: T.RootState,
  ) {
    if (
      state.showAllAspects ||
      rootState.composition.selectedAspectsRead.length === 0
    ) {
      return () => true;
    }

    return (container: T.ContainerObject | null) =>
      !!container &&
      AspectChecker.isAnyAspectInList(
        container.markup.aspects,
        rootState.composition.selectedAspectsRead,
      );
  },
  modeAllowsSelection: authorUtils.modeContentFilter,
};
