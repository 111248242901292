import { T } from '../../types';
import { CircleArea } from './CircleArea';
import { RectangleArea } from './RectangleArea';

export const ImageMapArea = (dto: T.ImageMapAreaDto): T.ImageMapArea => {
  return {
    circle: dto.circle ? CircleArea(dto.circle) : undefined,
    rectangle: dto.rectangle ? RectangleArea(dto.rectangle) : undefined,
    type: dto.type,
  };
};
