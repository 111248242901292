import { T } from '@/types';

export const User = (dto: T.TicketInformationDto): T.User => {
  return {
    active: dto.active,
    assignmentDisplayName: dto.assignmentDisplayName,
    assignmentName: dto.assignmentName,
    authType: dto.authType,
    email: dto.email,
    firstName: dto.firstName,
    lastName: dto.lastName,
    mobile: dto.mobile,
    personId: dto.personId,
    ticket: dto.ticket,
    userId: dto.userId,
  };
};
