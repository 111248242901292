export enum ApiErrorType {
  VALIDATION = 'VALIDATION',
  NOT_FOUND = 'NOT_FOUND',
  GENERAL = 'GENERAL',
  CONFLICT = 'CONFLICT',
  TRANSACTIONAL = 'TRANSACTIONAL',
  AUTHORIZATION = 'AUTHORIZATION',
  AUTHORIZATION_INVALID_TICKET = 'AUTHORIZATION_INVALID_TICKET',
  AUTHORIZATION_NO_LOCK = 'AUTHORIZATION_NO_LOCK',
  LOCK = 'LOCK',
  COMMUNICATION = 'COMMUNICATION',
  VALIDATION_UNMARKED_CONTAINEROBJECTS = 'VALIDATION_UNMARKED_CONTAINEROBJECTS',
  VALIDATION_ILLEGAL_ASPECTID = 'VALIDATION_ILLEGAL_ASPECTID',
}
