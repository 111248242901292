import { ownershipActions } from './actions';
import { ownershipGetters } from './getters';
import { ownershipMutations } from './mutations';
import { ownershipState } from './state';

export const ownershipStore = {
  actions: ownershipActions,
  getters: ownershipGetters,
  mutations: ownershipMutations,
  namespaced: true,
  state: ownershipState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
