import { T } from '../../types';

export const PictureReference = (
  dto: T.PictureReferenceDto,
): T.PictureReference => {
  return {
    fileSize: dto.fileSize,
    mediaType: dto.mediaType,
    reference: dto.reference,
    type: dto.type,
  };
};
