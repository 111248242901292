import { PublicationState as State } from './state';
import { T } from '../../types';
import { AspectChecker } from '@/core/checkers';

export type PublicationGetters = typeof publicationGetters;

export const publicationGetters = {
  getAllPubReferences(state: State, _: unknown, rootState: T.RootState) {
    let refIndex = 1;
    const updatedReferences: Record<string, number> = {};
    if (!state.activePublication) {
      return [];
    }
    return state.activePublication.containers.reduce(
      (allReferences, container) => {
        container.containerObjectIds.forEach((containerId) => {
          const content = state.activePublication?.content[containerId];
          const containerObject =
            state.activePublication?.containerObjects[containerId];

          if (
            !(
              rootState.author.showAllAspects ||
              state.selectedAspectsRead.length === 0 ||
              (containerObject &&
                AspectChecker.isAnyAspectInList(
                  containerObject.markup.aspects,
                  state.selectedAspectsRead,
                ))
            )
          ) {
            return;
          }
          if (!content) {
            return;
          }
          const refs = content.aggregatedReferenceIds;
          refs.forEach((ref) => {
            if (!updatedReferences[ref]) {
              updatedReferences[ref] = refIndex;
              refIndex += 1;
              allReferences.push({
                id: ref,
                number: updatedReferences[ref] ?? 0,
                text: state.activePublication?.references?.[ref]?.text ?? '',
              });
            }
          });
        });
        return allReferences;
      },
      [] as T.ReferenceItem[],
    );
  },
  isPublicationContentModified(state: State) {
    return (content: T.Content) => {
      return !!state.correctedPublicationContent[content.id];
    };
  },
};
