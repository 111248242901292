export enum ProseMirrorType {
  BULLET_LIST = 'bulletList',
  DOCUMENT = 'doc',
  HEADING = 'heading',
  ORDERED_LIST_ITEM = 'orderedListItem',
  BULLET_LIST_ITEM = 'bulletListItem',
  ORDERED_LIST = 'orderedList',
  PARAGRAPH = 'paragraph',
  TABLE = 'table',
  TABLE_CELL = 'table_cell',
  TABLE_HEADER = 'table_header',
  TABLE_ROW = 'table_row',
  TEXT = 'text',
  RECOMMENDATON = 'recommendation',
  REFERENCE = 'reference',
  LINK = 'link',
}
