import { T } from '@/types';
import { ContentUpdateRequestDto } from './ContentUpdateRequestDto';

export const ContentCreateWithIdRequestDto = (
  model: T.CreateContentWithIdPayload,
): T.ContentCreateWithIdRequestDto => {
  return {
    ...ContentUpdateRequestDto(model),
    id: model.id,
  };
};
