import { T } from '@/types';

export const ReferenceItemV2 = (
  dto: T.ReferenceItemV2Dto,
): T.ReferenceItemV2Dto => {
  return {
    id: dto.id,
    text: dto.text,
  };
};

export const ReferenceItem = (dto: T.ReferenceItemDto): T.ReferenceItem => {
  return {
    id: dto.referenceId,
    number: dto.number,
    text: dto.referenceText,
  };
};
