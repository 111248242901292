import { T } from '@/types';

export const HeaderContentDto = (
  model: T.HeaderContentData,
): T.HeaderContentDto => {
  return {
    text: model.text,
    type: model.type,
  };
};
