import { SelectMode, StatusType } from '@/objects/enums';
import { T } from '@/types';

export type AuthorState = typeof authorState;

export const authorState = {
  activeOwnership: null as string | null,
  aspectMismatch: false,
  bulkItemsLocked: [] as T.LockedBulkItem[],
  bulkStatus: null as StatusType | null,
  expandedBlocks: [] as string[],
  savedFilter: null as T.Filter | null,
  selectMode: null as SelectMode | null,
  selectedAspectsWrite: [] as T.Aspect[],
  selectedContentIds: [] as string[],
  showAllAspects: true,
};
