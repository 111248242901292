<template>
  <v-btn
    :class="'custom-button'"
    v-bind="$attrs"
    background-color="disabledGrey"
    :color="color"
    depressed
    :outlined="outlined"
    v-on="$listeners"
  >
    <template v-for="slot in parentSlots" #[slot]>
      <slot :name="slot" />
    </template>
  </v-btn>
</template>
<script lang="ts">
import { Vue } from '@/vue';

export default Vue.extend({
  name: 'CustomButton',
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  computed: {
    parentSlots(): string[] {
      return Object.keys(this.$slots);
    },
  },
});
</script>
