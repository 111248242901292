import { T } from '@/types';
import { ContentReferenceV2 } from '../models';

export const TextContentDto = (model: T.TextContentData): T.TextContentDto => {
  const references = [];
  if (model.blockReferences) {
    references.push(
      ...model.blockReferences.map((ref) => {
        return {
          ...ref,
          targetPart: null,
        };
      }),
    );
  }
  if (model.reference) {
    references.push(...model.reference.map(ContentReferenceV2));
  }
  return {
    basisForRecommendation: model.basisForRecommendation,
    reference: references,
    text: model.text,
  };
};
