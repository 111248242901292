import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-reference/references';

export const ReferenceProxy = {
  async createReference(
    compositionId: string,
    request: T.CreateReferenceRequestDto,
  ) {
    return await proxy.post<T.ReferenceDto>(
      `${URL}/composition/${compositionId}`,
      request,
    );
  },
  async deleteReference(
    referenceId: string,
    compositionId: string,
    contentId: string,
    commitNumber: number,
    versionNumber: number,
  ) {
    return await proxy.delete(
      `${URL}/reference/${referenceId}/composition/${compositionId}/object/${contentId}/version/${versionNumber}/commit/${commitNumber}`,
    );
  },
  async getReferencesForContents(
    compositionId: string,
    connectObjects: T.ConnectObjectDto[],
  ) {
    return await proxy.post<T.ReferenceResponseDto>(
      `${URL}/getAllForList/composition/${compositionId}`,
      connectObjects,
    );
  },
  async getReferencesListByLatest(compositionId: string, contentIds: string[]) {
    return await proxy.post<T.ReferenceResponseDto>(
      `${URL}/numberedLatest/composition/${compositionId}`,
      contentIds,
    );
  },
  async getReferencesListByVersion(
    compositionId: string,
    connectObjects: T.ConnectObjectDto[],
  ) {
    return await proxy.post<T.ReferenceResponseDto>(
      `${URL}/numbered/composition/${compositionId}`,
      connectObjects,
    );
  },
  async getReferencesOnComposition(compositionId: string) {
    return await proxy.get<T.ReferenceDto[]>(
      `${URL}/composition/${compositionId}`,
    );
  },
  async getReferencesOnContentByLatest(
    compositionId: string,
    objectId: string,
  ) {
    return await proxy.get<T.ReferenceDto[]>(
      `${URL}/composition/${compositionId}/object/${objectId}`,
    );
  },
  async getReferencesOnContentByVersion(
    compositionId: string,
    contentId: string,
    versionNumber: number,
    commitNumber: number,
  ) {
    return await proxy.get<T.ReferenceDto[]>(
      `${URL}/composition/${compositionId}/object/${contentId}/version/${versionNumber}/commit/${commitNumber}`,
    );
  },
  async updateReference(
    referenceId: string,
    compositionId: string,
    request: T.ReferenceRequestDto,
  ) {
    return await proxy.put<T.ReferenceDto>(
      `${URL}/${referenceId}/composition/${compositionId}`,
      request,
    );
  },
  async useReference(
    referenceId: string,
    compositionId: string,
    request: T.ConnectReferenceRequestDto,
  ) {
    return await proxy.post<T.ReferenceDto>(
      `${URL}/reference/${referenceId}/composition/${compositionId}`,
      request,
    );
  },
};
