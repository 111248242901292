import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-aggregator';

export const AggregatorProxy = {
  async createComposition(request: T.CreateCompositionRequestDto) {
    return await proxy.post<T.CompositionWithContainersAndMetadataDto>(
      `${URL}/compositions`,
      request,
    );
  },
  async createWordExport(compositionId: string, aspectId?: string) {
    return await proxy.get<{ url: string }>(
      `${URL}/composition/${compositionId}/wordexport`,
      { params: { aspectid: aspectId } },
    );
  },
  async deleteAssociatedReferences(payload: T.ReferenceAssociationBody) {
    return await proxy.put(
      `${URL}/compositions/removereferenceassociations`,
      payload,
    );
  },
  async deleteCompositionReference(compositionId: string, referenceId: string) {
    return await proxy.delete<T.ReferenceDeleteResponse>(
      `${URL}/compositions/${compositionId}/reference/${referenceId}`,
    );
  },
  async getBrokenLinks(requestId: string) {
    return await proxy.get<T.BrokenLinksDto>(
      `${URL}/verifylinks/poll/${requestId}`,
    );
  },
  async getCompositionDecorate(compositionId: string) {
    return await proxy.get<T.CompositionDecorateDto>(
      `${URL}/compositions/${compositionId}/decorate`,
    );
  },
  async getOutOfSyncContainerObjects(compositionId: string) {
    return await proxy.get<Record<string, T.ContainerObjectDto>>(
      `${URL}/compositions/${compositionId}/containerobjects/outofsynch`,
    );
  },
  async getRequestID(includeNpos: boolean) {
    return await proxy.get<T.RequestId>(
      `${URL}/verifylinks/begin?excludeNPO=${!includeNpos}`,
    );
  },
  async getTimemachine(
    compositionId: string,
    containerUUID: string,
    commit?: number,
  ) {
    return await proxy.get<T.ContainerTimelineDto>(
      `${URL}/timemachine/compositions/${compositionId}/containers/${containerUUID}`,
      { params: { commit } },
    );
  },
  async migrateComposition(compositionId: string, dryRun: boolean) {
    return await proxy.put<T.ExternalMigrationInformationDto>(
      `${URL}/compositions/${compositionId}/migrate_to_latest_template?dryRun=${dryRun}`,
    );
  },
};
