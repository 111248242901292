import { T } from '@/types';

export const Code = (dto: T.CodeDto): T.Code => {
  return {
    code: dto.code,
    description: dto.description ?? '',
    oid: dto.oid,
    version: dto.version ?? '',
  };
};
