import { T } from '../../types';
import * as dtos from '@/objects/dtos';
import { ProcessBlockContentType } from '../enums';

export const BlockContentDto = (model: T.BlockContent): T.BlockContentDto => {
  switch (model.type) {
    case ProcessBlockContentType.TEXT:
      return { textContent: dtos.TextContentDto(model.data), type: model.type };
    case ProcessBlockContentType.LIST:
      return { listContent: dtos.ListContentDto(model.data), type: model.type };
    default:
      throw new TypeError(
        'Failed to create model "BlockContentDto". Input is not a valid BlockContent.',
      );
  }
};
