/* eslint-disable no-console */
const b64id = (guid: string) =>
  ((guidb) =>
    btoa(
      Array.from(Array(guidb.length / 2), (_, i) =>
        String.fromCharCode(parseInt(guidb.substr(i * 2, 2), 16)),
      ).join(''),
    )
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_'))(guid.replace(/-/g, ''));

const { logEvent, endEvent, dump } =
  process.env.NODE_ENV === 'development'
    ? {
        dump: (name?: string, value?: unknown) => {
          if (typeof value === 'object') {
            const content = JSON.parse(JSON.stringify(value));
            console.debug(`${name}: %O`, content);
          } else {
            console.debug(`${name}: ${value}`);
          }
        },
        endEvent: () => console.groupEnd(),
        logEvent: (
          name?: string,
          color?: string,
          id: string | null = null,
          single = false,
          ...opts: unknown[]
        ) => {
          (single ? console.debug : console.group)(
            `%c${name}%c%s`,
            `color: white;background:${color};margin-right:4px;padding:2px 4px`,
            'color:#e0e0e0; background:#202020',
            typeof id === 'string' ? b64id(id) : '',
            ...opts,
          );
        },
      }
    : {
        dump: () => {},
        endEvent: () => {},
        logEvent: () => {},
      };

export const debug = { dump, endEvent, logEvent };
