import { Colors } from '@/core/helpers';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as Components from 'vuetify/lib';
import sv from 'vuetify/es5/locale/sv';

Vue.use(Vuetify, {
  components: {
    Components,
  },
});

export type VuetifyOptions = typeof vuetifyOptions;

export const vuetifyOptions = {
  breakpoint: {
    scrollBarWidth: 24,
    thresholds: {
      lg: 1280,
      md: 800,
      sm: 540,
      xs: 340,
    },
  },
  lang: {
    current: 'sv',
    locales: { sv },
  },
  theme: {
    themes: {
      dark: Colors,
      light: Colors,
    },
  },
};

export const createVuetify = () => {
  return new Vuetify(vuetifyOptions);
};
