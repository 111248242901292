export const DateConverter = {
  dateTimeToString(date: Date) {
    const day = this.dateToString(date);
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    const hh = (hour < 10 ? '0' : '') + hour;
    const mm = (min < 10 ? '0' : '') + min;
    const ss = (sec < 10 ? '0' : '') + sec;
    const time = `${hh}:${mm}:${ss}`;
    return `${day} ${time}`;
  },
  dateToCalendarDate(date: string | number | Date, mode: string) {
    const formattedDate = this.dateToString(date, mode).replaceAll('-', '/');
    return `${formattedDate.substring(5)}`;
  },
  dateToString(date: string | number | Date, mode = 'se') {
    const dateObject = new Date(date);
    let month = '';
    let day = '';

    if (dateObject.getMonth() + 1 < 10) {
      month = `-0${dateObject.getMonth() + 1}`;
    } else {
      month = `-${dateObject.getMonth() + 1}`;
    }

    if (dateObject.getDate() < 10) {
      day = `-0${dateObject.getDate()}`;
    } else {
      day = `-${dateObject.getDate()}`;
    }
    if (mode === 'en') {
      return dateObject.getFullYear() + day + month;
    }
    return dateObject.getFullYear() + month + day;
  },
  getPrettyDate(date: string | number | Date) {
    const dateObject = new Date(date);
    const month = this.numberToMonthString(dateObject.getMonth());

    const day = dateObject.getDate();
    const yyyy = dateObject.getFullYear();
    const hh = dateObject.getHours().toString().padStart(2, '0');
    const mm = dateObject.getMinutes().toString().padStart(2, '0');
    return `${day} ${month} ${yyyy} • ${hh}:${mm}`;
  },
  getTimeAndDate(date: string | number | Date) {
    const dateObject = new Date(date);
    let month = '';
    let day = '';

    if (dateObject.getMonth() + 1 < 10) {
      month = `-0${dateObject.getMonth() + 1}`;
    } else {
      month = `-${dateObject.getMonth() + 1}`;
    }

    if (dateObject.getDate() < 10) {
      day = `-0${dateObject.getDate()}`;
    } else {
      day = `-${dateObject.getDate()}`;
    }
    const yyyy = dateObject.getFullYear();
    const hh = dateObject.getHours().toString().padStart(2, '0');
    const mm = dateObject.getMinutes().toString().padStart(2, '0');
    return `${yyyy}${month}${day} ${hh}:${mm}`;
  },
  numberToMonthString(month: number) {
    switch (month) {
      case 0: {
        return 'Januari';
      }
      case 1: {
        return 'Februari';
      }
      case 2: {
        return 'Mars';
      }
      case 3: {
        return 'April';
      }
      case 4: {
        return 'Maj';
      }
      case 5: {
        return 'Juni';
      }
      case 6: {
        return 'Juli';
      }
      case 7: {
        return 'Augusti';
      }
      case 8: {
        return 'September';
      }
      case 9: {
        return 'Oktober';
      }
      case 10: {
        return 'November';
      }
      case 11: {
        return 'December';
      }
      default: {
        throw new Error('Parameter is not a valid month.');
      }
    }
  },
  parseDateToLong(date: string) {
    return Date.parse(date) - new Date().getTimezoneOffset() * 60 * 1000;
  },
};
