import _ from 'lodash';
import { T } from '@/types';

export const PublicationMetadataMarkup = (
  dto?: T.PublicationMetadataMarkupDto | null,
): T.PublicationMetadataMarkup => {
  return {
    codes: dto?.codes ?? [],
    proposedCodesForDiagnosis: dto?.proposedCodesForDiagnosis ?? [],
    searchTerms: _.uniq(dto?.searchTerms ?? []),
  };
};
