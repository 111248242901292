import { T } from '@/types';

export const ContextRequestDto = (
  model: T.GetContextPayload,
): T.ContextRequestDto => {
  return {
    context: model.context,
    contextValue: model.contextValue,
  };
};
