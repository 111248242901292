import { T } from '@/types';

export const ReviewMetaData = (dto?: T.ReviewMetaDataDto): T.ReviewMetaData => {
  if (dto) {
    return {
      availableAspectIds: dto.availableAspectIds
        ? JSON.parse(dto.availableAspectIds)
        : [],
      defaultAspectId: dto.defaultAspectId || null,
      includeRegionalContent: dto.includeRegionalContent
        ? JSON.parse(dto.includeRegionalContent)
        : false,
      selectedAspectIds: dto.selectedAspectIds
        ? JSON.parse(dto.selectedAspectIds)
        : [],
    };
  }
  return {
    availableAspectIds: [],
    defaultAspectId: null,
    includeRegionalContent: false,
    selectedAspectIds: [],
  };
};
