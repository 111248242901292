import { globalActions } from './actions';
import { globalGetters } from './getters';
import { globalMutations } from './mutations';
import { globalState } from './state';

export const globalStore = {
  actions: globalActions,
  getters: globalGetters,
  mutations: globalMutations,
  namespaced: true,
  state: globalState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
export * from './utils';
