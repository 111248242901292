import { ReviewObjectType, ReviewType } from '@/objects/enums';
import { ReviewMetaData } from '@/objects/models/ReviewMetaData';
import { Services } from '@/services';
import { T } from '@/types';
import { authorUtils, ReviewState } from '@/types/store';
import { ReviewActions } from './actions.types';

const getActivePublicationCompositionId = (rootState: T.RootState) => {
  const compositionId =
    rootState.publication.activePublication?.publication.compositionId;
  if (!compositionId) {
    throw new ReferenceError('Kunde inte hitta ett aktivt kunskapsstöd.');
  }
  return compositionId;
};

const getActiveResponseReviewId = (state: ReviewState) => {
  const reviewId = state.activeReview?.review.id;
  if (!reviewId) {
    throw new ReferenceError('Kunde inte hitta en aktiv granskning.');
  }
  return reviewId;
};

const getReviewBeingHandled = (state: ReviewState) => {
  const reviewBeingHandled = state.reviewBeingHandled;
  if (!reviewBeingHandled) {
    throw new ReferenceError('Recensionen kunde inte hittas.');
  }
  return reviewBeingHandled;
};

const getActiveReviewResponse = (state: ReviewState) => {
  const reviewResponse = state.activeReview;
  if (!reviewResponse) {
    throw new ReferenceError('Recensionen kunde inte hittas.');
  }
  return reviewResponse;
};

export const reviewActions: ReviewActions = {
  async addReceiver({ commit, dispatch, rootState, state }, payload) {
    try {
      commit('SET_ADDING_RECEIVERS', true);
      const compositionId = getActivePublicationCompositionId(rootState);
      const reviewId = getActiveResponseReviewId(state);
      const receiver = await Services.Review.addReceiver(
        compositionId,
        reviewId,
        payload,
      );

      commit('ADD_RECEIVER', receiver);
      commit('SET_ADDING_RECEIVERS', false);
    } catch (error) {
      commit('SET_ADDING_RECEIVERS', false);
      const errorObject: { data: { details: string[]; type: string } } =
        error as {
          data: {
            type: string;
            details: string[];
          };
        };
      if (errorObject.data.type === 'VALIDATION') {
        commit('SET_BAD_RESPONSE', {
          all: [payload],
          bad: errorObject.data.details,
        });
        return;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när ny granskare skulle läggas till. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async addReceivers({ commit, dispatch }, payload) {
    const { receivers, reviewId, compositionId } = payload;
    try {
      commit('SET_ADDING_RECEIVERS', true);

      const receiversRes = await Services.Review.addReceivers(
        compositionId,
        reviewId,
        receivers,
      );

      commit('ADD_RECEIVERS', receiversRes);
      commit('SET_ADDING_RECEIVERS', false);
      return receiversRes;
    } catch (error) {
      commit('SET_ADDING_RECEIVERS', false);
      const errorObject: { data: { details: string[]; type: string } } =
        error as {
          data: {
            type: string;
            details: string[];
          };
        };
      if (errorObject.data?.type === 'VALIDATION') {
        commit('SET_BAD_RESPONSE', {
          all: payload.receivers,
          bad: errorObject.data.details,
        });
        return null;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när nya granskare skulle läggas till. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async addReviewObjects({ commit, dispatch, rootState }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);

      const objectResponses = await Services.Review.addReviewObjects(
        compositionId,
        payload.reviewCreated,
        { objectRequests: payload.objectRequests },
      );

      commit('ADD_OBJECT_RESPONSES', objectResponses);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av granskning. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async closeReview({ commit, dispatch, state }, payload) {
    const { message, reviewId, compositionId } = payload;
    try {
      const review = await Services.Review.closeReview(
        reviewId,
        compositionId,
        message,
      );

      const index = state.reviews.findIndex((review) => {
        return review.id === state.activeReview?.review.id;
      });

      commit('SET_REVIEW', { index, review });
      commit('SET_ACTIVE_REVIEW_RESPONSE', null);
      return review;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid stängning av granskning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createCommentOnComment(
    { commit, dispatch, rootState, state },
    payload,
  ) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const review = getReviewBeingHandled(state);
      const reviewId = getActiveResponseReviewId(state);
      const objectResponseIndex = review.objectResponses.findIndex(
        (response) => {
          return response.reviewObject.objectId === payload.objectId;
        },
      );
      const comments =
        review.objectResponses[objectResponseIndex]?.comments ?? [];

      const commentOnComment = await Services.Review.createCommentOnComment(
        compositionId,
        reviewId,
        payload.objectId,
        payload.commentId,
        payload.comment,
      );

      const commentId = commentOnComment.commentId;
      const index = comments.findIndex((comment) => {
        return comment.commentId === commentId;
      });

      commit('ADD_COMMENT_ON_COMMENT', {
        commentOnComment,
        index,
        objectResponseIndex,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när kommentaren skulle skapas. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async createQuestion({ commit, dispatch, rootState, state }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const review = getActiveReviewResponse(state);
      const reviewId = review.review.id;

      const question = await Services.Review.createQuestion(
        compositionId,
        reviewId,
        payload.objectId,
        payload,
      );

      const index = review.objectResponses.findIndex((response) => {
        return response.reviewObject.objectId === question.objectId;
      });

      commit('ADD_QUESTION', {
        index,
        question,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Något gick fel vid skapande av fråga. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async createReview({ commit, dispatch, rootState }, payload) {
    try {
      // Create review publication
      const publication = await dispatch(
        'publication/createReviewPublication',
        {
          compositionId: payload.compositionId,
          publicationType: payload.publicationType,
          reviewType: payload.reviewType,
          title: payload.title,
        },
        { root: true },
      );
      if (!publication) {
        return null;
      }
      const {
        publication: { compositionId, defaultAspectId },
        containers,
        containerObjects,
        metadata,
      } = publication;

      const publicationMetadataItems = authorUtils
        .getAvailableMetadata(publication, null)
        .map((md) => md.responsible);
      const responsibleNpos: string[] = [];
      publicationMetadataItems.forEach((md) => {
        md.forEach((item) => {
          responsibleNpos.push(item.name);
        });
      });

      const metaData = ReviewMetaData();
      metaData.availableAspectIds = authorUtils
        .getAvailableAspects(publication)
        .map((aspect) => aspect.id);
      metaData.defaultAspectId = metaData.availableAspectIds[0] || null;

      // Set expire date per type
      const defaultDate = new Date();
      const extraDays = 21;
      const nationalExtraDays = 60;

      if (payload.reviewType === ReviewType.NATIONAL) {
        defaultDate.setDate(defaultDate.getDate() + nationalExtraDays);
      } else {
        defaultDate.setDate(defaultDate.getDate() + extraDays);
      }

      const expireDate = defaultDate.toISOString().substring(0, 10);

      const createReviewPayload = {
        expires: new Date(expireDate).getTime(),
        heading: '',
        message: '',
        metaData,
        name: publication.publication.compositionTitle!,
        ownerGroup: rootState.user.activeAuthorizationGroup?.displayName,
        publicationId: publication.publication.id,
        responsible: [...new Set(responsibleNpos)],
        reviewType: payload.reviewType,
      };

      const review = await Services.Review.createReview(
        compositionId,
        createReviewPayload,
      );

      await dispatch('ownership/fetchOwnershipForObject', review.id, {
        root: true,
      });

      const objectRequests: T.ObjectRequest[] = [];

      // Add review objects
      containers.forEach((container) => {
        container.containerObjectIds.forEach((containerObjectId) => {
          const containerObject = containerObjects[containerObjectId];

          if (containerObject) {
            objectRequests.push({
              id: containerObjectId,
              reviewObjectType:
                containerObject.objectType === 'PROCESS_BLOCK'
                  ? ReviewObjectType.PROCESS_BLOCK
                  : ReviewObjectType.CONTENT,
            });
          }
        });
      });
      dispatch(
        'review/addReviewObjects',
        {
          objectRequests,
          reviewCreated: review.id,
        },
        { root: true },
      );

      const assignment = rootState.user.activeAuthorizationGroup?.context;
      const publicationMetadata = metadata.find((meta) => {
        return (
          meta.assignment === assignment && meta.aspectId === defaultAspectId
        );
      });
      if (publicationMetadata) {
        const objectRequests: T.ObjectRequest[] = [
          {
            id: `${assignment}`,
            reviewObjectType: ReviewObjectType.METADATA,
          },
        ];

        dispatch(
          'review/addReviewObjects',
          {
            objectRequests,
            reviewCreated: review.id,
          },
          { root: true },
        );
      }
      commit('CLEAR_REVIEWS', undefined);
      commit('ADD_REVIEW', review);
      commit('SET_ACTIVE_REVIEW', review);

      return review;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av granskning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async deleteCommentOnComment(
    { commit, dispatch, rootState, state },
    payload,
  ) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const review = getReviewBeingHandled(state);
      const reviewId = review.review.id;

      await Services.Review.deleteCommentOnComment(
        payload.commentOnCommentId,
        compositionId,
        reviewId,
        payload.objectId,
        payload.commentId,
      );

      const commentId = payload.commentId;
      const objectResponseIndex = review.objectResponses.findIndex(
        (response) => {
          return response.reviewObject.objectId === payload.objectId;
        },
      );
      const response = review.objectResponses[objectResponseIndex]!;
      const index = response.comments.findIndex((response) => {
        return response.commentId === commentId;
      });
      const commentsOnComment = response.comments[index]!.commentsOnComment;
      const item = commentsOnComment.findIndex((response) => {
        return response.commentOnCommentId === payload.commentOnCommentId;
      });

      commit('DELETE_COMMENT_ON_COMMENT', {
        index,
        objectResponseIndex,
        remove: item,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när kommentaren skulle tas bort. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async deleteQuestion({ commit, dispatch, rootState, state }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const reviewId = getActiveResponseReviewId(state);

      await Services.Review.deleteQuestion(
        compositionId,
        reviewId,
        payload.objectId,
      );

      commit('DELETE_QUESTION', payload.index);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av fråga. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async fetchAndCommitReview({ commit, dispatch }, payload) {
    const reviewResponse = await dispatch('fetchReview', payload);
    commit('SET_ACTIVE_REVIEW_RESPONSE', reviewResponse);
    return reviewResponse;
  },
  async fetchAndLoadQuestions({ dispatch, commit }, payload) {
    try {
      const { compositionId, reviewId } = payload;
      const response = await Services.Review.getQuestions(
        compositionId,
        reviewId,
      );
      commit('SET_ACTIVE_QUESTIONS', response);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av utskicksfrågor. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchReview({ dispatch }, payload) {
    const { compositionId, reviewId } = payload;
    try {
      return await Services.Review.getReview(reviewId, compositionId);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av granskning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async getActiveReview({ commit, dispatch, rootState, state }, payload) {
    try {
      const review = state.reviews[payload];
      if (!review) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: null,
            message:
              'Något gick fel vid hämtning av granskning. Granskningen kunde inte hittas.',
          },
          { root: true },
        );
        return null;
      }

      const reviewId = review.id;
      const compositionId = getActivePublicationCompositionId(rootState);

      const reviewResponse = await Services.Review.getReview(
        reviewId,
        compositionId,
      );

      commit('SET_ACTIVE_REVIEW_RESPONSE', reviewResponse);

      return reviewResponse;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av granskning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async getCommentUpvotes({ dispatch, rootState, state }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const reviewId = getReviewBeingHandled(state).review.id;

      const upvotes = await Services.Review.getCommentUpvotes(
        compositionId,
        reviewId,
        payload,
      );

      return upvotes;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av gillamarkeringar. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async getCommentsOnComment({ commit, dispatch, rootState, state }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const review = getReviewBeingHandled(state);
      const reviewId = review.review.id;

      const commentsOnComment = await Services.Review.getCommentsOnComment(
        compositionId,
        reviewId,
        payload.objectId,
        payload.comment,
      );

      const comment = payload.comment;
      const objectResponseIndex = review.objectResponses.findIndex(
        (response) => {
          return response.reviewObject.objectId === payload.objectId;
        },
      );
      const response = review.objectResponses[objectResponseIndex]!;
      const index = response.comments.findIndex(
        (response) => response.commentId === comment,
      );

      commit('SET_COMMENTS_ON_COMMENT', {
        commentsOnComment,
        index,
        objectResponseIndex,
      });

      return commentsOnComment;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när kommentarerna skulle hämtas. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async getReviews({ commit, dispatch }, compositionId) {
    try {
      const reviews = await Services.Review.getReviews(compositionId);

      commit('SET_REVIEWS', reviews);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av granskningar. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async removeReceiver({ commit, dispatch }, payload) {
    const { reviewId, compositionId } = payload;
    try {
      await Services.Review.deleteReceiver(
        payload.email,
        compositionId,
        reviewId,
      );

      commit('REMOVE_RECEIVER', payload.receiverIndex);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av granskare. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async removeReview({ commit, dispatch, rootState, state }, reviewId) {
    try {
      const compositionId = rootState.composition.activeComposition?.id;
      if (!compositionId) {
        throw Error('Could not find composition');
      }

      await Services.Review.deleteReview(reviewId, compositionId);
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        'Granskning borttagen',
        { root: true },
      );
      const index = state.reviews.findIndex((rev) => rev.id === reviewId);

      if (index === -1) {
        return null;
      }
      const updatedReviews = state.reviews;
      updatedReviews.splice(index, 1);

      commit('SET_REVIEWS', updatedReviews);
      return reviewId;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av granskning. Vänligen försök igen',
        },
        { root: true },
      );
      return null;
    }
  },
  async resetObjectResponseBeingHandled({ commit, dispatch }) {
    try {
      commit('SET_OBJECT_RESPONSE_BEING_HANDLED', null);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel med kommentarshanteringen. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async sendReview({ commit, dispatch, rootState, state }) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const reviewId = getActiveResponseReviewId(state);

      const reviewResponse = await Services.Review.sendEmailToAllReceivers(
        reviewId,
        compositionId,
      );

      const index = state.reviews.findIndex((review) => {
        return review.id === reviewResponse.review.id;
      });

      commit('SET_REVIEW', {
        index,
        review: reviewResponse.review,
      });
      commit('SET_ACTIVE_REVIEW', reviewResponse.review);

      reviewResponse.receivers.forEach((updatedReceiver) => {
        const indexToUpdate =
          state.activeReview?.receivers.findIndex((receiver) => {
            return (
              receiver.receiverInfo?.email ===
              updatedReceiver.receiverInfo?.email
            );
          }) ?? -1;

        if (indexToUpdate > -1) {
          commit('UPDATE_RECEIVER', {
            index: indexToUpdate,
            receiver: updatedReceiver,
          });
        }
      });

      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        `Granskning "${reviewResponse.review.header}" är startad!`,
        { root: true },
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Något gick fel vid mailutskick. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async sendReviewToListOfReceivers({ commit, dispatch, state }, payload) {
    const { receivers, reviewId, compositionId } = payload;
    try {
      const receiversRes = await Services.Review.sendEmailToReceivers(
        reviewId,
        compositionId,
        receivers,
      );

      receiversRes.forEach((newReceiver) => {
        const index =
          state.activeReview?.emailReceivers.findIndex((receiver) => {
            return receiver.email === newReceiver.email;
          }) ?? -1;

        if (index !== -1) {
          commit('UPDATE_EMAIL_RECEIVER', {
            index,
            receiver: newReceiver,
          });
        }
      });

      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        `Mejl till granskare är skickade!`,
        { root: true },
      );
      return receiversRes;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Något gick fel vid mailutskick. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async updateActiveReview({ dispatch, rootState, state }, payload) {
    const compositionId = getActivePublicationCompositionId(rootState);
    const reviewId = getActiveResponseReviewId(state);
    await dispatch('updateReview', { ...payload, compositionId, reviewId });
  },
  async updateCommentOnComment(
    { commit, dispatch, rootState, state },
    payload,
  ) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const review = getReviewBeingHandled(state);
      const reviewId = review.review.id;

      const commentOnComment = await Services.Review.updateCommentOnComment(
        payload.commentOnCommentId,
        compositionId,
        reviewId,
        payload.objectId,
        payload.commentId,
        payload.comment,
      );

      const objectResponseIndex = review.objectResponses.findIndex(
        (response) => {
          return response.reviewObject.objectId === payload.objectId;
        },
      );
      const response = review.objectResponses[objectResponseIndex]!;
      const index = response.comments.findIndex((response) => {
        return response.commentId === commentOnComment.commentId;
      });
      const removeIndex = response.comments[index]!.commentsOnComment.findIndex(
        (commentOnComment) => {
          return (
            commentOnComment.commentOnCommentId === payload.commentOnCommentId
          );
        },
      );

      commit('UPDATE_COMMENT_ON_COMMENT', {
        commentOnComment,
        index,
        objectResponseIndex,
        removeIndex,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när kommentaren skulle uppdateras. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async updateCommentStatus({ commit, dispatch, rootState, state }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);
      const reviewId = getActiveResponseReviewId(state);

      await Services.Review.updateCommentStatus(
        payload.commentId,
        compositionId,
        reviewId,
        payload.objectId,
        payload.status,
      );

      commit('UPDATE_COMMENT_STATUS', {
        commentId: payload.commentId,
        objectId: payload.objectId,
        status: payload.status,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när kommentaren skulle uppdateras. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async updateReview({ commit, dispatch, state }, payload) {
    const { reviewId, compositionId } = payload;
    try {
      commit('UPDATE_REVIEW', { reviewId, reviewPayload: payload });
      const apiReview = await Services.Review.updateReview(
        reviewId,
        compositionId,
        payload,
      );
      const index = state.reviews.findIndex((review) => {
        return review.id === reviewId;
      });
      commit('SET_REVIEW', { index, review: apiReview });
      if (state.activeReview?.review.id === reviewId) {
        commit('SET_ACTIVE_REVIEW', apiReview);
      }

      return apiReview;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdatering av granskning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async updateReviewStatus({ commit, dispatch, rootState }, payload) {
    try {
      const compositionId = getActivePublicationCompositionId(rootState);

      await Services.Review.updateReviewStatus(
        payload.reviewId,
        compositionId,
        payload.status,
      );

      commit('UPDATE_REVIEW_STATUS', {
        reviewId: payload.reviewId,
        reviewStatus: payload.status,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdatering av granskning. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
};
