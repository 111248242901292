import { T } from '@/types';
import { ReferenceResponse } from './ReferenceResponse';
import { PublicationMarkup } from './PublicationMarkup';
import { PublicationType } from '../enums';
import { InformationTypeModel } from './InformationTypeModel';

export const Publication = (
  dto: T.PublicationDto | T.PublicationForListDto,
): T.Publication => {
  const references = {} as Record<string, T.ReferenceResponse>;
  if ('references' in dto && dto.references) {
    Object.keys(dto.references).forEach((key) => {
      if (dto.references) {
        const refResponseDto = dto.references[key];
        if (refResponseDto) {
          references[key] = ReferenceResponse(refResponseDto);
        }
      }
    });
  }
  return {
    compositionId: dto.compositionId,
    compositionTitle: dto.compositionTitle,
    compositionVersionKey: dto.compositionVersionKey,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    defaultAspectId: 'defaultAspectId' in dto ? dto.defaultAspectId : undefined,
    events: 'events' in dto && dto.events ? dto.events : [],
    id: dto.id,
    informationType: InformationTypeModel(dto.informationType),
    markup:
      'markup' in dto && dto.markup
        ? PublicationMarkup(dto.markup)
        : { aspects: [], codes: [] },
    publicationType: dto.publicationType as PublicationType,
    references,
    scheduledPublishingDate:
      'scheduledPublishingDate' in dto
        ? dto.scheduledPublishingDate
        : undefined,
    scheduledPublishingDateHr:
      'scheduledPublishingDateHr' in dto
        ? dto.scheduledPublishingDateHr
        : undefined,
    status: 'status' in dto ? dto.status : undefined,
    technicalPublishingInformation:
      'technicalPublishingInformation' in dto
        ? dto.technicalPublishingInformation
        : undefined,
    templateAtCreation: dto.templateAtCreation,
    title: dto.title,
    versionKey: dto.versionKey,
  };
};
