import { T } from '@/types';
import { v4 as uuidv4 } from 'uuid';
import { ListSubList, TextContentData } from '.';

export const ListRow = (listRowDto: T.ListRowDto): T.ListRow => {
  return {
    id: uuidv4(),
    referenceItem: listRowDto?.referenceItem ?? null,
    row: listRowDto?.row ? { text: listRowDto.row.text } : null,
    sublist: listRowDto?.sublist ? ListSubList(listRowDto.sublist) : null,
    textItem: listRowDto?.textItem
      ? TextContentData(listRowDto.textItem)
      : null,
    type: listRowDto.type,
  };
};

export const SubListRow = (listRowDto: T.SubListRowDto): T.SubListRow => {
  return {
    id: uuidv4(),
    referenceItem: listRowDto?.referenceItem ?? null,
    row: listRowDto?.row ? { text: listRowDto.row.text } : null,
    textItem: listRowDto?.textItem
      ? TextContentData(listRowDto.textItem)
      : null,
    type: listRowDto.type,
  };
};
