import { T } from '@/types';
import { TemplateHeader } from './TemplateHeader';

export const TemplateContainer = (
  dto: T.TemplateContainerDto,
): T.TemplateContainer => {
  return {
    headers: (dto.headers ?? []).map(TemplateHeader),
    id: dto.uuid ?? '',
    name: '',
    subheaders: [],
  };
};
