import { T } from '../../types';
import { CodeSystemRequestDto } from './CodeSystemRequestDto';

export const CreateTemplateRequestDto = (
  model: T.NewTemplate,
): T.CreateTemplateRequestDto => {
  return {
    addonsAllowed: model.addonsAllowed,
    containers: model.containers,
    informationType: model.informationType,
    name: model.name,
    validAspectIds: model.validAspectIds,
    validCodeSystems: model.validCodeSystems.map(CodeSystemRequestDto),
    validCodeSystemsForDiagnosis:
      model.validCodeSystemsForDiagnosis.map(CodeSystemRequestDto),
    validHeaders: model.validHeaders,
  };
};
