import { T } from '@/types';
import { ContentType } from '../enums';
import { ContentBase, ListContentData } from '.';

export const ListContent = (
  dto: T.ContentDto<ContentType.LIST>,
): T.ListContent => {
  return {
    ...ContentBase(dto),
    data: ListContentData(dto.listContent),
    type: ContentType.LIST,
  };
};
