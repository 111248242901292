import { ApiErrorType } from '@/objects/enums';
import {
  LockedByOtherUserError,
  NoLockError,
} from '@/objects/exceptions/ApiExceptions';
import { T } from '@/types';
import axios, { AxiosError } from 'axios';
import { ProxyConfig } from './proxy-config';

const DEFAULT_CONTENT_TYPE = 'application/json; charset=utf-8';

const proxy = axios.create({
  baseURL: ProxyConfig.baseUrl,
}) as T.Proxy;

proxy.defaults.headers!.post['Content-Type'] = DEFAULT_CONTENT_TYPE;
proxy.defaults.headers!.put['Content-Type'] = DEFAULT_CONTENT_TYPE;
proxy.interceptors.response.use((response) => response, errorInterceptor);

function errorInterceptor(error: AxiosError) {
  const errorData = error.response?.data as T.ApiErrorData | undefined;
  if (errorData) {
    switch (errorData.type) {
      case ApiErrorType.AUTHORIZATION_NO_LOCK:
        return Promise.reject(new NoLockError(errorData.message));
      case ApiErrorType.LOCK:
        return Promise.reject(
          new LockedByOtherUserError(
            errorData.message,
            (error.response?.data as T.APILockError | undefined)
              ?.currentLockOwner ?? '',
            (error.response?.data as T.APILockError | undefined)?.objectId ??
              '',
          ),
        );
      default:
        break;
    }
  }
  return Promise.reject(error.response);
}

export { proxy };
