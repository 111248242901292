import { T } from '@/types';
import { SystemMessageActions } from './actions.types';

export const systemMessageActions: SystemMessageActions = {
  async addSystemErrorMessageToQueue({ dispatch }, payload) {
    dispatch('addSystemMessageToQueue', {
      color: 'danger',
      text: payload.message,
      timeout: 5000,
      top: true,
    });

    console.error(payload.error);
    if (payload.error instanceof Error) {
      return payload.error;
    }

    if (typeof payload.error === 'string') {
      return new Error(payload.error);
    }

    return new Error(payload.message);
  },
  async addSystemMessageToQueue(
    { commit, state },
    systemMessage: T.SystemMessage,
  ) {
    commit('ADD_SYSTEM_MESSAGE', systemMessage);

    if (!state.showSystemMessage) {
      commit('SET_ACTIVE_MESSAGE', systemMessage);
      commit('SET_SHOW_SYSTEM_MESSAGE', undefined);
    }
  },
  async addSystemSuccessMessageToQueue({ dispatch }, message) {
    dispatch('addSystemMessageToQueue', {
      color: 'primary',
      text: message,
      timeout: 5000,
      top: true,
    });
  },
  async showNextSystemMessage({ commit, state }) {
    commit('SET_HIDE_SYSTEM_MESSAGE', undefined);
    commit('REMOVE_FIRST_SYSTEM_MESSAGE', undefined);

    const nextMessage = state.systemMessageQueue[0];
    if (nextMessage) {
      setTimeout(() => {
        commit('SET_ACTIVE_MESSAGE', nextMessage);
        commit('SET_SHOW_SYSTEM_MESSAGE', undefined);
      }, 400);
    }
  },
};
