<template>
  <component
    :is="view"
    v-if="isOpen"
    :value="isOpen"
    v-bind="props"
    @input="$store.commit('modal/CLOSE_MODAL', undefined)"
  />
</template>

<script lang="ts">
import { useState } from '@/store';
import { Vue } from '@/vue';

export default Vue.extend({
  name: 'Modal',
  components: {
    ReferenceListDialog: () =>
      import('@/components/dialogs/ReferenceListDialog.vue'),
    DetailedReferenceDialog: () =>
      import('@/components/dialogs/DetailedReferenceDialog.vue'),
    ReferenceDialog: () =>
      import('@/components/dialogs/ReferenceDialog/ReferenceDialog.vue'),
    ReferenceGuideDialog: () =>
      import('@/components/dialogs/ReferenceGuideDialog.vue'),
    PublicationErrorDialog: () =>
      import('@/components/dialogs/PublicationErrorDialog.vue'),
    PublicationRegionalAspectErrorDialog: () =>
      import('@/components/dialogs/PublicationRegionalAspectErrorDialog.vue'),
    ReviewCommentsDialog: () =>
      import('@/components/dialogs/ReviewCommentsDialog.vue'),
    ReviewHandleCommentsDialog: () =>
      import('@/components/dialogs/ReviewHandleCommentsDialog.vue'),
    ReviewReceiversDialog: () =>
      import('@/components/dialogs/ReviewReceiversDialog.vue'),
    ReviewEmailReceiversDialog: () =>
      import('@/components/dialogs/ReviewEmailReceiversDialog.vue'),
    ReviewDashInfoDialog: () =>
      import('@/components/dialogs/ReviewDashInfoDialog.vue'),
    WordExportDialog: () => import('@/components/dialogs/WordExportDialog.vue'),
    LikesDialog: () => import('@/components/dialogs/LikesDialog.vue'),
    CloseReviewDialog: () =>
      import('@/components/review/CloseReviewDialog.vue'),
    RegionListDialog: () => import('@/components/dialogs/RegionListDialog.vue'),
  },
  computed: {
    ...useState({
      isOpen: 'modal/isOpen',
      view: 'modal/view',
      props: 'modal/props',
    }),
  },
  watch: {
    $route() {
      // Handle route change so that modal does not remain on route change
      if (this.isOpen) {
        this.$store.commit('modal/CLOSE_MODAL', undefined);
      }
    },
  },
});
</script>
