import { ContentHelper } from '@/core/objects/content';
import { OwnershipType } from '@/objects/enums';
import { LockedByOtherUserError } from '@/objects/exceptions/ApiExceptions';
import { Services } from '@/services';
import { T } from '@/types';
import { OwnershipActions } from './actions.types';

async function lockMultipleObjects(
  commit: Function,
  dispatch: Function,
  objectIds: T.MultiLockPayload[],
) {
  const lockResponse = await Services.Ownership.setLocks(objectIds);
  commit('ADD_MULTIPLE_LOCKS', lockResponse.acquiredLocks);
  return lockResponse.acquiredLocks;
}

async function lockObject(
  commit: Function,
  objectId: string,
  objectType: OwnershipType,
) {
  const lock = await Services.Ownership.setLock({ objectId, objectType });
  commit('ADD_LOCK', lock);
  return lock;
}

export const ownershipActions: OwnershipActions = {
  async createOwnership({ commit, dispatch, rootState }, newOwnership) {
    if (!rootState.user.activeAuthorizationGroup) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Något gick fel när behörigheten skulle skapas. Ägargruppen kunde inte hittas.',
        },
        { root: true },
      );
      return null;
    }

    if (!rootState.user.user) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message: 'Kunde inte sätta ägandeskap. Användaren kunde inte hittas.',
        },
        { root: true },
      );
      return null;
    }

    let authGroup;
    if (!newOwnership.authGroup) {
      authGroup = rootState.user.activeAuthorizationGroup;
    } else {
      authGroup = newOwnership.authGroup;
    }

    const createdOwnership = await Services.Ownership.createOwnership({
      groupTag: newOwnership.groupTag,
      objectId: newOwnership.objectId,
      objectType: newOwnership.objectType,
      organizationId: rootState.user.user.assignmentName,
      organizationName: rootState.user.user.assignmentDisplayName,
      ownershipRelation: newOwnership.ownershipRelation,
      sectionId: `${authGroup.context}.${authGroup.name}`,
      sectionName: authGroup.displayName,
    });

    commit('ADD_OWNERSHIP', createdOwnership);

    return createdOwnership;
  },
  async fetchAuthGroup({ dispatch }, payload) {
    const { context, authGroup } = payload;
    try {
      const members = await Services.Ownership.getAuthGroup(context, authGroup);
      return members;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtning av medlemmar. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchChangesForOwnerships({ commit }, payload) {
    const ownershipResponses = await Services.Ownership.getSpecificOwnerships(
      payload,
    );
    commit('SET_OWNERSHIP_FOR_MULTIPLE_OBJECTS', ownershipResponses);
  },
  async fetchClearAndSetAllContentLocks({ commit, dispatch, rootState }) {
    const contentIds: string[] = Object.keys(rootState.content.contents);
    const locks = await dispatch('fetchLocks', contentIds);
    commit('CLEAR_AND_SET_LOCKS_BY_TYPE', {
      lockType: OwnershipType.CONTENT,
      locks,
    });
    return locks;
  },
  async fetchClearAndSetLocks({ commit, dispatch }, objectIds) {
    const locks = await dispatch('fetchLocks', objectIds);

    commit('CLEAR_AND_SET_LOCKS', locks);
    if (locks.length > 0) {
      dispatch('releaseAllActiveUserLocksByType', [
        OwnershipType.CONTENT,
        OwnershipType.PROCESS,
      ]);
    }
    return locks;
  },
  async fetchLocks({ dispatch }, objectIds) {
    try {
      const locks = await Services.Ownership.getLocks(objectIds);
      return locks;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när lås för innehåll skulle hämtas. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchOwnershipForObject({ commit, dispatch }, objectId) {
    try {
      const ownerships = await Services.Ownership.getOwnershipsForObject(
        objectId,
      );
      const containerObjectId = ownerships[0]?.id;
      if (containerObjectId) {
        commit('SET_OWNERSHIP_FOR_OBJECT', {
          containerObjectId,
          ownerships,
        });
      }

      return ownerships;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när ägandeskap skulle hämtas. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchOwnershipStructures({ commit }) {
    const ownershipStructures =
      await Services.Ownership.getOwnershipStructures();

    if (ownershipStructures[0]) {
      commit('SET_STRUCTURE', ownershipStructures[0]);
    }

    return ownershipStructures;
  },
  async fetchOwnerships({ commit }, payload) {
    const ownerships = await Services.Ownership.getOwnershipsForGroupTag(
      payload.groupTag,
      payload.type,
    );
    commit('ADD_OWNERSHIPS', ownerships);
  },
  async lockContent({ commit, dispatch, getters, rootState, state }, payload) {
    const { contentId, contentType } = payload;
    const content = rootState.content.contents[contentId];
    const isUnsaved =
      !!content && ContentHelper.isContentNewAndUnsaved(content);
    if (
      getters.isLocked(contentId) &&
      !state.pendingLockReleases.includes(contentId)
    ) {
      return state.locks[contentId] ?? null;
    }
    // Clear previous locks to avoid stale locks
    if (state.locks) {
      dispatch(
        'releaseMultipleLocks',
        Object.values(state.locks).map((lock) => lock.id),
      );
    }
    try {
      if (!isUnsaved) {
        return await lockObject(
          commit,
          contentId,
          contentType ?? OwnershipType.CONTENT,
        );
      }
      return null;
    } catch (error) {
      if (error instanceof LockedByOtherUserError) {
        dispatch(
          'systemMessage/addSystemMessageToQueue',
          {
            color: 'warning',
            text: 'Innehållet redigeras av en annan användare',
            timeout: 5000,
            top: true,
          },
          {
            root: true,
          },
        );
        commit('ADD_LOCK', {
          id: error.objectId,
          userFullName: error.currentLockOwner,
        } as T.Ownership);
        return null;
      }

      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid låsning av innehåll. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async lockMultipleContent(
    { commit, dispatch, getters, rootState, state },
    containerObjects,
  ) {
    const existingLocksByUser = [] as T.Ownership[];
    const validContentIds = containerObjects.filter((containerObject) => {
      const contentId = containerObject.objectId;
      const content = rootState.content.contents[contentId];
      const isNewAndUnsaved =
        !!content && ContentHelper.isContentNewAndUnsaved(content);
      if (!getters.isLocked(contentId) && !isNewAndUnsaved) {
        return true;
      }
      if (getters.isLockedByActiveUser(contentId)) {
        existingLocksByUser.push(state.locks[contentId]!);
      }
      return false;
    });
    try {
      if (validContentIds.length > 0) {
        const newLocks = await lockMultipleObjects(
          commit,
          dispatch,
          validContentIds,
        );
        return [...existingLocksByUser, ...newLocks];
      }
      return existingLocksByUser;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid låsning av innehåll. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async releaseAllActiveUserLocksByType(
    { dispatch, state, rootState },
    objectType,
  ) {
    const activeUser = rootState.user.user;
    if (activeUser) {
      const userLocks = Object.values(state.locks).filter((lock) => {
        return (
          lock.userId === activeUser.userId &&
          lock.objectType &&
          objectType.includes(lock.objectType)
        );
      });
      const lockIds = userLocks.map((lock) => lock.id);
      dispatch('releaseMultipleLocks', lockIds);
    }
  },
  async releaseLock({ commit, dispatch, getters, state }, objectId) {
    if (
      getters.isLocked(objectId) &&
      !state.pendingLockReleases.includes(objectId)
    ) {
      try {
        commit('RELEASE_LOCK_STARTED', objectId);
        await Services.Ownership.removeLock(objectId);
        commit('REMOVE_LOCK', objectId);
      } catch (error) {
        if (error instanceof LockedByOtherUserError) {
          // Error is already handled in previous 'lockContent' request
          return;
        }
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Något gick fel när innehåll skulle låsas upp. Försök igen senare.',
          },
          { root: true },
        );
        return;
      } finally {
        commit('RELEASE_LOCK_COMPLETE', objectId);
      }
    }
  },
  async releaseMultipleLocks({ commit, dispatch, getters, state }, objectIds) {
    const validObjectIds = objectIds.filter(
      (objectId) =>
        getters.isLocked(objectId) &&
        !state.pendingLockReleases.includes(objectId),
    );

    if (validObjectIds.length > 0) {
      try {
        commit('RELEASE_MULTIPLE_LOCK_STARTED', validObjectIds);
        const multiLockResponse = await Services.Ownership.removeLocks(
          validObjectIds,
        );

        commit('REMOVE_MULTIPLE_LOCKS', multiLockResponse.releasedLocks);
      } catch (error) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Något gick fel när innehåll skulle låsas upp. Försök igen senare.',
          },
          { root: true },
        );
        return;
      } finally {
        commit('RELEASE_MULTIPLE_LOCK_COMPLETE', validObjectIds);
      }
    }
  },
};
