/* eslint-disable max-classes-per-file */
export class NoLockError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoLockError';
  }
}

export class LockedByOtherUserError extends Error {
  currentLockOwner: string;

  objectId: string;

  constructor(message: string, currentLockOwner: string, objectId: string) {
    super(message);
    this.name = 'LockedByOtherUserError';
    this.currentLockOwner = currentLockOwner;
    this.objectId = objectId;
  }
}
