import { T } from '@/types';

export const browserCheck = ({ router, next }: T.NextFactoryContext) => {
  const browser = window.navigator.userAgent.toLowerCase();
  const validBrowser =
    browser.includes('edge') ||
    browser.includes('edg/') ||
    browser.includes('chrome');

  if (validBrowser) {
    next();
  } else {
    router.push({ name: 'invalidBrowser' });
  }
};
