import { T } from '@/types';
import { proxy } from '..';

const URL = '/api-productionplanning';

export const ProductionPlanningProxy = {
  async getSystemPlanningInformation() {
    return await proxy.get<T.SystemPlanningObjectDto[]>(
      `${URL}/productionplanning/system?activeOnly=true`,
    );
  },
};
