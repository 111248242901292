import { T } from '@/types';

export const CompositionForContentSearchResponse = (
  dto: T.CompositionForContentSearchResponseDto[],
): T.CompositionForContentSearchResponse[] => {
  return dto.map((d) => {
    return {
      id: d.id,
      statusDisplayName: d.statusDisplayName,
      title: d.title,
    };
  });
};
