import * as dtos from '@/objects/dtos';
import { ContextType } from '@/objects/enums';
import * as models from '@/objects/models';
import { T } from '@/types';
import { GroupProxy as proxy } from '../proxies';

export const GroupService = {
  async addMember(
    contextType: ContextType,
    contextValue: string,
    name: string,
    payload: T.MemberPayload,
  ) {
    const request = dtos.MemberDto(payload);
    const response = await proxy.addMember(
      contextType,
      contextValue,
      name,
      request,
    );
    return models.Group(response.data);
  },
  async changeAccessType(
    contextType: ContextType,
    contextValue: string,
    name: string,
    payload: T.SetGroupAccessTypePayload,
  ) {
    const request = dtos.SetGroupAccessTypeRequestDto(payload);
    const response = await proxy.changeAccessType(
      contextType,
      contextValue,
      name,
      request,
    );
    return models.Group(response.data);
  },
  async createGroup(payload: T.CreateGroupPayload) {
    const request = dtos.CreateGroupRequestDto(payload);
    const response = await proxy.createGroup(request);
    return models.Group(response.data);
  },
  async deleteGroup(
    contextType: ContextType,
    contextValue: string,
    id: string,
  ): Promise<boolean> {
    const response = await proxy.deleteGroup(contextType, contextValue, id);
    return response.status === 200;
  },
  async getGroupsForContext(contextType: ContextType, contextValue: string) {
    const response = await proxy.getGroupsForContext(contextType, contextValue);
    return response.data.map(models.Group);
  },
  async getGroupsForContexts(payloads: T.GetContextPayload[]) {
    const requests = payloads.map(dtos.ContextRequestDto);
    const response = await proxy.getGroupsForContexts(requests);
    return response.data.map(models.GroupsForContext);
  },
  async getPublicGroups() {
    const response = await proxy.getPublicGroups();
    return response.data.map(models.Group);
  },
  async getPublicGroupsForAssignment(assignment: string) {
    const response = await proxy.getPublicGroupsForAssignment(assignment);
    return response.data.map(models.Group);
  },
  async removeMember(
    contextType: ContextType,
    contextValue: string,
    id: string,
    email: string,
  ) {
    const result = await proxy.removeMember(
      contextType,
      contextValue,
      id,
      email,
    );
    return result.status === 200;
  },
  async removeMembers(
    contextType: ContextType,
    contextValue: string,
    name: string,
    emails: string[],
  ) {
    const response = await proxy.removeMembers(
      contextType,
      contextValue,
      name,
      emails,
    );
    return models.Group(response.data);
  },
  async updateGroup(group: T.Group) {
    const request = dtos.UpdateGroupRequestDto(group);
    const response = await proxy.updateGroup(
      group.context,
      group.contextValue,
      group.id,
      request,
    );
    return models.Group(response.data);
  },
};
