import { T } from '../../types';

export const CompositionListSearchRequestDto = (
  model: T.CompositionListSearchRequest,
): T.CompositionListSearchRequestDto => {
  return {
    assignment: model.assignment,
    authgroup: model.authgroup,
    npo: model.npo,
    onlyWithCandidate: model.withCandidate,
    page: model.page,
    pageSize: model.pageSize,
    sortBy: model.sortBy,
    sortOrder: model.sortOrder,
    title: model.title,
  };
};
