import {
  PublicationType,
  OwnershipType,
  ActiveMode,
  ReviewType,
} from '@/objects/enums';
import { Services } from '@/services';
import { T } from '@/types';
import { PublicationActions } from './actions.types';

export const publicationActions: PublicationActions = {
  async correctActivePublication({ rootState, dispatch }, payload) {
    try {
      const compositionId =
        rootState.publication.activePublication!.publication.compositionId;
      const publicationId =
        rootState.publication.activePublication!.publication.id;

      await Services.Publication.correctPublication(
        publicationId,
        compositionId,
        payload,
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när korrigeringen skulle publiceras. Kontrollera din data och försök igen.',
        },
        { root: true },
      );
    }
  },
  async correctPublicationContent({ commit, dispatch }, payload) {
    try {
      commit('CORRECT_PUBLICATION_CONTENT', payload);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte uppdatera textfältet. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async correctPublicationTitle({ commit, dispatch }, modifiedTitle: string) {
    try {
      commit('CORRECT_PUBLICATION_TITLE', modifiedTitle);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte uppdatera textfältet. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async createPublication(
    { dispatch, commit },
    payload: T.CreatePublicationPayload,
  ) {
    try {
      const res = await Services.Publication.createPublication(payload);

      commit('composition/SET_COMP_PUBLICATION', res, {
        root: true,
      });
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när publiceringen skulle publiceras. Kontrollera din data och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationBasedOnExisting(
    { dispatch },
    payload: T.CreatePublicationPayload,
  ) {
    try {
      const res = await Services.Publication.createPublicationBasedOnExisting(
        payload,
      );
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid publicering av tillägg. Kontrollera din data och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationCandidate({ dispatch }, payload) {
    try {
      const res = await Services.Publication.createPublicationCandidate(
        payload,
      );
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        'Publiceringskandidat skapad!',
        { root: true },
      );
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när publiceringskandidaten skulle skapas. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationCandidateBasedOnExisting({ dispatch }, payload) {
    try {
      const res =
        await Services.Publication.createPublicationCandidateBasedOnExisting(
          payload.compositionId,
          payload.settingsByAspect,
        );
      return res;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när publiceringskandidate skulle uppdateras. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationCandidatePreview({ commit, dispatch }, compositionId) {
    try {
      const publicationCandidatePreview =
        await Services.Publication.createPublicationCandidatePreview({
          compositionId,
        });
      commit('SET_ACTIVE_PUBLICATION', publicationCandidatePreview);

      return publicationCandidatePreview;
      // eslint-disable-next-line
    } catch (error: any) {
      const errorData = error?.data as T.ApiErrorData | undefined;
      // Handle custom error dialogs
      if (errorData?.type === 'VALIDATION_UNMARKED_CONTAINEROBJECTS') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {},
            view: 'PublicationErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      if (errorData?.type === 'VALIDATION_ILLEGAL_ASPECTID') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {
              aspectIds: errorData.details,
            },
            view: 'PublicationRegionalAspectErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av förhandsvisning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationCandidatePreviewBasedOnExisting(
    { commit, dispatch },
    payload,
  ) {
    const { compositionId, latest } = payload;
    try {
      const publicationCandidatePreview =
        await Services.Publication.createPublicationCandidatesPreviewBasedOnExisting(
          {
            compositionId,
            latest,
          },
        );

      commit('SET_ACTIVE_PUBLICATION', publicationCandidatePreview);

      return publicationCandidatePreview;
      // eslint-disable-next-line
    } catch (error: any) {
      const errorData = error?.data as T.ApiErrorData | undefined;
      // Handle custom error dialogs
      if (errorData?.type === 'VALIDATION_UNMARKED_CONTAINEROBJECTS') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {},
            view: 'PublicationErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      if (errorData?.type === 'VALIDATION_ILLEGAL_ASPECTID') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {
              aspectIds: errorData.details,
            },
            view: 'PublicationRegionalAspectErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av förhandsvisning. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createPublicationPreview({ commit, dispatch }, payload) {
    const { compositionId, latest } = payload;
    try {
      const publicationPreview =
        await Services.Publication.createPublicationPreviewBasedOnExisting({
          compositionId,
          latest,
        });

      commit('SET_ACTIVE_PUBLICATION', publicationPreview);

      return publicationPreview;
      // eslint-disable-next-line
    } catch (error: any) {
      const errorData = error?.data as T.ApiErrorData | undefined;
      // Handle custom error dialogs
      if (errorData?.type === 'VALIDATION_UNMARKED_CONTAINEROBJECTS') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {},
            view: 'PublicationErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      if (errorData?.type === 'VALIDATION_ILLEGAL_ASPECTID') {
        commit(
          'modal/SET_ACTIVE_MODAL',
          {
            props: {
              aspectIds: errorData.details,
            },
            view: 'PublicationRegionalAspectErrorDialog',
          },
          { root: true },
        );
        return null;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av publikationer. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createReviewPublication({ commit, dispatch, rootState }, payload) {
    try {
      let createReviewFunction;
      if (payload.publicationType === PublicationType.NATIONAL_REVIEW) {
        createReviewFunction = Services.Publication.createNationalReview;
      } else if (payload.reviewType === ReviewType.OPINION) {
        createReviewFunction = Services.Publication.createOpinionReview;
      } else {
        createReviewFunction = Services.Publication.createReview;
      }

      const publicationReview = await createReviewFunction({
        compositionId: payload.compositionId,
        defaultAspectId: rootState.publication.selectedAspectsRead[0]?.id,
        title: payload.title ?? undefined,
      });

      commit('ADD_PUBLICATION', publicationReview);
      commit('SET_ACTIVE_PUBLICATION', publicationReview);

      return publicationReview;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapande av publicering. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async createReviewPublicationPreview(
    { dispatch, commit, rootState },
    payload,
  ) {
    try {
      commit('global/SET_ACTIVE_MODE', ActiveMode.PUBLICATION_PREVIEW, {
        root: true,
      });
      commit('review/CLEAR_REVIEWS', undefined, { root: true });
      let publication;
      if (payload.publicationType === PublicationType.NATIONAL_REVIEW) {
        publication = await Services.Publication.createNationalReviewPreview({
          compositionId: payload.compositionId,
          defaultAspectId: rootState.publication.selectedAspectsRead[0]?.id,
          title: payload.title ?? undefined,
        });
      }
      publication = await Services.Publication.createReviewPreview({
        compositionId: payload.compositionId,
        defaultAspectId: rootState.publication.selectedAspectsRead[0]?.id,
        title: payload.title ?? undefined,
      });

      if (!publication) {
        return null;
      }

      commit('publication/SET_ACTIVE_PUBLICATION', publication, { root: true });
      // await dispatch(
      //   'reference/commitPublicationReferences',
      //   publication.publication,
      //   { root: true },
      // );

      await dispatch(
        'ownership/fetchOwnerships',
        {
          groupTag: payload.compositionId,
        },
        { root: true },
      );
      return publication;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel när förhandsgranskningen skulle skapas. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async deleteRegionalAddons({ dispatch }, payload) {
    try {
      await Services.Publication.deleteRegionalAddons(
        payload.compositionId,
        payload.publicationId,
        payload.body,
      );
      return true;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av regionala tillägg. Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return false;
    }
  },
  async fetchAndLoadLatestPublication({ dispatch, commit }, compositionId) {
    try {
      // Clear state before fetching
      commit('SET_ACTIVE_PUBLICATION', null);
      commit('SET_SELECTED_ASPECTS_READ', []);
      const publication = await Services.Publication.getLatestPublication(
        compositionId,
      );
      if (publication) {
        commit('SET_ACTIVE_PUBLICATION', publication);
      }
      return publication;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av senaste publiceringen. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchAndLoadPublication({ commit, dispatch }, publicationId) {
    // Clear state before fetching
    commit('SET_ACTIVE_PUBLICATION', null);
    commit('SET_SELECTED_ASPECTS_READ', []);

    const publication = await dispatch('fetchPublication', publicationId);
    if (publication) {
      commit('SET_ACTIVE_PUBLICATION', publication);
      // await dispatch(
      //   'reference/commitPublicationReferences',
      //   publication.publication,
      //   { root: true },
      // );
    }
    return publication;
  },
  async fetchAndLoadPublications({ commit, dispatch }, payload) {
    const publicationReviews = await dispatch('fetchPublications', payload);
    commit('SET_PUBLICATIONS', publicationReviews);
    return publicationReviews;
  },
  async fetchLatestPublicationMetadata({ dispatch }, compositionId) {
    try {
      return await Services.Publication.getLatestPublicationMetadata(
        compositionId,
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av senaste publiceringen. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchPublication({ dispatch }, publicationId) {
    try {
      return await Services.Publication.getPublication(publicationId);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av publiceringen. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchPublicationMetadata(_, publicationId) {
    return await Services.Publication.getPublicationMetadata(publicationId);
  },
  async fetchPublications({ dispatch }, payload) {
    const { compositionId, publicationType } = payload;
    try {
      const publications = await Services.Publication.getPublications(
        compositionId,
        publicationType,
      );
      const publicationReviews = publications.map(
        (publication): T.PublicationReview => {
          return {
            containerObjects: {},
            containers: [],
            content: {},
            metadata: [],
            publication,
            references: {},
          };
        },
      );

      return publicationReviews;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av publikationer. Försök igen senare.',
        },
        { root: true },
      );
      return [];
    }
  },
  async pausePublication({ dispatch, commit }, payload) {
    try {
      const updatedPublication = await Services.Publication.pausePublication(
        payload.compositionId,
        payload.reason,
      );
      commit('composition/SET_COMP_PUBLICATION', updatedPublication, {
        root: true,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid pausningen av publiceringen. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async publishScheduledPublication({ dispatch }, publicationId) {
    try {
      await Services.Publication.publishScheduledPublication(publicationId);
      return true;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid publicering av publiceringskandidaten. Försök igen senare.',
        },
        { root: true },
      );
      return false;
    }
  },
  async publishedPublicationExists({ dispatch }, payload) {
    const { compositionId, assignment } = payload;
    try {
      return await Services.Publication.getPublicationExists(
        compositionId,
        assignment,
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inhämtning av tidigare publiceringar. Försök igen senare.',
        },
        { root: true },
      );
      return null;
    }
  },
  async rejectPublicationCandidate({ dispatch }, { comment, publicationId }) {
    try {
      await Services.Publication.rejectPublicationCandidate(
        publicationId,
        comment,
      );
      dispatch(
        'systemMessage/addSystemSuccessMessageToQueue',
        'Publiceringskandidat avvisad.',
        { root: true },
      );
      return true;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagande av publiceringskandidaten. Försök igen senare.',
        },
        { root: true },
      );
      return false;
    }
  },
  async reschedulePublication({ dispatch }, payload) {
    try {
      await Services.Publication.reschedulePublication(payload);
      return true;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid publicering av publiceringskandidaten. Försök igen senare.',
        },
        { root: true },
      );
      return false;
    }
  },

  async updateContainerObjectStatus({ dispatch, state }, payload) {
    if (!state.activePublication) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Kunde inte hitta en aktiv publicering. Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return;
    }
    try {
      const contentType =
        state.activePublication?.content[payload.contentId]?.type;
      await dispatch(
        'ownership/lockContent',
        {
          contentId: payload.contentId,
          contentType:
            contentType === 'PROCESS'
              ? OwnershipType.PROCESS
              : OwnershipType.CONTENT,
        },
        {
          root: true,
        },
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte uppdatera blockets status. Blocket är låst av någon annan.',
        },
        { root: true },
      );
      return;
    }

    try {
      await Services.Composition.bulkUpdateContainerObjectStatus(
        state.activePublication.publication.compositionId,
        [
          {
            newStatus: payload.newStatus,
            objectId: payload.contentId,
          },
        ],
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte uppdatera blockets status. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return;
    }

    await dispatch(
      'ownership/lockContent',
      { contentId: payload.contentId },
      { root: true },
    );
  },
};
