import { T } from '@/types';
import { ReceiverInfo } from './ReceiverInfo';

export const Upvote = (dto: T.UpVoteDto): T.Upvote => {
  return {
    created: dto.created,
    id: dto.id,
    objectId: dto.objectId,
    receiverId: dto.receiverId,
    receiverInfo: ReceiverInfo(dto.receiverInfo),
    title: dto.title,
    versionKey: dto.versionKey,
  };
};
