import { T } from '@/types';

export const SimpleAuthorizationGroup = (
  dto: T.SimpleAuthorizationGroupDto,
): T.SimpleAuthorizationGroup => {
  return {
    context: dto.context,
    displayName: dto.displayName,
    name: dto.name,
  };
};
