import { WindowUnloadHelper } from '@/core/helpers';
import { ContentConverter, ContentCreator } from '@/core/objects/content';
import {
  ContentType,
  ObjectType,
  OwnershipType,
  StatusType,
} from '@/objects/enums';
import {
  LockedByOtherUserError,
  NoLockError,
} from '@/objects/exceptions/ApiExceptions';
import * as models from '@/objects/models';
import { Services } from '@/services';
import { T } from '@/types';
import { ContainerObjectCreator } from '../../core/objects/content/container-object-creator';
import { CompositionUtils } from '../composition/utils';
import { ContentActions } from './actions.types';

const MAX_RETRY_ATTEMPTS = 3;

export const contentActions: ContentActions = {
  async bulkUpdateStatus({ commit, rootState, dispatch }, payload) {
    // Filter content based on type
    const filteredContents = payload.contentIds.reduce(
      (
        allIds: {
          processBlockContent: T.MultiLockPayload[];
          content: T.MultiLockPayload[];
        },
        contentId,
      ) => {
        const type =
          rootState.content.contents[contentId]?.type === ContentType.PROCESS
            ? OwnershipType.PROCESS
            : OwnershipType.CONTENT;

        if (type === OwnershipType.PROCESS) {
          allIds.processBlockContent.push({
            objectId: contentId,
            objectType: type,
          });
          return allIds;
        }

        allIds.content.push({
          objectId: contentId,
          objectType: type,
        });
        return allIds;
      },
      { content: [], processBlockContent: [] },
    );
    await dispatch(
      'ownership/lockMultipleContent',
      filteredContents.content.concat(filteredContents.processBlockContent),
      {
        root: true,
      },
    );

    try {
      const processBlockPromise = async () => {
        return await Services.ProcessBlock.updateProcessBlockStatus({
          ids: filteredContents.processBlockContent.map(
            (content) => content.objectId,
          ),
          status: payload.newStatus,
        });
      };

      const contentPromise = async () => {
        return await Services.Content.bulkUpdateStatus(
          filteredContents.content.map((content) => content.objectId),
          payload.newStatus,
        );
      };

      const [updatedContent, updatedProcessBlocks] = await Promise.all([
        filteredContents.content.length > 0 ? contentPromise() : null,
        filteredContents.processBlockContent.length > 0
          ? processBlockPromise()
          : null,
      ]);

      const containerObjectPayload = payload.contentIds.map(
        (containerObjectId) => {
          return {
            newStatus: payload.newStatus,
            objectId: containerObjectId,
          };
        },
      );

      await dispatch(
        'composition/bulkUpdateContainerObjectStatus',
        containerObjectPayload,
        {
          root: true,
        },
      );

      if (updatedProcessBlocks) {
        // Refetch updated data
        dispatch('fetchContents', {
          contentIds: updatedProcessBlocks.patchedIds,
          contentType: ContentType.PROCESS,
        });
      }

      if (updatedContent) {
        commit('UPDATE_CONTENTS', updatedContent.successfullyUpdatedContents);
      }

      if (payload.newStatus === StatusType.LEVEL_3) {
        commit('composition/SET_LEVEL_3_CONTENT_EXISTS', true, { root: true });
      }

      if (
        (updatedContent && updatedContent?.failedContents?.length > 0) ||
        (updatedProcessBlocks &&
          updatedProcessBlocks?.failedProcesses.length > 0)
      ) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error: `Failed to update status. Failed contents: ${JSON.stringify(
              {
                content: updatedContent?.failedContents,
                processBlocks: updatedProcessBlocks?.failedProcesses,
              },
              null,
              2,
            )}`,
            message:
              'Misslyckades att uppdatera status. Vänligen ladda om sidan och försök igen!',
          },
          { root: true },
        );
        return;
      }
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Misslyckades att uppdatera status. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }

    await dispatch('ownership/releaseMultipleLocks', payload.contentIds, {
      root: true,
    });
  },
  async changeContentType({ commit, dispatch, state }, payload) {
    const { id, meta, type } = payload;

    if (payload.type === ContentType.PROCESS && payload.id) {
      // Update the container object to correct type
      await dispatch(
        'composition/updateContainerObjectType',
        {
          id: payload.id,
          type: ObjectType.PROCESS_BLOCK,
        },
        { root: true },
      );
    }

    const content = state.contents[id];
    if (!content) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Misslyckades att konvertera innehållet. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return;
    }

    const convertedContent = ContentConverter.convertContentToType(
      content,
      meta,
      type,
    );

    commit('UPDATE_CONTENT', convertedContent);
    commit('SET_CONTENT_IS_MODIFIED', convertedContent.id);

    WindowUnloadHelper.preventUnload();
  },
  async createContent({ dispatch, rootState, state }, content) {
    if (!state.focusedContentId) {
      return;
    }

    const contentIndexes = CompositionUtils.getContentIndexes(
      rootState.composition.containers,
      state.focusedContentId,
    );

    if (contentIndexes) {
      const indexes = {
        containerIndex: contentIndexes.containerIndex,
        contentIndex: contentIndexes.contentIndex + 1,
      };

      dispatch('createContentAtIndex', { content, indexes });
    }
  },

  async createContentAtIndex({ commit, rootState }, payload) {
    const aspects = [...rootState.author.selectedAspectsWrite];
    const content = payload.content ?? ContentCreator.createNewText();
    const containerObject = ContainerObjectCreator.createNewContainerObject({
      aspects,
      id: content.id,
      status: content.status,
      type:
        content.type === ContentType.PROCESS
          ? ObjectType.PROCESS_BLOCK
          : ObjectType.CONTENT,
    });

    commit('SET_CONTENT', content);
    commit('SET_CONTENT_IS_MODIFIED', content.id);
    commit('SET_FOCUSED_CONTENT', content.id);
    commit(
      'composition/ADD_CONTAINER_OBJECT_AT_INDEX',
      {
        containerObject,
        indexes: payload.indexes,
      },
      { root: true },
    );

    return content;
  },
  async createProcessBlockAndAddToExisting(
    { dispatch, commit },
    { id, parentId, payload },
  ) {
    try {
      const response =
        await Services.ProcessBlock.createProcessBlockAndAddToExisting(
          id,
          parentId,
          payload,
        );

      const updatedContents = models.ProcessBlockContent(response);
      commit('SET_CONTENT', updatedContents);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid skapandet av ett processblock. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async deleteContent({ commit }, contentId) {
    commit('composition/DELETE_CONTAINEROBJECT', contentId, { root: true });
    commit('DELETE_CONTENT', contentId);
  },
  async deleteProcessBlock({ commit, rootState, dispatch }, payload) {
    try {
      const response = await Services.ProcessBlock.deleteProcessBlock(payload);

      if (response?.state === 'DELETED') {
        const containerObject =
          rootState.composition.containerObjects[payload.rootId];
        // Delete is for the root process block
        if (containerObject) {
          dispatch('composition/deleteContainerObject', containerObject, {
            root: true,
          });
        }
        return;
      }

      commit('UPDATE_PROCESS_PROPERTY', {
        id: payload.rootId,
        key: 'processBlocks',
        value: models.ProcessBlock(response).processBlocks,
      });
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid borttagning av innehållet. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }
  },
  async deleteUnusedContentFromState({ commit, rootState }) {
    const activeContentIds = new Set<string>();

    rootState.composition.containers.forEach((container) =>
      container.containerObjectIds.forEach((contentId) => {
        activeContentIds.add(contentId);
      }),
    );

    Object.keys(rootState.content.contents).forEach((contentId) => {
      if (!activeContentIds.has(contentId)) {
        commit('DELETE_CONTENT', contentId);
      }
    });
  },
  async fetchContents({ commit, dispatch }, payload) {
    try {
      const { contentIds, contentType } = payload;
      let contents;
      if (contentType === ContentType.PROCESS) {
        const processBlocks = await Services.ProcessBlock.fetchProcessBlocks(
          contentIds,
        );
        contents = processBlocks.map(models.ProcessBlockContent);
      } else {
        contents = await Services.Content.getContents(contentIds);
      }

      commit('UPDATE_CONTENTS', contents);

      return contents;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid inladdningen av innehållet. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return [];
    }
  },
  async fetchProcessBlock({ dispatch }, { id }) {
    try {
      const response = await Services.ProcessBlock.fetchProcessBlock(id);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid hämtningen av processblocket. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async fetchProcessBlockHistory({ dispatch }, payload) {
    const { id, index, pageSize } = payload;
    let history: T.SimpleProcessBlock[] = [];
    try {
      history = await Services.ProcessBlock.fetchProcessBlockHistory(
        id,
        index,
        pageSize,
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte hämta historik för innehållsdel, försök igen senare.',
        },
        { root: true },
      );
    }
    return history;
  },
  async getCommandFromEditorCommandQueue({ state, commit }) {
    if (!state.editorCommandQueue[0]) {
      return null;
    }
    const commandQueueItem = state.editorCommandQueue[0];
    commit('REMOVE_NEXT_EDITOR_COMMAND', undefined);
    return commandQueueItem;
  },
  async getContentHistory({ dispatch }, payload) {
    const { id, index, pageSize } = payload;
    let history: T.Content[] = [];
    try {
      history = await Services.Content.getContentHistory(id, index, pageSize);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Kunde inte hämta historik för innehållsstycke, försök igen senare!',
        },
        { root: true },
      );
    }
    return history;
  },
  async moveChildProcessBlock({ dispatch, commit }, { id, sourceId, payload }) {
    try {
      const response = await Services.ProcessBlock.moveChildProcessBlock(
        id,
        sourceId,
        payload,
      );
      const parsedResponse = models.ProcessBlockContent(response);
      commit('SET_CONTENT', parsedResponse);
      commit('SET_CONTENT_IS_UNMODIFIED', response.id);
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid flyttning av ett processblock. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async patchChildProcessBlock({ commit, dispatch }, { id, childId, payload }) {
    try {
      const response = await Services.ProcessBlock.patchChildProcessblock(
        id,
        childId,
        payload,
      );
      const parsedResponse = models.ProcessBlockContent(response);
      commit('SET_CONTENT', parsedResponse);
      commit('SET_CONTENT_IS_UNMODIFIED', childId);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdateringen av ett processblock. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
    }
  },
  async queueEditorCommand({ state, commit }, commandQueueItem) {
    if (!state.focusedContentId) {
      return false;
    }
    commit('QUEUE_EDITOR_COMMAND', commandQueueItem);
    return true;
  },
  async saveNewContent({ commit, dispatch, rootState }, payload) {
    if (!rootState.composition.activeComposition) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Innehållet kunde inte sparas. Inget aktivt kunskapsstöd kunde hittas.',
        },
        { root: true },
      );
      return null;
    }

    try {
      commit('global/SET_PREVENT_NAVIGATION', true, { root: true });
      const compositionId = rootState.composition.activeComposition.id;

      let response;
      if (payload.content.type === ContentType.PROCESS) {
        const data = await Services.ProcessBlock.createProcessBlock(
          {
            ...payload.content.data,
            id: payload.containerObject.id,
          },
          compositionId,
        );
        response = models.ProcessBlockContent(data);
      } else {
        const createContentPayload = models.CreateContentWithIdPayload(
          payload.content,
        );
        response = await Services.Content.createContent(
          compositionId,
          createContentPayload,
        );
      }

      await dispatch('ownership/fetchOwnershipForObject', response.id, {
        root: true,
      });
      commit('SET_CONTENT', response);

      commit(
        'author/REPLACE_EXPANDED_BLOCK',
        { newId: response.id, oldId: payload.content.id },
        { root: true },
      );
      commit('SET_CONTENT_IS_UNMODIFIED', response.id);

      await dispatch(
        'composition/updateContainer',
        { containerObject: payload.containerObject },
        { root: true },
      );

      WindowUnloadHelper.allowUnload();
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Innehållet kunde inte sparas. Ladda om sidan och testa igen.',
        },
        { root: true },
      );
      return null;
    } finally {
      commit('global/SET_PREVENT_NAVIGATION', false, { root: true });
    }
  },
  async updateAttachmentProperty({ commit }, payload) {
    commit('UPDATE_ATTACHMENT_PROPERTY', payload);

    WindowUnloadHelper.preventUnload();
  },
  async updateContent(
    { commit, dispatch },
    { content, retryAttemptCount = 0 },
  ) {
    try {
      let response;
      if (content.type === ContentType.PROCESS) {
        const data = await Services.ProcessBlock.updateProcessBlock(
          content.id,
          content.data,
        );
        response = models.ProcessBlockContent(data);
      } else {
        const payload = models.CreateContentWithIdPayload(content);
        response = await Services.Content.updateContent(payload.id, payload);
      }

      commit('SET_CONTENT', response);
      commit('SET_CONTENT_IS_UNMODIFIED', response.id);

      WindowUnloadHelper.allowUnload();
    } catch (error) {
      if (
        error instanceof NoLockError &&
        retryAttemptCount < MAX_RETRY_ATTEMPTS
      ) {
        console.error('Content is missing lock, locking and retrying...');
        await dispatch(
          'ownership/lockContent',
          {
            contentId: content.id,
            contentType:
              content.type === ContentType.PROCESS
                ? OwnershipType.PROCESS
                : OwnershipType.CONTENT,
          },
          { root: true },
        );
        await dispatch('updateContent', {
          content,
          retryAttemptCount: retryAttemptCount + 1,
        });
      } else if (error instanceof LockedByOtherUserError) {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Blocket är låst av en annan användare. Det går inte att spara blocket.',
          },
          { root: true },
        );
      } else {
        dispatch(
          'systemMessage/addSystemErrorMessageToQueue',
          {
            error,
            message:
              'Något gick fel vid sparandet. Vänligen ladda om sidan och försök igen.',
          },
          { root: true },
        );
      }
    }
  },
  async updateHeader({ commit }, payload) {
    commit('UPDATE_HEADER', payload);

    WindowUnloadHelper.preventUnload();
  },
  async updateListData({ commit, dispatch }, payload) {
    try {
      commit('UPDATE_LIST_DATA', payload);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte uppdatera listan. Försök igen senare.',
        },
        { root: true },
      );
    }
    WindowUnloadHelper.preventUnload();
  },
  async updatePictureProperty({ commit }, payload) {
    commit('UPDATE_PICTURE_PROPERTY', payload);

    WindowUnloadHelper.preventUnload();
  },
  async updateProcessBlock({ dispatch }, { id, payload }) {
    try {
      const response = await Services.ProcessBlock.updateProcessBlock(
        id,
        payload,
      );
      return response;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Något gick fel vid uppdateringen av ett processblock. Vänligen ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return null;
    }
  },
  async updateReferenceProperty({ commit }, payload) {
    commit('UPDATE_REFERENCE_PROPERTY', payload);

    WindowUnloadHelper.preventUnload();
  },
  async updateStatus({ commit, dispatch, rootState }, payload) {
    if (!rootState.composition.activeComposition) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Något gick fel vid sparandet. Innehållet lästes inte in korrekt. ' +
            'Ladda om sidan och försök igen.',
        },
        { root: true },
      );
      return;
    }

    try {
      if (payload.content.type === ContentType.PROCESS) {
        const updatedProcessBlocks =
          await Services.ProcessBlock.updateProcessBlockStatus({
            ids: [payload.content.id],
            status: payload.newStatus,
          });

        // Fetch updated information for processBlock
        dispatch('fetchContents', {
          contentIds: [payload.content.id],
          contentType: ContentType.PROCESS,
        });

        if (updatedProcessBlocks.failedProcesses.length > 0) {
          dispatch(
            'systemMessage/addSystemErrorMessageToQueue',
            {
              error: `Failed to update status. Failed processBlocks: ${JSON.stringify(
                updatedProcessBlocks.failedProcesses,
                null,
                2,
              )}`,
              message:
                'Misslyckades att uppdatera status. Vänligen ladda om sidan och försök igen!',
            },
            { root: true },
          );
          return;
        }
      } else {
        const response = await Services.Content.updateStatus(
          payload.content.id,
          payload.newStatus,
        );
        commit('SET_CONTENT', response);
      }

      await dispatch(
        'composition/bulkUpdateContainerObjectStatus',
        [{ newStatus: payload.newStatus, objectId: payload.content.id }],
        { root: true },
      );
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Något gick fel vid sparandet. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async updateTableData({ commit, dispatch }, payload) {
    try {
      commit('UPDATE_TABLE_DATA', payload);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte uppdatera tabellen. Försök igen senare.',
        },
        { root: true },
      );
    }
    WindowUnloadHelper.preventUnload();
  },
  async updateText({ commit, dispatch }, payload) {
    try {
      commit('UPDATE_TEXT', payload);
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte uppdatera textfältet. Försök igen senare.',
        },
        { root: true },
      );
    }
    WindowUnloadHelper.preventUnload();
  },
};
