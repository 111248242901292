import { T } from '@/types';

export const AspectChecker = {
  isAnyAspectInList(aspects: T.Aspect[], collection: T.Aspect[]) {
    if (!aspects) {
      return false;
    }

    const isBothEmptyOrUndefined =
      (!aspects || aspects.length === 0) &&
      (!collection || collection.length === 0);
    return (
      isBothEmptyOrUndefined ||
      aspects.some((aspect) => {
        return collection.some((aspectInCollection) => {
          return aspectInCollection.id === aspect.id;
        });
      })
    );
  },
  isWriteAspectsInTemplate(aspectsWrite: T.Aspect[], template: T.Template) {
    const { validAspects } = template;

    if (validAspects == null || aspectsWrite == null) {
      return false;
    }

    validAspects.sort();
    aspectsWrite.sort();

    for (let i = 0; i < aspectsWrite.length; i += 1) {
      if (!validAspects.some((a) => a.id === aspectsWrite[i]?.id)) {
        return false;
      }
    }

    return true;
  },
};
