import { T } from '@/types';

export const AuthorizationGroup = (
  dto: T.AuthorizationGroupDto,
): T.AuthorizationGroup => {
  return {
    context: dto.context,
    displayName: dto.displayName,
    id: dto.id,
    members: dto.members,
    name: dto.name,
  };
};
