import { ContextType } from '@/objects/enums';
import { Services } from '@/services';
import { T } from '@/types';
import { UserActions } from './actions.types';

export const userActions: UserActions = {
  async addMemberToGroup({ commit, dispatch }, { group, newMember }) {
    try {
      const updatedGroup = await Services.Group.addMember(
        group.context,
        group.contextValue,
        group.id,
        newMember,
      );
      if (updatedGroup) {
        const groupId = group.id;
        if (group.context === ContextType.PERSONAL) {
          commit('ADD_MEMBER_TO_PERSONAL_GROUP', { groupId, newMember });
        } else if (group.context === ContextType.ASSIGNMENT) {
          commit('ADD_MEMBER_TO_ASSIGNMENT_GROUP', { groupId, newMember });
        } else {
          commit('ADD_MEMBER_TO_CHILD_AUTHORIZATION_GROUP', {
            groupId,
            newMember,
          });
        }
      }
      return updatedGroup;
    } catch (error) {
      const errorObject: { data: { details: string[]; type: string } } =
        error as {
          data: {
            type: string;
            details: string[];
          };
        };
      if (errorObject.data.type === 'VALIDATION') {
        dispatch(
          'systemMessage/addSystemMessageToQueue',
          {
            color: 'warning',
            text: `Mailadressen du angivit (${newMember.email}) är felaktig. Vänligen försök igen med en annan mailadress.`,
            timeout: 7500,
            top: true,
          },
          { root: true },
        );
        return null;
      }
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Någonting gick fel när medlem skulle läggas till grupp. Ladda om sidan och försök igen',
        },
        { root: true },
      );
      return null;
    }
  },
  async createGroup({ commit, dispatch }, newGroup: T.Group) {
    try {
      const savedGroup = await Services.Group.createGroup({
        context: newGroup.context,
        contextValue: newGroup.contextValue,
        description: newGroup.description,
        displayName: newGroup.displayName,
        groupAccessType: newGroup.groupAccessType,
        members: newGroup.members,
        metadata: [],
      });
      switch (savedGroup.context) {
        case ContextType.PERSONAL:
          commit('ADD_PERSONAL_GROUP', savedGroup);
          break;
        case ContextType.ASSIGNMENT:
          commit('ADD_ASSIGNMENT_GROUP', savedGroup);
          break;
        default:
          commit('ADD_CHILD_AUTHORIZATION_GROUP', savedGroup);
          break;
      }
      return savedGroup;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Misslyckades att skapa grupp, ladda om sidan och försök igen',
        },
        { root: true },
      );
      return null;
    }
  },
  async deleteGroup({ commit, dispatch, state }, group) {
    let groupToDelete;
    if (group.context === ContextType.PERSONAL) {
      groupToDelete = state.personalGroups.find(
        (pGroup) => pGroup.id === group.id,
      );
    }

    if (group.context === ContextType.AUTHORIZATIONGROUP) {
      groupToDelete = state.childAuthorizationGroups
        .flatMap((context) => context.groups)
        .find((cGroup) => cGroup.id === group.id);
    }

    if (group.context === ContextType.ASSIGNMENT) {
      groupToDelete = state.assignmentGroups.find(
        (aGroup) => aGroup.id === group.id,
      );
    }

    try {
      if (groupToDelete) {
        const deleteSuccess = await Services.Group.deleteGroup(
          groupToDelete.context,
          groupToDelete.contextValue,
          groupToDelete.id,
        );
        if (deleteSuccess) {
          switch (groupToDelete.context) {
            case ContextType.PERSONAL:
              commit('REMOVE_PERSONAL_GROUP', groupToDelete.id);
              break;
            case ContextType.ASSIGNMENT:
              commit('REMOVE_ASSIGNMENT_GROUP', groupToDelete.id);
              break;
            default:
              commit('REMOVE_AUTHORIZATION_GROUP', groupToDelete.id);
              break;
          }
        }
      }
      return false;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Borttagning av utskicksgrupp misslyckades, försök igen senare',
        },
        { root: true },
      );
      return false;
    }
  },
  async fetchAllAuthorizationGroups({ commit, state }) {
    if (state.allAuthorizationGroups) {
      return state.allAuthorizationGroups;
    }

    const authorizationGroups =
      await Services.Authentication.getAllAuthorizationGroups();

    commit('SET_ALL_AUTHORIZATION_GROUPS', authorizationGroups);

    return authorizationGroups;
  },
  async fetchAssignmentGroups({ commit, state }) {
    if (!state.user?.assignmentName) {
      return null;
    }
    const groups = await Services.Group.getGroupsForContext(
      ContextType.ASSIGNMENT,
      state.user?.assignmentName,
    );

    commit('SET_ASSIGNMENT_GROUPS', groups);

    return groups;
  },
  async fetchAuthorizationGroups({ commit }) {
    const authorizationGroups =
      await Services.Authentication.getAuthorizationGroups();

    commit(
      'SET_AVAILABLE_AUTHORIZATION_GROUPS',
      authorizationGroups.authorizationGroups,
    );

    return authorizationGroups;
  },
  async fetchChildAuthorizationGroups({ commit, dispatch }) {
    const authorizationGroups = (
      await dispatch('fetchAuthorizationGroups', undefined)
    )?.authorizationGroups;

    const contexts = authorizationGroups?.map((group): T.GetContextPayload => {
      return {
        context: ContextType.AUTHORIZATIONGROUP,
        contextValue: `${group.context}.${group.name}`,
      };
    });

    if (!contexts) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message:
            'Hämtningen av behörighetsgrupper misslyckades, försök igen senare',
        },
        { root: true },
      );
      return [];
    }

    const groupsForContexts = await Services.Group.getGroupsForContexts(
      contexts,
    );

    commit('SET_CHILD_AUTHORIZATION_GROUPS', groupsForContexts);

    return groupsForContexts;
  },
  async fetchPersonalGroups({ commit, state }) {
    const groups = await Services.Group.getGroupsForContext(
      ContextType.PERSONAL,
      state.user!.email.toLowerCase(),
    );

    commit('SET_PERSONAL_GROUPS', groups);

    return groups;
  },
  async fetchPublicGroups({ commit }) {
    const groups = await Services.Group.getPublicGroups();

    commit('SET_PUBLIC_GROUPS', groups);

    return groups;
  },
  async fetchResourcesForGroup({ commit }, group) {
    const resources = await Services.Authentication.getResourcesForGroup(group);

    commit('SET_RESOURCES', resources);

    return resources;
  },
  async fetchUserFromTicket({ commit, state }, ticket) {
    if (state.user && state.user.ticket === ticket) {
      return state.user;
    }

    const user = await Services.Authentication.getTicketInfo({ ticket });

    commit('SET_USER', user);

    return user;
  },
  async populateActiveAuthorizationGroup(
    { commit, dispatch },
    selectedAuthGroup,
  ) {
    const authorizationGroups = await dispatch(
      'fetchAuthorizationGroups',
      undefined,
    );

    if (!authorizationGroups) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message: 'Lyckades inte hämta grupper. Försök igen senare.',
        },
        { root: true },
      );
      return;
    }

    const { authorizationGroups: availableGroups } = authorizationGroups;
    const group =
      availableGroups.find(
        (authGroup) => authGroup.name === selectedAuthGroup.name,
      ) ??
      availableGroups[0] ??
      null;

    if (group) {
      commit('SET_ACTIVE_AUTHORIZATION_GROUP', group);

      await dispatch('fetchResourcesForGroup', group);
    } else {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message: 'Lyckades inte hämta grupper. Försök igen senare.',
        },
        { root: true },
      );
    }
  },
  async populateAuthorizationGroup({ commit, dispatch }) {
    const authorizationGroups = await dispatch(
      'fetchAuthorizationGroups',
      undefined,
    );

    if (!authorizationGroups) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error: null,
          message: 'Lyckades inte hämta grupper. Försök igen senare.',
        },
        { root: true },
      );
      return;
    }

    const { authorizationGroups: availableGroups } = authorizationGroups;
    const group = availableGroups[0];

    if (group) {
      commit('SET_ACTIVE_AUTHORIZATION_GROUP', group);

      await dispatch('fetchResourcesForGroup', group);
    }
  },
  async removeMemberFromGroup({ commit, dispatch }, { group, member }) {
    try {
      const result = await Services.Group.removeMember(
        group.context,
        group.contextValue,
        group.id,
        member.email,
      );
      if (result) {
        const groupId = group.id;
        if (group.context === ContextType.PERSONAL) {
          commit('REMOVE_MEMBER_FROM_PERSONAL_GROUP', {
            groupId,
            memberUuid: member.uuid,
          });
        } else if (group.context === ContextType.ASSIGNMENT) {
          commit('REMOVE_MEMBER_FROM_ASSIGNMENT_GROUP', {
            groupId,
            memberUuid: member.uuid,
          });
        } else {
          commit('REMOVE_MEMBER_FROM_CHILD_AUTHORIZATION_GROUP', {
            groupId,
            memberUuid: member.uuid,
          });
        }
      }
      return result;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message:
            'Misslyckades att ta bort medlem från grupp. Ladda om sidan och försök igen',
        },
        { root: true },
      );
      return false;
    }
  },
  async saveGroup({ commit, dispatch }, group) {
    try {
      const savedGroup = await Services.Group.updateGroup(group);
      if (savedGroup.context === ContextType.PERSONAL) {
        commit('UPDATE_PERSONAL_GROUP', savedGroup);
      } else if (savedGroup.context === ContextType.ASSIGNMENT) {
        commit('UPDATE_ASSIGNMENT_GROUP', savedGroup);
      } else {
        commit('UPDATE_CHILD_AUTHORIZATION_GROUP', savedGroup);
      }
      return savedGroup;
    } catch (error) {
      dispatch(
        'systemMessage/addSystemErrorMessageToQueue',
        {
          error,
          message: 'Kunde inte spara gruppen, ladda om sidan och försök igen',
        },
        { root: true },
      );
      return null;
    }
  },
  async setActiveAuthGroup({ commit, dispatch }, group) {
    commit('SET_ACTIVE_AUTHORIZATION_GROUP', group);
    if (group !== null) {
      dispatch('fetchResourcesForGroup', group);
    }
  },
  async setActiveAuthGroupFromComposition(
    { dispatch, rootState },
    compositionId,
  ) {
    const userAuthGroups = rootState.user.availableAuthorizationGroups;
    const compositionAuthGroups = rootState.ownership.ownerships[compositionId];
    if (compositionAuthGroups) {
      const activeGroup = userAuthGroups.find((group) => {
        const sectionId = `${group.context}.${group.name}`;
        return compositionAuthGroups?.find(
          (compositionGroup) => compositionGroup.sectionId === sectionId,
        );
      });
      if (activeGroup) {
        await dispatch('setActiveAuthGroup', activeGroup);
      }
    }
  },
};
