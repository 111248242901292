import { authorActions } from './actions';
import { authorGetters } from './getters';
import { authorMutations } from './mutations';
import { authorState } from './state';

export const authorStore = {
  actions: authorActions,
  getters: authorGetters,
  mutations: authorMutations,
  namespaced: true,
  state: authorState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
export * from './utils';
