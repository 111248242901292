import { T } from '@/types';

export const AspectSpecificCompositionPlanningObjectDto = (
  model: T.AspectSpecificCompositionPlanningObject,
): T.AspectSpecificCompositionPlanningObjectDto => {
  return {
    aspectId: model.aspectId,
    plannedDateForNationalReview: model.plannedDateForNationalReview,
  };
};
