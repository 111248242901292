import { systemMessageActions } from './actions';
import { systemMessageGetters } from './getters';
import { systemMessageMutations } from './mutations';
import { systemMessageState } from './state';

export const systemMessageStore = {
  actions: systemMessageActions,
  getters: systemMessageGetters,
  mutations: systemMessageMutations,
  namespaced: true,
  state: systemMessageState,
};

export * from './actions';
export * from './actions.types';
export * from './getters';
export * from './mutations';
export * from './state';
