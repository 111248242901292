import _ from 'lodash';
import { MetadataLink } from '@/objects/models/MetadataLink';
import { T } from '@/types';
import { SubjectGroup } from '.';
import { MetadataMarkup } from './MetadataMarkup';

export const Metadata = (dto: T.MetadataDto): T.Metadata => {
  return {
    aspectId: dto.aspectId,
    aspectName: dto.aspectName,
    assignment: dto.assignment,
    assignmentDisplayName: dto.assignmentDisplayName,
    authors: _.uniq(dto.authors),
    comments: dto.comments,
    commitNumber: dto.commitNumber,
    contact: dto.contact ? MetadataLink(dto.contact) : null,
    created: dto.created,
    createdBy: dto.createdBy,
    createdHr: dto.createdHr,
    deleted: dto.deleted,
    id: dto.id,
    markup: MetadataMarkup(dto.markup),
    medicalApproved: dto.medicalApproved,
    medicalApprovedHr: dto.medicalApprovedHr,
    nationalSender: dto.nationalSender,
    published: dto.published,
    publishedHr: dto.publishedHr,
    responsible: dto.responsible.map(MetadataLink),
    reviewers: _.uniq(dto.reviewers),
    revised: dto.revised,
    revisedHr: dto.revisedHr,
    subjectGroups: dto.subjectGroups.map(SubjectGroup),
    versionKey: dto.versionKey,
    versionNumber: dto.versionNumber,
  };
};
